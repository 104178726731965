<template>
  <v-card>
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mb-4"
        :cancel-text="$t('base.back')"
        :saveDisabled="!isDirty"
        :showSave="hasUserRight('manageBaseData')"
        @save="save()"
        @cancel="$router.back()"
      />
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.dealerKey"
            :label="$t('condAdmin.dealer.dealerKey') + $t('base.mandatory')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.name"
            :label="$t('base.contact.name') + $t('base.mandatory')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.street"
            :label="$t('base.contact.street')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.zipCode"
            :label="$t('base.contact.zipCode')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.city"
            :label="$t('base.contact.city')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.country"
            :label="$t('base.contact.country')"
            @change="setDirty"
          />
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.phone"
            :label="$t('base.contact.phone')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.email"
            :label="$t('base.contact.email')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.fax"
            :label="$t('base.contact.fax')"
            @change="setDirty"
          />
          <v-text-field
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.additionalData"
            :label="$t('base.contact.additionalData')"
            @change="setDirty"
          />
          <v-select
            :readonly="!hasUserRight('manageBaseData')"
            v-model="dealer.conditionMatch"
            :items="conditionMatchTypes"
            item-text="text"
            item-value="key"
            :label="$t('condAdmin.dealer.conditionMatch')"
            @change="setDirty"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import condAdminMixin from '../js/condAdminMixin.js';
import { copyDealer, saveDealer } from '../js/condAdminDealerHelper.js';

export default {
  name: 'DealerDetail',
  components: {
    SaveOnLeaveDlg,
    SaveCancelButtonBar
  },
  mixins: [ condAdminMixin ],
  props: {
    dealerId: { type: String, default: () => { return null; } }
  },
  data () {
    return {
      dealer: copyDealer(),
      isDirty: false,
      conditionMatchTypes: [
        { key: 'bI', text: this.$t('condAdmin.dealer.conditionMatch_bI') },
        { key: 'bM', text: this.$t('condAdmin.dealer.conditionMatch_bM') }
      ]
    };
  },
  watch: {
    dealerId () {
      this.init();
    }
  },
  async mounted () {
    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    next();
  },
  methods: {
    init () {
      if (this.dealerId != null) {
        this.dealer = copyDealer(this.$store.state.condAdmin.dealerList.find((p) => p.id === Number(this.dealerId)));
        this.$emit('setPageMode', 'edit', this.dealer.name);
      } else {
        this.$emit('setPageMode', 'create');
      }
    },
    async save () {
      let state = await saveDealer(this.dealer);
      this.isDirty = !state;
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
