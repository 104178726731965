<template>
  <!--<v-row
    no-gutters
    class="overline py-2"
  >-->
  <v-row
    class="py-0 px-3"
    :class="fontStyle"
  >
    <div
      class="mr-3"
      v-if="author"
    >
      <nobr class="font-weight-bold text-uppercase mr-4">{{ author }}</nobr>
    </div>
    <div
      v-if="createdOn"
      class="itemInfoStripItem"
      :class="createdOn ? (placedOnTile ? 'mr-3' : 'mr-xl-9 mr-lg-9 mr-md-6 mr-sm-4 mr-2') : ''"
    >
      <nobr>
        {{$t('forum.createdOn')}}<v-icon
          small
          color="secondary"
          class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2 ml-1 mr-xl-2 mr-lg-2 mr-md-2 mr-sm-2 mr-1 itemInfoStripItemIcon"
        >mdi-calendar-month-outline</v-icon>{{ createdOn | formatDateSocialMedia }}
      </nobr>
    </div>
    <div
      v-for="(att, i) in attributes"
      :key="i"
      class="itemInfoStripItem"
      :class="divider.length > 0 ? 'ml-1' : (i === (attributes.length - 1) ? 'mr-0' : (placedOnTile ? 'mr-3' : 'mr-xl-9 mr-lg-9 mr-md-6 mr-sm-4 mr-2'))"
    >
      <nobr>
        <span>
          {{ (i > 0 || createdOn ? divider : '') }}
          <v-icon
            v-if="att.prependIcon"
            small
            :color="att.prependIcon.color"
            class="itemInfoStripItemIcon"
          >{{ att.prependIcon.icon }}</v-icon>
          {{ getInfoString(att, i) }}
          <v-icon
            v-if="att.appendIcon"
            small
            :color="att.appendIcon.color"
            class="itemInfoStripItemIcon"
          >{{ att.appendIcon.icon }}</v-icon>
        </span>
      </nobr>
    </div>
  </v-row>
  <!--</v-row>-->
</template>

<script lang="js">
export default {
  name: 'ItemInfoStrip',
  props: {
    author: { type: String, default: null },
    createdOn: { type: String, default: null },
    attributes: { type: Array, default: () => { return []; } },
    fontStyle: { type: String, default: 'overline' },
    divider: { type: String, default: '| ' },
    placedOnTile: { type: Boolean, default: false }
    // attribute = {
    //   mode - 'vt' => 5 Texts; 'tv' => Texts: 5; 'text' => predefinedText
    //   title - string used on mode vt or tv
    //   value - any
    //   prependIcon: { icon: string, color: string}
    //   appendIcon: { icon: string, color: string}
    // }
  },
  computed: {
    /*
    createdString () {
      return this.$t('forum.createdOn') + ' ' + this.$options.filters.formatDateSocialMedia(this.createdOn) + ' ';
    }
    */
  },
  methods: {
    getInfoString (att, i) {
      if (typeof att.mode !== 'string') att.mode = 'vt';
      let str = '';
      if (att.mode === 'vt') {
        str += att.value + ' ' + this.$t(att.value === 1 ? att.title + '1' : att.title);
      } else if (att.mode === 'tv') {
        str += this.$t(att.title) + ': ' + att.value;
      } else {
        str += att.value;
      }
      return str;
    }
  }
};
</script>

<style scoped>
.itemInfoStripItem {
  /*line-height: 2rem;*/
  text-transform: none;
}

.itemInfoStripItemIcon {
  vertical-align: text-bottom !important;
}
</style>
