<template>
  <v-card
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="6"
        >
          <v-select
            v-model="additionalProduct.tariffFEName"
            :items="additionalProductNameList"
            item-text="tariffFEName"
            item-value="tariffFEName"
            :label="$t('condAdmin.additionalProduct.tariffName')"
            @change="setDirty"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          lg="3"
          md="3"
          sm="6"
          xs="6"
        >
          <!--
          <EditField
            v-model="additionalProduct.tariffId"
            :label="$t('condAdmin.additionalProduct.tariffId')"
            @change="setDirty"
            hideDetails
          />
          -->
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.back()"
              class="mr-4 prio2"
            >
              {{ $t('base.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!additionalProduct.isDirty"
              @click.native="saveAddProduct()"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="!enableAction('save')"
                  @click="saveAddProduct()"
                >{{ capitalizeString('condAdmin.actions.save') }}</v-list-item>
                <v-list-item
                  :disabled="!enableAction('setCheck')"
                  @click="setCheckState(additionalProduct)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}</v-list-item>
              </v-list>
            </v-menu>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('condAdmin.version') }}</div>
          <div class="">{{ additionalProduct.version }}</div>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('base.state') }} </div>
          <v-chip
            :class="stateClass"
            class=""
          >
            {{ stateText }}
          </v-chip>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validFrom') + ' ' }}
          <DatePicker
            :value="additionalProduct.validFrom"
            :isBegin="true"
            @change="setProperty('validFrom', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validTo') + ' ' }}
          <DatePicker
            :value="additionalProduct.validTo"
            :isEnd="true"
            @change="setProperty('validTo', $event)"
          />
          <!--<span class="text-h6">{{ formatEndDate(condition.validTo) }}</span>-->
        </v-col>
      </v-row>

      <!-- #### additional Data #### -->
      <component
        v-if="additionalProduct.tariffValuesType != null"
        v-show="customComponents.AdditionalProduct != null"
        v-bind:is="customComponents.AdditionalProduct"
        class="pb-4 grey lighten-5"
        ref="customAdditionalProduct"
        :params="additionalProduct || {}"
        :readonly="false"
        :clearable="true"
        @setDirty="setDirty"
      ></component>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { setProperty } from '@/base/js/ObjectHelper';
import condAdminAdditionalProductMixin from '../js/condAdminAdditionalProductMixin.js';
import EditField from '@/common/EditField';
import DatePicker from '@/common/DatePicker';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import { removeLock } from '../js/condAdminHelper.js';

export default {
  name: 'AdditionalProductEditor',
  mixins: [ condAdminAdditionalProductMixin ],
  components: {
    DatePicker,
    EditField,
    SaveOnLeaveDlg
  },
  data () {
    return {
      additionalProduct: this.$store.state.condAdmin.workingAdditionalProduct === null ? {} : JSON.parse(JSON.stringify(this.$store.state.condAdmin.workingAdditionalProduct)),
      customComponents: {},
      locked: true
    };
  },
  computed: {
    stateClass () {
      return this.getStateClass(this.additionalProduct, false);
    },
    stateText () {
      return this.getStateText(this.additionalProduct, false);
    },
    versionProductList () {
      let list = [];
      list.push(this.additionalProduct);

      return list.sort();
    },
    additionalProductNameList () {
      let list = [];
      list.push(this.additionalProduct);
      return list.sort();
    }
  },
  async mounted () {
    if (this.$store.state.condAdmin.additionalProductList == null) await this.refreshAdditionalProductList();
    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.additionalProduct != null && this.additionalProduct.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    if (this.additionalProduct != null && this.locked === true) {
      await removeLock('additionalProduct', this.additionalProduct.uid, this.additionalProduct.version);
    }
    next();
  },
  methods: {
    async init () {
      this.additionalProduct = this.$store.state.condAdmin.workingAdditionalProduct;

      if (this.additionalProduct != null) {
        this.$emit('setPageMode', this.additionalProduct.workingMode, this.additionalProduct.tariffName);
        this.getAdditionalProductsComponents();
      } else {
        this.$router.push('/condAdmin/additionalProduct');
      }
    },
    getAdditionalProductsComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('AdditionalProduct'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + this.additionalProduct.tariffValuesType + '_' + shortname + '.vue');
        }
      }
    },
    setDirty () {
      if (!this.additionalProduct.isDirty) {
        this.additionalProduct.isDirty = true;
      }
    },
    enableAction (action) {
      switch (action) {
        case 'save': return this.additionalProduct.isDirty;
        case 'remove': return this.additionalProduct.state === 0 || this.additionalProduct.state === 1;
        case 'setCheck': return !this.additionalProduct.isDirty;
      }
      return true;
    },
    setProperty (key, value) {
      if (key.includes('.')) setProperty(key, this.additionalProduct, value);
      else this.additionalProduct[key] = value;
      this.setDirty();
    },
    async saveAddProduct () {
      let processData = await this.saveAdditionalProduct(this.additionalProduct, false);
      if (processData != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.additionalProductEditor.infoAddProductSaved') }]);
        this.additionalProduct.isDirty = false;
        // update AdditionalProductList
        await this.refreshAdditionalProductList();
        this.$store.state.condAdmin.workingAdditionalProduct = null;

        this.locked = false;
        // this.$router.push('/condAdmin/additionalProduct/' + this.additionalProduct.uid + '/' + this.additionalProduct.version);
        this.$router.push('/condAdmin/additionalProduct/' + this.additionalProduct.objectKey);
      }
    }
    /*
    async deleteAddProduct () {
      if (await this.deleteAdditionalProduct(this.additionalProduct) === true) {
        this.locked = false;
        this.$store.state.condAdmin.workingAdditionalProduct = null;
        this.$router.push('/condAdmin/additionalProduct');
      }
    } */
  }
};
</script>

<style scoped>
/*
.conditionTable >>> .v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}
.conditionTable td,
.conditionTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;

}
.conditionTable td.noBorder {
  border: 0px !important;
}
.conditionTable td.border {
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}
.conditionTable td.first,
.conditionTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}
.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-primary-lighten5) !important;
}
.lastRow {
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  padding-bottom: 8px !important;
}
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
}

.tfexpand >>> button {
  color: var(--v-secondary-base) !important;
}
.tfexpand >>> .v-input__icon--prepend {
  margin-left: 8px !important;
  margin-right: 16px !important;
}*/
</style>
