<template>
  <v-card
    class="minContent" v-if="filteredList != null"
  >
    <v-card-title>
      <v-menu
        offset-y
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="deleteRvGroups()"
            :disabled=false
            v-if="getOrgSetting('createOwnData') === true"
          >
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ capitalizeString("base.delete")}}
          </v-list-item>
          <v-list-item
            @click="exportData()"
            :disabled=false
          >
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <QuickFilter
        v-if="hasInheritedGroups"
        class="mr-2"
        :label="$t('condAdmin.filter.inherited')"
        :count="inheritedCount"
        :active="showInherited === true"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <QuickFilter
        v-if="hasInheritedGroups"
        :label="$t('condAdmin.filter.own')"
        :count="filteredList.length - inheritedCount"
        :active="showInherited === false"
        :selectValue="false"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <v-btn
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
        @click="createRvGroup()"
        color="secondary"
        class="mx-8"
        :disabled=false
        v-tooltip="$tooltip($t('condAdmin.tooltip.newRVGroup'))"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon
        @click="loadLists()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('rvGroupTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog
      </v-icon>
      <JsonImporter
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
        :show=true
        class="ml-8"
        objectType="rvGroupList"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        v-model="selected"
        :headers="listHeaders"
        :items="filteredList"
        :items-per-page="footerProps.itemsPerPage"
        :footer-props="footerProps"
        item-key="feid"
        show-select
        fixed-header
        :sort-by="listSortKey"
        @click:row="clickedRow"
        :loading="$store.state.condAdmin.rvGroupsLoading || $store.state.condAdmin.productsLoading"
        @update:items-per-page="getItemPerPage"
      >
        <template
          v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`body.prepend`]="{}">
          <tr>
            <td></td>
            <td
              v-for="(h, index) in listHeaders"
              :key="index"
            >
              <SearchSelect
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
                :items="productSelectLists(filter[h.value].items)"
                :item-text="filter[h.value].itemText"
                :item-value="filter[h.value].itemValue"
              />
              <SearchField
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
              >
              </SearchField>
              <SearchDatePicker
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'DatePicker'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
              />
            </td>
          </tr>
        </template>
        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <v-simple-checkbox
            color="secondary"
            v-bind="props"
            v-on="on"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`header.menu`]="{}">
        </template>
        <template v-slot:[`item.productTypes`]="{ item }">
          {{ getProductTypesText(item.productTypes) }}
          <v-icon
            v-if="($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid && p.state === 10)) != null"
            v-tooltip="$tooltip($t('condAdmin.productType.disabled'))"
          >mdi-alert-outline</v-icon>
        </template>
        <template v-slot:[`item.brands`]="{ item }">
          {{ getBrandsText(item.brands) }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-chip :class="getStateClass(item)">
            {{ getStateText(item) }}
            <v-icon
              class="ml-2"
              v-if="item.inherited === true"
              v-tooltip="$tooltip($t('condAdmin.rvGroup.inheritedRVGroup'))"
            >{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock' }}</v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.menu`]="{ item }">
          <v-menu
            offset-y
            v-if="getOrgSetting('createOwnData') === true"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!(enableAction('edit', item))"
                @click="editRvGroup('edit', item)"
              >{{ capitalizeString('condAdmin.actions.edit') }}
              </v-list-item>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
                :disabled="!(enableAction('copy', item))"
                @click="editRvGroup('copy', item) && getOrgSetting('createOwnData') === true"
              >{{ capitalizeString('condAdmin.actions.copy') }}
              </v-list-item>
              <!--<v-list-item
                    v-if="hasUserRight('manageResidualValues', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('end', item)"
                    @click="end(item)"
                  >{{ capitalizeString('condAdmin.actions.end') }}
                  </v-list-item>-->
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('remove', item)"
                @click="removeDraft(item)"
              >{{ capitalizeString('condAdmin.actions.delete') }}
              </v-list-item>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setCheck', item)"
                @click="setRvGroupState(2, item)"
              >{{ hasExamination() ? capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}
              </v-list-item>
              <v-list-item
                v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setChecked', item)"
                @click="setRvGroupState(4, item)"
              >{{ capitalizeString('condAdmin.actions.setChecked') }}
              </v-list-item>
              <v-list-item
                v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setRework', item)"
                @click="setRvGroupState(1, item)"
              >{{ capitalizeString('condAdmin.actions.setRework') }}
              </v-list-item>
              <v-list-item
                v-if="hasActivatorRole() && hasUserRight('activateResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('enable', item)"
                @click="setRvGroupState(6, item)"
              >{{ capitalizeString('condAdmin.actions.enable') }}
              </v-list-item>
              <v-list-item
                v-if="item.inherited !== true"
                @click="showHistory('getRvGroupHistory', item.uid, item.name)"
              >{{ $t('condAdmin.actions.history') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <JsonExporter
      objectType="rvGroupList"
      module="condAdmin"
      ref="jsonExporter"
    />
    <RvGroupImportDlg ref="rvGroupImportDlg" />
    <DependencyObjectDlg ref="dependencyObjectDialog" />
  </v-card>
</template>

<script lang="js">
import condAdminRvGroupMixin from '../js/condAdminRvGroupMixin.js';
import { refreshProductTypeList, refreshProductList } from '../js/condAdminHelper.js';
import { complexFilter } from '@/base/js/ObjectHelper';
import { newMessage } from '@/base/js/ProcessDataHelper';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import QuickFilter from '@/common/QuickFilter';
import HistoryDlg from './HistoryDlg';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import RvGroupImportDlg from './RvGroupImportDlg';
import DependencyObjectDlg from './DependencyObjectDlg';
import SearchDatePicker from '@/common/SearchDatePicker';

export default {
  name: 'RVGroupTable',
  components: {
    SearchField,
    SearchSelect,
    QuickFilter,
    HistoryDlg,
    JsonImporter,
    JsonExporter,
    RvGroupImportDlg,
    DependencyObjectDlg,
    SearchDatePicker
  },
  mixins: [ condAdminRvGroupMixin ],

  data () {
    return {
      selected: [],
      listSortKey: 'name',
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.rvGroup.name'), active: true, value: 'name', formatter: this.formatValue },
        { text: this.$t('condAdmin.rvGroup.productType'), active: true, value: 'productTypes', formatter: this.formatValue },
        { text: this.$t('condAdmin.rvGroup.brand'), active: true, value: 'brands', formatter: this.formatValue },
        { text: this.$t('condAdmin.version'), active: true, value: 'version' },
        { text: this.$t('condAdmin.rvGroup.finRVTableKey'), active: false, value: 'finRVTableKey' },
        { text: this.$t('condAdmin.rvGroup.leasRVTableKey'), active: false, value: 'leasRVTableKey' },
        { text: this.$t('condAdmin.validFrom'), active: true, value: 'validFrom', formatter: this.formatBeginDate },
        { text: this.$t('condAdmin.validTo'), active: true, value: 'validTo', formatter: this.formatEndDate },
        { text: this.$t('base.state'), active: true, value: 'state' }
      ],
      showInherited: null,
      filter: {
        productTypes: { condition: 'arr.is', value: null, type: 'SelectBox', items: 'productTypeList', itemText: 'name', itemValue: 'uid' },
        brands: { condition: 'arr.is', value: null, type: 'SelectBox', items: 'brandList' },
        validFrom: { condition: 'date.<=', value: null, type: 'DatePicker' },
        validTo: { condition: 'date.>=', value: null, type: 'DatePicker' },
        state: { condition: '=', value: null, type: 'SelectBox', items: 'stateList', itemText: 'text', itemValue: 'state' },
        name: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      }
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    brandList () {
      let list = [];
      for (let e of this.$store.state.condAdmin.productList) {
        if (e.brand != null) {
          let brands = e.brand.split(',');
          for (let b of brands) {
            if (!list.includes(b.trim())) list.push(b.trim());
          }
        }
        if (e.brand == null && !list.includes(this.NULLVALUE)) list.push(this.NULLVALUE);
      }
      return list.sort((a, b) => a > b);
    },
    stateList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.rvGroupList) {
        if (list.length === 0) {
          list.push({ state: c.state, text: this.$t('condAdmin.state.' + this.states[c.state]) });
        } else {
          if (list.find((i) => i.value === c.state) == null) {
            list.push({ state: c.state, text: this.$t('condAdmin.state.' + this.states[c.state]) });
          }
        }
      }
      return list.sort();
    },
    hasInheritedGroups () {
      return (this.$store.state.condAdmin.rvGroupList.filter((c) => c.inherited === true).length > 0);
    },
    inheritedCount () {
      return this.filteredList.filter((e) => e.inherited === true).length;
    }
  },
  watch: {
    '$store.state.condAdmin.rvGroupList': function () {
      this.filterGroups();
    }
  },
  mounted () {
    this.getUserTableSettings('rvGroupTable');
    this.filterGroups();
    this.$emit('setPageMode', 'table');
  },
  methods: {
    filterGroups () {
      let list = this.$store.state.condAdmin.rvGroupList;
      if (this.showInherited === true) {
        list = list.filter((e) => e.inherited === true);
      } else if (this.showInherited === false) {
        list = list.filter((e) => e.inherited !== true);
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    applyInheritedFilter (value) {
      this.showInherited = value;
      console.log('inheritedFilter', this.showInherited);
      this.filterGroups();
    },
    productSelectLists (value) {
      if (value === 'productTypeList') {
        return this.$store.state.condAdmin.productTypeList;
      } else if (value === 'brandList') {
        return this.brandList;
      } else if (value === 'stateList') {
        return this.stateList;
      }
    },
    async loadLists (forced = true) {
      if (forced || this.$store.state.condAdmin.rvGroupList == null || this.$store.state.condAdmin.rvGroupList.length === 0) {
        await this.refreshRvGroupList();
        await refreshProductList();
      }
      await refreshProductTypeList();
      this.filterGroups();
    },
    enableAction (action, rvGroup) {
      switch (action) {
        case 'edit': return rvGroup.inherited !== true;
        case 'copy': return true;
        case 'end': return false;
        case 'remove': return rvGroup.inherited !== true && rvGroup.state < 6;
        case 'setCheck': return rvGroup.inherited !== true && (rvGroup.state === 0 || rvGroup.state === 1);
        case 'setChecked': return rvGroup.inherited !== true && (rvGroup.state === 2 || rvGroup.state === 4);
        case 'setRework': return rvGroup.inherited !== true && (rvGroup.state === 2 || rvGroup.state === 4);
        case 'enable': return rvGroup.inherited !== true && rvGroup.state === 5;
      }
      return true;
    },
    async createRvGroup () {
      if (await this.checkEditPreRvGroups('new') === true) {
        this.$router.push('/condAdmin/rvGroup/edit/objects');
      }
    },
    async editRvGroup (mode, rvGroup) {
      if (rvGroup.versions == null) {
        rvGroup = await this.readRvGroup(rvGroup.uid, rvGroup.version, true);
      }
      if (await this.checkEditPreRvGroups(mode, rvGroup) === true) {
        this.$router.push('/condAdmin/rvGroup/edit/objects');
      }
    },
    async deleteRvGroups () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvGroupEditor.dlghDeleteRvGroups'), this.$t('condAdmin.rvGroupEditor.dlgtDeleteRvGroups'))) {
        for (let rvGroup of this.selected) {
          await this.deleteRvGroup(rvGroup, true);
        }
        await refreshProductList();
      }
    },
    async removeDraft (rvGroup) {
      if (await this.deleteRvGroup(rvGroup) === true) {
        await this.refreshRvGroupList();
        await refreshProductList();
      }
    },
    async setRvGroupState (state, rvGroup) {
      let rValue = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        if (rvGroup.versions == null || rvGroup.attributes == null) {
          rvGroup = await this.readRvGroup(rvGroup.uid, rvGroup.version, true);
        }
        let beginDateCheck = await this.validateBeginDate(rvGroup);
        let endDateCheck = await this.validateEndDate(rvGroup);
        rValue = await this.checkDependencies(rvGroup);
        if (!beginDateCheck || !endDateCheck || !rValue) {
          return false;
        }
      }
      await this.setResValueGroupState(rvGroup, state);
      if (state === 6) await this.refreshRvGroupList();
    },
    viewRvGroup (rvGroup = null) {
      this.$router.push('/condAdmin/rvGroup/' + rvGroup.uid + '/' + rvGroup.version + '/objects');
    },
    clickedRow (rvGroup) {
      this.viewRvGroup(rvGroup);
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['rvGroupTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['rvGroupTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filterGroups();
      this.$store.state.condAdmin.userSettings['rvGroupTable'].filter[filtername] = value;
    },
    async exportData () {
      let rvGroupList = [];
      for (let rvg of this.selected) {
        let rvGroup = JSON.parse(JSON.stringify(await this.readRvGroup(rvg.uid, rvg.version, true, true)));
        delete rvGroup.uid;
        delete rvGroup.clientKey;
        delete rvGroup.versions;
        delete rvGroup.brands;
        delete rvGroup.timestamp;

        for (let i = 0; i < rvGroup.productTypes.length; i++) {
          let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.uid === rvGroup.productTypes[i]);
          rvGroup.productTypes[i] = pt.name;
        }

        for (let product of rvGroup.assignedProducts) {
          product.productType = null;
          if (product.productTypeUid != null) {
            let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.uid === product.productTypeUid);
            product.productType = pt.name;
          }
          delete product.uid;
          delete product.productTypeUid;
        }

        rvGroupList.push(rvGroup);
      }
      this.$refs.jsonExporter.exportJsonData({ rvGroupList });
    },
    async importData (json) {
      await this.refreshRvGroupList();
      let imported = [];
      let total = json.data.rvGroupList.length;

      for (let imp of json.data.rvGroupList) {
        let action = await this.$refs.rvGroupImportDlg.show(imp);
        switch (action) {
          case 'startImport':
            for (let i = 0; i < imp.productTypes.length; i++) {
              let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.name === imp.productTypes[i]);
              imp.productTypes[i] = pt.uid;
            }
            let processData = await this.saveResidualValueGroup(imp);
            if (processData != null) {
              this.$store.state.condAdmin.rvGroupList.push(processData.ioData.rvGroup);
              console.log('imported', JSON.stringify(processData.ioData.rvGroup));
              imported.push({ uid: processData.ioData.rvGroup.uid, version: processData.ioData.rvGroup.version });
            }
            break;
          case 'skip':
          default:
            break;
        }
      }

      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported.length > 0) {
        let activate = await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvGroupImport.dlghActivateImports'), this.$t('condAdmin.rvGroupImport.dlgtActivateImports'), this.$t('condAdmin.actions.setChecked'), this.$t('condAdmin.actions.keepDraft'));
        if (activate === true) { // activate conditions
          for (let r of imported) {
            this.setResValueGroupState(r, 6);
          }
        }
        this.loadLists(true);
      }
    },
    preImportCheckFunction (json) {
      if (json.data.rvGroupList == null || json.data.rvGroupList.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.rvGroupImport.noImportData')) ]);
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
</style>
