<template>
  <div v-if="display">
    <v-row
      no-gutters
      v-for="(message, i) in messages"
      :key="i"
      class="my-0"
      :class="getColor(message.type, '--text')"
    >
      <v-icon :class="getColor(message.type, '--text mr-4 small')">
        {{ getIcon(message.type) }}
      </v-icon>
      <span class="">{{ getMessage(message) }}</span>
    </v-row>
  </div>
</template>

<script lang="js">
export default {
  name: 'DlgInfoBar',
  data () {
    return {
      display: false,
      messages: [],
      timeout: 3000
    };
  },
  methods: {
    show (messages) {
      if (!this.snackbar) {
        this.messages = [];
      }
      for (let m of messages) {
        if (typeof m.message !== 'undefined' && m.message && m.message.length > 0) {
          this.messages.push(m);
          // on error-messages infobar has to be closed manually; other messages disappear after a timeout (in ms)
          if (m.type !== 'success' && m.type !== 'info') {
            this.timeout = 0;
          }
          this.display = true;
        }
      }
      this.startTimer();
    },
    hide () {
      this.display = false;
      this.messages = [];
      this.timeout = 3000;
    },
    async startTimer () {
      if (this.timeout > 0) {
        setTimeout(this.hide(), this.timeout);
      }
    },
    getMessage (msg) {
      if (msg.sysMsg != null && msg.sysMsg.length > 0) return this.$t(msg.message) + ': ' + msg.sysMsg;
      return this.$t(msg.message);
    },
    getIcon (type) {
      switch (type) {
        case 'success':
          return 'mdi-check';
        case 'warning':
          return 'mdi-alert-circle-outline';
        case 'userError':
          return 'mdi-alert-outline';
        case 'systemError':
          return 'mdi-flash-alert-outline';
        case 'networkError':
          return 'mdi-lan-disconnect';
        case 'info':
        default:
          return 'mdi-information-outline';
      }
    },
    getColor (type, postfix = '') {
      switch (type) {
        case 'success':
          return 'success' + postfix;
        case 'warning':
          return 'warning' + postfix;
        case 'userError':
        case 'systemError':
        case 'networkError':
          return 'error' + postfix;
        case 'info':
        default:
          return 'text--lighten-3 info' + postfix;
      }
    }
  }
};
</script>

<style scoped>
</style>
