<template>
  <v-card v-if="loaded" flat class="px-4">
    <v-card-text>
      <v-row>
        <v-col lg="5" md="5" sm="6" xs="6">
          <v-text-field readonly :label="$t('condAdmin.rvGroup.name') + $t('base.mandatory')" :value="rvGroup.name" />
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="6" xs="6">
          <v-text-field readonly :label="$t('condAdmin.rvGroup.brand')" :value="rvGroup.brands" />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" xs="6" class="mt-2">
          <v-row no-gutters class="justify-end">
            <v-btn @click.native="$router.push('/condAdmin/rvGroup')" class="mr-4 prio2">
              {{ $t("base.back") }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
              @click.native="edit('edit')" color="secondary" class="mr-4">
              {{ $t("condAdmin.actions.edit") }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('examineResidualValues') && enableAction('setChecked') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
              @click.native="setRvGroupState(4)" color="secondary" class="mr-4">
              {{ $t("condAdmin.actions.setChecked") }}
            </v-btn>

            <v-menu offset-y v-if="(hasUserRight('manageResidualValues') ||
                hasUserRight('examineResidualValues')) &&
              getOrgSetting('createOwnData') === true">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
                  :disabled="!(enableAction('copy'))" @click="edit('copy')">{{ capitalizeString("condAdmin.actions.copy")
                  }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!(enableAction('create'))" @click="edit('new')">{{
                    capitalizeString("condAdmin.actions.create") }}
                </v-list-item>
                <!--
                <v-list-item
                  v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands)"
                  :disabled="!enableAction('end')"
                  @click="end()"
                >{{ capitalizeString('condAdmin.actions.end') }}
                </v-list-item>-->
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!enableAction('remove')" @click="deleteRVGroup()">{{
                    capitalizeString("condAdmin.actions.delete") }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!enableAction('setCheck')" @click="setRvGroupState(2)">{{ hasExamination() ?
                    capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!enableAction('setChecked')" @click="setRvGroupState(4)">{{
                    capitalizeString("condAdmin.actions.setChecked") }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!enableAction('setRework')" @click="setRvGroupState(1)">{{
                    capitalizeString("condAdmin.actions.setRework") }}
                </v-list-item>
                <v-list-item
                  v-if="hasActivatorRole() && hasUserRight('activateResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  :disabled="!enableAction('enable')" @click="setRvGroupState(6)">{{
                    capitalizeString("condAdmin.actions.enable") }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
                  @click="showHistory('getRvGroupHistory', rvGroup.uid, rvGroup.name)">{{
                    capitalizeString("condAdmin.actions.history") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <!-- ProductTypes -->
      <v-row>
        <v-col lg="5" md="5" sm="6" xs="6">
          <v-text-field readonly :label="$t('condAdmin.rvGroup.productType') + $t('base.mandatory')"
            :value="getProductType()" />
        </v-col>
      </v-row>

      <v-row> </v-row>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-select :label="$t('condAdmin.version')" :value="rvGroup.version" :items="rvGroup.versions" item-key="version"
            item-text="version" @change="loadVersion">
            <template v-slot:selection="{ item }">
              <v-row class="align-center" no-gutters>
                <div class="secondary--text" style="width: 150px">
                  {{ item.version }}
                </div>
                <div style="width: 600px">
                  {{ $t("condAdmin.validFrom") + " " }}
                  <span class="">{{ formatBeginDate(item.validFrom) }}</span>
                  {{ $t("base.to") }}
                  <span class="">{{ formatEndDate(item.validTo) }}</span>
                </div>
                <div>
                  <v-chip :class="getStateClass(item, false)" class="">
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-row class="align-center" no-gutters>
                <div class="secondary--text" style="width: 100px">
                  {{ item.version }}
                </div>
                <div style="width: 150px">
                  <v-chip :class="getStateClass(item, false)">
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
                <div>
                  <span class="text-caption">{{
                    $t("condAdmin.validFrom") + " "
                  }}</span>
                  {{ formatBeginDate(item.validFrom) }}
                  <span class="text-caption">{{ $t("base.to") }}</span>
                  {{ formatEndDate(item.validTo) }}
                </div>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="rvGroupTab">
            <v-tab href="#objects">
              {{ $t("condAdmin.productLabel") }}
            </v-tab>
            <v-tab href="#rvGroups">
              {{ $t("condAdmin.rvGroup.residualValue") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="rvGroupTab">
            <!------------------ Objects ------------------------------------>
            <v-tab-item value="objects">
              <v-card class="pt-4 grey lighten-5" v-if="rvGroup != null">
                <v-card-text>
                  <v-row class="text-right">
                    <v-col>
                      <v-spacer />
                      <v-icon @click="loadLists()" color="secondary" class="mr-8"
                        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))">mdi-reload</v-icon>
                      <v-menu v-if="rvGroup.assignedProducts != null && rvGroup.assignedProducts.length > 0" offset-y
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="secondary"
                            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-format-columns</v-icon>
                        </template>

                        <v-list>
                          <v-list-item v-for="(col, index) in availableColumns" :key="index">
                            <v-checkbox v-model="availableColumns[index].active" :label="$t(availableColumns[index].text)"
                              @change="setAvailableHeaders(availableColumns[index], $event)"></v-checkbox>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="rvGroup.assignedProducts == null || rvGroup.assignedProducts.length === 0">
                      {{ $t('condAdmin.rvGroup.noAssignedObjects') }}
                    </v-col>
                    <v-col v-else>
                      <v-data-table :headers="listHeaders" :items="rvGroup.assignedProducts"
                        :items-per-page="footerProps.itemsPerPage" :footer-props="footerProps" item-key="uid" fixed-header
                        :sort-by="listSortKey" @update:items-per-page="getItemPerPage">
                        <template v-for="header in listHeaders.filter((header) =>
                          header.hasOwnProperty('formatter')
                        )" v-slot:[`item.${header.value}`]="{ value }">
                          {{ header.formatter(value) }}
                        </template>
                        <template v-slot:[`item.productTypeUid`]="{ item }">
                          {{ $store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid).name }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!------------------ RVGroups ------------------------------------>
            <v-tab-item value="rvGroups">
              <v-card class="pt-4 grey lighten-5" v-if="rvGroup != null">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                      <v-row>
                        <v-col>

                          <v-text-field :readonly="true"
                            :label="$t('condAdmin.rvGroup.rvTableKeyFin') + $t('base.mandatory')"
                            :value="formatValue(getRVTable(rvGroup.finRVTableKey).name)">

                            <template v-slot:prepend-inner>
                              <v-chip v-if="getRVTable(rvGroup.finRVTableKey).inherited === true"
                                :class="getStateClass(getRVTable(rvGroup.finRVTableKey))">
                                {{ getStateText(getRVTable(rvGroup.finRVTableKey)) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link' :
                                  'mdi-link-lock' }}</v-icon>
                              </v-chip>
                              <v-chip v-if="getRVTable(rvGroup.finRVTableKey).inherited !== true"
                                :class="getStateClass(getRVTable(rvGroup.finRVTableKey))">
                                {{ getStateText(getRVTable(rvGroup.finRVTableKey)) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                  'mdi-link-unlock' }}</v-icon>
                              </v-chip>

                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row style="overflow-x: auto; overflow-y: hidden">
                        <v-col class="mb-16">
                          <table class="text-center">
                            <tr>
                              <th class="text-center first firstRow px-4">{{
                                $t("condAdmin.rvGroupDetailEditor.residualValueClass") }}</th>
                              <th class="text-center first firstRow px-4">{{
                                $t("condAdmin.rvGroupDetailEditor.residualImpactDiscount_percent") }}
                              </th>
                              <v-btn class="mr-4 prio2"
                                @click="showResultTable(rvGroup.finRVTableKey, rvGroup.finSubProductMods, rvGroup.finBaseRVMods, $t('condAdmin.rvGroupDetailEditor.resultTableHeadlineFinance'))">
                                <v-icon color="secondary"
                                  v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-table-check
                                </v-icon>
                                {{ $t("condAdmin.rvGroupDetailEditor.resultTable") }}
                              </v-btn>
                            </tr>
                            <tr v-for="mods in rvGroup.finBaseRVMods" :key="mods.modClass">
                              <!-- Modklasses -->
                              <td class="px-4 first" :class="getModClassColor(mods.modClass)">
                                <span>{{ mods.modClass }}</span>
                              </td>
                              <!-- Modklass Werte -->
                              <td class="px-4 first" :class="getModClassColor(mods.modClass)">
                                <span>{{ mods.modValue }}</span>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <!--
                      <v-row>
                        <v-col>
                          <EditField
                            :label="$t('condAdmin.rvGroup.subProductMods')"
                            :value="
                              getSubProductModsString(rvGroup.finSubProductMods)
                            "
                            :readonly="true"
                          />
                        </v-col>
                      </v-row>
                      -->
                      <v-row>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.addKmCharge')"
                            :value="getAddKmChargeString(rvGroup.finAdditionalParams)" :readonly="true" :suffix="currency"
                            type="ufloat" />
                        </v-col>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.lowKmCharge')"
                            :value="getLowKmChargeString(rvGroup.finAdditionalParams)" :readonly="true" :suffix="currency"
                            type="ufloat" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col v-if="showLeasingRVGroup == true" cols="6" lg="6" md="6" sm="12" xs="12">
                      <v-row>
                        <v-col>
                          <v-text-field :readonly="true"
                            :label="$t('condAdmin.rvGroup.rvTableKeyLeas') + $t('base.mandatory')"
                            :value="formatValue(getRVTable(rvGroup.leasRVTableKey).name)">

                            <template v-slot:prepend-inner>
                              <v-chip v-if="getRVTable(rvGroup.leasRVTableKey).inherited === true"
                                :class="getStateClass(getRVTable(rvGroup.leasRVTableKey))">
                                {{ getStateText(getRVTable(rvGroup.leasRVTableKey)) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link' :
                                  'mdi-link-lock' }}</v-icon>
                              </v-chip>
                              <v-chip v-if="getRVTable(rvGroup.leasRVTableKey).inherited !== true"
                                :class="getStateClass(getRVTable(rvGroup.leasRVTableKey))">
                                {{ getStateText(getRVTable(rvGroup.leasRVTableKey)) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                  'mdi-link-unlock' }}</v-icon>
                              </v-chip>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row style="overflow-x: auto; overflow-y: hidden">
                        <v-col class="mb-16">
                          <table class="text-center">
                            <tr>
                              <th class="text-center first firstRow px-4">{{
                                $t("condAdmin.rvGroupDetailEditor.residualValueClass") }}</th>
                              <th class="text-center first firstRow px-4">{{
                                $t("condAdmin.rvGroupDetailEditor.residualImpactDiscount_percent") }}</th>
                              <v-btn class="mr-4 prio2"
                                @click="showResultTable(rvGroup.leasRVTableKey, rvGroup.leasSubProductMods, rvGroup.leasBaseRVMods, $t('condAdmin.rvGroupDetailEditor.resultTableHeadlineLeasing'))">
                                <v-icon color="secondary"
                                  v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-table-check
                                </v-icon>
                                {{ $t("condAdmin.rvGroupDetailEditor.resultTable") }}
                              </v-btn>
                            </tr>
                            <tr v-for="mods in rvGroup.leasBaseRVMods" :key="mods.modClass">
                              <!-- Modklasses -->
                              <td class="px-4 first" :class="getModClassColor(mods.modClass)">
                                <span>{{ mods.modClass }}</span>
                              </td>
                              <!-- Modklass Werte -->
                              <td class="px-4 first" :class="getModClassColor(mods.modClass)">
                                <span>{{ mods.modValue }}</span>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <!--
                      <v-row>
                        <v-col>
                          <EditField
                            :label="$t('condAdmin.rvGroup.subProductMods')"
                            :value="getSubProductModsString(rvGroup.leasSubProductMods)"
                            :readonly="true"
                          />
                        </v-col>
                      </v-row>
                      -->
                      <v-row>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.addKmCharge')"
                            :value="getAddKmChargeString(rvGroup.leasAdditionalParams)" :readonly="true"
                            :suffix="currency" type="ufloat" />
                        </v-col>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.lowKmCharge')"
                            :value="getLowKmChargeString(rvGroup.leasAdditionalParams)" :readonly="true"
                            :suffix="currency" type="ufloat">

                          </EditField>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <ResidualValuesResultTableDlg ref="residualValuesResultTableDlg" />
    <DependencyObjectDlg ref="dependencyObjectDialog" />
  </v-card>
</template>

<script lang="js">
import condAdminRvGroupMixin from '../js/condAdminRvGroupMixin.js';
import condAdminResidualValuesMixin from '../js/condAdminResidualValuesMixin.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import HistoryDlg from './HistoryDlg';
import ResidualValuesResultTableDlg from './ResidualValuesResultTableDlg';
import DependencyObjectDlg from './DependencyObjectDlg';
import EditField from '@/common/EditField';

export default {
  name: 'RvGroupDetail',
  components: {
    HistoryDlg,
    // KeyValueRow,
    EditField,
    ResidualValuesResultTableDlg,
    DependencyObjectDlg

  },
  mixins: [condAdminRvGroupMixin, condAdminResidualValuesMixin],
  props: {
    rvGroupUid: { type: String, required: true },
    rvGroupVersion: { type: String, required: true },
    rvGroupTabPosition: { type: String, required: false }
  },
  data () {
    return {
      clientSettings: null,
      listSortKey: 'productName',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      rvGroup: null,
      loaded: false,
      rvGroupTab: this.rvGroupTabPosition !== null ? this.rvGroupTabPosition : null,
      filteredList: null,
      availableColumns: [
        { text: this.$t('condAdmin.product.productType'), active: false, value: 'productTypeUid' },
        { text: this.$t('condAdmin.product.brand'), active: true, value: 'brand', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productName'), active: true, value: 'productName', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productKey'), active: false, value: 'productKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.orderKey'), active: false, value: 'orderKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.chassis'), active: true, value: 'chassis', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.driveType'), active: true, value: 'driveType', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.serialId'), active: false, value: 'serialId', formatter: this.formatValue }
      ]
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      // h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    showLeasingRVGroup () {
      if (this.$store.state.condAdmin.orgSettings.getDataFromParent && this.clientSettings != null) {
        if (this.clientSettings.inheritedObjects.brand.filter((i) => i.localeCompare(this.rvGroup.brands.find((e) => e.localeCompare(i) === 0)) === 0).length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  async mounted () {
    if (this.$store.state.condAdmin.rvGroupList == null || this.$store.state.condAdmin.rvGroupList.length === 0) await this.refreshRvGroupList();
    if (this.$store.state.condAdmin.productTypeList == null) await this.refreshProductTypeList();
    if (this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) await this.refreshRvTableList();
    this.init(parseInt(this.rvGroupVersion));
  },
  methods: {
    async init (version) {
      this.loaded = false;
      this.rvGroup = this.$store.state.condAdmin.rvGroupList.find((c) => c.uid === this.rvGroupUid && c.version === version);

      if (this.rvGroup == null || this.rvGroup.versions == null || this.rvGroup.attributes == null) {
        this.rvGroup = await this.readRvGroup(this.rvGroupUid, version, true);
      }
      if (this.clientSettings == null && this.$store.state.condAdmin.orgSettings.getDataFromParent) {
        await this.getClientSettings();
      }
      this.$emit('setPageMode', 'view', '');
      this.loaded = true;
    },
    loadVersion (version) {
      this.init(version);
    },
    async getClientSettings () {
      let ioData = {
        targetClientKey: this.$store.state.condAdmin.clientKey
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'getCondClientSettings', ioData, true);
      if (isProcessStateOK(processData)) {
        this.clientSettings = processData.ioData.clientSettings;
        if (this.clientSettings.inheritedObjects == null) this.clientSettings.inheritedObjects = {};
        if (this.clientSettings.performance == null) this.clientSettings.performance = 1000;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    getRVTable (rvTableKey) {
      return this.$store.state.condAdmin.rvTableList.find((rvt) => rvt.rvTableKey === rvTableKey);
    },
    /*
    getRVTableTooltip (rvTableKey) {
      let tooltip = 'Eigene';
      if (this.getRVTable(rvTableKey).inherited === true) {
        tooltip = 'Standard';
      }
      return tooltip;
    }, */

    // ---------------------- //
    // -- showResultTable --- //
    // ---------------------- //
    async showResultTable (rvTableKey, subProductMods, baseRVMods, headline) {
      if (this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) {
        await this.refreshRvTableList();
      }
      let residualValueMods = [];
      if (subProductMods != null && subProductMods.modResidualValues != null) {
        residualValueMods = JSON.parse(JSON.stringify(subProductMods.modResidualValues));
      }
      await this.showRVResultTable(rvTableKey, residualValueMods, baseRVMods, headline);
    },

    enableAction (action) {
      switch (action) {
        case 'edit':
        case 'copy':
        case 'create': return true;
        case 'end': return false;
        case 'remove': return this.rvGroup.inherited !== true && this.rvGroup.state < 6;
        case 'setCheck': return this.rvGroup.state === 0 || this.rvGroup.state === 1;
        case 'setChecked': return this.rvGroup.state === 2 || this.rvGroup.state === 4;
        case 'setRework': return this.rvGroup.state === 2 || this.rvGroup.state === 4;
        case 'enable': return this.rvGroup.state === 5;
      }
      return true;
    },
    getProductType () {
      let productTypeList = [];
      if (this.rvGroup.productTypes != null) {
        for (let i = 0; i < this.rvGroup.productTypes.length; i++) {
          let item = this.$store.state.condAdmin.productTypeList.find((pT) => pT.uid === this.rvGroup.productTypes[i]);
          if (item != null) {
            productTypeList.push(item.name);
          }
        }
      }
      return productTypeList;
    },
    async edit (mode) {
      if (await this.checkEditPreRvGroups(mode, this.rvGroup) === true) {
        this.$router.push('/condAdmin/rvGroup/edit/' + this.rvGroupTab);
      }
    },
    async deleteRvGroup () {
      if (await this.deleteRvGroup(this.rvGroup) === true) {
        this.$router.push('/condAdmin/rvGroup');
      }
    },
    async setRvGroupState (state) {
      let rValue = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        let beginDateCheck = await this.validateBeginDate(this.rvGroup);
        let endDateCheck = await this.validateEndDate(this.rvGroup);
        rValue = await this.checkDependencies(this.rvGroup);
        if (!beginDateCheck || !endDateCheck || !rValue) {
          return false;
        }
      }

      await this.setResValueGroupState(this.rvGroup, state);
      this.loadVersion(this.rvGroup.version);
    },
    getModClassColor (modClass) {
      switch (modClass) {
        case 1: return 'colorClass1';
        case 2: return 'colorClass2';
        case 3: return 'colorClass3';
        case 4: return 'colorClass4';
        case 5: return 'colorClass5';
        case 6: return 'colorClass6';
        case 7: return 'colorClass7';
        case 8: return 'colorClass8';
        case 9: return 'colorClass9';
        default: return 'colorClass0';
      }
    },
    getSubProductModsString (mods) {
      if (mods == null || mods.length === 0) return this.$t('base.none');

      let s = '';
      for (let i = 0; i < mods.length; i++) {
        if (i > 0) s += ' / ';
        s += mods[i].subProductType + ': ' + mods[i].modValue;
      }
      return s;
    },
    getAddKmChargeString (params) {
      let s = '-';
      if (params != null && params.addKmCharge != null) {
        s = params.addKmCharge;
      }
      return s;
    },
    getLowKmChargeString (params) {
      let s = '-';
      if (params != null && params.lowKmCharge != null) {
        s = params.lowKmCharge;
      }
      return s;
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['rvGroupDetail'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['rvGroupDetail'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filteredList = complexFilter(this.$store.state.condAdmin.productList, this.filter);
      this.$store.state.condAdmin.userSettings['rvGroupDetail'].filter[filtername] = value;
    },
    end () {
    }
  }
};
</script>

<style scoped>
.RvGroupTable>>>.v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}

.RvGroupTable td,
.RvGroupTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.RvGroupTable .borderBottom {
  border-right: 0px !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.RvGroupTable td.noBorder {
  border: 0px !important;
}

.RvGroupTable td.first,
.RvGroupTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  min-width: 120px !important;
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
