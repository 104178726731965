<template>
  <v-card
    v-if="loaded"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>

        <v-col
          lg="5"
          md="5"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            :label="$t('condAdmin.rvTable.rvTableKey')"
            :value="rvTable.name"
          />
        </v-col>

        <v-col
          cols="12"
          lg="3"
          md="3"
          sm="6"
          xs="6"
        >
          <!--
          <v-text-field
            readonly
            :label="$t('condAdmin.additionalProduct.tariffId')"
            :value="additionalProduct.tariffId"
          />
          -->
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.push('/condAdmin/residualValues')"
              class="mr-4 prio2"
            >
              {{ $t('base.back') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
              @click.native="edit('edit')"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.edit') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('examineResidualValues') && enableAction('setChecked') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
              @click.native="setRVTableState(4)"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.setChecked') }}
            </v-btn>

            <v-menu
              offset-y
              v-if="hasUserRight('manageResidualValues') || hasUserRight('examineResidualValues')"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasUserRight('manageResidualValues')  && getOrgSetting('createOwnData') === true"
                  :disabled="!(enableAction('copy'))"
                  @click="edit('copy')"
                >{{ capitalizeString('condAdmin.actions.copy') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
                  :disabled="!(enableAction('create'))"
                  @click="edit('new')"
                >{{ capitalizeString('condAdmin.actions.create') }}
                </v-list-item>
                <!--
                <v-list-item
                  v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands)"
                  :disabled="!enableAction('end')"
                  @click="end()"
                >{{ capitalizeString('condAdmin.actions.end') }}
                </v-list-item>-->
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
                  :disabled="!(enableAction('remove'))"
                  @click="removeDraft()"
                >{{ capitalizeString('condAdmin.actions.delete') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
                  :disabled="!(enableAction('setCheck'))"
                  @click="setRVTableState(2)"
                >{{ hasExamination() ? capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
                  :disabled="!(enableAction('setChecked') )"
                  @click="setRVTableState(4)"
                >{{ capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
                  :disabled="!(enableAction('setRework'))"
                  @click="setRVTableState(1)"
                >{{ capitalizeString('condAdmin.actions.setRework') }}
                </v-list-item>
                <v-list-item
                  v-if="hasActivatorRole() && hasUserRight('activateResidualValues') && getOrgSetting('createOwnData') === true && !rvTable.inherited"
                  :disabled="!(enableAction('enable'))"
                  @click="setRVTableState(6)"
                >{{ capitalizeString('condAdmin.actions.enable') }}
                </v-list-item>
                <v-list-item @click="showHistory('getRvTableHistory', rvTable.rvTableKey, rvTable.name)">{{ capitalizeString('condAdmin.actions.history') }}</v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
      <v-row>
        <v-col
          lg="8"
          md="8"
          sm="12"
          xs="12"
        >
          <v-select
            :label="$t('condAdmin.version')"
            :value="rvTable.version"
            :items="rvTable.versions"
            item-key="version"
            item-text="version"
            @change="loadVersion"
          >
            <template v-slot:selection="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 150px;"
                >{{ item.version }}</div>
                <div style="width: 600px;">
                  {{ $t('condAdmin.validFrom') + ' ' }}
                  <span class="">{{ formatBeginDate(item.validFrom) }}</span>
                  {{ $t('base.to') }}
                  <span class="">{{ formatEndDate(item.validTo) }}</span>
                </div>
                <div>
                  <v-chip
                    :class="getStateClass(item, false)"
                    class=""
                  >
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 100px;"
                >{{ item.version }}</div>
                <div style="width: 150px;">
                  <v-chip :class="getStateClass(item, false)">
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
                <div>
                  <span class="text-caption">{{ $t('condAdmin.validFrom') + ' ' }}</span>
                  {{ formatBeginDate(item.validFrom) }}
                  <span class="text-caption">{{ $t('base.to') }}</span>
                  {{ formatEndDate(item.validTo) }}
                </div>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- #### additional Data #### -->
      <ResidualValuesVersionDetail
        :params="rvTable || {}"
        :readonly="true"
        :clearable="false"
        />
      <!--<component
        class="pb-4 grey lighten-5"
        v-bind:is="customComponents.ResidualValuesVersionDetail"
        ref="customResidualValues"
        :params="rvTable || {}"
        :readonly="true"
        :clearable="false"
        v-show="customComponents.ResidualValuesVersionDetail != null"
      ></component>-->
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
  </v-card>
</template>

<script lang="js">
import condAdminResidualValuesMixin from '../js/condAdminResidualValuesMixin.js';
import HistoryDlg from './HistoryDlg';
import ResidualValuesVersionDetail from './ResidualValuesVersionDetail';
// import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'residualValuesDetail',
  components: {
    HistoryDlg,
    ResidualValuesVersionDetail
  },
  mixins: [ condAdminResidualValuesMixin ],
  props: {
    rvTableKey: { type: String, required: true },
    rvVersion: { type: String, required: true }
  },
  data () {
    return {
      rvTable: null,
      customComponents: {},
      loaded: false,
      shortname: ''
    };
  },
  computed: {
  },
  async mounted () {
    if (this.$store.state.condAdmin.rvTableList == null) {
      await this.refreshRvTableList();
    }
    this.init(parseInt(this.rvVersion));
  },
  methods: {
    async init (rvVersion) {
      this.rvTable = this.$store.state.condAdmin.rvTableList.find((e) => e.rvTableKey === this.rvTableKey && e.version === rvVersion);
      this.loaded = false;
      if (this.rvTable.versions == null) {
        await this.getRvTableData(rvVersion);
      }
      this.$emit('setPageMode', 'view');
      this.getResidualValuesComponents();
      this.loaded = true;
    },
    loadVersion (version) {
      this.rvTable = this.rvTableList.versions.find((e) => e.version === version);
      if (this.rvTable != null) {
        this.init(parseInt(this.rvTable.version));
      } else {
        this.init(parseInt(this.rvVersion));
      }
    },

    getResidualValuesComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('ResidualValues'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + shortname + '.vue');
        }
      }
    },

    enableAction (action) {
      switch (action) {
        case 'edit':
        case 'copy':
        case 'create': return true;
        case 'end': return false;
        case 'remove': return this.rvTable.inherited !== true && this.rvTable.state < 6;
        case 'setCheck': return this.rvTable.state === 0 || this.rvTable.state === 1;
        case 'setChecked': return this.rvTable.state === 2 || this.rvTable.state === 4;
        case 'setRework': return this.rvTable.state === 2 || this.rvTable.state === 4;
        case 'enable': return this.rvTable.state === 5;
      }
      return true;
    },

    async edit (mode) {
      if (await this.checkEditPreResidualValuesTable(mode, this.rvTable) === true) {
        this.$router.push('/condAdmin/residualValues/edit');
      }
    },
    /*
    async deleteAddProduct () {
      if (await this.deleteAdditionalProduct(this.additionalProduct) === true) {
        this.$router.push('/condAdmin/additionalProduct');
      }
    }, */
    async setRVTableState (state) {
      let setState = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        let beginDateCheck = await this.validateBeginDate(this.rvTable);
        let endDateCheck = await this.validateEndDate(this.rvTable);
        setState = (beginDateCheck && endDateCheck);
      }

      if (setState) {
        await this.setState(this.rvTable, state);
        let v = this.rvTable.versions.find((c) => c.version === this.rvTable.version);
        v.state = this.rvTable.state;
      }
    },
    formatValue (v) {
      return this.formatValue(v, this.$t('condAdmin.noneValue'));
    },
    async removeDraft () {
      if (await this.deleteRvTable(this.rvTable) === true) {
        this.$router.push('/condAdmin/rvGroup');
      }
    },
    async getRvTableData (rvVersion) {
      this.rvTable = await this.readRvTableData(this.rvTable.rvTableKey, rvVersion);
    },

    end () {
    }
  }
};
</script>

<style scoped>
</style>
