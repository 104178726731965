<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card v-if="rvGroup != null">
      <v-card-title class="primary-bg">
        {{ $t('condAdmin.rvGroupImport.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row v-if="errors.length > 0">
          <v-col class="error--text">{{ $t('condAdmin.rvGroupImport.errors') }}</v-col>
        </v-row>
        <v-row
          v-for="err, index in errors"
          :key="index"
        >
          <v-col class="error--text">{{ err }}</v-col>
        </v-row>
        <v-row v-if="errors.length === 0">
          <v-col>{{ $t(found ? 'condAdmin.rvGroupImport.existingFound' : 'condAdmin.rvGroupImport.noExistingFound') }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>{{ $t('condAdmin.rvGroup.name') }}</v-col>
          <v-col>{{ rvGroup.name }}</v-col>
          <v-col>{{ $t('condAdmin.version') }}</v-col>
          <v-col>{{ rvGroup.version }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>{{ $t('condAdmin.validFrom') }}</v-col>
          <v-col>{{ rvGroup.validFrom }}</v-col>
          <v-col>{{ $t('condAdmin.validTo') }}</v-col>
          <v-col>{{ rvGroup.validTo }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>{{ $t('condAdmin.rvGroup.finRVTableKey') }}</v-col>
          <v-col>{{ rvGroup.finRVTableKey }}</v-col>
          <v-col>{{ $t('condAdmin.rvGroup.leasRVTableKey') }}</v-col>
          <v-col>{{ rvGroup.leasRVTableKey }}</v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>{{ $t('condAdmin.rvGroup.assignedProducts') }}</v-label>
          </v-col>
        </v-row>
        <v-row dense v-for="product, index in rvGroup.assignedProducts" :key="index">
          <v-col>{{ Boolean(product.isInclude) === true ? $t('condAdmin.isInclude.include') : $t('condAdmin.isInclude.exclude') }}</v-col>
          <v-col>{{ product.productType }}</v-col>
          <v-col>{{ product.brand }}</v-col>
          <v-col>{{ product.productName }}</v-col>
          <v-col>{{ product.chassis }}</v-col>
          <v-col>{{ product.driveType }}</v-col>
        </v-row>
        <v-row v-if="warnings.length > 0">
          <v-col class="warning--text">{{ $t('condAdmin.rvGroupImport.warnings') }}</v-col>
        </v-row>
        <v-row
          v-for="warn, index in warnings"
          :key="index"
        >
          <v-col class="warning--text">{{ warn }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :showSave="errors.length === 0"
          @cancel="skip"
          @save="startImport"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import { v4 as uuidv4 } from 'uuid';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'RvGroupImportDlg',
  components: {
    SaveCancelButtonBar
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      errors: [],
      warnings: [],
      found: false,
      rvGroup: null
    };
  },
  methods: {
    show (rvGroup) {
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvGroup = rvGroup;
      // get productTypeUid
      for (let product of rvGroup.assignedProducts) {
        product.productTypeUid = null;
        if (product.productType != null) {
          let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.name === product.productType);
          if (pt == null) {
            this.errors.push(this.$t('condAdmin.rvGroupImport.unresolvedProductType', { productType: product.productType }));
          } else {
            product.productTypeUid = pt.uid;
          }
        }
      }
      // get rvGroupUid from rvGroupKey and provider and check calcType
      let existing = this.$store.state.condAdmin.rvGroupList.find(
        (e) => e.name === rvGroup.name
      );
      if (existing != null) {
        rvGroup.uid = existing.uid;
        if (existing.state === 0) {
          rvGroup.version = existing.version;
          this.warnings.push(this.$t('condAdmin.rvGroupImport.draftToBeOverwritten'));
        } else {
          rvGroup.version = existing.version + 1;
        }
      } else {
        rvGroup.uid = uuidv4();
        rvGroup.version = 1;
      }
      rvGroup.state = 0;
      this.found = existing != null;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    skip () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvGroup = null;
      this.resolve('skip');
    },
    startImport () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvGroup = null;
      this.resolve('startImport');
    }
  }
};
</script>

<style scoped>
</style>
