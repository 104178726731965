<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-car"
    />
    <ProductTypeTable
      ref="productTypeTable"
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.productTypeList.headline')"
    />
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';
import ProductTypeTable from './components/ProductTypeTable.vue';

export default {
  name: 'ProductType',
  components: {
    Headline,
    ProductTypeTable
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.productTypeList.headline')
    };
  },
  async beforeRouteLeave (to, from, next) {
    if (await this.$refs.productTypeTable.checkLeave() === true) next();
  },
  methods: {
    setPageMode (mode, param = null) {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('condAdmin.productTypeList.createProductType');
          break;
        case 'edit':
          this.headlineText = param != null ? param : this.$t('condAdmin.productTypeList.editProductType');
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.productTypeList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
