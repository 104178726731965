<template>
  <div v-show="show">
    <v-icon
      @click="$refs.importFile.click()"
      :color="color"
      v-tooltip="$tooltip($t('base.importData'))"
    >{{ icon }}</v-icon>
    <input
      type="file"
      ref="importFile"
      accept=".json"
      @change="loadFile"
      style="display:none"
    />
  </div>
</template>

<script lang="js">
import { newMessage } from '@/base/js/ProcessDataHelper';

export default {
  name: 'JsonImporter',
  props: {
    objectType: { type: String, required: true },
    module: { type: String, required: true },
    preImportCheck: { type: Function, default: () => { return true; } },
    color: { type: String, default: 'secondary' },
    icon: { type: String, default: 'mdi-import' },
    tooltip: { type: String, default: 'base.importData' },
    show: { type: Boolean, default: true }
  },
  methods: {
    startImport () {
      this.$refs.importFile.click();
    },
    loadFile (e) {
      let files = e.target.files || e.dataTransfer.files;
      let reader = new FileReader();
      reader.addEventListener('load', async () => {
        try {
          let json = JSON.parse(reader.result);
          await this.checkHeaderData(json);
        } catch (err) {
          this.$globals.Info.open([ newMessage('userError', this.$t('base.jsonImporter.invalidData')) ]);
        }
      }, false);
      if (files[0]) {
        reader.readAsText(files[0]);
      }
    },
    async checkHeaderData (json) {
      // check module and objectType
      if (json.header == null || json.data == null || typeof json.header !== 'object') {
        this.$globals.Info.open([ newMessage('userError', this.$t('base.jsonImporter.invalidData')) ]);
        return;
      }
      if (json.header.module !== this.module || json.header.objectType !== this.objectType) {
        this.$globals.Info.open([ newMessage('userError', this.$t('base.jsonImporter.invalidModuleOrObjectType')) ]);
        return;
      }
      // check targetOrg
      let targetOrgName = json.header.targetOrgName;
      if (this.$store.state.base.admin.administratedOrg.shortname !== targetOrgName) {
        // || this.$store.state.base.admin.administratedOrg.uid !== json.header.targetOrgKey) {
        this.$globals.Info.open([ newMessage('userError', this.$t('base.jsonImporter.targetOrgNotMatching', { targetOrgName })) ]);
        return;
      }
      // do preImportCheck given from parent component
      if (this.preImportCheck(json) !== true) {
        return;
      }

      // check app-version
      let importVersion = json.header.version;
      let appVersion = this.$configHelper.getConfigParam('appVersion');
      if (appVersion !== importVersion) {
        if (await this.$globals.Confirm.yesNoDlg(this.$t('base.jsonImporter.dlghImportDataQuestion'), this.$t('base.jsonImporter.dlgtDifferentVersions', { importVersion, appVersion }) + this.$t('base.jsonImporter.dlgtImportDataQuestion')) !== true) {
          return;
        }
      }
      // check stage and do security-request
      this.$emit('import', json);
    }
  }
};
</script>

<style scoped>
</style>
