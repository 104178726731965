<template>
  <v-card
    v-if="loaded"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>
         <!-- YARETO Teil -->
        <v-col
          v-if="useConditionKeyVariable()"
          cols="4"
          lg="1"
          md="4"
          sm="6"
          xs="6"
        >
          <v-radio-group
            v-model= "condition.conditionKeyFix"
            column
          >
            <v-radio
              :label="$t('condAdmin.condition.conditionKeyID.labelFix')"
              :value="true"
              :readonly = true
            />
            <v-radio
              :label="$t('condAdmin.condition.conditionKeyID.labelVariabel')"
              :value="false"
              :readonly = true
            />
          </v-radio-group>
        </v-col>
        <v-col
          v-if="useConditionKeyVariable()"
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            v-if="condition.conditionKeyFix === true"
            :label="$t('condAdmin.condition.conditionKey')"
            :value="condition.conditionKey"
          />
          <v-text-field
            v-if="condition.conditionKeyFix === false"
            :label="$t('condAdmin.condition.conditionKey')"
            readonly
            :value="$t('condAdmin.condition.conditionKeyID.variabelInput')"
            class="subsidyFieldDisabled"
          />
        </v-col>
        <!-- YARETO Teil Ende -->
        <!-- SONST -->
        <v-col
          v-if="!useConditionKeyVariable()"
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            :label="$t('condAdmin.condition.conditionKey')"
            :value="condition.conditionKey"
          />
        </v-col>
        <!-- ------------------------ -->
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            :label="$t('condAdmin.condition.name')"
            :value="condition.name"
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.push('/condAdmin/condition')"
              class="mr-4 prio2"
            >
              {{ $t('base.back') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
              @click.native="editCondition('edit')"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.edit') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('examineCondition', condition.providerUid, condition.brands) && enableAction('setChecked')"
              @click.native="setConditionState(4)"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.setChecked') }}
            </v-btn>
            <v-menu
              offset-y
              v-if="getOrgSetting('createOwnData') === true && hasUserRight('manageCondition', condition.providerUid, condition.brands) || hasUserRight('examineCondition', condition.providerUid, condition.brands)"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <!--
                  <v-list-item
                    v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                    :disabled="!enableAction('edit', condition)"
                    @click="editCondition('edit', condition)"
                  >{{ capitalizeString('condAdmin.actions.edit') }}
                  </v-list-item> -->
                <v-list-item
                  v-if="hasUserRight('manageCondition') && getOrgSetting('createOwnData') === true"
                  :disabled="!enableAction('copy', condition)"
                  @click="editCondition('copy') && getOrgSetting('createOwnData') === true"
                >{{ capitalizeString('condAdmin.actions.copy') }}
                </v-list-item>
                <!--<v-list-item
                    v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                    :disabled="!enableAction('end', condition)"
                    @click="end(condition)"
                  >{{ capitalizeString('condAdmin.actions.end') }}
                  </v-list-item>-->
                <v-list-item
                  v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                  :disabled="!enableAction('remove', condition)"
                  @click="removeDraft(condition)"
                >{{ capitalizeString('condAdmin.actions.delete') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                  :disabled="!enableAction('setCheck', condition)"
                  @click="setConditionState(2, condition)"
                >{{ hasExamination() ? capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                  :disabled="!enableAction('setChecked', condition)"
                  @click="setConditionState(4, condition)"
                >{{ capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                  :disabled="!enableAction('setRework', condition)"
                  @click="setConditionState(1, condition)"
                >{{ capitalizeString('condAdmin.actions.setRework') }}
                </v-list-item>
                <v-list-item
                  v-if="hasActivatorRole() && hasUserRight('activateCondition', condition.providerUid, condition.brands) && getOrgSetting('createOwnData') === true && !condition.inherited"
                  :disabled="!enableAction('enable', condition)"
                  @click="setConditionState(6, condition)"
                >{{ capitalizeString('condAdmin.actions.enable') }}
                </v-list-item>
                <v-list-item
                  v-if="condition.inherited !== true"
                  @click="showHistory('getConditionHistory', condition.uid, condition.name)"
                >{{ $t('condAdmin.actions.history') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
      <v-row>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            dense
            :label="$t('condAdmin.condition.calcType')"
            :value="condition.calcTypeName"
          />
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            dense
            :label="$t('condAdmin.condition.provider')"
            :value="condition.providerName"
          />
        </v-col>
      </v-row>
      <v-row v-if="useInterestType() === true">
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            dense
            :label="$t('condAdmin.condition.interestType')"
            :value="condition.additionalParams.interestType.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          lg="8"
          md="8"
          sm="12"
          xs="12"
        >
          <v-select
            :label="$t('condAdmin.version')"
            :value="condition.version"
            :items="condition.versions"
            item-key="version"
            item-text="version"
            @change="loadVersion"
          >
            <template v-slot:selection="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 150px;"
                >{{ item.version }}</div>
                <div style="width: 600px;">
                  {{ $t('condAdmin.validFrom') + ' ' }}
                  <span class="">{{ formatBeginDate(item.validFrom) }}</span>
                  {{ $t('base.to') }}
                  <span class="">{{ formatEndDate(item.validTo) }}</span>
                </div>
                <div>
                  <v-chip
                    :class="getStateClass(item)"
                    class=""
                  >
                    {{ getStateText(item) }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 100px;"
                >{{ item.version }}</div>
                <div style="width: 150px;">
                  <v-chip :class="getStateClass(item)">
                    {{ getStateText(item) }}
                  </v-chip>
                </div>
                <div>
                  <span class="text-caption">{{ $t('condAdmin.validFrom') + ' ' }}</span>
                  {{ formatBeginDate(item.validFrom) }}
                  <span class="text-caption">{{ $t('base.to') }}</span>
                  {{ formatEndDate(item.validTo) }}
                </div>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ConditionVersionDetail
            ref="conditionVersionDetail"
            :conditionUid="conditionUid"
            :conditionVersion="condition.version"
          />
        </v-col>
      </v-row>
      <!-- #### additional Data #### -->
      <v-row class="">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.condition.additionalData') }}</span>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            dense
            :label="$t('condAdmin.condition.add.internalContact')"
            :value="formatValue(condition.additionalParams.internalContact)"
          />
          <v-text-field
            readonly
            :label="$t('condAdmin.condition.add.externalContact')"
            :value="formatValue(condition.additionalParams.externalContact)"
          />
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-textarea
              :label="getAdditionalInformationLabel()"
              readonly
              dense
              outlined
              :value="formatValue(getAdditionalInformationText())"
            />
            <v-textarea v-if= "useInterestType() === true  && condition.additionalParams.interestType.name === 'Bonität'"
              :label="$t('condAdmin.condition.add.creditRatingAdditionalInformation')"
              readonly
              dense
              outlined
              :value="condition.additionalParams.creditRatingAdditionalInformation"
            />
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="6"
        >
          <v-textarea
            :label="$t('condAdmin.condition.add.comment')"
            readonly
            dense
            outlined
            :value="formatValue(condition.additionalParams.comment)"
          />
        </v-col>

      </v-row>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
  </v-card>
</template>

<script lang="js">
import condAdminConditionMixin from '../js/condAdminConditionMixin.js';
import ConditionVersionDetail from './ConditionVersionDetail';
import HistoryDlg from './HistoryDlg';

export default {
  name: 'ConditionDetail',
  components: {
    ConditionVersionDetail,
    HistoryDlg
  },
  mixins: [ condAdminConditionMixin ],
  props: {
    conditionUid: { type: String, required: true },
    conditionVersion: { type: String, required: true }
  },
  data () {
    return {
      condition: null,
      loaded: false
    };
  },
  async mounted () {
    if (this.$store.state.condAdmin.conditionList == null) await this.refreshConditionList();
    this.init(parseInt(this.conditionVersion));
  },
  methods: {
    async init (version) {
      this.loaded = false;
      this.condition = this.$store.state.condAdmin.conditionList.find((c) => c.uid === this.conditionUid && c.version === version);
      if (this.condition == null || this.condition.versions == null || this.condition.attributes == null) {
        this.condition = await this.readCondition(this.conditionUid, version, true);
      }
      this.$emit('setPageMode', 'view', this.condition.name);
      this.loaded = true;
    },
    loadVersion (version) {
      this.init(version);
      this.$refs.conditionVersionDetail.init(version);
    },
    enableAction (action) {
      switch (action) {
        case 'edit':
        case 'copy':
        case 'create': return true;
        case 'end': return false;
        case 'remove': return this.condition.inherited !== true && this.condition.state < 6;
        case 'setCheck': return this.condition.state === 0 || this.condition.state === 1;
        case 'setChecked': return this.condition.state === 2 || this.condition.state === 4;
        case 'setRework': return this.condition.state === 2 || this.condition.state === 4;
        case 'enable': return this.condition.state === 5;
      }
      return true;
    },
    async editCondition (mode) {
      if (await this.checkEditPreConditions(mode, this.condition) === true) {
        this.$router.push('/condAdmin/condition/edit');
      }
    },

    async removeDraft () {
      if (await this.deleteCondition(this.condition) === true) {
        this.$router.push('/condAdmin/condition');
      }
    },
    async setConditionState (state) {
      let setState = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        let productAssignmentsCheck = await this.validateProductAssignments(this.condition);
        let beginDateCheck = await this.validateBeginDate(this.condition);
        let endDateCheck = await this.validateEndDate(this.condition);
        let rvCheck = await this.validateResidualValues(this.condition);

        setState = (productAssignmentsCheck && beginDateCheck && endDateCheck && rvCheck);
      }

      if (setState) {
        await this.setState(this.condition, state);
        let v = this.condition.versions.find((c) => c.version === this.condition.version);
        v.state = this.condition.state;
      }
    },
    useConditionKeyVariable () {
      return this.$store.state.condAdmin.orgSettings.useConditionKeyVariable === true;
    },
    useInterestType () {
      return this.$store.state.condAdmin.orgSettings.useInterestType === true;
    },
    getAdditionalInformationLabel () {
      let infoLabel = this.$t('condAdmin.condition.add.saleInfo');
      if (this.condition.additionalParams.interestType != null) {
        switch (this.condition.additionalParams.interestType.name) {
          case 'Aktion':
            infoLabel = this.$t('condAdmin.condition.add.campaignInformation');
            break;
          case 'Bonität':
            infoLabel = this.$t('condAdmin.condition.add.creditRatingInformation');
            break;
        }
      }
      return infoLabel;
    },
    getAdditionalInformationText () {
      let infoText = this.condition.additionalParams.saleInfo;

      if (this.condition.additionalParams.interestType != null) {
        switch (this.condition.additionalParams.interestType.name) {
          case 'Aktion':
            infoText = this.condition.additionalParams.campaignInfo;
            break;
          case 'Bonität':
            infoText = this.condition.additionalParams.creditRatingInfo;
            break;
        }
      }
      return infoText;
    },
    end () {
    }
  }
};
</script>

<style scoped>
</style>
