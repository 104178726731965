import Vue from 'vue';
import { store } from '@/plugins/store';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

/* *****************************************************
 * Dealer
***************************************************** */
export const refreshDealerList = async () => {
  store.state.condAdmin.dealerList = [];
  store.state.condAdmin.dealersLoading = true;
  let ioData = {
    clientKey: store.state.condAdmin.clientKey
  };

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'getDealerList', ioData);
  if (isProcessStateOK(processData)) {
    store.state.condAdmin.dealerList = processData.ioData.dealerList;
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
  store.state.condAdmin.dealersLoading = false;
  return true;
};

export const copyDealer = (source = {}) => {
  return {
    id: source.id != null ? source.id : null,
    dealerKey: source.dealerKey != null ? source.dealerKey : '',
    name: source.name != null ? source.name : '',
    street: source.street != null ? source.street : '',
    zipCode: source.zipCode != null ? source.zipCode : '',
    city: source.city != null ? source.city : '',
    country: source.country != null ? source.country : '',
    phone: source.phone != null ? source.phone : '',
    email: source.email != null ? source.email : '',
    fax: source.fax != null ? source.fax : '',
    additionalData: source.additionalData != null ? source.additionalData : '',
    conditionMatch: source.conditionMatch != null ? source.conditionMatch : 'bI'
  };
};

export const saveDealer = async (dealer) => {
  let returnState = false;
  const ioData = {
    clientKey: store.state.condAdmin.clientKey,
    dealer: dealer
  };
  let processName = 'updateDealer';
  if (dealer.id == null) {
    processName = 'createDealer';
  }
  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', processName, ioData, true);
  if (isProcessStateOK(processData)) {
    if (processName === 'createDealer') {
      store.state.condAdmin.dealerList.push(processData.ioData.dealer);
    } else {
      let pIndex = store.state.condAdmin.dealerList.findIndex((p) => p.id === processData.ioData.dealer.id);
      store.state.condAdmin.dealerList[pIndex] = processData.ioData.dealer;
    }
    returnState = true;
  }
  Vue.prototype.$globals.Info.open(processData.processState.messages, true);
  return returnState;
};
