<template>
  <v-card
    v-if="loaded"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row class="mb-4">
        <v-col>
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.push('/condAdmin/product')"
              class="prio2"
            >
              {{ $t('base.back') }}
            </v-btn>
            <v-btn
              :disabled="!isDirty"
              v-if="hasUserRight('manageResidualValues') && product.inherited !== true"
              @click.native="save()"
              color="secondary"
              class="mx-4"
            >
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <v-menu
              offset-y
              v-if="hasUserRight('manageResidualValues') && product.inherited !== true"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="deleteProduct()"
                  :disabled="isAssigned === true"
                >
                  <v-icon class="mr-4">mdi-delete-forever</v-icon>
                  {{ capitalizeString("condAdmin.actions.delete")}}
                </v-list-item>
                <v-list-item
                  v-if="product.state === 6"
                  @click="setActiveProduct(false)"
                >
                  <v-icon class="mr-4">mdi-pause</v-icon>
                  {{ capitalizeString("condAdmin.actions.disable")}}
                </v-list-item>
                <v-list-item
                  v-else
                  @click="setActiveProduct(true)"
                >
                  <v-icon class="mr-4">mdi-play</v-icon>
                  {{ capitalizeString("condAdmin.actions.enable")}}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-row>
            <v-col>
              <v-select
                :label="$t('condAdmin.product.productType')  + $t('base.mandatory')"
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                v-model="product.productTypeUid"
                :items="productTypeList"
                item-text="name"
                item-value="uid"
                dense
                @change="setDirty"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.brand')"
                v-model="product.brand"
                @change="setDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.productName')"
                v-model="product.productName"
                @change="setDirty"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.chassis')"
                v-model="product.chassis"
                @change="setDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.driveType')"
                v-model="product.driveType"
                @change="setDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.productKey')"
                v-model="product.productKey"
                @change="setDirty"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.orderKey')"
                v-model="product.orderKey"
                @change="setDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :readonly="!hasUserRight('manageResidualValues') || product.inherited === true"
                dense
                :label="$t('condAdmin.product.serialId')"
                v-model="product.serialId"
                @change="setDirty"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>{{ $t('condAdmin.rvGroupList.headline') }}</v-label>
          <div v-if="product.prv == null || product.prv.length === 0">{{ $t('condAdmin.product.notAssignedToRvGroup')}}</div>
          <v-data-table
            v-else
            :headers="listHeaders"
            :items="product.prv"
            :items-per-page="-1"
            item-key="id"
            fixed-header
            hide-default-footer
            @click:row="clickedRow"
          >
            <template
              v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-chip
                :class="getStateClass(item)"
                small
              >
                {{ getStateText(item) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { refreshProductList, copyProduct, saveProduct, updateProductState } from '../js/condAdminHelper.js';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import condAdminMixin from '../js/condAdminMixin.js';
import HistoryDlg from './HistoryDlg';

export default {
  name: 'ProductDetail',
  components: {
    HistoryDlg,
    SaveOnLeaveDlg
  },
  mixins: [ condAdminMixin ],
  props: {
    productUid: { type: String, default: null }
  },
  data () {
    return {
      product: null,
      isDirty: false,
      loaded: false,
      listHeaders: [
        { text: this.$t('condAdmin.rvGroup.name'), value: 'rvGroupName' },
        { text: this.$t('condAdmin.version'), value: 'version' },
        { text: this.$t('condAdmin.validFrom'), value: 'validFrom', formatter: this.formatBeginDate },
        { text: this.$t('condAdmin.validTo'), value: 'validTo', formatter: this.formatEndDate },
        { text: this.$t('base.state'), value: 'state' }
      ]
    };
  },
  computed: {
    isAssigned () {
      for (let g of this.product.prv) {
        if (g.validTo == null || this.DateTimeHelper.isLaterOrEqual(g.validTo /* than NOW */)) {
          return true;
        }
      }
      return false;
    },
    productTypeList () {
      return this.$store.state.condAdmin.productTypeList.filter((e) => e.state < 10);
    }
  },
  async mounted () {
    if (this.$store.state.condAdmin.productList == null) await refreshProductList();
    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    next();
  },
  methods: {
    async init () {
      if (this.productUid != null) {
        this.product = copyProduct(this.$store.state.condAdmin.productList.find((e) => e.uid === this.productUid));
        if (this.product.prv == null) {
          this.loaded = false;
          this.product.prv = await this.getProductRvData();
        }
        this.$emit('setPageMode', 'edit');
      } else {
        this.product = copyProduct();
        this.product.prv = [];
        this.$emit('setPageMode', 'create');
      }
      this.loaded = true;
    },
    async getProductRvData () {
      let prv = null;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        productUid: this.product.uid
      };

      if (this.product.inherited === true) {
        ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
      }

      let processData = await this.$restClient.callProcess('condAdmin', 'readProduct', ioData, true);
      if (isProcessStateOK(processData)) {
        prv = processData.ioData.product.prv;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return prv;
    },
    setDirty () {
      this.isDirty = true;
    },
    async save () {
      let newProduct = await saveProduct(this.product);
      console.debug('newProduct', JSON.stringify(newProduct));
      if (newProduct != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.productList.savedProduct') }]);
        this.isDirty = false;
        this.$router.push('/condAdmin/product');
      }
    },
    setActiveProduct (value) {
      let state = value === true ? 6 : 10;
      updateProductState(this.product, state);
    },
    async deleteProduct () {
      // check if product is assigend to active or coming rvGroup
      if (this.isAssigned === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.productList.productAssignedToRvGroup') }]);
        return;
      }

      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.productList.deleteProduct'), this.$t('condAdmin.productList.deleteProductConfirmText'))) {
        this.callDeleteProviderService();
      }
    },
    callDeleteProviderService () {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        productUid: this.product.uid
      };
      this.$restClient.callProcess('condAdmin', 'deleteProduct', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.productList.deletedProduct') }]);
            this.$store.state.condAdmin.productList.splice(
              this.$store.state.condAdmin.productList.findIndex((p) => p.uid === this.product.uid), 1
            );
            this.$router.push('/condAdmin/product');
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    clickedRow (item) {
      this.$router.push('/condAdmin/rvGroup/' + item.rvGroupUid + '/' + item.version + '/objects');
      console.log('clicked:', JSON.stringify(item));
    }
  }
};
</script>

<style scoped>
</style>
