<template>
  <div />
</template>

<script lang="js">
import { newMessage } from '@/base/js/ProcessDataHelper';
import { saveAs } from 'file-saver';
import DTH from '@/base/js/DateTimeHelper.js';

export default {
  name: 'JsonExporter',
  props: {
    objectType: { type: String, required: true },
    module: { type: String, required: true }
  },
  methods: {
    exportJsonData (exportData) {
      try {
        let timestamp = DTH.NOW;
        let shortname = this.$store.state.base.admin.administratedOrg.shortname;
        let fileName = 'ECC_' + shortname + '_' + '_' + this.module + '_' + this.objectType;
        fileName += '_' + timestamp.replace(' ', '_').replace(/:/g, '-') + '.json';
        let json = {
          header: {
            targetOrgKey: this.$store.state.base.admin.administratedOrg.uid,
            targetOrgName: shortname,
            module: this.module,
            objectType: this.objectType,
            timestamp: timestamp,
            user: this.$store.state.base.user.fullName,
            org: this.$store.state.base.org.shortname,
            stage: this.$configHelper.getStage(),
            version: this.$configHelper.getConfigParam('appVersion')
          },
          data: exportData
        };

        // create blob and save file
        let blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
        saveAs(blob, fileName);
        this.$globals.Info.open([newMessage('success', this.$t('base.jsonExporter.success'))]);
      } catch (err) {
        this.$globals.Info.open([newMessage('systemError', this.$t('base.jsonExporter.failed'), err.message)]);
      }
    }
  }
};
</script>

<style scoped>
</style>
