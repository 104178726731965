<template>
  <v-card
    class="minContent"
    v-if="filteredList != null"
  >
    <v-card-title>
      <v-menu
        offset-y
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="viewProduct()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ capitalizeString("base.edit")}}
          </v-list-item>
          <v-list-item @click="setActiveProducts(false)">
            <v-icon class="mr-4">mdi-pause</v-icon>
            {{ capitalizeString("condAdmin.actions.disable")}}
          </v-list-item>
          <v-list-item @click="setActiveProducts(true)">
            <v-icon class="mr-4">mdi-play</v-icon>
            {{ capitalizeString("condAdmin.actions.enable")}}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <QuickFilter
        v-if="hasInheritedProducts"
        class="mr-2"
        :label="$t('condAdmin.filter.inherited')"
        :count="inheritedCount"
        :active="showInherited === true"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <QuickFilter
        v-if="hasInheritedProducts"
        :label="$t('condAdmin.filter.own')"
        :count="filteredList.length - inheritedCount"
        :active="showInherited === false"
        :selectValue="false"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <v-btn
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
        @click="createProduct()"
        color="secondary"
        class="mx-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.newProduct'))"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon
        @click="loadLists()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('productTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
      <JsonImporter
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
        class="ml-8"
        objectType="products"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        v-model="selected"
        :headers="listHeaders"
        :items="filteredList"
        :items-per-page="footerProps.itemsPerPage"
        :footer-props="footerProps"
        item-key="uid"
        show-select
        fixed-header
        :sort-by="listSortKey"
        @click:row="viewProduct"
        :loading="$store.state.condAdmin.productsLoading"
        @update:items-per-page="getItemPerPage"
      >
        <template
          v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`body.prepend`]="{}">
          <tr>
            <td></td>
            <td
              v-for="(h, index) in listHeaders"
              :key="index"
            >
              <SearchSelect
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
                :items="productSelectLists(filter[h.value].items)"
                :item-text="filter[h.value].itemText"
                :item-value="filter[h.value].itemValue"
              />
              <SearchField
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
              >
              </SearchField>
            </td>
          </tr>
        </template>
        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <v-simple-checkbox
            color="secondary"
            v-bind="props"
            v-on="on"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`header.menu`]="{}">
        </template>
        <template v-slot:[`item.productTypeUid`]="{ item }">
          {{ ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)) == null ? item.productTypeUid : ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)).name }}
          <v-icon
            v-if="($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid && p.state === 10)) != null"
            v-tooltip="$tooltip($t('condAdmin.productType.disabled'))"
          >mdi-alert-outline</v-icon>
        </template>
        <template v-slot:[`item.rvGroups`]="{ item }">
          <template v-if="item.rvGroups != null && item.rvGroups.length > 0">
            <v-icon
              v-for="rvg, index in item.rvGroups"
              :key="index"
              :color="rvg.state === 6 ? 'success' : ''"
            > mdi-check-circle</v-icon>
          </template>
          <v-icon
            v-else
            color="error"
          >mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-chip :class="getStateClass(item)">
            {{ getStateText(item) }}
            <v-icon
              class="ml-2"
              v-if="item.inherited === true"
              v-tooltip="$tooltip($t('condAdmin.product.inheritedProduct'))"
            >{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock' }}</v-icon>
          </v-chip>
        </template>

        <template v-slot:[`item.menu`]="{ item }">
          <v-menu
            offset-y
            v-if="hasUserRight('manageResidualValues', null, item.brand)"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="viewProduct(item)">
                <v-icon class="mr-4">mdi-pencil</v-icon>
                {{ capitalizeString("base.edit")}}
              </v-list-item>
              <v-list-item
                v-if="item.state === 6"
                @click="setActiveProduct(item, false)"
              >
                <v-icon class="mr-4">mdi-pause</v-icon>
                {{ capitalizeString("condAdmin.actions.disable")}}
              </v-list-item>
              <v-list-item
                v-else
                @click="setActiveProduct(item, true)"
              >
                <v-icon class="mr-4">mdi-play</v-icon>
                {{ capitalizeString("condAdmin.actions.enable")}}
              </v-list-item>
              <!--<v-list-item @click="showHistory('getProductHistory', item.uid, '')">
                {{ capitalizeString('condAdmin.actions.history') }}
              </v-list-item>-->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <JsonExporter
      objectType="products"
      module="condAdmin"
      ref="jsonExporter"
    />
  </v-card>
</template>

<script lang="js">
import { refreshProductList, refreshProductTypeList, updateProductState, productUniqueCheck, saveProduct } from '../js/condAdminHelper.js';
import { newMessage } from '@/base/js/ProcessDataHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import condAdminMixin from '../js/condAdminMixin.js';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import QuickFilter from '@/common/QuickFilter';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';

export default {
  name: 'ProductTable',
  components: {
    SearchField,
    SearchSelect,
    QuickFilter,
    JsonImporter,
    JsonExporter
  },
  mixins: [ condAdminMixin ],

  data () {
    return {
      selected: [],
      listSortKey: 'brand',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      filteredList: null,
      availableColumns: [
        { text: this.$t('condAdmin.product.productType'), active: true, value: 'productTypeUid' },
        { text: this.$t('condAdmin.product.brand'), active: true, value: 'brand', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productName'), active: true, value: 'productName', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productKey'), active: false, value: 'productKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.orderKey'), active: false, value: 'orderKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.chassis'), active: true, value: 'chassis', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.driveType'), active: true, value: 'driveType', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.serialId'), active: false, value: 'serialId', formatter: this.formatValue },
        { text: this.$t('base.state'), active: true, value: 'state' },
        { text: this.$t('condAdmin.product.rvGroupAssigned'), active: true, value: 'rvGroups' }
      ],
      showInherited: null,
      filter: {
        productTypeUid: { condition: 'is', value: null, type: 'SelectBox', items: 'productSelectTypeList', itemText: 'name', itemValue: 'uid' },
        brand: { condition: 'startsWith', value: null, type: 'SelectBox', items: 'brandList' },
        productName: { condition: 'startsWith', value: null, type: 'SearchField' },
        productKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        orderKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        chassis: { condition: 'startsWith', value: null, type: 'SearchField' },
        driveType: { condition: 'startsWith', value: null, type: 'SearchField' },
        serialId: { condition: 'startsWith', value: null, type: 'SearchField' },
        rvGroups: { condition: 'bool', value: null, type: 'SelectBox', items: 'trueFalseList' }
      },
      doubletFields: [
        { key: 'brand', text: this.$t('condAdmin.product.brand'), unique: false, readonly: true },
        { key: 'productName', text: this.$t('condAdmin.product.productName'), unique: false, readonly: true },
        { key: 'chassis', text: this.$t('condAdmin.product.chassis'), unique: false, readonly: true },
        { key: 'driveType', text: this.$t('condAdmin.product.driveType'), unique: false, readonly: true }
      ]
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    brandList () {
      let list = [];
      for (let b of this.$store.state.condAdmin.productList) {
        if (b.brand != null && !list.includes(b.brand)) list.push(b.brand);
        if (b.brand == null && !list.includes(this.NULLVALUE)) list.push(this.NULLVALUE);
      }
      return list.sort((a, b) => a > b);
    },
    hasInheritedProducts () {
      return (this.$store.state.condAdmin.productList.filter((c) => c.inherited === true).length > 0);
    },
    inheritedCount () {
      return this.filteredList.filter((e) => e.inherited === true).length;
    }
  },
  async mounted () {
    this.getUserTableSettings('productTable');
    this.$emit('setPageMode', 'table');
    this.filterProducts();
  },
  watch: {
    '$store.state.condAdmin.productList': function () {
      this.filterProducts();
    }
  },
  methods: {
    filterProducts () {
      let list = this.$store.state.condAdmin.productList;
      if (this.showInherited === true) {
        list = list.filter((e) => e.inherited === true);
      } else if (this.showInherited === false) {
        list = list.filter((e) => e.inherited !== true);
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    applyInheritedFilter (value) {
      this.showInherited = value;
      console.log('inheritedFilter', this.showInherited);
      this.filterProducts();
    },
    productSelectLists (value) {
      switch (value) {
        case 'productSelectTypeList':
          return this.$store.state.condAdmin.productTypeList;
        case 'brandList':
          return this.brandList;
        case 'trueFalseList':
          return [{ text: this.$t('base.assigned'), value: true }, { text: this.$t('base.NULL'), value: false }];
      }
    },
    async loadLists (forced = true) {
      if (forced || this.$store.state.condAdmin.productList == null || this.$store.state.condAdmin.productList.length === 0) {
        await refreshProductList();
      }
      if (this.$store.state.condAdmin.productTypeList == null) await refreshProductTypeList();
      this.filterProducts();
    },
    createProduct () {
      if (this.hasUserRight('manageResidualValues')) {
        this.$router.push('/condAdmin/product/create');
      }
    },
    viewProduct (product = null) {
      if (!product) {
        product = this.selected[0];
      }
      if (this.hasUserRight('readResidualValues')) {
        this.$router.push('/condAdmin/product/' + product.uid);
      }
    },
    setActiveProducts (value) {
      let state = value === true ? 6 : 10;
      for (let product of this.selected) {
        if (state !== product.state) updateProductState(product, state);
      }
    },
    setActiveProduct (product, value) {
      let state = value === true ? 6 : 10;
      updateProductState(product, state);
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['productTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['productTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filterProducts();
      this.$store.state.condAdmin.userSettings['productTable'].filter[filtername] = value;
    },
    exportData () {
      let products = JSON.parse(JSON.stringify(this.selected));
      for (let p of products) {
        p.productType = null;
        if (p.productTypeUid != null) {
          let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.uid === p.productTypeUid);
          p.productType = pt.name;
        }
        delete p.id;
        delete p.uid;
        delete p.clientKey;
        delete p.productTypeUid;
        delete p.state;
        delete p.rvGroupId;
        delete p.timestamp;
      }
      this.$refs.jsonExporter.exportJsonData({ products });
    },
    async importData (json) {
      // check rights
      if (!this.hasUserRight('manageResidualValues')) {
        this.$globals.Info.open([newMessage('userError', this.$t('base.error.insufficientRights'))]);
        return;
      }

      await refreshProductList();
      // await refreshProductTypeList();

      let imported = 0;
      let total = json.data.products.length;

      for (let imp of json.data.products) {
        imp.productTypeUid = null;
        if (imp.productType != null) {
          let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.name === imp.productType);
          imp.productTypeUid = pt.uid;
        }
        // check already existing product
        let existing = productUniqueCheck(imp);
        if (existing == null) {
          await saveProduct(imp);
          console.log('imported', JSON.stringify(imp));
          imported++;
        }
      }
      if (total - imported > 0) this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.productList.notImportedDoublets', { count: total - imported })) ]);
      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported > 0) this.loadLists();
    },
    preImportCheckFunction (json) {
      if (json.data.products == null || json.data.products.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.productList.noImportData')) ]);
        return false;
      }
      return true;
    },
    importUniqueCheck (product) {
      let existing = productUniqueCheck(product);
      return existing;
    }
  }
};
</script>

<style scoped>
</style>
