<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-wallet-plus-outline"
    />
    <router-view
      v-if="$store.state.condAdmin.additionalProductList != null"
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.additionalProductList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';
import condAdminAdditionalProductMixin from './js/condAdminAdditionalProductMixin.js';

export default {
  name: 'AdditionalProducts',
  mixins: [ condAdminAdditionalProductMixin ],
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.additionalProductList.headline')
    };
  },
  async mounted () {
    // nur Aufruf bei Seitenwechsel
    if (this.$store.state.condAdmin.clientKey == null) {
      this.$logger.debug('additionalProductsVue', 'mounted: ', 'if');
      // this.initClient();
    } else { // wenn keine Änderung des "aktuell administrierten Kontos erfolgt ist
      if (this.$store.state.condAdmin.administratableClientKey !== this.$store.state.base.admin.administratedOrg.uid) {
        this.$logger.debug('additionalProductsVue', 'mounted: ', 'else if');
        await this.refreshAdditionalProductList();
      } else {
        this.$logger.debug('additionalProductsVue', 'mounted: ', 'else else');
        this.clientVerified = true;
      }
    }
  },
  watch: {
    '$store.state.base.admin.administratedOrg': function () {
      this.$logger.debug('condAdmin', 'getAdditionalProductList watch !!!');
      this.refreshAdditionalProductList();
      if (!this.$route.path.endsWith('/condAdmin/additionalProduct')) this.$router.push('/condAdmin/additionalProduct');
      // this.setPageMode('table');
    }
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'edit':
          this.headlineText = headlineParam + ' - ' + this.$t('condAdmin.additionalProductList.edit');
          break;
        case 'copy':
        case 'new':
          this.headlineText = this.$t('condAdmin.additionalProductList.create');
          break;
        case 'view':
          this.headlineText = /* this.$t('condAdmin.conditionList.conditionEdit') + ': ' + */ headlineParam;
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.additionalProductList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
