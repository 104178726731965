<template>
  <v-row
    no-gutters
    :class="alignment"
  >
    <v-btn
      :id="$options.name + 'Cancel'"
      v-if="showCancel"
      @click.native="$emit('cancel')"
      :icon="type === 'icon'"
      :text="type === 'text'"
      :small="buttonSize === 'small'"
      class="mr-4 prio2"
      :class="buttonClass"
    >
      <v-icon v-if="showIcon">{{ cancelIcon }}</v-icon>
      {{ cText }}
    </v-btn>
    <v-btn
      :id="$options.name + 'Save'"
      v-if="showSave"
      @click.native="$emit('save')"
      :icon="type === 'icon'"
      :text="type === 'text'"
      :small="buttonSize === 'small'"
      :large="buttonSize === 'large'"
      :class="buttonClass"
      :color="buttonColor"
      :disabled="saveDisabled"
    >
      <v-icon v-if="showIcon">{{ saveIcon }}</v-icon>
      {{ sText }}
    </v-btn>
  </v-row>
</template>

<script lang="js">
export default {
  name: 'SaveCancelButtonBar',
  props: {
    align: { type: String, default: () => { return 'right'; } },
    type: { type: String, default: () => { return 'textBtn'; } }, // text, icon, fullBtn, iconBtn, textBtn
    buttonClass: { type: String, default: () => { return ''; } },
    buttonSize: { type: String, default: () => { return ''; } },
    buttonColor: { type: String, default: () => { return 'secondary'; } },
    saveText: { type: String, default: null },
    saveIcon: { type: String, default: () => { return 'mdi-check-circle'; } },
    showCancel: { type: Boolean, default: true },
    showSave: { type: Boolean, default: true },
    saveDisabled: { type: Boolean, default: false },
    cancelText: { type: String, default: null },
    cancelIcon: { type: String, default: () => { return 'mdi-close-circle'; } }
  },
  computed: {
    showIcon () {
      return (this.type === 'icon' || this.type === 'iconBtn' || this.type === 'fullBtn');
    },
    sText () {
      if (this.type === 'text' || this.type === 'textBtn' || this.type === 'fullBtn') {
        if (this.saveText) return this.saveText;
        return this.$t('base.save');
      }
      return '';
    },
    cText () {
      if (this.type === 'text' || this.type === 'textBtn' || this.type === 'fullBtn') {
        if (this.cancelText) return this.cancelText;
        return this.$t('base.cancel');
      }
      return '';
    },
    alignment () {
      switch (this.align) {
        case 'left': return ' justify-start';
        case 'center': return ' justify-center';
        case 'outside': return ' justify-space-between';
        case 'spread': return ' justify-space-around';
        case 'right':
        default: return ' justify-end';
      }
    }
  }
};
</script>

<style scoped>
.v-btn:not(.v-btn--icon) {
  min-width: 100px !important;
}
</style>
