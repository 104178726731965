/* <script> */
import { v4 as uuidv4 } from 'uuid';
import condAdminMixin from './condAdminMixin.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { refreshProductList, formatLock } from './condAdminHelper.js';

export default {
  mixins: [condAdminMixin],
  data () {
    return {
      rules: {
        required: (value) => !!value || this.$t('base.required'),
        max100: (value) => value == null || value <= 100.0 || this.$t('condAdmin.rvGroupEditor.infoMax100Percent'),
        isArray: (array) => array != null && array.length > 0
      }
    };
  },
  computed: {
  },
  methods: {
    async refreshRvGroupList () {
      this.$store.state.condAdmin.rvGroupList = [];
      this.$store.state.condAdmin.rvGroupsLoading = true;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };

      let processData = await this.$restClient.callProcess('condAdmin', 'getRvGroupList', ioData);
      if (isProcessStateOK(processData)) {
        this.$store.state.condAdmin.rvGroupList = this.prepareRvGroupList(processData.ioData.rvGroupList);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      this.$store.state.condAdmin.rvGroupsLoading = false;
    },
    prepareRvGroupList (rvGroupList) {
      if (rvGroupList == null || (rvGroupList.length > 0 && rvGroupList[0] == null)) return [];
      for (let rvGroup of rvGroupList) {
        rvGroup.feid = rvGroup.uid + '_' + rvGroup.version;
      }
      return rvGroupList;
    },
    // ----------------------------------------------------------------------------------------------
    //
    //  readRvGroup
    //
    // ----------------------------------------------------------------------------------------------
    async readRvGroup (uid, version, getVersions = false, useCopy = false) {
      let rvGroup = {};
      if (useCopy) {
        rvGroup = JSON.parse(JSON.stringify(this.$store.state.condAdmin.rvGroupList.find((v) => v.uid === uid && v.version === version)));
      } else {
        rvGroup = this.$store.state.condAdmin.rvGroupList.find((v) => v.uid === uid && v.version === version);
      }
      // let rvGroup = this.$store.state.condAdmin.rvGroupList.find((v) => v.uid === uid && v.version === version);
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        rvGroupUid: uid,
        rvGroupVersion: version,
        includeVersions: getVersions && (rvGroup == null || rvGroup.versions == null)
      };
      if (rvGroup != null && rvGroup.inherited === true) {
        ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
      }

      let processData = await this.$restClient.callProcess('condAdmin', 'readRvGroup', ioData, true);
      if (isProcessStateOK(processData)) {
        if (rvGroup == null) {
          rvGroup = processData.ioData.rvGroup;
        } else {
          Object.assign(rvGroup, processData.ioData.rvGroup);
        }
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return rvGroup;
    },
    // ---------------------------------------------------------------------------------------------- //
    //
    //    Save
    //
    // ---------------------------------------------------------------------------------------------- //
    async saveRvGroup () {
      let processData = await this.saveResidualValueGroup(this.rvGroup, false);
      if (processData != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.rvGroupEditor.infoRRvGroupSaved') }]);
        this.rvGroup.isDirty = false;
        // update AdditionalProductList
        await this.refreshRvGroupList();
        await refreshProductList();
        this.$store.state.condAdmin.workingRvGroup = null;

        this.locked = false;
        this.$router.push('/condAdmin/rvGroup/' + this.rvGroup.uid + '/' + this.rvGroup.version + '/' + this.rvGroupTab);
      }
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    async showRVResultTable (rvTableKey, residualValueMods, baseRVMods, headline, editMode = false) {
      let rvTableList = [];
      let result = null;

      rvTableList = this.$store.state.condAdmin.rvTableList.find((tl) => (rvTableKey === tl.rvTableKey) &&
        (((this.rvGroup.validFrom == null || this.DateTimeHelper.isEarlierOrEqual(tl.validFrom, this.rvGroup.validFrom)) && (tl.validTo == null || this.DateTimeHelper.isLater(tl.validTo, this.rvGroup.validFrom)))));
      if (rvTableList != null) {
        let rvTable = await this.readRvTableData(rvTableList.rvTableKey, rvTableList.version);
        if (rvTable != null) {
          result = await this.$refs.residualValuesResultTableDlg.show(
            headline,
            rvTable,
            residualValueMods,
            baseRVMods,
            editMode
          );
        }
      }
      return result;
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    async checkEditPreRvGroups (mode, rvGroup = null) {
      let newVersion = 1;
      let uid = null;
      let rvGroupList = this.$store.state.condAdmin.rvGroupList;

      if (mode === 'edit') {
        uid = rvGroup.uid;

        if (rvGroup.state !== 0 && rvGroup.state !== 1) {
          // check if draft--version already exists
          let draft = rvGroupList.find((v) => v.uid === uid && v.state === 0);

          if (draft != null) {
            try {
              let replace = await this.$globals.Confirm.yesNoCancelDlg(
                this.$t('condAdmin.additionalProductEditor.dlghDraftExists'),
                this.$t('condAdmin.additionalProductEditor.dlgtDraftExists'),
                this.$t('base.replace'), this.$t('base.keep'));
              if (!replace) {
                rvGroup = draft;
              }
              newVersion = draft.version;
            } catch (e) {
              return false;
            }
          } else {
            newVersion = 1;
            // find highest version
            for (let v of rvGroupList) {
              if (v.version > newVersion && v.uid === uid) newVersion = v.version;
            }
            newVersion++;// = newVersion + 1;
          }
        } else {
          newVersion = rvGroup.version;
        }
      } else { // copy or new-mode
        uid = uuidv4();
      }

      // check and set editLock in BE
      let isLockSet = false;
      let lockIoData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        targetType: 'rvGroup',
        targetIdentifier: uid,
        targetVersion: newVersion
      };

      let forceUpdateLock = false;
      let finishedLockCheck = false;
      let processName = 'createLock';
      while (!finishedLockCheck) {
        let processData = await this.$restClient.callProcess('condAdmin', processName, lockIoData, true);
        if (isProcessStateOK(processData)) {
          if (processData.processState.state === 'warning') {
            // rvGroup already locked
            let lock = processData.ioData.lock;
            forceUpdateLock = await this.$globals.Confirm.yesNoDlg(
              this.$t('condAdmin.rvGroupEditor.dlghRvGroupLocked'),
              this.$t('condAdmin.rvGroupEditor.dlgtRvGroupLocked', formatLock(lock)),
              this.$t('base.overwrite'), this.$t('base.cancel'));
            if (forceUpdateLock) {
              processName = 'forceLock';
            } else {
              finishedLockCheck = true;
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
            finishedLockCheck = true;
            isLockSet = true;
          }
        } else {
          this.$globals.Info.open(processData.processState.messages);
          finishedLockCheck = true;
        }
      }

      if (isLockSet) {
        this.setWorkingRvGroup(mode, uid, newVersion, rvGroup);

        return true;
      }
      return false;
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    setWorkingRvGroup (mode = 'new', uid, version = 1, source = null) {
      console.log('setWorkingResidualValuesTable', mode);

      let workingValidFrom = this.DateTimeHelper.NOW;
      let workingValidTo = this.DateTimeHelper.INFINITEDATETIME;

      if (mode === 'copy') {
        if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
        if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
      }
      if (mode === 'edit') {
        if (source.state === 0 || source.state === 1) {
          if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
          if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
        } else {
          if (source.validTo != null && this.DateTimeHelper.isLater(source.validTo /* than NOW */)) {
            if (this.DateTimeHelper.isEarlier(source.validTo, this.DateTimeHelper.INFINITEDATE)) {
              workingValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter(source.validTo);
            } else if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) {
              workingValidFrom = source.validFrom;
            }
          }
        }
      }

      let wAP = {
        workingMode: mode,
        isDirty: false,
        clientKey: this.$store.state.condAdmin.clientKey,
        feid: uid + '_' + version,
        uid: uid,
        version: version,
        validFrom: this.DateTimeHelper.formatSqlDateTime(workingValidFrom),
        validTo: this.DateTimeHelper.formatSqlDateTime(workingValidTo),
        state: 0,
        name: (mode === 'edit' || mode === 'copy') ? source.name : '',
        productTypes: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.productTypes)) : [],
        brands: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.brands)) : [],
        finRVTableKey: (mode === 'edit' || mode === 'copy') ? source.finRVTableKey : '',
        finRvTableKey: (mode === 'edit' || mode === 'copy') ? source.finRvTableKey : '',

        leasRVTableKey: (mode === 'edit' || mode === 'copy') ? source.leasRVTableKey : '',
        leasRvTableKey: (mode === 'edit' || mode === 'copy') ? source.leasRvTableKey : '',
        finBaseRVMods: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.finBaseRVMods)) : [],
        leasBaseRVMods: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.leasBaseRVMods)) : [],
        finSubProductMods: (mode === 'edit' || mode === 'copy') ? (source.finSubProductMods != null ? JSON.parse(JSON.stringify(source.finSubProductMods)) : {}) : {},
        leasSubProductMods: (mode === 'edit' || mode === 'copy') ? (source.leasSubProductMods != null ? JSON.parse(JSON.stringify(source.leasSubProductMods)) : {}) : {},
        finAdditionalParams: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.finAdditionalParams)) : {},
        leasAdditionalParams: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.leasAdditionalParams)) : {},
        versions: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.versions)) : [],
        assignedProducts: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.assignedProducts)) : []
      };

      // console.debug('wAP', JSON.parse(JSON.stringify(wAP)));
      this.$store.state.condAdmin.workingRvGroup = wAP;
      if (mode === 'edit' && source != null) source.outdated = true;
    },
    // ---------------------------------------------------------------------------------------------------

    // ---------------------------------------------------------------------------------------------------
    async setResValueGroupState (rvGroup, state) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        rvGroupUid: rvGroup.uid,
        rvGroupVersion: rvGroup.version,
        state: state
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'updateRvGroupState', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoChangedState') }]);
        rvGroup.state = processData.ioData.newState;
        let listedRvGroup = this.$store.state.condAdmin.rvGroupList.find((v) => v.uid === rvGroup.uid && v.version === rvGroup.version);
        if (listedRvGroup != null) {
          listedRvGroup.state = processData.ioData.newState;
        }
        return true;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return false;
    },
    // ---------------------------------------------------------------------------------------------------

    // ---------------------------------------------------------------------------------------------------
    async deleteRvGroup (rvGroup, skipDialog = false) {
      if (rvGroup.state > 5 || rvGroup.inherited === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.rvGroupEditor.infoCannotDeleteRvGroup') }]);
        return false;
      }
      if (skipDialog === true || await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvGroupEditor.dlghDeleteRvGroup'), this.$t('condAdmin.rvGroupEditor.dlgtDeleteRvGroup'))) {
        let ioData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          rvGroupUid: rvGroup.uid,
          rvGroupVersion: rvGroup.version
        };
        let processData = await this.$restClient.callProcess('condAdmin', 'deleteRvGroup', ioData, true);
        if (isProcessStateOK(processData)) {
          this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.rvGroupEditor.infoDeletedRvGroup') }]);
          this.refreshRvGroupList();
          return true;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
      return false;
    },
    /* *******************
    *
    * Save and check
    *
    ******************* */
    async saveResidualValueGroup (rvGroup) {
      if (rvGroup.state !== 0 && rvGroup.state !== 1) {
        return null;
      }

      let messages = [];
      // check mandatory values
      // rvGroup Name
      if (this.rules.required(rvGroup.name) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvGroup.name') });
      // check rvGroup Objects
      if (this.rules.required(rvGroup.assignedProducts) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvGroup.assignedProducts') });
      // check rvGroup productType
      if (this.rules.isArray(rvGroup.productTypes) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvGroup.productType') });
      // check rvGroup TableKeys
      if (this.rules.required(rvGroup.finRVTableKey) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvGroup.rvTableKeyFin') });
      // check rvGroup TableKeys
      if (this.rules.required(rvGroup.leasRVTableKey) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvGroup.rvTableKeyLeas') });

      // if rvGroup.validTo is passed an error will be returned, because rvGroup can't become active anymore
      if (rvGroup.validTo != null && this.DateTimeHelper.isEarlier(rvGroup.validTo /* than NOW */)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') });
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        try {
          /*
          let prList = [];
          let grList = [];
          let dependencyList = []; */
          // if rvGroup.validFrom is passed it will be set to current date
          if (rvGroup.validFrom == null || this.DateTimeHelper.isEarlier(rvGroup.validFrom /* than NOW */)) {
            rvGroup.validFrom = this.DateTimeHelper.NOW;
            this.$globals.Info.open([{ type: 'warning', message: this.$t('condAdmin.rvGroupEditor.infoValidFromChangedToToday') }]);
          }

          let rvGroup2save = JSON.parse(JSON.stringify(rvGroup));

          // clean Values
          delete rvGroup2save.feid;
          delete rvGroup2save.versions;
          delete rvGroup2save.isDirty;
          delete rvGroup2save.workingMode;
          delete rvGroup2save.productTypes;
          delete rvGroup2save.brands;

          // create ioData
          let ioData = {
            clientKey: this.$store.state.condAdmin.clientKey,
            rvGroup: rvGroup2save
          };

          // backend-request
          let processName = rvGroup.workingMode === 'edit' ? 'updateRvGroup' : 'createRvGroup';
          let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
          if (isProcessStateOK(processData)) {
            await this.checkDependencies(rvGroup);
            return processData;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        } catch (err) {
          console.error(err.message);
        }
        return null;
      }
    },
    // ----------------------------------------------------------------------------------------
    //
    //
    //
    // ----------------------------------------------------------------------------------------
    checkDate (dateListFrom, dateListTo, dateCurGrFrom, dateCurGrTo) {
      let rValue = false;

      // Anfangsdatum de Listenrestwertgruppe liegt zwischen oder gleich Start und Endedatum der aktuellen zu speichernden Restwertgruppe
      if (this.DateTimeHelper.isLaterOrEqual(dateListFrom, dateCurGrFrom) && (dateCurGrTo == null || this.DateTimeHelper.isEarlierOrEqual(dateListFrom, dateCurGrTo))) {
        rValue = true;
        // Anfangsdatum der Listenrestwertgruppe ist vor dem Startdatum der aktuellen zu speichernden Restwertgruppe, aber Endedatum der Listenrestwertgruppe
        // ist nach dem Anfangsdatum der aktuellen zu speichernden Restwertgruppe
      } else {
        if (this.DateTimeHelper.isEarlier(dateListFrom, dateCurGrFrom) && (dateListTo == null || this.DateTimeHelper.isLater(dateListTo, dateCurGrFrom))) {
          rValue = true;
        }
      }
      return rValue;
    },
    // ----------------------------------------------------------------------------------------
    //
    // ----------------------------------------------------------------------------------------
    async checkDependencies (rvGroup) {
      // check Objects
      // console.debug('rvGroup', JSON.stringify(rvGroup));
      // Liste aller Objekte die zu der Restwertgruppe gehören
      let prList = this.$store.state.condAdmin.productList.filter((pI) => rvGroup.assignedProducts.find((aP) => aP.id === pI.id));

      let dependencyList = [];
      if (prList != null || prList.length > 0) {
        for (let prItem of prList) {
          for (let item of prItem.rvGroups) {
            // not a new rvGroup, rvGroup exist
            if (rvGroup.workingMode === 'edit' || rvGroup.workingMode === 'copy' || rvGroup.id != null) {
              // finde alle rvGruppen mit unterschiedlicher uid zu derzeit zu speichernden rvGruppe und aus dem ObjektItem
              let rvGrList = this.$store.state.condAdmin.rvGroupList.filter((grv) => grv.uid !== rvGroup.uid && item.rvGroupId === grv.id);
              if (rvGrList !== null) {
                for (let rvGrItem of rvGrList) {
                  if (!rvGrItem.inherited || typeof rvGrItem.inherited === 'undefined') {
                    // Beginn Datum der Listenrestwertgruppe liegt zwischen Anfang und Ende Datum der aktuellen Restwertgruppe
                    if (this.checkDate(rvGrItem.validFrom, rvGrItem.validTo, rvGroup.validFrom, rvGroup.validTo)) {
                      let dependency = {};
                      dependency.object = prItem.brand + ' ' + (prItem.productName == null ? '' : prItem.productName) + ' ' + (prItem.chassis == null ? '' : prItem.chassis) + ' ' + (prItem.driveType == null ? '' : prItem.driveType);
                      dependency.rvGroupName = rvGrItem.name;
                      dependency.version = rvGrItem.version;
                      dependency.validFrom = rvGrItem.validFrom == null ? this.DateTimeHelper.NOW : item.validFrom;
                      dependency.validTo = rvGrItem.validTo == null ? this.DateTimeHelper.INFINITEDATETIME : item.validTo;
                      dependencyList.push(dependency);
                    }
                  }
                }
              }
              // new Group
            } else {
              let rvGrList = this.$store.state.condAdmin.rvGroupList.filter((grv) => item.rvGroupId === grv.id);
              if (rvGrList !== null) {
                for (let rvGrItem of rvGrList) {
                  if (this.checkDate(rvGrItem.validFrom, rvGrItem.validTo, rvGroup.validFrom, rvGroup.validTo)) {
                    let dependency = {};
                    dependency.object = prItem.brand + ' ' + prItem.productName + ' ' + (prItem.chassis == null ? '' : prItem.chassis) + ' ' + (prItem.driveType == null ? '' : prItem.driveType);
                    dependency.rvGroupName = rvGrItem.name;
                    dependency.version = rvGrItem.version;
                    dependency.validFrom = rvGrItem.validFrom == null ? this.DateTimeHelper.NOW : item.validFrom;
                    dependency.validTo = rvGrItem.validTo == null ? this.DateTimeHelper.INFINITEDATETIME : item.validTo;
                    dependencyList.push(dependency);
                  }
                }
              }
            }
          }
        }
      }
      if (dependencyList.length > 0) {
        await this.$refs.dependencyObjectDialog.show(this.$t('condAdmin.rvGroupEditor.dlgdoubleDependencies'), dependencyList, this.$t('condAdmin.rvGroupEditor.dlgdoubleRVGroup'));
        return false;
      } else {
        return true;
      }
    }
  }
};
// </script>
