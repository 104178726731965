<template>
  <v-card
    v-if="loaded"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>

        <v-col
          lg="5"
          md="5"
          sm="6"
          xs="6"
        >
          <v-text-field
            readonly
            :label="$t('condAdmin.additionalProduct.tariffName')"
            :value="additionalProduct.tariffFEName"
          />
        </v-col>

        <v-col
          cols="12"
          lg="3"
          md="3"
          sm="6"
          xs="6"
        >
          <!--
          <v-text-field
            readonly
            :label="$t('condAdmin.additionalProduct.tariffId')"
            :value="additionalProduct.tariffId"
          />
          -->
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.push('/condAdmin/additionalProduct')"
              class="mr-4 prio2"
            >
              {{ $t('base.back') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('manageCondition')"
              @click.native="edit('edit')"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.edit') }}
            </v-btn>
            <v-btn
              v-if="hasUserRight('examineCondition') && enableAction('setChecked')"
              @click.native="setAdditionalProductState(4)"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.setChecked') }}
            </v-btn>

            <v-menu
              offset-y
              v-if="hasUserRight('manageCondition') || hasUserRight('examineCondition')"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <!--
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('copy')"
                  @click="edit('copy')"
                >{{ capitalizeString('condAdmin.actions.copy') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('create')"
                  @click="edit('new')"
                >{{ capitalizeString('condAdmin.actions.create') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageCondition', condition.providerUid, condition.brands)"
                  :disabled="!enableAction('end')"
                  @click="end()"
                >{{ capitalizeString('condAdmin.actions.end') }}
                </v-list-item>-->
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('remove')"
                  @click="removeDraft()"
                >{{ capitalizeString('condAdmin.actions.delete') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('setCheck')"
                  @click="setAdditionalProductState(2)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition')"
                  :disabled="!enableAction('setChecked')"
                  @click="setAdditionalProductState(4)"
                >{{ capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition')"
                  :disabled="!enableAction('setRework')"
                  @click="setAdditionalProductState(1)"
                >{{ capitalizeString('condAdmin.actions.setRework') }}
                </v-list-item>
                <v-list-item
                  v-if="hasActivatorRole() && hasUserRight('activateCondition')"
                  :disabled="!enableAction('enable')"
                  @click="setAdditionalProductState(6)"
                >{{ capitalizeString('condAdmin.actions.enable') }}
                </v-list-item>
                <v-list-item @click="showHistory('getAdditionalProductHistory', additionalProduct.uid, additionalProduct.tariffFEName)">{{ capitalizeString('condAdmin.actions.history') }}</v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
      <v-row>
        <v-col
          lg="8"
          md="8"
          sm="12"
          xs="12"
        >
          <v-select
            :label="$t('condAdmin.version')"
            :value="additionalProduct.version"
            :items="additionalProduct.versions"
            item-key="objectKey"
            item-text="version"
            @change="loadVersion"
          >
            <template v-slot:selection="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 150px;"
                >{{ item.version }}</div>
                <div style="width: 600px;">
                  {{ $t('condAdmin.validFrom') + ' ' }}
                  <span class="">{{ formatBeginDate(item.validFrom) }}</span>
                  {{ $t('base.to') }}
                  <span class="">{{ formatEndDate(item.validTo) }}</span>
                </div>
                <div>
                  <v-chip
                    :class="getStateClass(item, false)"
                    class=""
                  >
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 100px;"
                >{{ item.version }}</div>
                <div style="width: 150px;">
                  <v-chip :class="getStateClass(item, false)">
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
                <div>
                  <span class="text-caption">{{ $t('condAdmin.validFrom') + ' ' }}</span>
                  {{ formatBeginDate(item.validFrom) }}
                  <span class="text-caption">{{ $t('base.to') }}</span>
                  {{ formatEndDate(item.validTo) }}
                </div>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- #### additional Data #### -->
      <component
        class="pb-4 grey lighten-5"
        v-bind:is="customComponents.AdditionalProduct"
        ref="customAdditionalProduct"
        :params="additionalProduct || {}"
        :readonly="true"
        :clearable="false"
        v-show="customComponents.AdditionalProduct != null"
      ></component>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
  </v-card>
</template>

<script lang="js">
import condAdminAdditionalProductMixin from '../js/condAdminAdditionalProductMixin.js';
import HistoryDlg from './HistoryDlg';

export default {
  name: 'AdditionalProductDetail',
  components: {
    HistoryDlg

  },
  mixins: [ condAdminAdditionalProductMixin ],
  props: {
    // addProductUid: { type: String, required: true },
    // addProductVersion: { type: String, required: true }
    addProductObjectKey: { type: String, required: true }
  },
  data () {
    return {
      additionalProduct: null,
      customComponents: {},
      loaded: false
    };
  },
  computed: {
    additionalProductNameList () {
      let list = [];
      list.push(this.additionalProduct);
      return list.sort();
    }
  },
  async mounted () {
    if (this.$store.state.condAdmin.additionalProductList == null) {
      await this.refreshAdditionalProductList();
    }

    this.init(this.addProductObjectKey);
  },
  methods: {
    async init (addProductObjectKey) {
      this.loaded = false;

      // this.additionalProduct = this.$store.state.condAdmin.additionalProductList.find((c) => c.uid === this.addProductUid && c.version === version);
      this.additionalProduct = this.$store.state.condAdmin.additionalProductList.find((c) => c.objectKey === addProductObjectKey);
      if (this.additionalProduct == null || this.additionalProduct.versions == null) {
        // this.additionalProduct = await this.readAdditionalProduct(this.addProductUid, version, this.additionalProduct, false);
        this.additionalProduct = await this.readAdditionalProduct(addProductObjectKey, this.additionalProduct, true);
      }
      this.getAdditionalProductsComponents();
      this.$emit('setPageMode', 'view', this.additionalProduct.tariffName);
      this.loaded = true;
    },
    loadVersion (version) {
      let tempAP = this.additionalProduct.versions.find((e) => e.version === version);
      if (tempAP != null) {
        this.init(tempAP.objectKey);
      } else {
        this.init(this.addProductObjectKey);
      }
    },
    getAdditionalProductsComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('AdditionalProduct'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + this.additionalProduct.tariffValuesType + '_' + shortname + '.vue');
        }
      }
    },
    enableAction (action) {
      switch (action) {
        case 'edit':
        case 'copy':
        case 'create': return true;
        case 'end': return false;
        case 'remove': return this.additionalProduct.inherited !== true && this.additionalProduct.state < 6;
        case 'setCheck': return this.additionalProduct.state === 0 || this.additionalProduct.state === 1;
        case 'setChecked': return this.additionalProduct.state === 2 || this.additionalProduct.state === 4;
        case 'setRework': return this.additionalProduct.state === 2 || this.additionalProduct.state === 4;
        case 'enable': return this.additionalProduct.state === 5;
      }
      return true;
    },

    async edit (mode) {
      if (await this.checkEditPreAdditionalProduct(mode, this.additionalProduct) === true) {
        this.$router.push('/condAdmin/additionalProduct/edit');
      }
    },
    async removeDraft () {
      if (await this.deleteAdditionalProduct(this.additionalProduct) === true) {
        this.$router.push('/condAdmin/additionalProduct');
      }
    },
    async setAdditionalProductState (state) {
      let setState = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        let beginDateCheck = await this.validateBeginDate(this.additionalProduct);
        let endDateCheck = await this.validateEndDate(this.additionalProduct);
        setState = (beginDateCheck && endDateCheck);
      }

      if (setState) {
        await this.setState(this.additionalProduct, state);
        let v = this.additionalProduct.versions.find((c) => c.version === this.additionalProduct.version);
        v.state = this.additionalProduct.state;
      }
    },

    end () {
    }
  }
};
</script>

<style scoped>
</style>
