<template>
  <v-card
    v-if="tempProductType"
    flat
    tile
  >
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar
        class="mb-4"
        :saveDisabled="!isDirty"
        :cancelText="$t('base.back')"
        @cancel="cancel()"
        @save="save()"
      />
    </v-card-text>
    <v-card-text class="py-4 subProductList">
      <DlgInfoBar
        ref="dlgInfoBar"
        class="pb-2"
      />
      <v-row>
        <v-col cols=12>
          <v-text-field
            v-model="tempProductType.name"
            :rules="rules.concat([uniqueCheckFunction(tempProductType) == null || $t('condAdmin.productType.productTypeAlreadyExists', { name: tempProductType.name })])"
            persistent-hint
            :label="$t('condAdmin.productType.name') + $t('base.mandatory')"
            @change="setDirty"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="align-center pt-6 pb-2 justify-space-between"
      >
        <v-label>{{ $t('condAdmin.productType.subProductTypes') }}</v-label>
        <v-btn
          @click="createSubProductType()"
          color="secondaryContrast"
          class="secondary ml-4"
          icon
          v-tooltip="$tooltip($t('base.create'))"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row>
      <v-label v-if="tempProductType.subProductTypes.length === 0">
        {{ $t('condAdmin.productTypeList.noSubProductTypes') }}
      </v-label>
      <v-row
        no-gutters
        v-for="sub in activeSubProductTypes"
        :key="sub.uid"
      >
        <v-text-field
          :label="sub.name.length > 0 ? null : $t('base.insertText') + $t('base.mandatory')"
          :value="sub.name"
          @change="changedSubProductType(sub, $event)"
          :rules="rules.concat([checkUniqueSubproductName(sub) == null || $t('condAdmin.productType.subProductTypeAlreadyExists', { name: sub.name })])"
          dense
          :append-outer-icon="'mdi-delete-forever'"
          @click:append-outer="removeSubProductType(sub)"
        />
      </v-row>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { newMessage } from '@/base/js/ProcessDataHelper';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import DlgInfoBar from '@/common/DlgInfoBar';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';

export default {
  name: 'ProductTypeDetail',
  components: {
    SaveCancelButtonBar,
    DlgInfoBar,
    SaveOnLeaveDlg
  },
  props: {
    productType: { type: Object, default: () => { return null; } },
    uniqueCheckFunction: { type: Function, default: () => { return null; } }
  },
  data () {
    return {
      tempProductType: null,
      isDirty: false,
      newId: -1,
      errorMsg: null,
      rules: [
        (v) => this.checkLength(v)
      ]
    };
  },
  computed: {
    activeSubProductTypes () {
      return this.tempProductType.subProductTypes.filter((e) => e.state !== -1 && e.state !== 10);
    }
  },
  watch: {
    productType () {
      this.tempProductType = this.productType;
    }
  },
  async mounted () {
    this.tempProductType = this.productType;
  },
  methods: {
    cancel () {
      this.checkLeave();
    },
    async checkLeave () {
      return new Promise(async (resolve, reject) => {
        if (this.isDirty) {
          this.$refs.leaveDialog.open().then(async (save) => {
            if (save === true) await this.save();
            this.tempProductType = null;
            this.$emit('cancel');
            this.isDirty = false;
            resolve(true);
          }).catch((e) => {
            reject(e);
          });
        } else {
          this.tempProductType = null;
          this.$emit('cancel');
          resolve(true);
        }
      });
    },
    save () {
      let messages = [];
      let missingMandatory = false;
      this.errorMsg = null;
      if (this.uniqueCheckFunction(this.tempProductType) != null) {
        messages.push(newMessage('userError', this.$t('condAdmin.productType.productTypeAlreadyExists', { name: this.tempProductType.name })));
      }
      if (this.checkLength(this.tempProductType.name) !== true) missingMandatory = true;
      for (let sub of this.tempProductType.subProductTypes) {
        if (this.checkUniqueSubproductName(sub) != null) {
          messages.push(newMessage('userError', this.$t('condAdmin.productType.subProductTypeAlreadyExists', { name: sub.name })));
        }
        if (this.checkLength(sub.name) !== true) missingMandatory = true;
        if (sub.uid.startsWith('new-')) sub.uid = null;
      }
      if (missingMandatory) {
        messages.push(newMessage('userError', this.$t('condAdmin.productType.missingData')));
      }
      if (messages.length > 0) {
        this.$refs.dlgInfoBar.show(messages);
      } else {
        this.$refs.dlgInfoBar.hide();
        this.$emit('save', this.tempProductType);
      }
    },
    checkLength (v) {
      if (v.length === 0) return this.$t('base.mandatoryText');
      if (v.length > 45) return this.$t('base.exceededMaxTextLength', { l: 45 });
      return true;
    },
    checkUniqueSubproductName (sub) {
      let existing = this.tempProductType.subProductTypes.find((e) => e.name === sub.name && e.uid !== sub.uid);
      return existing;
    },
    changedSubProductType (sub, newName) {
      sub.name = newName;
      // if (sub.uid.startsWith('new')) {}
      sub.state = 0;
    },
    createSubProductType () {
      this.tempProductType.subProductTypes.push({ uid: 'new' + this.newId, name: '', state: 0 });
      this.setDirty();
      this.newId--;
    },
    removeSubProductType (sub) {
      sub.state = -1;
      // this.tempProductType.subProductTypes = this.tempProductType.subProductTypes.filter(e => e.uid !== sub.uid);
      this.setDirty();
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    },
    validate (value) {
      for (let rule of this.rules) {
        if (rule(value) !== true) return rule(value);
      }
      return null;
    }
  }
};
</script>

<style scoped>
.subProductList {
  min-height: 300px !important;
}
</style>
