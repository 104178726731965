<template>
  <v-card class="minContent">
    <v-card-title v-if="showEditor === false">
      <v-menu
        offset-y
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editProductType()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ capitalizeString("base.edit")}}
          </v-list-item>
          <v-list-item @click="deleteProductTypes()">
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ capitalizeString("base.delete")}}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span
        v-if="hasUserRight('manageBaseData')"
        class="ml-8 body-1"
      >
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <v-btn
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
        @click="createProductType()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.newProductType'))"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon
        @click="getProductTypeList()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('productTypeTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
      <JsonImporter
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
        class="ml-8"
        objectType="productTypes"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text
      class="pt-4"
      v-if="showEditor === false"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="filteredList"
            :items-per-page="footerProps.itemsPerPage"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.condAdmin.productTypesLoading"
            @update:items-per-page="getItemPerPage"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchField
                    v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu
                offset-y
                v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true && item.inherited !== true"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editProductType(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("base.edit")}}
                  </v-list-item>
                  <v-list-item @click="deleteProductType(item)">
                    <v-icon class="mr-4">mdi-delete-forever</v-icon>
                    {{ $t("base.delete")}}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip
                v-if="item.inherited === true"
                v-tooltip="$tooltip($t('condAdmin.productType.inheritedProductType'))"
              >
                <v-icon>mdi-link-lock</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.subProductTypes="{ item }">
              <v-label v-if="item.subProductTypes.length === 0">
                {{ $t('condAdmin.productTypeList.noSubProductTypes') }}
              </v-label>
              <v-chip
                v-for="sub in getActiveSubProductTypes(item.subProductTypes)"
                :key="sub.uid"
                class="mr-4 px-4"
                :class="sub.state !== 6 ? 'error' : ''"
              >
                <v-text-field
                  v-if="item.edit"
                  v-model="sub.newName"
                />
                <span v-else>{{ sub.name }}</span>
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!--<v-dialog
        v-model="showEditor"
        persistent
        max-width="800"
      >-->
    <ProductTypeDetail
      v-else
      :productType="editedItem"
      :uniqueCheckFunction="importUniqueCheck"
      ref="productTypeDetail"
      @cancel="cancelEditProductType"
      @save="saveProductType"
    />
    <!--</v-dialog>-->
    <JsonExporter
      objectType="productTypes"
      module="condAdmin"
      ref="jsonExporter"
    />
    <DoubletDlg
      ref="doubletDlg"
      :uniqueCheckFunction="importUniqueCheck"
      :fields="doubletFields"
      :hint="this.$t('condAdmin.productTypeList.importDoublet')"
    />
  </v-card>
</template>

<script lang="js">
import { refreshProductTypeList } from '../js/condAdminHelper.js';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import condAdminMixin from '../js/condAdminMixin.js';
import SearchField from '@/common/SearchField';
import ProductTypeDetail from './ProductTypeDetail';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import DoubletDlg from '@/common/DoubletDlg';

export default {
  name: 'ProductTypeTable',
  components: {
    SearchField,
    ProductTypeDetail,
    JsonImporter,
    JsonExporter,
    DoubletDlg
  },
  mixins: [ condAdminMixin ],
  data () {
    return {
      productTypeList: [],
      showEditor: false,
      editedItem: null,
      selected: [],
      listSortKey: 'name',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.productType.name'), active: true, value: 'name' },
        { text: this.$t('condAdmin.productType.subProductTypes'), active: true, value: 'subProductTypes' }
      ],
      filter: {
        name: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      doubletFields: [
        { key: 'name', text: this.$t('condAdmin.productType.name'), unique: true, readonly: false },
        { key: 'stList', text: this.$t('condAdmin.productType.subProductTypes'), unique: false, readonly: true }
      ]
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }
  },
  async mounted () {
    this.getUserTableSettings('productTypeTable');
    this.filteredList = complexFilter(this.$store.state.condAdmin.productTypeList, this.filter);
  },
  methods: {
    getActiveSubProductTypes (subProductTypes) {
      return subProductTypes.filter((e) => e.state === 6 || e.state === 0);
    },
    async checkLeave () {
      if (this.showEditor === true) {
        return this.$refs.productTypeDetail.checkLeave();
      }
      return true;
    },
    async getProductTypeList () {
      await refreshProductTypeList();
      this.filteredList = complexFilter(this.$store.state.condAdmin.productTypeList.filter((e) => e.state === 6 || e.state === 0), this.filter);
    },
    async deleteProductTypes () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.productTypeList.deleteProductType'), this.$t('condAdmin.productTypeList.deleteProductTypesConfirmText'))) {
        for (let productType of this.selected) {
          this.callDeleteProductTypeService(productType);
        }
      }
    },
    async deleteProductType (productType) {
      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.productTypeList.deleteProductType') + ': ' + productType.name, this.$t('condAdmin.productTypeList.deleteProductTypeConfirmText'))) {
        this.callDeleteProductTypeService(productType);
      }
    },
    callDeleteProductTypeService (productType) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        productTypeUid: productType.uid
      };
      this.$restClient.callProcess('condAdmin', 'deleteProductType', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.productTypeList.deletedProductType', { productType: productType.name }) }]);
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
          this.$store.state.condAdmin.productTypeList.splice(
            this.$store.state.condAdmin.productTypeList.findIndex((p) => p.uid === productType.uid), 1
          );
        }
      );
    },
    createProductType () {
      this.showEditor = true;
      this.editedItem = { uid: null, name: '', subProductTypes: [] };
      this.$emit('setPageMode', 'create');
    },
    editProductType (productType = null) {
      if (productType == null) {
        productType = this.selected[0].uid;
      }
      this.showEditor = true;
      this.editedItem = JSON.parse(JSON.stringify(productType));
      this.$emit('setPageMode', 'edit', productType.name);
    },
    cancelEditProductType () {
      this.showEditor = false;
      this.editedItem = null;
      this.$emit('setPageMode', 'table');
    },
    saveProductType (productType) {
      let processName = 'updateProductType';
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        productType: {
          name: productType.name,
          subProductTypes: productType.subProductTypes
        }
      };

      if (productType.uid == null) {
        processName = 'createProductType';
      } else {
        ioData.productType.uid = productType.uid;
      }

      this.$restClient.callProcess('condAdmin', processName, ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            if (productType.uid != null) {
              this.$store.state.condAdmin.productTypeList.splice(
                this.$store.state.condAdmin.productTypeList.findIndex((p) => p.uid === productType.uid), 1
              );
            }
            this.$store.state.condAdmin.productTypeList.push(processData.ioData.productType);
            this.showEditor = false;
            this.editedItem = null;
            this.$emit('setPageMode', 'table');
            this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.productTypeList.savedProductType', { productType: productType.name }) }]);
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    clickedRow (productType) {
      if (this.hasUserRight('manageBaseData') === true && this.getOrgSetting('createOwnData') === true && productType.inherited !== true) {
        this.editProductType(productType);
      }
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['productTypeTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['productTypeTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filteredList = complexFilter(this.$store.state.condAdmin.productTypeList, this.filter);
      this.$store.state.condAdmin.userSettings['productTypeTable'].filter[filtername] = value;
    },
    exportData () {
      let productTypes = JSON.parse(JSON.stringify(this.selected));
      for (let pt of productTypes) {
        for (let spt of pt.subProductTypes) {
          delete spt.uid;
        }
        delete pt.uid;
      }
      this.$refs.jsonExporter.exportJsonData({ productTypes });
    },
    async importData (json) {
      await refreshProductTypeList();
      let imported = 0;
      let total = json.data.productTypes.length;

      for (let imp of json.data.productTypes) {
        // check already existing dealerKey
        let existing = this.importUniqueCheck(imp);
        if (existing != null) {
          // build temporay subProductTypeList to be displayed in doubletDlg
          existing.stList = '';
          for (let st of existing.subProductTypes) existing.stList += ', ' + st.name;
          existing.stList = existing.stList.substring(2);
          imp.stList = [];
          for (let st of imp.subProductTypes) imp.stList += ', ' + st.name;
          imp.stList = imp.stList.substring(2);

          let [action, merged] = await this.$refs.doubletDlg.show(existing, imp);
          switch (action) {
            case 'overwrite':
              merged.uid = existing.uid;
              delete merged.stList;
              // try to map existing subProductTypeUids
              for (let st of merged.subProductTypes) {
                if (existing.stList.includes(st.name)) {
                  let est = existing.subProductTypes.find((e) => e.name === st.name);
                  st.uid = est.uid;
                }
              }
              await this.saveProductType(merged);
              // console.log('imported', JSON.stringify(merged));
              imported++;
              break;
            case 'copy':
              delete merged.stList;
              await this.saveProductType(merged);
              // console.log('imported', JSON.stringify(merged));
              imported++;
              break;
            case 'skipNew':
            default:
              break;
          }
        } else {
          await this.saveProductType(imp);
          // console.log('imported', JSON.stringify(imp));
          imported++;
        }
      }
      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported > 0) refreshProductTypeList();
    },
    preImportCheckFunction (json) {
      if (json.data.productTypes == null || json.data.productTypes.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.productTypeList.noImportData')) ]);
        return false;
      }
      return true;
    },
    importUniqueCheck (merged) {
      let existing = this.$store.state.condAdmin.productTypeList.find((i) => i.name === merged.name && i.uid !== merged.uid);
      return existing;
    }
  }
};
</script>

<style scoped>
</style>
