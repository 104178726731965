<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-chart-timeline-variant"
    />
    <router-view
      v-if="$store.state.condAdmin.rvTableList != null"
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.residualValuesList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';
import { newMessage } from '@/base/js/ProcessDataHelper';
import condAdminResidualValuesMixin from './js/condAdminResidualValuesMixin.js';

export default {
  name: 'ResidualValues',
  mixins: [ condAdminResidualValuesMixin ],
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.residualValuesList.headline')
    };
  },
  async mounted () {
    if (this.$store.state.condAdmin.orgSettings.showResidualValues !== true || (this.$route.path.startsWith('/condAdmin/rvTable') && !this.hasUserRight('readResidualValues'))) {
      if (this.$store.state.condAdmin.orgSettings.showConditions === true && this.hasUserRight('readCondition')) {
        this.$router.push('/condAdmin/condition');
      } else if (this.$store.state.condAdmin.orgSettings.showBaseData === true && this.hasUserRight('readBaseData')) {
        this.$router.push('/condAdmin/productType');
      } else {
        this.$globals.Info.open([newMessage('userError', this.$t('base.error.mismatchingRights'))]);
      }
      return;
    }
    if (this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) {
      await this.refreshRvTableList();
    }
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('condAdmin.residualValuesList.tableCreate');
          break;
        case 'edit':
          this.headlineText = this.$t('condAdmin.residualValuesList.tableEdit');// + ': ' + */ headlineParam;
          break;
        case 'view':
          this.headlineText = this.$t('condAdmin.residualValuesList.tableView');
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.residualValuesList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
