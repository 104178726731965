<template>
  <v-dialog
    v-model="dialog"
    light
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title class="px-4 py-2 primary-bg">
        {{ $t('condAdmin.history.label') + title }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="prio2"
          @click.native="close"
        >
          {{ $t('base.close') }}
        </v-btn>
      </v-card-actions>
      <v-card-text class="pa-4">
        <v-row v-if="hint != null">
          <v-col>{{ hint }}</v-col>
        </v-row>
        <v-row
          class="primary lighten-5"
          v-if="history.length > 0"
        >
          <v-col>{{ $t('condAdmin.history.timestamp') }}</v-col>
          <v-col>{{ $t('condAdmin.history.action') }}</v-col>
          <v-col>{{ $t('condAdmin.version') }}</v-col>
          <v-col>{{ $t('base.user') }}</v-col>
          <v-col>{{ $t('base.organisation') }}</v-col>
          <!--<v-col>{{ item.payload }}</v-col>-->
        </v-row>
        <v-row
          v-for="(item, index) in history"
          :key="index"
        >
          <v-col>{{ $filters.formatDateTime(item.timestamp) }}</v-col>
          <v-col>{{ $t('condAdmin.history.' + item.action) }}</v-col>
          <v-col>{{ item.version }}</v-col>
          <v-col>{{ item.user }}</v-col>
          <v-col>{{ item.org }}</v-col>
          <!--<v-col>{{ item.payload }}</v-col>-->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="prio2"
          @click.native="close"
        >
          {{ $t('base.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
export default {
  name: 'HistoryDlg',
  data () {
    return {
      dialog: false,
      resolve: null,
      title: '',
      hint: null,
      history: ''
    };
  },
  methods: {
    show (title, history, hint = null) {
      this.title = title;
      this.hint = hint;
      this.history = history; // .sort((a, b) => a.timestamp < b.timestamp);
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    close () {
      this.dialog = false;
      this.title = '';
      this.hint = null;
      this.history = [];
      this.resolve(true);
    }

  }
};
</script>

<style scoped>
.v-btn {
  min-width: 64px !important;
}
</style>
