<template>
  <v-dialog
    v-if= "rvTable != null"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="1500"
    light
    persistent
  >
    <v-card>
      <v-card-title class="px-4 py-2 primary-bg">
        {{ title }}
      </v-card-title>
      <v-card-title class="px-4 py-2 primary-bg">
        {{ additionalInfo }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
        <v-col>
          <v-label>{{ $t('condAdmin.rvTableList.residualValues') }}</v-label>
            <table class="text-center">
            <tr>
              <th></th>
              <th
                :colspan="rvTable.mileages.length"
                class="primary lighten-5"
              >{{ $t('condAdmin.rvTable.mileages') }}</th>
            </tr>
            <tr>
              <th class="primary lighten-3">{{ $t('condAdmin.rvTable.durations') }}</th>
              <th
                v-for="(m) in rvTable.mileages"
                :key="m"
                class="primary lighten-5"
              >{{ m }}
              </th>
            </tr>
            <tr
              v-for="(d) in rvTable.durations"
              :key="d"
            >
              <th class="primary lighten-3">{{ d }}
              </th>
              <!-- Residual Values -->
              <td
                class="px-4"
                v-for="(m) in rvTable.mileages"
                :key="m"
                :class="getModClassColor(d, m)">
                <v-row>
                  <v-col>
                    <span
                      v-if="readonly == true"
                      :class="ownValue(d,m) ? 'readOnlyOwnRV' : 'readOnlyInheritedRV'">
                      {{ getRvValue(d, m) }}
                    </span>
                    <EditField
                      v-if="readonly !== true"
                      @change="setRvValue(d,m,parseFloat($event))"
                      :value="getRvValue(d, m)"
                      :placeholder="$t('base.value')"
                      :readonly="!ownValue (d, m)"
                      :clearable="ownValue (d, m)"
                      :appendInnerIcon="ownValue (d, m) ? 'mdi-undo' : 'mdi-pencil'"
                      @appendInnerClicked="!ownValue(d,m) ? editValue(d,m): resetValue(d,m) "
                      v-tooltip="ownValue (d, m) ? $tooltip('auf Standardwert zurücksetzen') : $tooltip('eigenen Wert definieren')"
                      :class="ownValue(d,m) ? 'subsidyFieldEnabled' : 'subsidyFieldDisabled'"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      </v-card-text>
      <v-card-text v-if="readonly !== true">
        <v-row no-gutters>
          <span> <v-icon>mdi-pencil</v-icon><b> - Standardwert</b></span>
        </v-row>
        <v-row no-gutters>
          <span> <v-icon>mdi-undo</v-icon><b> - eigen definierter Wert</b></span>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if= !readonly
          class="prio2"
          @click.native="cancel"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-btn
          v-if= !readonly
          :disabled="!isDirty"
          class="prio2"
          color="secondary"
          @click.native="save"
        >
          {{ $t('base.save') }}
        </v-btn>
        <v-btn
          v-if= readonly
          class="prio2"
          @click.native="cancel"
        >
          {{ $t('base.close') }}
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script lang="js">
import { minRVValues } from '@/condAdmin/js/condAdminHelper.js';
import condAdminMixin from '@/condAdmin/js/condAdminMixin.js';
import EditField from '@/common/EditField';

export default {
  name: 'ResidualValuesResultTableDlg',
  components: {
    EditField
  },
  mixins: [ condAdminMixin ],
  data () {
    return {
      dialog: false,
      resolve: null,
      title: '',
      rvTable: null,
      rvGroupBaseMods: null,
      additionalInfo: '',
      readonly: false,
      subProductModsRV: null,
      readOnly: true,
      isDirty: false

    };
  },
  mounted () {

  },
  methods: {
    show (title, rvTable, subProductModsRV, rvGroupBaseMods, editMode = false) {
      this.title = title + ': ' + rvTable.name + ', Version: V' + rvTable.version;

      this.additionalInfo = ' gültig vom ' + this.DateTimeHelper.formatDate(rvTable.validFrom) + ' bis ' + this.DateTimeHelper.formatDate(rvTable.validTo);
      this.rvGroupBaseMods = rvGroupBaseMods;
      this.rvTable = rvTable;
      this.dialog = true;
      this.readonly = !editMode;
      this.subProductModsRV = subProductModsRV;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    save () {
      this.dialog = false;
      this.title = '';
      this.rvGroupBaseMods = null;
      this.rvTable = null;
      this.isDirty = false;
      // this.resolve(true);
      this.resolve(this.subProductModsRV);
    },
    cancel () {
      this.dialog = false;
      this.title = '';
      this.rvGroupBaseMods = null;
      this.rvTable = null;
      this.isDirty = false;
      // this.resolve(true);
      this.resolve(null);
    },

    // -----------------------------------------------------------------------------------------
    //
    //
    // -----------------------------------------------------------------------------------------
    getModClassColor (d, m) {
      let att = this.rvTable.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        switch (att.modClass) {
          case 1: return 'colorClass1';
          case 2: return 'colorClass2';
          case 3: return 'colorClass3';
          case 4: return 'colorClass4';
          case 5: return 'colorClass5';
          case 6: return 'colorClass6';
          case 7: return 'colorClass7';
          case 8: return 'colorClass8';
          case 9: return 'colorClass9';
          default: return 'colorClass0';
        }
      }
      return 'colorClass0';
    },
    getRvValue (d, m) {
      let att = null;

      if (this.subProductModsRV != null && (typeof this.subProductModsRV !== 'undefined') && this.subProductModsRV.length > 0) {
        att = this.subProductModsRV.find((e) => e.duration === d && e.mileage === m);
        if (att == null) {
          att = this.rvTable.attributes.find((e) => e.duration === d && e.mileage === m);
        }
      } else {
        att = this.rvTable.attributes.find((e) => e.duration === d && e.mileage === m);
      }
      if (att != null) {
        return att.residualValue + this.rvGroupBaseMods[att.modClass - 1].modValue;
      }
    },
    setRvValue (d, m, value) {
      let parentBaseMod = this.rvTable.attributes.find((e) => e.duration === d && e.mileage === m);
      let minAllowedRvValue = minRVValues[this.$store.state.condAdmin.countrySettings.key];
      if (value < minAllowedRvValue) {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.minResidualValue', { minRv: minAllowedRvValue }) }]);
      }
      if (value > 99) {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.maxResidualValue') }]);
      }
      if (this.subProductModsRV != null) {
        let att = this.subProductModsRV.find((e) => e.duration === d && e.mileage === m);
        if (att == null) {
          att = { 'mileage': m, 'duration': d, 'modClass': parentBaseMod.modClass, 'residualValue': (value - this.rvGroupBaseMods[parentBaseMod.modClass - 1].modValue) };
          this.subProductModsRV.push(att);
        } else {
          att.residualValue = value - this.rvGroupBaseMods[att.modClass - 1].modValue;
        }
      } else {
        this.subProductModsRV = [];
        let att = { 'mileage': m, 'duration': d, 'modClass': parentBaseMod.modClass, 'residualValue': value - this.rvGroupBaseMods[parentBaseMod.modClass - 1].modValue };
        this.subProductModsRV.push(att);
      }
      this.isDirty = true;
    },
    ownValue (d, m) {
      if (this.subProductModsRV.find((e) => e.duration === d && e.mileage === m) != null) return true;
      return false;
    },

    editValue (d, m) {
      let parentBaseMod = JSON.parse(JSON.stringify(this.rvTable.attributes.find((e) => e.duration === d && e.mileage === m)));
      if (this.subProductModsRV != null) {
        this.subProductModsRV.push(parentBaseMod);
      } else {
        this.subProductModsRV = [];
        this.subProductModsRV.push(parentBaseMod);
      }
    },
    resetValue (d, m) {
      this.subProductModsRV = this.subProductModsRV.filter((e) => e.duration !== d || e.mileage !== m);
    }

  }
};
</script>

<style scoped>
.v-btn {
  min-width: 64px !important;

}
.readOnlyOwnRV {
  color: #000000 !important;
   font-weight: bold;
}
.readOnlyInheritedRV {
  color: #8f8a8a !important;
}
.subsidyFieldEnabled >>> .v-text-field__slot,
.subsidyFieldEnabled >>> input {
  color: #000000 !important;
  font-weight: bold;
}
.subsidyFieldEnabled >>> .v-icon {
  color: #000000 !important;
  font-weight: bold;
}
.subsidyFieldDisabled >>> .v-text-field__slot,
.subsidyFieldDisabled >>> input {
  color: #8f8a8a !important;
}
.subsidyFieldDisabled >>> .v-icon {
  color: #8f8a8a !important;
}

</style>
