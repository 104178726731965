<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card v-if="condition != null">
      <v-card-title class="primary-bg">
        {{ $t('condAdmin.conditionImport.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row v-if="errors.length > 0">
          <v-col class="error--text">{{ $t('condAdmin.conditionImport.errors') }}</v-col>
        </v-row>
        <v-row
          v-for="err, index in errors"
          :key="index"
        >
          <v-col class="error--text">{{ err }}</v-col>
        </v-row>
        <v-row v-if="errors.length === 0">
          <v-col>{{ $t(found ? 'condAdmin.conditionImport.existingFound' : 'condAdmin.conditionImport.noExistingFound') }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.condition.conditionKey') }}</v-col>
          <v-col>{{ condition.conditionKey }}</v-col>
          <v-col>{{ $t('condAdmin.condition.name') }}</v-col>
          <v-col>{{ condition.name }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.condition.calcType') }}</v-col>
          <v-col>{{ condition.calcTypeName }}</v-col>
          <v-col>{{ $t('condAdmin.condition.provider') }}</v-col>
          <v-col>{{ condition.providerName }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.condition.version') }}</v-col>
          <v-col>{{ condition.version }}</v-col>
          <v-col>{{ $t('condAdmin.condition.validFrom') }}</v-col>
          <v-col>{{ condition.validFrom }}</v-col>
        </v-row>
        <v-row v-if="warnings.length > 0">
          <v-col class="warning--text">{{ $t('condAdmin.conditionImport.warnings') }}</v-col>
        </v-row>
        <v-row
          v-for="warn, index in warnings"
          :key="index"
        >
          <v-col class="warning--text">{{ warn }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :showSave="errors.length === 0"
          @cancel="skip"
          @save="startImport"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import { v4 as uuidv4 } from 'uuid';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'ConditionImportDlg',
  components: {
    SaveCancelButtonBar
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      errors: [],
      warnings: [],
      found: false,
      condition: null
    };
  },
  methods: {
    show (condition) {
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.condition = condition;
      // get providerUid from providerName
      let prov = this.$store.state.condAdmin.providerList.find((e) => e.name === condition.providerName);
      if (prov == null) {
        this.errors.push(this.$t('condAdmin.conditionImport.unresolvedProvider', { provider: condition.providerName }));
      } else {
        condition.providerUid = prov.uid;
      }
      // get calcTypeUid from calcType
      let ct = this.$store.state.condAdmin.calcTypeList.find((e) => e.calcType === condition.calcType);
      if (ct == null) {
        this.errors.push(this.$t('condAdmin.conditionImport.unresolvedCalcType'));
      } else {
        condition.calcTypeUid = ct.uid;
      }
      // get productTypeUid and subProductTypeUid
      for (let product of condition.products) {
        product.productTypeUid = null;
        product.subProductTypeUid = null;
        if (product.productType != null) {
          let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.name === product.productType);
          if (pt == null) {
            this.errors.push(this.$t('condAdmin.conditionImport.unresolvedProductType', { productType: product.productType }));
          } else {
            product.productTypeUid = pt.uid;
          }
          if (product.subProductType != null) {
            let spt = pt.subProductTypes.find((e) => e.name === product.subProductType);
            if (spt == null) {
              this.errors.push(this.$t('condAdmin.conditionImport.unresolvedSubProductType', { productType: product.productType, subProductType: product.subProductType }));
            } else {
              product.subProductTypeUid = spt.uid;
            }
          }
        }
      }
      let existing;
      if (condition.conditionKey === 'variabel') {
        existing = this.$store.state.condAdmin.conditionList.find(
          (e) => e.providerUid === condition.providerUid && e.name === condition.name
        );
      } else {
      // get conditionUid from conditionKey and provider and check calcType
        existing = this.$store.state.condAdmin.conditionList.find(
          (e) => e.providerUid === condition.providerUid && e.conditionKey === condition.conditionKey
        );
      }
      if (existing != null) {
        if (existing.calcType !== condition.calcType) {
          this.warnings.push(this.$t('condAdmin.conditionImport.differentCalcTypes', { existing: existing.calcType, import: condition.calcType }));
        }
        condition.uid = existing.uid;
        if (existing.state === 0) {
          condition.version = existing.version;
          this.warnings.push(this.$t('condAdmin.conditionImport.draftToBeOverwritten'));
        } else {
          condition.version = existing.version + 1;
        }
      } else {
        condition.uid = uuidv4();
        condition.version = 1;
      }
      condition.state = 0;
      this.found = existing != null;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    skip () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.condition = null;
      this.resolve('skip');
    },
    startImport () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.condition = null;
      this.resolve('startImport');
    }
  }
};
</script>

<style scoped>
</style>
