<template>
  <v-card
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="6"
        >
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
          sm="6"
          xs="6"
        >
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-btn
              @click.native="$router.back()"
              class="mr-4 prio2"
            >
              {{ $t('base.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!partnerOfTheWeek.isDirty"
              @click.native="savePartOfWeek()"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="!enableAction('save')"
                  @click="saveAddProduct()"
                >{{ capitalizeString('condAdmin.actions.save') }}</v-list-item>
                <v-list-item
                  :disabled="!enableAction('setCheck')"
                  @click="setCheckState(additionalProduct)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}</v-list-item>
              </v-list>
            </v-menu>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('condAdmin.version') }}</div>
          <div class="">{{ partnerOfTheWeek.version }}</div>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          <div class="caption">{{ $t('base.state') }} </div>
          <v-chip
            :class="stateClass"
            class=""
          >
            {{ stateText }}
          </v-chip>
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validFrom') + ' ' }}
          <DatePicker
            :value="partnerOfTheWeek.validFrom"
            :isBegin="true"
            @change="setProperty('validFrom', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
          sm="3"
          xs="3"
        >
          {{ $t('condAdmin.validTo') + ' ' }}
          <DatePicker
            :value="partnerOfTheWeek.validTo"
            :isEnd="true"
            @change="setProperty('validTo', $event)"
          />
          <!--<span class="text-h6">{{ formatEndDate(condition.validTo) }}</span>-->
        </v-col>
      </v-row>
      <!-- #### Produkt-Ein-/Ausschlüsse #### -->
      <v-row class="grey lighten-5">
          <v-col class="pb-0 text-h6">
            <span>{{ $t('condAdmin.partnerOfTheWeekLabel') }}</span>

          </v-col>
        </v-row>
        <v-row class="grey lighten-5">
          <v-col>
            <v-data-table
              :headers="partOfWeekHeaders"
              :items="partnerOfTheWeek.partOfTheWeek"
              fixed-header
              hide-default-footer
              :no-data-text="$t('condAdmin.partnerOfTheWeekDetail.noPartnerOfTheWeek')"
              item-key="id"
              class="grey lighten-5 noLines"

              :items-per-page= "-1">
              <template
                v-for="header in partOfWeekHeaders.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>

              <template v-slot:[`item.productType`]="{ item }">
                <v-select
                  :value="item.productTypeUid"
                  @change="setProductType(item, $event)"
                  :items="[{ uid: null, name: $t('base.all')}].concat($store.state.condAdmin.productTypeList)"
                  item-text="name"
                  item-value="uid"
                  hide-details
                  dense
                >
                </v-select>
              </template>
              <template v-slot:[`item.subProductType`]="{ item }">
                <v-select
                  :value="item.subProductTypeUid"
                  :disabled="item.productTypeUid == null"
                  @change="setSubProductType(item, $event)"
                  :items="[{ uid: null, name: $t('base.all')}].concat(getSubProductTypeList(item.productTypeUid))"
                  item-text="name"
                  item-value="uid"
                  item-key="uid"
                  hide-details
                  dense
                >
                </v-select>
              </template>
              <template v-slot:[`item.optimisationType`]="{ item }">
                <v-select
                  :value="item.optimisationType"
                  :items="[{ uid: null, name: $t('base.all')}].concat(optimisationTypeList)"
                  item-text="name"
                  item-value="uid"
                  @change="setOptimisationType(item, $event)"
                ></v-select>
              </template>
              <template v-slot:[`item.calcType`]="{ item }">
                <v-select
                  :value="item.calcTypeUid"
                  :items="[{ uid: null, name: $t('base.all')}].concat(calcTypeList)"
                  item-text="name"
                  item-value="uid"
                  @change="setCalcType(item, $event)"
                ></v-select>
              </template>
              <template  v-slot:[`item.providerType`]="{ item }">
                <v-select
                  :value="item.providerUid"
                  :items="[{ uid: null, name: $t('base.all')}].concat(providerList)"
                  item-text="name"
                  item-value="uid"
                  @change="setProvider(item, $event)"
                ></v-select>
              </template>

                <template v-slot:[`item.menu`]="{ item }">
                  <v-btn
                    @click="copyPartnerOfTheWeek(item)"
                    icon
                    color="secondary"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    @click="removePartnerOfTheWeek(item)"
                    icon
                    color="secondary"
                    :disabled="partnerOfTheWeek.partOfTheWeek.length < 1"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
            </v-data-table>
            <v-btn
              class="mt-2"
              icon
              color="secondary"
              @click="addPartnerOfWeek"
            >
              <v-icon large>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>

    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { setProperty } from '@/base/js/ObjectHelper';
import condAdminPartnerOfTheWeekMixin from '../js/condAdminPartnerOfTheWeekMixin.js';
// import EditField from '@/common/EditField';
import DatePicker from '@/common/DatePicker';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import { refreshProductTypeList, refreshCalcTypeList, removeLock } from '../js/condAdminHelper.js';
import { refreshProviderList } from '../js/condAdminProviderHelper.js';

export default {
  name: 'PartnerOfTheWeekEditor',
  mixins: [ condAdminPartnerOfTheWeekMixin ],
  components: {
    DatePicker,
    // EditField,
    SaveOnLeaveDlg
  },
  data () {
    return {
      partnerOfTheWeek: this.$store.state.condAdmin.workingPartnerOfTheWeek === null ? {} : JSON.parse(JSON.stringify(this.$store.state.condAdmin.workingPartnerOfTheWeek)),
      locked: true
    };
  },
  computed: {
    stateClass () {
      return this.getStateClass(this.partnerOfTheWeek, false);
    },
    stateText () {
      return this.getStateText(this.partnerOfTheWeek, false);
    },
    partOfWeekHeaders () {
      let ph = [
        { width: '150px', text: this.$t('condAdmin.product.productType'), value: 'productType', formatter: this.formatValue },
        { width: '150px', text: this.$t('condAdmin.product.subProductType'), value: 'subProductType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.optimisationType'), value: 'optimisationType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.calcType'), value: 'calcType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.provider'), value: 'providerType', formatter: this.replaceAsAll }
      ];

      ph.push({ width: '90px', text: null, value: 'menu', sortable: false });
      return ph;
    },
    versionProductList () {
      let list = [];
      list.push(this.partnerOfTheWeek);

      return list.sort();
    },
    productTypeList () {
      let list = [];
      let typeList = this.$store.state.condAdmin.productTypeList;
      if (typeList != null) {
        for (let ptl of typeList) {
          list.push({ uid: ptl.uid, name: ptl.name });
        }
      }
      return list;
    },

    optimisationTypeList () {
      let list = [];
      for (let opt in this.optimisationTypes) {
        list.push({ uid: opt, name: this.optimisationTypes[opt] });
      }
      return list;
    },

    calcTypeList () {
      let list = [];
      let tempArray = this.$store.state.condAdmin.calcTypeList.filter((i) => (i.isActive === 1 || i.isActive === true) && i.base === 'F');

      if (tempArray != null) {
        for (let ct of tempArray) {
          list.push({ uid: ct.uid, name: ct.text });
        }
      }
      return list;
    },

    providerList () {
      let list = [];
      for (let prov of this.$store.state.condAdmin.providerList) {
        list.push({ uid: prov.uid, name: prov.name });
      }
      return list;
    }
  },

  async mounted () {
    let requests = [];
    console.debug('mounted edit');
    if (this.$store.state.condAdmin.partnerOfTheWeekList == null) await this.refreshPartnerOfTheWeekList();
    if (this.$store.state.condAdmin.productTypeList == null) requests.push(refreshProductTypeList());
    if (this.$store.state.condAdmin.calcTypeList == null) requests.push(refreshCalcTypeList());
    if (this.$store.state.condAdmin.providerList == null) requests.push(refreshProviderList());
    await Promise.all(requests);

    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.partnerOfTheWeek != null && this.partnerOfTheWeek.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.savePartOfWeek()) {
          next();
          return false;
        }
      }
    }
    if (this.partnerOfTheWeek != null && this.locked === true) {
      await removeLock('partnerOfTheWeek', this.partnerOfTheWeek.uid, this.partnerOfTheWeek.version);
    }
    next();
  },
  methods: {
    async init () {
      this.partnerOfTheWeek = this.$store.state.condAdmin.workingPartnerOfTheWeek;

      if (this.partnerOfTheWeek != null) {
        this.$emit('setPageMode', this.partnerOfTheWeek.workingMode);
      } else {
        this.$router.push('/condAdmin/partnerOfTheWeek');
      }
    },
    addPartnerOfWeek () {
      let item = {
        'productTypeUid': null,
        'productTypeName': '',
        'subProductTypeUid': null,
        'subProductTypeName': '',
        'calcTypeUid': null,
        'calcTypeName': '',
        'providerUid': null,
        'providerName': '',
        'optimisationType': null,
        'optimisationName': ''
      };
      this.partnerOfTheWeek.partOfTheWeek.push(item);
      this.setDirty();
    },
    copyPartnerOfTheWeek (item) {
      this.partnerOfTheWeek.partOfTheWeek.push(JSON.parse(JSON.stringify(item)));
      this.setDirty();
    },
    removePartnerOfTheWeek (item) {
      item.delete = true;
      let index = this.partnerOfTheWeek.partOfTheWeek.findIndex((d) => d.delete === true);
      if (index > -1) {
        this.partnerOfTheWeek.partOfTheWeek.splice(index, 1);
      }
      this.setDirty();
    },
    getSubProductTypeList (productTypeUid) {
      // let list = [{ id: null, name: null }];
      let list = [];
      if (productTypeUid == null) {
        for (let pt of this.$store.state.condAdmin.productTypeList) {
          list = list.concat(pt.subProductTypes);
        }
        return list;
      } else {
        let pt = this.$store.state.condAdmin.productTypeList.find((p) => p.uid === productTypeUid);
        return list.concat(pt.subProductTypes);
      }
    },

    setDirty () {
      if (!this.partnerOfTheWeek.isDirty) {
        this.partnerOfTheWeek.isDirty = true;
      }
    },
    enableAction (action) {
      switch (action) {
        case 'save': return this.partnerOfTheWeek.isDirty;
        case 'remove': return this.partnerOfTheWeek.state === 0 || this.partnerOfTheWeek.state === 1;
        case 'setCheck': return !this.partnerOfTheWeek.isDirty;
      }
      return true;
    },
    setProperty (key, value) {
      if (key.includes('.')) setProperty(key, this.partnerOfTheWeek, value);
      else this.partnerOfTheWeek[key] = value;
      this.setDirty();
    },
    setProductType (item, uid) {
      if (item.productTypeUid !== uid) {
        item.productTypeUid = uid;
        item.productTypeName = '';
        if (uid != null) {
          item.productTypeName = this.$store.state.condAdmin.productTypeList.find((p) => p.uid === uid).name;
        }
        item.subProductTypeUid = null;
        item.subProductTypeName = '';
        console.debug('setProductType', JSON.stringify(item));
        this.setDirty();
      }
    },
    setSubProductType (item, uid) {
      if (item.subProductTypeUid !== uid) {
        let spt = this.$store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid).subProductTypes;
        item.subProductTypeUid = uid;
        item.subProductTypeName = '';
        if (uid != null) {
          item.subProductTypeName = spt.find(el => el.uid === uid).name;
        }
        this.setDirty();
        console.debug('setSubProductType', JSON.stringify(item));
      }
    },

    setOptimisationType (item, name) {
      if (item.optimisationType !== name) {
        item.optimisationType = name;
        item.optimisationName = '';
        if (name != null) {
          item.optimisationName = this.optimisationTypes[name];
        }
        console.debug('setOptimisationType', JSON.stringify(item));
        this.setDirty();
      }
    },
    setCalcType (item, uid) {
      if (item.calcTypeUid !== uid) {
        item.calcTypeUid = uid;
        item.calcTypeName = '';
        if (uid != null) {
          item.calcTypeName = this.$store.state.condAdmin.calcTypeList.find(cl => cl.uid === uid).text;
        }
        console.debug('setCalcType', JSON.stringify(item));
        this.setDirty();
      }
    },
    setProvider (item, uid) {
      if (item.providerUid !== uid) {
        item.providerUid = uid;
        item.providerName = '';
        if (uid != null) {
          item.providerName = this.$store.state.condAdmin.providerList.find(el => el.uid === uid).name;
        }
        console.debug('setProvider', JSON.stringify(item));
        this.setDirty();
      }
    },
    async savePartOfWeek () {
      console.debug('partnerOf', JSON.stringify(this.partnerOfTheWeek));

      let processData = await this.savePartnerOfTheWeek(this.partnerOfTheWeek, false);

      if (processData != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.partnerOfTheWeekEditor.infoPartOfWeekSaved') }]);
        this.partnerOfTheWeek.isDirty = false;
        // update AdditionalProductList
        await this.refreshPartnerOfTheWeekList();
        this.$store.state.condAdmin.workingPartnerOfTheWeek = null;

        this.locked = false;
        this.$router.push('/condAdmin/partnerOfTheWeek/' + this.partnerOfTheWeek.objectKey);
      }
    }

  }
};
</script>

<style scoped>
</style>
