/* <script> */
import { v4 as uuidv4 } from 'uuid';
import condAdminMixin from './condAdminMixin.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { formatLock } from './condAdminHelper.js';

export default {
  mixins: [condAdminMixin],
  data () {
    return {
      interestModes: [
        { value: 'eff', text: this.getInterestModeText('eff'), financing: true, leasing: true },
        { value: 'nom', text: this.getInterestModeText('nom'), financing: true, leasing: true },
        { value: 'factors', text: this.getInterestModeText('factors'), financing: false, leasing: true }
      ],
      commissionModes: [
        { value: 'percentNetLoan', text: this.$t('condAdmin.condition.commissionMode.percentNetLoan') },
        { value: 'percentProductPrice', text: this.$t('condAdmin.condition.commissionMode.percentProductPrice') },
        { value: 'amount', text: this.$t('condAdmin.condition.commissionMode.amount', { currency: this.currency }) }
      ],
      commissionTypes: [
        { value: 'creditCommission', text: this.$t('condAdmin.condition.commissionType.creditCommission'), isSubsidy: false },
        { value: 'creditSubsidy', text: this.$t('condAdmin.condition.commissionType.creditSubsidy'), isSubsidy: true },
        { value: 'additionalCreditCommission', text: this.$t('condAdmin.condition.commissionType.additionalCreditCommission'), isSubsidy: false },
        { value: 'additionalCreditSubsidy', text: this.$t('condAdmin.condition.commissionType.additionalCreditSubsidy'), isSubsidy: true }
      ],
      rules: {
        required: (value) => !!value || this.$t('base.required'),
        depositRange: (condition) => condition.minDepositPercent == null || condition.maxDepositPercent == null || Number(condition.minDepositPercent) < Number(condition.maxDepositPercent) || this.$t('condAdmin.minGreaterMax'),
        vehicleAgeRange: (condition) => condition.minVehicleAge == null || condition.maxVehicleAge == null || Number(condition.minVehicleAge) < Number(condition.maxVehicleAge) || this.$t('condAdmin.minGreaterMax'),
        max100: (value) => value == null || value <= 100.0 || this.$t('condAdmin.conditionEditor.infoMax100Percent')
      }
    };
  },
  computed: {
  },
  methods: {
    getInterestModeText (mode) {
      return this.$t('condAdmin.condition.interestMode.' + mode);
    },
    isFinancing (ct) {
      return (ct === 'CC' || ct === 'BC');
    },
    isLeasing (ct) {
      return (ct.startsWith('PL') || ct.startsWith('BL'));
    },
    // --------------------------------------------------------------------------------------------------
    async validateProductAssignments (condition, messages = null) {
      if (condition.products.length < 1) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoNoAssignedProducts') });
        return;
      }

      condition.products = condition.products.filter((v, i, a) => a.findIndex((t) => (JSON.stringify(t) === JSON.stringify(v))) === i);
      let hasIncludeAll = condition.products.find((p) => p.isInclude === 1 && p.productTypeUid == null && p.subProductTypeUid == null && p.brand == null && p.productName == null && p.chassis == null && p.driveType == null && p.orderKey == null && p.productKey == null && p.serialId == null);
      let hasExcludeAll = condition.products.find((p) => p.isInclude === 0 && p.productTypeUid == null && p.subProductTypeUid == null && p.brand == null && p.productName == null && p.chassis == null && p.driveType == null && p.orderKey == null && p.productKey == null && p.serialId == null);
      let hasInclude = condition.products.find((p) => p.isInclude === 1 && (p.productTypeUid != null || p.subProductTypeUid != null || p.brand != null || p.productName != null || p.chassis != null || p.driveType != null || p.orderKey != null || p.productKey != null || p.serialId != null));
      let hasExclude = condition.products.find((p) => p.isInclude === 0 && (p.productTypeUid != null || p.subProductTypeUid != null || p.brand != null || p.productName != null || p.chassis != null || p.driveType != null || p.orderKey != null || p.productKey != null || p.serialId != null));

      if (messages != null) {
        if (hasExcludeAll != null || (hasIncludeAll == null && hasInclude == null)) {
          messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoProductAssignmentsInvalid') });
        }
        return;
      }

      if (hasIncludeAll != null && hasExclude == null) {
        return this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.conditionEditor.dlgtSaveConditionForAllProducts'));
      }
      return true;
    },
    // --------------------------------------------------------------------------------------------------
    async validateResidualValues (condition) {
      if (condition.additionalParams.residualValues != null) {
        let usedDurations = [];
        for (let att of condition.attributes) {
          if (!usedDurations.includes(att.duration)) usedDurations.push(att.duration);
        }
        let missing = 0;
        for (let d of usedDurations) {
          let rv = condition.additionalParams.residualValues.find((r) => r.duration === d);
          if (rv == null || rv.residualValue == null) {
            missing++;
          }
        }
        if (missing === usedDurations.length) {
          if (await this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.conditionEditor.dlgtNoResidualValuesSet')) === false) {
            return false;
          }
          delete condition.additionalParams.residualValues;
        } else if (missing > 0) {
          return this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.conditionEditor.dlgtMissingResidualValues'));
        }
      }
      return true;
    },
    // --------------------------------------------------------------------------------------------------
    async refreshConditionList (includeExpired = true, includeInvalidated = false, showLoader = false) {
      this.$store.state.condAdmin.conditionList = [];
      this.$store.state.condAdmin.conditionsLoading = true;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };

      if (includeExpired === true) ioData.includeExpired = true;
      if (includeInvalidated === true) ioData.includeInvalidated = true;

      let processData = await this.$restClient.callProcess('condAdmin', 'getConditionList', ioData, showLoader);

      if (isProcessStateOK(processData)) {
        if (processData.ioData.conditionList != null && processData.ioData.conditionList.length > 0 && processData.ioData.conditionList[0] != null) {
          this.$store.state.condAdmin.conditionList = this.prepareConditionList(processData.ioData.conditionList);
        }
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      this.$store.state.condAdmin.conditionsLoading = false;
    },
    // --------------------------------------------------------------------------------------------------
    prepareConditionList (conditionList) {
      if (conditionList == null || (conditionList.length > 0 && conditionList[0] == null)) return [];
      for (let condition of conditionList) {
        condition.feid = condition.uid + '-' + condition.version;
        condition.providerName = '';
        if (this.$store.state.condAdmin.providerList != null) {
          let provider = this.$store.state.condAdmin.providerList.find((p) => p.uid === condition.providerUid);
          if (provider != null) {
            condition.providerName = provider.name;
          }
        }
        condition.calcType = '';
        condition.calcTypeName = '';
        if (this.$store.state.condAdmin.calcTypeList != null) {
          let calcType = this.$store.state.condAdmin.calcTypeList.find((p) => p.uid === condition.calcTypeUid);
          if (calcType != null) {
            condition.calcTypeName = calcType.text;
            condition.calcType = calcType.calcType;
          }
        }

        condition.feState = this.getModifiedStateValue(condition);
        condition.feInterestType = condition.interestType;
        if (condition.versions != null && condition.disabled === true) {
          for (let v of condition.versions) {
            v.disabled = true;
          }
        }

        if (condition.commissionParams != null) {
          let tmpSubsidyList = {};
          for (let comTable of condition.commissionParams) {
            comTable.expand = false;
            tmpSubsidyList[comTable.name] = comTable.isSubsidy;
          }
          // subsidies are negative values, but shall be displayed as positiv
          for (let att of condition.attributes) {
            att.commission = att.commission.filter((e) => e.value != null);
            for (let comm of att.commission) {
              if (tmpSubsidyList[comm.name] === true && comm.value < 0) {
                comm.value = comm.value * (-1);
              }
            }
          }
        }
      }
      return conditionList;
    },
    // --------------------------------------------------------------------------------------------------
    async readCondition (uid, version, getVersions = false) {
      let condition = this.$store.state.condAdmin.conditionList.find((v) => v.uid === uid && v.version === version);
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        conditionUid: uid,
        conditionVersion: version,
        includeVersions: getVersions && (condition == null || condition.versions == null)
      };
      if (condition != null && condition.inherited === true) {
        ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
      }

      let processData = await this.$restClient.callProcess('condAdmin', 'readCondition', ioData, true);
      if (isProcessStateOK(processData)) {
        if (condition == null) {
          condition = processData.ioData.condition;
        } else {
          Object.assign(condition, processData.ioData.condition);
        }
        this.prepareConditionList([condition]);
        this.createInterestLines(condition);
        // interestType
        if (this.$store.state.condAdmin.orgSettings.useInterestType === true) {
          if (condition.additionalParams.interestType == null) {
            condition.additionalParams.interestType = { name: 'Standard', indexName: 'standard' };
          }
        }
        // condition.commissionParams = createcommissionParams(condition);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return condition;
    },
    // --------------------------------------------------------------------------------------------------
    async checkEditPreConditions (mode, condition = null) {
      let newVersion = 1;
      let uid = null;

      if (mode === 'edit') {
        uid = condition.uid;
        if (condition.state !== 0 && condition.state !== 1) {
          // check if draft--version already exists
          let draft = condition.versions.find((v) => v.state === 0);
          if (draft != null) {
            try {
              let overwrite = await this.$globals.Confirm.yesNoCancelDlg(
                this.$t('condAdmin.conditionEditor.dlghDraftExists'),
                this.$t('condAdmin.conditionEditor.dlgtDraftExists'),
                this.$t('base.replace'), this.$t('base.keep'));
              if (!overwrite) {
                condition = this.$store.state.condAdmin.conditionList.find((v) => v.uid === condition.uid && v.version === draft.version);
                if (condition == null || condition.versions == null) {
                  condition = await this.readCondition(condition.uid, draft.version, true);
                }
              }
              newVersion = draft.version;
            } catch (e) {
              return false;
            }
          } else {
            newVersion = 1;
            for (let v of condition.versions) {
              if (v.version > newVersion) newVersion = v.version;
            }
            newVersion = newVersion + 1;
          }
        } else {
          newVersion = condition.version;
        }
      } else {
        uid = uuidv4();
      }

      // check and set editLock in BE
      let isLockSet = false;
      let lockIoData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        targetType: 'condition',
        targetIdentifier: uid,
        targetVersion: newVersion
      };

      let forceUpdateLock = false;
      let finishedLockCheck = false;
      let processName = 'createLock';
      while (!finishedLockCheck) {
        let processData = await this.$restClient.callProcess('condAdmin', processName, lockIoData, true);
        if (isProcessStateOK(processData)) {
          if (processData.processState.state === 'warning') {
            // condition already locked
            let lock = processData.ioData.lock;
            forceUpdateLock = await this.$globals.Confirm.yesNoDlg(
              this.$t('condAdmin.conditionEditor.dlghConditionLocked'),
              this.$t('condAdmin.conditionEditor.dlgtConditionLocked', formatLock(lock)),
              this.$t('base.overwrite'), this.$t('base.cancel'));
            if (forceUpdateLock) {
              processName = 'forceLock';
            } else {
              finishedLockCheck = true;
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
            finishedLockCheck = true;
            isLockSet = true;
          }
        } else {
          this.$globals.Info.open(processData.processState.messages);
          finishedLockCheck = true;
        }
      }

      if (isLockSet) {
        this.setWorkingCondition(mode, uid, newVersion, condition);
        return true;
      }
      return false;
    },
    // --------------------------------------------------------------------------------------------------
    createInterestLines (condition) {
      // create interestLines
      let interestLines = [];
      let ilIndex = 0;
      if (condition.attributes == null || condition.attributes.length === 0) {
        this.createInterestLine(condition.calcType, interestLines, ilIndex, null);
      } else {
        for (let att of condition.attributes) {
          let interestLine = interestLines.find((i) => i.minCredit === att.minCredit && i.maxCredit === att.maxCredit);
          if (interestLine == null) {
            interestLine = this.createInterestLine(condition.calcType, interestLines, ilIndex, att);
            ilIndex++;
          }
          att.ilIndex = interestLine.index;
        }
      }
      condition.interestLines = interestLines;
      // return interestLines;
    },
    createInterestLine (calcType, interestLines, index, att = null) {
      let minCredit = null;
      if (att != null) {
        minCredit = att.minCredit;
      } else if (interestLines.length > 0 && interestLines[interestLines.length - 1].maxCredit != null) {
        minCredit = interestLines[interestLines.length - 1].maxCredit + 0.01;
      }
      let interestLine = {
        index: index,
        isOk: true,
        minCredit: minCredit,
        maxCredit: att != null ? att.maxCredit : null
      };
      interestLines.push(interestLine);
      return interestLine;
    },
    createNewCommission (commissionParams, com = null) {
      let commission = {
        uid: uuidv4(),
        name: com != null ? com.name : null,
        mode: com != null ? com.mode : null,
        isSubsidy: com != null ? com.isSubsidy : null,
        filter: {},
        expand: false
      };
      commissionParams.push(commission);
      return commission;
    },
    getDurationListForCommission (interestLine, attributes) {
      let list = [];
      for (let d of this.durationList) {
        if (attributes.find((a) => a.duration === d && a.ilIndex === interestLine.index && a.commissionInterest !== true) != null) list.push(d);
      }
      return list;
    },
    // ------------------------------------------------------------------------------------------------------------------------------------------
    setWorkingCondition (mode = 'new', uid, version = 1, source = null) {
      console.log('setWorkingCondition', mode);

      let workingValidFrom = this.DateTimeHelper.NOW;
      let workingValidTo = this.DateTimeHelper.INFINITEDATETIME;

      if (mode === 'copy') {
        if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
        if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
      }
      if (mode === 'edit') {
        if (source.state === 0 || source.state === 1) {
          if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
          if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
        } else {
          if (source.validTo != null && this.DateTimeHelper.isLater(source.validTo /* than NOW */)) {
            if (this.DateTimeHelper.isEarlier(source.validTo, this.DateTimeHelper.INFINITEDATE)) {
              workingValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter(source.validTo);
            } else if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) {
              workingValidFrom = source.validFrom;
            }
          }
        }
      }

      let wc = {
        workingMode: mode,
        isDirty: false,
        feid: uid + '_' + version,
        // id: null,
        uid: uid,
        version: version,
        providerUid: mode === 'new' ? null : source.providerUid,
        providerName: mode === 'new' ? null : source.providerName,
        calcTypeUid: mode === 'new' ? null : source.calcTypeUid,
        calcType: mode === 'new' ? null : source.calcType,
        calcTypeName: mode === 'new' ? null : source.calcTypeName,
        conditionKey: mode === 'edit' ? source.conditionKey : null,
        conditionKeyFix: mode === 'edit' ? source.conditionKeyFix : true, // +cb 12.2022
        conditionKeyParams: mode === 'new' ? {} : JSON.parse(JSON.stringify(source.conditionKeyParams)) || {},
        // conditionBrand: '',
        name: mode === 'edit' ? source.name : null,
        validFrom: this.DateTimeHelper.formatSqlDateTime(workingValidFrom),
        validTo: this.DateTimeHelper.formatSqlDateTime(workingValidTo),
        state: 0,
        clientKey: this.$store.state.condAdmin.clientKey,
        ownerUid: '0',
        interestMode: mode === 'new' ? 'eff' : source.interestMode,
        minCredit: mode === 'new' ? null : source.minCredit,
        maxCredit: mode === 'new' ? null : source.maxCredit,
        minRate: mode === 'new' ? null : source.minRate,
        // durations: generisch,
        minDepositPercent: mode === 'new' ? 0.0 : source.minDepositPercent,
        maxDepositPercent: mode === 'new' ? 100.0 : source.maxDepositPercent,
        maxMileage: mode === 'new' ? null : source.maxMileage,
        minVehicleAge: mode === 'new' ? null : source.minVehicleAge,
        maxVehicleAge: mode === 'new' ? null : source.maxVehicleAge,
        additionalParams: mode === 'new' ? {} : JSON.parse(JSON.stringify(source.additionalParams)),
        commissionParams: mode === 'new' ? [] : JSON.parse(JSON.stringify(source.commissionParams)) || [],
        attributes: mode === 'new' ? [] : JSON.parse(JSON.stringify(source.attributes)),
        products: [{
          isInclude: 1,
          productTypeUid: null,
          subProductTypeUid: null,
          brand: null,
          productName: null,
          productKey: null,
          orderKey: null,
          chassis: null,
          driveType: null,
          serialId: null
        }],
        dealers: [{
          isInclude: 1,
          id: null,
          dealerKey: null,
          name: null,
          city: null
        }],
        versions: mode === 'edit' && source.versions != null ? JSON.parse(JSON.stringify(source.versions)) : []
      };

      if (mode !== 'new') {
        wc.dealers = JSON.parse(JSON.stringify(source.dealers));
        wc.attributes = JSON.parse(JSON.stringify(source.attributes));

        wc.products = [];
        for (let p of source.products) {
          wc.products.push({
            isInclude: p.isInclude,
            productTypeUid: p.productTypeUid,
            subProductTypeUid: p.subProductTypeUid,
            brand: p.brand,
            productName: p.productName,
            productKey: p.productKey,
            orderKey: p.orderKey,
            chassis: p.chassis,
            driveType: p.driveType,
            serialId: p.serialId,
            validFrom: this.DateTimeHelper.NOW,
            validTo: null,
            state: 6
          });
        }

        if (source.interestLines != null) {
          wc.interestLines = source.interestLines;
        } else {
          this.createInterestLines(source);
        }
      } else {
        wc.interestLines = null;
      }

      if (mode !== 'edit' || source.state !== 0) {
        wc.versions.push({
          version: version,
          validFrom: workingValidFrom,
          validTo: workingValidTo,
          state: 0
        });
      }

      if (mode === 'edit' && source.state != null && source.state > 0) {
        for (let v of source.versions) {
          wc.versions.push({
            version: v.version,
            validFrom: v.validFrom,
            validTo: v.validTo,
            state: v.state
          });
        }
      }
      // interestType
      if (this.$store.state.condAdmin.orgSettings.useInterestType === true && mode === 'edit') {
        if (source.additionalParams.interestType === null) {
          wc.additionalParams.interestType = { name: 'Standard', indexName: 'standard' };
        }
      } else if (this.$store.state.condAdmin.orgSettings.useInterestType === true && mode !== 'edit') {
        wc.additionalParams.interestType = { name: 'Standard', indexName: 'standard' };
      }

      this.$store.state.condAdmin.workingCondition = wc;
      console.log('workingCondition was set');
      if (mode === 'edit' && source != null) source.outdated = true;
    },
    // -----------------------------------------------------------------------------------------------------------------
    async setState (condition, state) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        conditionUid: condition.uid,
        conditionVersion: condition.version,
        state: state
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'updateConditionState', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoChangedState') }]);
        condition.state = processData.ioData.newState;
        let listedCondition = this.$store.state.condAdmin.conditionList.find((v) => v.uid === condition.uid && v.version === condition.version);
        if (listedCondition != null) {
          listedCondition.state = processData.ioData.newState;
          listedCondition.feState = this.getModifiedStateValue(listedCondition);
        }
        return true;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return false;
    },
    async deleteCondition (condition, skipDialog = false) {
      if (condition.state > 5 || condition.inherited === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoCannotDeleteCondition') }]);
        return false;
      }
      if (skipDialog === true || await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.conditionEditor.dlghDeleteCondition'), this.$t('condAdmin.conditionEditor.dlgtDeleteCondition'))) {
        let ioData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          conditionUid: condition.uid,
          conditionVersion: condition.version
        };
        let processData = await this.$restClient.callProcess('condAdmin', 'deleteCondition', ioData, true);
        if (isProcessStateOK(processData)) {
          this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.conditionEditor.infoDeletedCondition') }]);
          this.refreshConditionList();
          return true;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
      return false;
    },
    /* *******************
    *
    * Save and check
    *
    ******************* */
    async saveCondition (condition, checkCustomComponents = true) {
      if (condition.state !== 0 && condition.state !== 1) {
        return null;
      }
      // this.collectCustomComponentsData(condition);
      // check condtion.atrributes cause of double Entries
      // Filter auf alle conditionAtrributes mit commissionInterest = 0
      let commInterestList = condition.attributes.filter((ca) => ca.commissionInterest === false);
      // jetzt alle herausfiltern mit commissionInterest = 1, duration,maxCredit,minCredit und InterestRate identisch
      for (let att of commInterestList) {
        let doublets = condition.attributes.filter((a) =>
          a.minCredit === att.minCredit &&
          a.maxCredit === att.maxCredit &&
          a.duration === att.duration &&
          a.ilIndex === att.ilIndex &&
          a.interestRate === att.interestRate &&
          a.commissionInterest === true);
        if (doublets != null) {
          for (let doublet of doublets) {
            doublet.interestRate = null; // so attribute will be removed on save
          }
        }
      }
      // clean up attributes
      for (let att of condition.attributes) {
        if (condition.interestMode === 'factors') {
          if (att.plusFactor == null || att.minusFactor == null) att.delete = true;
          else att.interestRate = null;
        } else {
          att.commission = att.commission.filter((e) => e.value != null);
          if (att.interestRate == null || (att.commissionInterest === true && att.commission.length === 0)) {
            att.delete = true;
          } else {
            att.plusFactor = null;
            att.minusFactor = null;
          }
        }
        for (let attCommission of att.commission) {
          let index = condition.commissionParams.findIndex((c) => c.uid === attCommission.uid);
          if (index === -1) {
            att.commission.splice(att.commission.findIndex((i) => i.uid === attCommission.uid), 1);
            if (att.commission.length === 0) {
              if (att.commissionInterest === true) {
                att.delete = true;
              } else {
                att.commission = [];
              }
            }
          }
        }
      }
      condition.attributes = condition.attributes.filter((a) => a.delete !== true);

      let messages = [];
      // check mandatory values
      if (this.rules.required(condition.conditionKey) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.conditionKey') });
      if (this.rules.required(condition.name) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.name') });
      if (this.rules.required(condition.providerName) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.provider') });

      // check conditionKey
      if (!condition.conditionKeyFix) {
        // check criteria one must be set
        if (JSON.stringify(condition.conditionKeyParams) === '{}') {
          messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.conditionKeyID.userError.missingCriteria') });
        } else { // check that all condition keys are set
          if (typeof condition.conditionKeyParams.conditionKeyValues === 'undefined' ||
              condition.conditionKeyParams.conditionKeyValues.length < condition.conditionKeyParams.maxConditionKeys ||
              condition.conditionKeyParams.conditionKeyValues.length === 0) {
            messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.conditionKeyID.userError.missingCondtionKeyID') });
          } else {
            for (let ckV of condition.conditionKeyParams.conditionKeyValues) {
              console.debug('ckV.conditionKey', JSON.stringify(ckV.conditionKey));
              if (ckV.conditionKey === null) {
                messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.condition.conditionKeyID.userError.missingCondtionKeyID') });
                console.debug('ckV', JSON.stringify(ckV));
                break;
              }
            }
          }
        }
      }
      // if condition.validTo is passed an error will be returned, because condition can't become active anymore
      if (condition.validTo != null && this.DateTimeHelper.isEarlier(condition.validTo /* than NOW */)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') });
      }

      this.validateProductAssignments(condition, messages);
      this.validateInterestLines(condition, messages);
      if (this.rules.depositRange(condition) !== true ||
        this.rules.max100(condition.minDepositPercent) !== true ||
        this.rules.max100(condition.maxDepositPercent) !== true) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoDepositValuesInvalid') });
      }
      if (this.rules.vehicleAgeRange(condition) !== true) messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoVehicleAgeValuesInvalid') });
      this.validateDealerAssignments(condition, messages);

      if (await this.validateResidualValues(condition) === false) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoResidualValuesInvalid') });
      }
      // check condition.commissionParams
      if (condition.commissionParams != null) {
        for (let commParams of condition.commissionParams) {
          if (commParams.name === null) {
            messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoCommissionParams') });
          }
        }
      }
      // validate custom components data
      if (checkCustomComponents === true) {
        this.validateCustomComponentsData(condition, messages);
      }
      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        try {
          let customSaveActions = [];
          if (this.$refs.CommissionEditor != null) {
            let commRef = this.$refs.CommissionEditor;
            if (commRef.$refs.customConditionsCommissionSettings != null) {
              for (let customComponent of commRef.$refs.customConditionsCommissionSettings) {
                customSaveActions.push(customComponent.doCustomSaveActions(condition));
              }
              await Promise.all(customSaveActions);
            }
          }
          // if condition.validFrom is passed it will be set to current date
          if (condition.validFrom == null || this.DateTimeHelper.isEarlier(condition.validFrom /* than NOW */)) {
            condition.validFrom = this.DateTimeHelper.NOW;
            this.$globals.Info.open([{ type: 'warning', message: this.$t('condAdmin.conditionEditor.infoValidFromChangedToToday') }]);
          }
          let condition2save = JSON.parse(JSON.stringify(condition));

          // clean Values
          delete condition2save.versions;
          delete condition2save.isDirty;
          delete condition2save.workingMode;
          delete condition2save.minCredit;
          delete condition2save.maxCredit;

          if (condition2save.calcType === 'CC') condition2save.maxMileage = null;
          if (!this.isLeasing(condition2save.calcType)) {
            condition2save.additionalParams.addKmCharge = null;
            condition2save.additionalParams.lowKmCharge = null;
          }
          let tmpSubsidyList = {};
          if (condition2save.commissionParams != null) {
            for (let comTable of condition2save.commissionParams) {
              delete comTable.expand;
              tmpSubsidyList[comTable.name] = comTable.isSubsidy;
            }
          }
          // subsidies are displayed as positiv, but shall be negativ in real
          for (let att of condition2save.attributes) {
            for (let comm of att.commission) {
              if (tmpSubsidyList[comm.name] === true && comm.value > 0) {
                comm.value = comm.value * (-1);
              }
            }
          }
          // create ioData
          let ioData = {
            clientKey: this.$store.state.condAdmin.clientKey,
            condition: condition2save
          };
          // backend-request
          let processName = condition.workingMode === 'edit' ? 'updateCondition' : 'createCondition';
          let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
          if (isProcessStateOK(processData)) {
            return processData;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        } catch (err) {
          console.error(err.message);
        }
        return null;
      }
    },
    // collectCustomComponentsData () {
    //   if (this.$store.state.condAdmin.orgSettings.customConditionCommissionSettings === true) {
    //     for (let ref of this.$refs.customConditionsCommissionSettings) {
    //       ref.getCustomParams(condition);
    //     }
    //   }
    //   if (this.$store.state.condAdmin.orgSettings.customConditionDealerSettings === true) {
    //     this.$refs.customConditionDealerSettings.getCustomParams(condition);
    //   }
    // },
    validateCustomComponentsData (condition, messages) {
      // if (this.$store.state.condAdmin.orgSettings.customConditionCommissionSettings === true) {
      //   for (let ref of this.$refs.customConditionsCommissionSettings) {
      //     ref.validateCustomParams(condition, messages);
      //   }
      // }
      if (this.$store.state.condAdmin.orgSettings.customConditionDealerSettings === true) {
        this.$refs.customConditionDealerSettings.validateCustomParams(condition, messages);
      }
    },
    async setCheckState (condition, isDirty) {
      if (isDirty && await this.saveCondition(condition) == null) {
        return false;
      }
      // if condition.validTo is passed an error will be returned, because condition can't become active anymore
      if (condition.validTo != null && this.DateTimeHelper.isEarlier(condition.validTo /* than NOW */)) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') }]);
      }
      // check plausibilities
      let productAssignmentsCheck = await this.validateProductAssignments(condition);
      let beginDateCheck = await this.validateBeginDate(condition);
      let endDateCheck = await this.validateEndDate(condition);
      let rvCheck = await this.validateResidualValues(condition);

      if (!productAssignmentsCheck || !beginDateCheck || !endDateCheck || !rvCheck) {
        return false;
      }

      await this.setState(condition, 2);
      if (condition.state !== 0 && condition.state !== 1) {
        this.locked = false;
        this.$router.push('/condAdmin/condition/' + condition.uid + '/' + condition.version);
      }
    },
    sortInterestLines (condition) {
      condition.interestLines.sort((a, b) => a.minCredit > b.minCredit);
      // let minCredit = null;
      let maxCredit = null;
      let isOk = true;
      for (let i = 0; i < condition.interestLines.length; i++) {
        condition.interestLines[i].isOk = true;
        let il = condition.interestLines[i];
        if ((i > 0 && (il.minCredit == null || il.minCredit !== maxCredit + 0.01 || maxCredit == null)) || (il.minCredit != null && il.maxCredit != null && il.minCredit >= il.maxCredit)) {
          il.isOk = false;
          isOk = false;
        }
        // minCredit = il.minCredit;
        maxCredit = il.maxCredit;
      }
      return isOk;
    },
    validateInterestLines (condition, messages) {
      if (!this.sortInterestLines(condition)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoInterestLineInvalidCreditRanges') });
      }
      for (let il of condition.interestLines) {
        let ilAtts = condition.attributes.filter((a) => a.ilIndex === il.index && a.commissionInterest !== true);
        if (ilAtts.length < 1) messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoEmptyInterestLine') });
      }
    },
    async validateDealerAssignments (condition, messages = null) {
      if (condition.dealers.length < 1) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoNoAssignedDealers') });
        return;
      }

      condition.dealers = condition.dealers.filter((v, i, a) => a.findIndex((t) => (JSON.stringify(t) === JSON.stringify(v))) === i);
      let hasIncludeAll = condition.dealers.find((d) => d.isInclude === 1 && d.dealerKey == null);
      let hasExcludeAll = condition.dealers.find((d) => d.isInclude === 0 && d.dealerKey == null);
      let hasInclude = condition.dealers.find((d) => d.isInclude === 1 && d.dealerKey != null);
      // let hasExclude = condition.dealers.find((d) => d.isInclude === 0 && d.dealerKey != null);

      if (messages != null) {
        if (hasExcludeAll != null && (hasIncludeAll != null || hasInclude == null)) {
          messages.push({ type: 'userError', message: this.$t('condAdmin.conditionEditor.infoDealerAssignmentsInvalid') });
        }
        return;
      }

      // if (hasIncludeAll != null && hasExclude == null) {
      //   return this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.conditionEditor.dlgtSaveConditionForAllProducts'));
      // }
      return true;
    },
    TEST (comp = '') {
      console.log('TEST:', comp);
      return this.readonly;
    }
  }
};
// </script>
