<template>
  <v-text-field
    v-model="filter"
    append-icon="mdi-magnify"
    :placeholder="$t(label)"
    @blur="apply()"
    @click:append="apply()"
    @click:clear="reset()"
    @keydown.enter="apply()"
    single-line
    outlined
    clearable
    hide-details
    height="24px"
    class="py-1"
  ></v-text-field>
</template>

<script lang="js">
export default {
  name: 'SearchField',
  props: {
    value: { type: String, default: () => { return ''; } },
    label: { type: String, default: () => { return 'base.search'; } }
  },
  data () {
    return {
      filter: this.value
    };
  },
  watch: {
    value () {
      this.filter = this.value;
    }
  },
  methods: {
    apply () {
      this.$emit('filter', this.filter);
    },
    reset () {
      this.$emit('filter', null);
    }
  }
};
</script>

<style scoped>
</style>
