<template>
  <v-card
    v-if="rvGroup != null && hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !rvGroup.inherited"
    flat class="px-4">
    <v-card-text>
      <v-row>
        <v-col lg="5" md="5" sm="6" xs="6">
          <v-text-field @change="setDirty" :label="$t('condAdmin.rvGroup.name') + $t('base.mandatory')"
            v-model.trim="rvGroup.name" :rules="[rules.required]" />
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="6" xs="6">
          <v-select v-model="rvGroup.brands" @change="setDirty" multiple :label="$t('condAdmin.rvGroup.brand')"
            :items="brandList" append-icon='' :readonly=true />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" xs="6" class="mt-2">
          <v-row no-gutters class="justify-end">
            <v-btn @click.native="getBack()" class="mr-4 prio2">
              {{ $t('base.cancel') }}
            </v-btn>
            <v-btn :disabled="!rvGroup.isDirty" @click.native="saveRvGroup()" color="secondary" class="mr-4">
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="!enableAction('save')"
                  @click="saveRvGroup()"
                >{{ capitalizeString('condAdmin.actions.save') }}</v-list-item>
                <v-list-item
                  :disabled="!enableAction('setCheck')"
                  @click="setCheckState(rvGroup)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}</v-list-item>
              </v-list>
            </v-menu>-->
          </v-row>
        </v-col>
      </v-row>
      <!-- ProductTypes -->
      <v-row>
        <v-col lg="5" md="5" sm="6" xs="6">
          <v-select @change="setTypeList" :value="getProductType" multiple
            :label="$t('condAdmin.rvGroup.productType') + $t('base.mandatory')" :items="productTypeList" item-value="uid"
            item-text="name" />

        </v-col>
      </v-row>

      <v-row>
      </v-row>

      <v-row class="mb-6">
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          <div class="caption">{{ $t('condAdmin.version') }}</div>
          <div class="">{{ rvGroup.version }}</div>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          <div class="caption">{{ $t('base.state') }} </div>
          <v-chip :class="stateClass" class="">
            {{ stateText }}
          </v-chip>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          {{ $t('condAdmin.validFrom') + ' ' }}
          <DatePicker :value="rvGroup.validFrom" :isBegin="true" @change="setDate('validFrom', $event)" />
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          {{ $t('condAdmin.validTo') + ' ' }}
          <DatePicker :value="rvGroup.validTo" :isEnd="true" @change="setDate('validTo', $event)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="rvGroupTab">
            <v-tab href="#objects">
              {{ $t('condAdmin.productLabel') }}
            </v-tab>
            <v-tab href="#rvGroups">
              {{ $t('condAdmin.rvGroup.residualValue') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="rvGroupTab">
            <!------------------ Objects ------------------------------------>
            <v-tab-item value="objects">
              <v-card class="pt-4 grey lighten-5" v-if="rvGroup != null">
                <v-card-title>
                  <v-spacer />
                  <QuickFilter :label="$t('base.selected')" :count="rvGroup.assignedProducts.length"
                    :active="productQuickFilter === true" :deselectValue="null" @clicked="applyProductQuickFilter" />
                  <QuickFilter class="ml-4" :label="$t('base.unselected')"
                    :count="$store.state.condAdmin.productList.length - rvGroup.assignedProducts.length"
                    :active="productQuickFilter === false" :selectValue="false" :deselectValue="null"
                    @clicked="applyProductQuickFilter" />
                  <v-icon @click="loadLists()" color="secondary" class="mr-8"
                    v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))">mdi-reload</v-icon>
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="secondary"
                        v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-format-columns</v-icon>
                    </template>

                    <v-list>
                      <v-list-item v-for="(col, index) in availableColumns" :key="index">
                        <v-checkbox v-model="availableColumns[index].active" :label="$t(availableColumns[index].text)"
                          @change="setAvailableHeaders(availableColumns[index], $event)"></v-checkbox>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>

                <v-card-text class="pt-4">

                  <v-data-table :headers="listHeaders" :loading="$store.state.condAdmin.productsLoading"
                    :value="selectedList" :items="filteredList" :items-per-page="footerProps.itemsPerPage"
                    :footer-props="footerProps" item-key="uid" show-select fixed-header :sort-by="listSortKey"
                    @update:items-per-page="getItemPerPage" @item-selected="setProductItem($event)"
                    @toggle-select-all="setProductItems($event)">

                    <template v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
                      v-slot:[`item.${header.value}`]="{ value }">
                      {{ header.formatter(value) }}

                    </template>

                    <template v-slot:[`body.prepend`]="{ }">
                      <tr>

                        <td></td>
                        <td v-for="(h, index) in listHeaders" :key="index">
                          <SearchSelect v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                            :value="filter[h.value].value" @filter="applyFilters($event, h.value)"
                            :items="productSelectLists(filter[h.value].items)" :item-text="filter[h.value].itemText"
                            :item-value="filter[h.value].itemValue" />
                          <SearchField v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                            :value="filter[h.value].value" @filter="applyFilters($event, h.value)">
                          </SearchField>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:[`header.data-table-select`]="{ on, props }">
                      <v-simple-checkbox color="secondary" v-bind="props" v-on="on"></v-simple-checkbox>
                    </template>
                    <template v-slot:[`header.menu`]="{ }">
                    </template>

                    <template v-slot:[`item.productTypeUid`]="{ item }">
                      {{ ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)).name }}
                    </template>

                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!------------------ RVGroups ------------------------------------>
            <v-tab-item value="rvGroups">

              <v-card class="pt-4 grey lighten-5" v-if="rvGroup != null && rvTableList != null">
                <v-card-text>
                  <v-row>
                    <!-- Finanzierung -->
                    <v-col cols="6" lg="5" md="6" sm="12" xs="12">
                      <v-row>
                        <v-col>
                          <v-select :value="getRVTableFinance" @change="setRvTableFinance('finance', $event)"
                            :label="$t('condAdmin.rvGroup.rvTableKeyFin') + $t('base.mandatory')" :items="rvTableList"
                            item-value="rvTableKey" item-text="name" return-object>
                            <template v-slot:selection="{ item }">
                              <table>
                                <v-chip v-if="item.inherited === true" :class="getStateClass(item)">
                                  {{ getStateText(item) }}
                                  <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link' :
                                    'mdi-link-lock' }}</v-icon>
                                </v-chip>
                                <v-chip v-if="item.inherited !== true" :class="getStateClass(item)">
                                  {{ getStateText(item) }}
                                  <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                    'mdi-link-unlock' }}</v-icon>
                                </v-chip>
                                {{ item.name }}
                              </table>
                            </template>

                            <template v-slot:item="{ item }">
                              <table>
                                <v-chip v-if="item.inherited === true" :class="getStateClass(item)">
                                  {{ getStateText(item) }}
                                  <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link' :
                                    'mdi-link-lock' }}</v-icon>
                                </v-chip>
                                <v-chip v-if="item.inherited !== true" :class="getStateClass(item)">
                                  {{ getStateText(item) }}
                                  <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                    'mdi-link-unlock' }}</v-icon>
                                </v-chip>
                                {{ item.name }}

                              </table>
                            </template>
                          </v-select>
                          <!--
                          <EditField
                            v-if="rvGroup.workingMode != 'new'"
                            :label="$t('condAdmin.rvGroup.rvTableKeyFin')  + $t('base.mandatory')"
                            :value="formatValue(rvGroup.finRVTableKey)"
                            :readonly=true
                          />-->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <table class="text-center">
                            <tr>
                              <th class="primary lighten-3">{{ $t('condAdmin.rvGroupDetailEditor.residualValueClass') }}
                              </th>
                              <th class="primary lighten-3">{{
                                $t('condAdmin.rvGroupDetailEditor.residualImpactDiscount_percent') }}</th>
                              <v-btn class="mr-4 prio2"
                                @click="showResultTable(rvGroup.finRVTableKey, rvGroup.finSubProductMods, rvGroup.finBaseRVMods, $t('condAdmin.rvGroupDetailEditor.resultTableHeadlineFinance'))">
                                <v-icon color="secondary"
                                  v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-table-check
                                </v-icon>
                                {{ $t("condAdmin.rvGroupDetailEditor.resultTable") }}
                              </v-btn>

                            </tr>
                            <tr v-for="(mods, index) in rvGroup.finBaseRVMods" :key="mods.modClass">
                              <!-- Modklasses -->
                              <td class="px-4" :class="getModClassColor(mods.modClass)">
                                <EditField :value="mods.modClass" :readonly=true />

                              </td>
                              <!-- Modklass Werte -->
                              <td class="px-4" :class="getModClassColor(mods.modClass)">
                                <EditField @change="setModClassValue(index, rvGroup.finBaseRVMods, $event)"
                                  :value="mods.modValue" :readonly=false type="int" />
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <!--
                      <v-row>
                        <v-col>
                          <EditField
                            :label="$t('condAdmin.rvGroup.subProductMods')"
                            :value="getSubProductModsString(rvGroup.finSubProductMods)"
                            :readonly=false
                          />
                        </v-col>
                      </v-row>
                      -->
                      <v-row>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.addKmCharge')"
                            :value="getAddKmChargeString(rvGroup.finAdditionalParams)" :readonly=false :suffix="currency"
                            type="ufloat" />
                        </v-col>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.lowKmCharge')"
                            :value="getLowKmChargeString(rvGroup.finAdditionalParams)" :readonly=false :suffix="currency"
                            type="ufloat" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- LEASING -->
                    <v-col v-if="showLeasingRVGroup" cols="6" lg="5" md="6" sm="12" xs="12">
                      <v-row>
                        <v-col>
                          <v-select :value="getRVTableLeasing" @change="setRvTableLeasing('leasing', $event)"
                            :label="$t('condAdmin.rvGroup.rvTableKeyLeas') + $t('base.mandatory')" :items="rvTableList"
                            item-value="rvTableKey" item-text="name" return-object>
                            <template v-slot:selection="{ item }">
                              <v-chip v-if="item.inherited === true" :class="getStateClass(item)">
                                {{ getStateText(item) }}
                                <v-icon>{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock'
                                }}</v-icon>
                              </v-chip>
                              <v-chip v-if="item.inherited !== true" :class="getStateClass(item)">
                                {{ getStateText(item) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                  'mdi-link-unlock' }}</v-icon>
                              </v-chip>
                              {{ item.name }}
                            </template>
                            <template v-slot:item="{ item }">
                              <v-chip v-if="item.inherited === true" :class="getStateClass(item)">
                                {{ getStateText(item) }}
                                <v-icon>{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock'
                                }}</v-icon>
                              </v-chip>
                              <v-chip v-if="item.inherited !== true" :class="getStateClass(item)">
                                {{ getStateText(item) }}
                                <v-icon class="ml-2">{{ getOrgSetting('createOwnData') === true ? 'mdi-link-off' :
                                  'mdi-link-unlock' }}</v-icon>
                              </v-chip>
                              {{ item.name }}
                            </template>
                          </v-select>
                          <!--
                          <EditField
                            v-if="rvGroup.workingMode != 'new'"
                            :label="$t('condAdmin.rvGroup.rvTableKeyLeas')  + $t('base.mandatory')"
                            :value="formatValue(rvGroup.leasRVTableKey)"
                            :readonly=false
                          />-->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <table class="text-center">
                            <tr>
                              <th class="primary lighten-3">{{ $t('condAdmin.rvGroupDetailEditor.residualValueClass') }}
                              </th>
                              <th class="primary lighten-3">{{
                                $t('condAdmin.rvGroupDetailEditor.residualImpactDiscount_percent') }}</th>
                              <v-btn class="mr-4 prio2"
                                @click="showResultTable(rvGroup.leasRVTableKey, rvGroup.leasSubProductMods, rvGroup.leasBaseRVMods, $t('condAdmin.rvGroupDetailEditor.resultTableHeadlineLeasing'))">
                                <v-icon color="secondary"
                                  v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-table-check
                                </v-icon>
                                {{ $t("condAdmin.rvGroupDetailEditor.resultTable") }}
                              </v-btn>
                            </tr>
                            <tr v-for="(mods, index) in rvGroup.leasBaseRVMods" :key="mods.modClass">
                              <!-- Modklasses -->
                              <td class="px-4" :class="getModClassColor(mods.modClass)">
                                <EditField :value="mods.modClass" :readonly=true />
                              </td>
                              <!-- Modklass Werte -->
                              <td class="px-4 first" :class="getModClassColor(mods.modClass)">
                                <EditField :value="formatValue(mods.modValue)" :readonly=false
                                  @change="setModClassValue(index, rvGroup.leasBaseRVMods, $event)" type="int" />
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                      <!--
                      <v-row>
                        <v-col>
                          <EditField
                            :label="$t('condAdmin.rvGroup.subProductMods')"
                            :value="getSubProductModsString(rvGroup.leasSubProductMods)"
                            :readonly=false
                          />
                        </v-col>
                      </v-row>
                      -->
                      <v-row>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.addKmCharge')"
                            :value="getAddKmChargeString(rvGroup.leasAdditionalParams)" :readonly=false :suffix="currency"
                            type="ufloat" />
                        </v-col>
                        <v-col>
                          <EditField :label="$t('condAdmin.rvGroup.lowKmCharge')"
                            :value="getLowKmChargeString(rvGroup.leasAdditionalParams)" :readonly=false :suffix="currency"
                            type="ufloat" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
    <DependencyObjectDlg ref="dependencyObjectDialog" />
    <ResidualValuesResultTableDlg ref="residualValuesResultTableDlg" />
  </v-card>
</template>

<script lang="js">
import { removeLock } from '../js/condAdminHelper.js';
import condAdminRvGroupMixin from '../js/condAdminRvGroupMixin.js';
import condAdminResidualValuesMixin from '../js/condAdminResidualValuesMixin.js';
import { setProperty, complexFilter } from '@/base/js/ObjectHelper';
import DatePicker from '@/common/DatePicker';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

import EditField from '@/common/EditField';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import QuickFilter from '@/common/QuickFilter';
import DependencyObjectDlg from './DependencyObjectDlg';
import ResidualValuesResultTableDlg from './ResidualValuesResultTableDlg';

export default {
  name: 'RvGroupEditor',
  components: {
    EditField,
    DatePicker,
    SaveOnLeaveDlg,
    SearchSelect,
    SearchField,
    QuickFilter,
    DependencyObjectDlg,
    ResidualValuesResultTableDlg
  },
  mixins: [condAdminRvGroupMixin, condAdminResidualValuesMixin],
  props: {
    rvGroupTabPosition: { type: String, required: false }
  },
  data () {
    return {
      rvGroup: this.$store.state.condAdmin.workingRvGroup === null ? {} : JSON.parse(JSON.stringify(this.$store.state.condAdmin.workingRvGroup)),
      rvTableList: null,
      rvGroupTab: this.rvGroupTabPosition !== null ? this.rvGroupTabPosition : null,
      locked: true,
      clientSettings: null,
      listSortKey: 'brand',
      productQuickFilter: null,
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.product.productType'), active: false, value: 'productTypeUid' },
        { text: this.$t('condAdmin.product.brand'), active: true, value: 'brand', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productName'), active: true, value: 'productName', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.productKey'), active: false, value: 'productKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.orderKey'), active: false, value: 'orderKey', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.chassis'), active: true, value: 'chassis', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.driveType'), active: true, value: 'driveType', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.serialId'), active: false, value: 'serialId', formatter: this.formatValue }
      ],
      filter: {
        productTypeUid: { condition: 'is', value: null, type: 'SelectBox', items: 'productSelectTypeList', itemText: 'name', itemValue: 'uid' },
        brand: { condition: 'startsWith', value: null, type: 'SelectBox', items: 'brandList' },
        productName: { condition: 'startsWith', value: null, type: 'SearchField' },
        productKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        orderKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        chassis: { condition: 'startsWith', value: null, type: 'SearchField' },
        driveType: { condition: 'startsWith', value: null, type: 'SearchField' },
        serialId: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      modColourClassInput: [
        { modClass: 0, colorClass: 'colorClass0' },
        { modClass: 1, colorClass: 'colorClass1' },
        { modClass: 2, colorClass: 'colorClass2' },
        { modClass: 3, colorClass: 'colorClass3' },
        { modClass: 4, colorClass: 'colorClass4' },
        { modClass: 5, colorClass: 'colorClass5' },
        { modClass: 6, colorClass: 'colorClass6' },
        { modClass: 7, colorClass: 'colorClass7' },
        { modClass: 8, colorClass: 'colorClass8' },
        { modClass: 9, colorClass: 'colorClass9' }
      ]
    };
  },
  // ---------------------------------------------------------------------------------------------- //
  //
  //   computed
  //
  // ---------------------------------------------------------------------------------------------- //
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      // h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    stateClass () {
      return this.getStateClass(this.rvGroup, false);
    },
    stateText () {
      return this.getStateText(this.rvGroup, false);
    },
    brandList () {
      let list = [];
      for (let b of this.$store.state.condAdmin.productList) {
        if (b.brand != null && !list.includes(b.brand)) list.push(b.brand);
        if (b.brand == null && !list.includes(this.NULLVALUE)) list.push(this.NULLVALUE);
      }
      return list.sort((a, b) => a > b);
    },

    // ---------------------------------------------------------------------------------------------- //
    //
    //    ProductType
    //
    // ---------------------------------------------------------------------------------------------- //
    productTypeList () {
      return this.$store.state.condAdmin.productTypeList;
    },
    getProductType () {
      let productTypeList = [];
      for (let i = 0; i < this.rvGroup.productTypes.length; i++) {
        let item = this.$store.state.condAdmin.productTypeList.find((pT) => pT.uid === this.rvGroup.productTypes[i]);
        if (item != null) {
          productTypeList.push(item);
        }
      }
      return productTypeList;
    },
    // ---------------------------------------------------------------------------------------------- //
    //
    //    ProductList
    //
    // ---------------------------------------------------------------------------------------------- //
    selectedList () {
      let selectList = [];
      for (let i = 0; i < this.rvGroup.assignedProducts.length; i++) {
        let item = this.$store.state.condAdmin.productList.find((pT) => pT.uid === this.rvGroup.assignedProducts[i].uid);
        if (item != null) {
          selectList.push(item);
        }
      }
      return selectList;
    },
    // ---------------------------------------------------------------------------------------------- //
    //
    //    ResidualValueList
    //
    // ---------------------------------------------------------------------------------------------- //
    getRVTableFinance () {
      return this.rvGroup.finRVTableKey;
    },
    getRVTableLeasing () {
      return this.rvGroup.leasRVTableKey;
    },

    // ---------------------------------------------------------------------------------------------- //
    //
    //    showLeasingRVGroup
    //
    // ---------------------------------------------------------------------------------------------- //
    showLeasingRVGroup () {
      if (this.$store.state.condAdmin.orgSettings.getDataFromParent && this.clientSettings != null) {
        if (this.clientSettings.inheritedObjects.brand.filter((i) => i.localeCompare(this.rvGroup.brands.find((e) => e.localeCompare(i) === 0)) === 0).length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

  },
  // ---------------------------------------------------------------------------------------------- //
  //
  //    mounted, beforeRouteLeaves
  //
  // ---------------------------------------------------------------------------------------------- //
  async mounted () {
    this.filteredList = complexFilter(this.$store.state.condAdmin.productList, this.filter);
    await this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.rvGroup != null && this.rvGroup.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.saveRvGroup()) {
          next();
          return false;
        }
      }
    }
    if (this.rvGroup != null && this.locked === true) {
      await removeLock('rvGroup', this.rvGroup.uid, this.rvGroup.version);
    }
    next();
  },
  // ---------------------------------------------------------------------------------------------- //
  //
  //    watch
  //
  // ---------------------------------------------------------------------------------------------- //
  watch: {
    '$store.state.condAdmin.productList': function () {
      this.filteredList = complexFilter(this.$store.state.condAdmin.productList, this.filter);
    },

    rvTableList () {
      let finTable = this.rvTableList.find(tl => tl.rvTableKey.localeCompare(this.rvGroup.finRVTableKey) === 0);
      // console.debug('finTable', JSON.stringify(this.rvTableList), this.rvGroup.finRVTableKey);
      let leasTable = this.rvTableList.find(tl => tl.rvTableKey.localeCompare(this.rvGroup.leasRVTableKey) === 0);
      if (finTable == null) {
        this.rvGroup.finRVTableKey = '';
        this.rvGroup.finRvTableKey = '';
        this.rvGroup.finBaseRVMods = [];
        this.rvGroup.finSubProductMods = null;
        this.rvGroup.finAdditionalParams = {};
      }
      if (leasTable == null) {
        this.rvGroup.leasRVTableKey = '';
        this.rvGroup.leasRvTableKey = '';
        this.rvGroup.leasBaseRVMods = [];
        this.rvGroup.leasSubProductMods = null;
        this.rvGroup.leasAdditionalParams = {};
      }
    }
  },
  // ---------------------------------------------------------------------------------------------- //
  //
  //    methods
  //
  // ---------------------------------------------------------------------------------------------- //
  methods: {
    async init () {
      this.rvGroup = this.$store.state.condAdmin.workingRvGroup;
      if (this.rvGroup.versions != null) {
        this.$emit('setPageMode', this.rvGroup.workingMode, '');
      } else {
        this.$router.push('/condAdmin/residualValues');
      }
      await this.loadRVTableList();
      if (this.clientSettings == null && this.$store.state.condAdmin.orgSettings.getDataFromParent) {
        await this.getClientSettings();
      }
    },
    // ---------------------- //
    // -- showResultTable --- //
    // ---------------------- //
    async showResultTable (rvTableKey, subProductMods, baseRVMods, headline) {
      if (this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) {
        await this.refreshRvTableList();
      }
      let residualValueMods = [];
      if (subProductMods != null && subProductMods.modResidualValues != null) {
        residualValueMods = JSON.parse(JSON.stringify(subProductMods.modResidualValues));
      }
      let result = await this.showRVResultTable(rvTableKey, residualValueMods, baseRVMods, headline, true);
      if (result != null) {
        this.setDirty();
        if (subProductMods == null) subProductMods = {};
        subProductMods.modResidualValues = result;
      }
    },
    // ---------------------- //
    // -- loadRVTableList --- //
    // ---------------------- //
    async loadRVTableList () {
      if (this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) {
        await this.refreshRvTableList();
      }
      if (this.rvTableList == null || this.rvTableList.length === 0) {
        this.rvTableList = this.$store.state.condAdmin.rvTableList.filter((tl) => ((this.DateTimeHelper.isEarlierOrEqual(tl.validFrom, this.rvGroup.validFrom) || this.rvGroup.validFrom == null) &&
          (this.DateTimeHelper.isLater(tl.validTo, this.rvGroup.validFrom) || tl.validTo == null)));
      }
    },

    // ------------------------ //
    // -- getClientSettings --- //
    // ------------------------ //
    async getClientSettings () {
      let ioData = {
        targetClientKey: this.$store.state.condAdmin.clientKey
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'getCondClientSettings', ioData, true);
      if (isProcessStateOK(processData)) {
        this.clientSettings = processData.ioData.clientSettings;
        if (this.clientSettings.inheritedObjects == null) this.clientSettings.inheritedObjects = {};
        if (this.clientSettings.performance == null) this.clientSettings.performance = 1000;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    },
    setTypeList (value) {
      this.setDirty();
      this.rvGroup.productTypes = value;
    },
    productSelectLists (value) {
      switch (value) {
        case 'productSelectTypeList':
          return this.$store.state.condAdmin.productTypeList;
        case 'brandList':
          return this.brandList;
      }
    },

    setProductItem (object) {
      this.setDirty();

      if (object != null) {
        if (object.value === true) {
          let tmp = JSON.parse(JSON.stringify(object.item));

          delete tmp.state;
          delete tmp.clientKey;
          tmp.isInclude = 1;
          this.rvGroup.assignedProducts.push(tmp);
          // not in brands push it
          if (this.rvGroup.brands.find((br) => br.localeCompare(tmp.brand) === 0) == null) {
            this.rvGroup.brands.push(tmp.brand);
          }
        } else {
          let includeItems = this.rvGroup.assignedProducts.filter((pT) => pT.uid !== object.item.uid);
          if (includeItems != null) {
            this.rvGroup.assignedProducts = includeItems;
            // not another object with the 'brand' exist
            if (this.rvGroup.assignedProducts.find((br) => br.brand.localeCompare(object.item.brand) === 0) == null) {
              this.rvGroup.brands.splice(this.rvGroup.brands.findIndex((br) => br.localeCompare(object.item.brand) === 0), 1);
            }
          }
        }
      }
    },

    setProductItems (object) {
      this.setDirty();
      if (object != null) {
        if (object.value === true) {
          for (let item of object.items) {
            let tmp = JSON.parse(JSON.stringify(item));
            delete tmp.state;
            delete tmp.clientKey;
            tmp.isInclude = 1;
            this.rvGroup.assignedProducts.push(tmp);
          }
        } else {
          let includeItems = this.rvGroup.assignedProducts.filter((pT) => object.items.find((it) => pT.uid === it.uid) == null);
          if (includeItems != null) {
            this.rvGroup.assignedProducts = includeItems;
          }
        }
      }
    },

    setDirty () {
      if (!this.rvGroup.isDirty) {
        this.rvGroup.isDirty = true;
      }
    },
    enableAction (action) {
      switch (action) {
        case 'save': return this.rvGroup.isDirty;
        case 'remove': return this.rvGroup.state === 0 || this.rvGroup.state === 1;
        case 'setCheck': return !this.rvGroup.isDirty;
      }
      return true;
    },
    setProperty (key, value) {
      if (key.includes('.')) {
        setProperty(key, this.rvGroup, value);
      } else {
        this.rvGroup[key] = value;
      }
      this.setDirty();
    },
    async setDate (key, value) {
      this.setProperty(key, value);
      this.rvTableList = null;
      await this.loadRVTableList();
    },
    // ---------------------------------------------------------------------------
    //
    //
    // ---------------------------------------------------------------------------
    async setRvTableFinance (key, value) {
      this.loaded = false;

      if (this.rvGroup.finRVTableKey.localeCompare(value.rvTableKey) !== 0) {
        let tmpRvData = await this.readRvTableData(value.rvTableKey, value.version);
        // find highest modClass of the residual Value Table
        let maxModClass = Math.max(...tmpRvData.attributes.map(function (o) { return o.modClass; }));
        this.rvGroup.finBaseRVMods = [];
        for (let i = 1; i <= maxModClass; i++) {
          let tmpObject = { modType: '+', modClass: i, modValue: 0 };
          if (key === 'finance') {
            if (this.rvGroup.finBaseRVMods.find((t) => t.modClass === i) == null) {
              this.rvGroup.finBaseRVMods.push(tmpObject);
            }
          }
        }
        if (key === 'finance') {
          this.setProperty('finRVTableKey', value.rvTableKey);
          this.setProperty('finRvTableKey', value.rvTableKey);
        }
      }
      this.loaded = true;
    },

    async setRvTableLeasing (key, value) {
      this.loaded = false;
      if (this.rvGroup.leasRVTableKey.localeCompare(value.rvTableKey) !== 0) {
        let tmpRvData = await this.readRvTableData(value.rvTableKey, value.version);
        // find highest modClass of the residual Value Table
        let maxModClass = Math.max(...tmpRvData.attributes.map(function (o) { return o.modClass; }));
        this.rvGroup.leasBaseRVMods = [];

        for (let i = 1; i <= maxModClass; i++) {
          let tmpObject = { modType: '+', modClass: i, modValue: 0 };
          if (key === 'leasing') {
            if (this.rvGroup.leasBaseRVMods.find((t) => t.modClass === i) == null) {
              this.rvGroup.leasBaseRVMods.push(tmpObject);
            }
          }
        }
        if (key === 'leasing') {
          this.setProperty('leasRVTableKey', value.rvTableKey);
          this.setProperty('leasRvTableKey', value.rvTableKey);
        }
      }

      this.loaded = true;
    },

    getModClassColor (modClass) {
      switch (modClass) {
        case 1: return 'colorClass1';
        case 2: return 'colorClass2';
        case 3: return 'colorClass3';
        case 4: return 'colorClass4';
        case 5: return 'colorClass5';
        case 6: return 'colorClass6';
        case 7: return 'colorClass7';
        case 8: return 'colorClass8';
        case 9: return 'colorClass9';
        default: return 'colorClass0';
      }
    },
    setModClassValue (index, baseRVMods, value) {
      baseRVMods[index].modValue = value;
      this.setDirty();
    },
    getSubProductModsString (mods) {
      if (mods == null || mods.length === 0) return this.$t('base.none');

      let s = '';
      for (let i = 0; i < mods.length; i++) {
        if (i > 0) s += ' / ';
        s += mods[i].subProductType + ': ' + mods[i].modValue;
      }
      return s;
    },
    getAddKmChargeString (params) {
      let s = '-';
      if (params != null && params.addKmCharge != null) {
        s = params.addKmCharge;
      }
      return s;
    },
    getLowKmChargeString (params) {
      let s = '-';
      if (params != null && params.lowKmCharge != null) {
        s = params.lowKmCharge;
      }
      return s;
    },
    // ---------------------------------------------------------------------------------------------- //
    //
    //    Table and Filter methods
    //
    // ---------------------------------------------------------------------------------------------- //
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['rvGroupDetail'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filteredList = complexFilter(this.$store.state.condAdmin.productList, this.filter);
      this.productQuickFilter = null;
    },

    applyProductQuickFilter (value) {
      this.productQuickFilter = value;
      this.filterProducts();
    },

    filterProducts () {
      let list = this.$store.state.condAdmin.productList;
      if (this.productQuickFilter === true) {
        list = this.rvGroup.assignedProducts;
      } else if (this.productQuickFilter === false) {
        list = list.filter((i) => this.rvGroup.assignedProducts.find((e) => i.uid === e.uid) == null);
      }
      for (let fi in this.filter) {
        this.filter[fi].value = null;
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    // ---------------------------------------------------------------------------------------------- //
    //
    //    INTERNER TEST
    //
    // ---------------------------------------------------------------------------------------------- //
    getBack () {
      if (this.rvGroup.version > 1) {
        this.$router.push('/condAdmin/rvGroup/' + this.rvGroup.uid + '/' + (this.rvGroup.version - 1) + '/' + this.rvGroupTab);
      } else if (this.rvGroup.workingMode === 'edit' && (this.rvGroup.state === 0 || this.rvGroup.state === 1)) {
        this.$router.push('/condAdmin/rvGroup/' + this.rvGroup.uid + '/' + (this.rvGroup.version) + '/' + this.rvGroupTab);
      } else if (this.rvGroup.workingMode === 'new' || this.rvGroup.workingMode === 'copy') {
        this.$router.back();
      }
    },
    end () {
    }
  }
};
</script>

<style scoped>
.RvGroupTable>>>.v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}

.RvGroupTable td,
.RvGroupTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.RvGroupTable .borderBottom {
  border-right: 0px !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.RvGroupTable td.noBorder {
  border: 0px !important;
}

.RvGroupTable td.first,
.RvGroupTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  border-left: 1px solid var(--v-grey-lighten3) !important;
  min-width: 120px !important;
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
