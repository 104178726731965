<template>
  <v-card class="minContent">
    <v-card-title>
      <v-menu
        offset-y
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editProvider()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ capitalizeString("base.edit")}}
          </v-list-item>
          <v-list-item @click="deleteProviders()">
            <v-icon class="mr-4">mdi-bank-remove</v-icon>
            {{ capitalizeString("base.delete")}}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span
        class="ml-8 body-1"
        v-if="hasUserRight('manageBaseData')"
      >
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <QuickFilter
        v-if="hasInheritedProviders"
        class="mr-2"
        :label="$t('condAdmin.filter.inherited')"
        :count="inheritedCount"
        :active="showInherited === true"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <QuickFilter
        v-if="hasInheritedProviders"
        :label="$t('condAdmin.filter.own')"
        :count="filteredList.length - inheritedCount"
        :active="showInherited === false"
        :selectValue="false"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <v-btn
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
        @click="createProvider()"
        color="secondary"
        class="mx-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.newProvider'))"
      >
        <v-icon class="mr-2">mdi-bank-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon
        @click="getProviderList()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>

      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('providerTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
      <JsonImporter
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
        class="ml-8"
        objectType="providers"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="filteredList"
            :items-per-page="footerProps.itemsPerPage"
            :footer-props="footerProps"
            item-key="uid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.condAdmin.providersLoading"
            @update:items-per-page="getItemPerPage"
          >
            <template v-slot:body.prepend="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchField
                    v-if="filter.hasOwnProperty(h.value)"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            <template v-slot:header.menu="{}">
            </template>
            <template v-slot:item.state="{ item }">
              <v-chip :class="getStateClass(item)">
                {{ getStateText(item) }}
                <v-icon
                  class="ml-2"
                  v-if="item.inherited === true"
                >{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock' }}</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu
                offset-y
                v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true && item.inherited !== true"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editProvider(item)">
                    <v-icon class="mr-4">mdi-pencil</v-icon>
                    {{ $t("base.edit")}}
                  </v-list-item>
                  <v-list-item @click="deleteProvider(item)">
                    <v-icon class="mr-4">mdi-bank-remove</v-icon>
                    {{ $t("base.delete")}}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip
                v-if="item.inherited === true"
                v-tooltip="$tooltip($t('condAdmin.provider.inheritedProvider'))"
              >
                <v-icon>mdi-link-lock</v-icon>
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <JsonExporter
      objectType="providers"
      module="condAdmin"
      ref="jsonExporter"
    />
    <DoubletDlg
      ref="doubletDlg"
      :uniqueCheckFunction="importUniqueCheck"
      :fields="doubletFields"
      :hint="this.$t('condAdmin.providerList.importDoublet')"
    />
  </v-card>
</template>

<script lang="js">
import { refreshProviderList, saveProvider, providerNameUniqueCheck, providerKeyUniqueCheck } from '../js/condAdminProviderHelper.js';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import condAdminMixin from '../js/condAdminMixin.js';
import SearchField from '@/common/SearchField';
import QuickFilter from '@/common/QuickFilter';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import DoubletDlg from '@/common/DoubletDlg';

export default {
  name: 'ProviderTable',
  components: {
    SearchField,
    QuickFilter,
    JsonImporter,
    JsonExporter,
    DoubletDlg
  },
  mixins: [ condAdminMixin ],
  data () {
    return {
      selected: [],
      listSortKey: 'name',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.provider.key'), active: true, value: 'providerKey' },
        { text: this.$t('base.contact.name'), active: true, value: 'name' },
        { text: this.$t('base.contact.street'), active: true, value: 'street' },
        { text: this.$t('base.contact.zipCode'), active: true, value: 'zipCode' },
        { text: this.$t('base.contact.city'), active: true, value: 'city' },
        { text: this.$t('base.contact.country'), active: false, value: 'country' },
        { text: this.$t('base.contact.phone'), active: false, value: 'phone' },
        { text: this.$t('base.contact.email'), active: false, value: 'email' },
        { text: this.$t('base.contact.fax'), active: false, value: 'fax' },
        { text: this.$t('base.state'), active: true, value: 'state' },
        { text: this.$t('base.contact.additionalData'), active: false, value: 'additionalData' }
      ],
      showInherited: null,
      filter: {
        providerKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        name: { condition: 'startsWith', value: null, type: 'SearchField' },
        street: { condition: 'startsWith', value: null, type: 'SearchField' },
        zipCode: { condition: 'startsWith', value: null, type: 'SearchField' },
        city: { condition: 'startsWith', value: null, type: 'SearchField' },
        country: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      doubletFields: [
        { key: 'providerKey', text: this.$t('condAdmin.provider.key'), unique: true, readonly: false },
        { key: 'name', text: this.$t('base.contact.name'), unique: true, readonly: false },
        { key: 'street', text: this.$t('base.contact.zipCode'), unique: false, readonly: false },
        { key: 'zipCode', text: this.$t('base.contact.zipCode'), unique: false, readonly: false },
        { key: 'city', text: this.$t('base.contact.city'), unique: false, readonly: false },
        { key: 'country', text: this.$t('base.contact.country'), unique: false, readonly: false },
        { key: 'doubletZipCodeRegions', text: this.$t('base.contact.country'), unique: false, readonly: true },
        { key: 'doubletBalloonOption', text: this.$t('base.contact.country'), unique: false, readonly: true }
      ]
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    hasInheritedProviders () {
      return (this.$store.state.condAdmin.providerList.filter((c) => c.inherited === true).length > 0);
    },
    inheritedCount () {
      return this.filteredList.filter((e) => e.inherited === true).length;
    }
  },
  mounted () {
    this.getUserTableSettings('providerTable');
    this.filterProviders();
    this.$emit('setPageMode', 'table');
  },
  methods: {
    filterProviders () {
      let list = this.$store.state.condAdmin.providerList;
      if (this.showInherited === true) {
        list = list.filter((e) => e.inherited === true);
      } else if (this.showInherited === false) {
        list = list.filter((e) => e.inherited !== true);
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    applyInheritedFilter (value) {
      this.showInherited = value;
      console.log('inheritedFilter', this.showInherited);
      this.filterProviders();
    },
    async getProviderList () {
      await refreshProviderList();
      this.filterProviders();
    },
    async deleteProviders () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.providerList.deleteProvider'), this.$t('condAdmin.providerList.deleteProvidersConfirmText'))) {
        for (let provider of this.selected) {
          this.callDeleteProviderService(provider);
        }
      }
    },
    async deleteProvider (provider) {
      // show confirmation dialog
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.providerList.deleteProvider') + ': ' + provider.name, this.$t('condAdmin.providerList.deleteProviderConfirmText'))) {
        this.callDeleteProviderService(provider);
      }
    },
    callDeleteProviderService (provider) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        providerUid: provider.uid
      };
      this.$restClient.callProcess('condAdmin', 'deleteProvider', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.providerList.deletedProvider', { provider: provider.name }) }]);
            // remove provider from list
            this.$store.state.condAdmin.providerList.splice(this.$store.state.condAdmin.providerList.findIndex((p) => p.uid === provider.uid), 1);
            // remove providers conditions from list
            if (this.$store.state.condAdmin.conditionList != null) {
              this.$store.state.condAdmin.conditionList = this.$store.state.condAdmin.conditionList.filter((e) => e.providerUid !== provider.uid);
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    createProvider () {
      this.$router.push('/condAdmin/provider/create');
    },
    editProvider (provider = null) {
      if (!provider) {
        provider = this.selected[0];
      }
      this.$router.push('/condAdmin/provider/' + provider.uid);
    },
    clickedRow (provider) {
      this.editProvider(provider);
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['providerTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['providerTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filterProviders();
      this.$store.state.condAdmin.userSettings['providerTable'].filter[filtername] = value;
    },
    exportData () {
      let providers = JSON.parse(JSON.stringify(this.selected));
      for (let p of providers) {
        delete p.uid;
        delete p.clientKey;
        delete p.timestamp;
      }
      this.$refs.jsonExporter.exportJsonData({ providers });
    },
    async importData (json) {
      await refreshProviderList();
      let imported = 0;
      let total = json.data.providers.length;

      for (let imp of json.data.providers) {
        // check already existing dealerKey
        let existing = this.importUniqueCheck(imp);
        if (existing != null) {
          this.addDoubletData(existing);
          this.addDoubletData(imp);

          let [action, merged] = await this.$refs.doubletDlg.show(existing, imp);
          switch (action) {
            case 'overwrite':
              merged.uid = existing.uid;
              delete merged.doubletBalloonOption;
              delete merged.doubletZipCodeRegions;
              await saveProvider(merged);
              console.log('overwrote', JSON.stringify(merged));
              imported++;
              break;
            case 'copy':
              delete merged.doubletBalloonOption;
              delete merged.doubletZipCodeRegions;
              await saveProvider(merged);
              console.log('copied', JSON.stringify(merged));
              imported++;
              break;
            case 'skipNew':
            default:
              break;
          }
        } else {
          await saveProvider(imp);
          console.log('imported', JSON.stringify(imp));
          imported++;
        }
      }
      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported > 0) this.getProviderList();
    },
    addDoubletData (provider) {
      provider.doubletZipCodeRegions = '';
      if (provider.calcSettings.zipCodeRegions != null) {
        for (let z of provider.calcSettings.zipCodeRegions) provider.doubletZipCodeRegions += ', ' + z;
        provider.doubletZipCodeRegions = provider.doubletZipCodeRegions.substring(2);
      }
      provider.doubletBalloonOption = provider.calcSettings.balloonOption;
    },
    preImportCheckFunction (json) {
      if (json.data.providers == null || json.data.providers.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.providerList.noImportData')) ]);
        return false;
      }
      return true;
    },
    importUniqueCheck (provider) {
      let existing = providerNameUniqueCheck(provider) || providerKeyUniqueCheck(provider);
      return existing;
    }
  }
};
</script>

<style scoped>
</style>
