<template>
  <v-card
    flat
    class="pl-2"
  >
    <v-card-text>

      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="6"
          sm="6"
          xs="6"
        >

          <v-row no-gutters>
            <span class="mt-1">{{ $t('condAdmin.rvTable.durations') }}</span>
            <v-btn
              v-if="readonly !== true"
              :disabled="tmpParams.durations.includes(null)"
              @click="tmpParams.durations.push(null)"
              icon
              color="secondary"
            >
            <v-icon
              v-if="readonly !== true"
              >mdi-plus-box</v-icon>
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpParams.durations"
              :key="v"
              :close="!readonly"
              @click:close="removeDuration(v)"
              class="mr-2 mb-1"
            >
            <EditField
              style="width: 50px"
              class="mt-0 caption"
              v-if="v == null"
              :value="v"
              @change="changeDuration(v, $event)"
              :placeholder="$t('base.value')"
              hideDetails
              dense
              type="uint"
            />
              <div v-else>{{ v }}</div>
            </v-chip>
            <span v-if="tmpParams.durations.length === 0">{{ $t('base.none') }}</span>
          </v-row>
          <v-row no-gutters>
            <span class="mt-1">{{ $t('condAdmin.rvTable.mileages') }}</span>
            <v-btn
              v-if="readonly !== true"
              :disabled="tmpParams.mileages.includes(null)"
              @click="tmpParams.mileages.push(null)"
              icon
              color="secondary"
            >
            <v-icon v-if="readonly !== true">mdi-plus-box</v-icon>
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpParams.mileages"
              :key="v"
              :close="!readonly"
              @click:close="removeMileages(v)"
              class="mr-2 mb-1"
            >
            <EditField
              style="width: 50px"
              class="mt-0 caption"
              v-if="v == null"
              :value="v"
              @change="changeMileages(v, $event)"
              :placeholder="$t('base.value')"
              hideDetails
              dense
              type="uint"
            />
              <div v-else>{{ v }}</div>
            </v-chip>
            <span v-if="tmpParams.durations.length === 0">{{ $t('base.none') }}</span>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-label>{{ $t('condAdmin.rvTableList.residualValues') }}</v-label>

          <v-select
              v-if="readonly !== true"
              v-model="residualSelect"
              :items="residualInput"
              item-text="text"
              item-value="value"
              :label="$t('condAdmin.rvTableEditor.rvInputMode.label')"
          ></v-select>
          <table class="text-center" :key="residualSelect">
            <tr>
              <th></th>
              <th
                :colspan="tmpParams.mileages.length"
                class="primary lighten-5"
              >{{ $t('condAdmin.rvTable.mileages') }}</th>
            </tr>
            <tr>
              <th class="primary lighten-3">{{ $t('condAdmin.rvTable.durations') }}</th>
              <th
                v-for="(m) in tmpParams.mileages"
                :key="m"
                class="primary lighten-5"
              >{{ m }}
              </th>
            </tr>
            <tr
              v-for="(d) in tmpParams.durations"
              :key="d"
            >
              <th class="primary lighten-3">{{ d }}
              </th>
              <!-- Residual Values -->
              <td
                class="px-4"
                v-for="(m) in tmpParams.mileages"
                :key="m"
                :class="getModClassColor(d, m)"
                v-tooltip="$tooltip(getRvTooltip(d, m))">
                <v-row>
                  <v-col>
                    <span v-if="readonly == true">{{ getRvValue(d, m) }}</span>
                    <EditField
                      v-if="readonly !== true && residualSelect === 'InputRV'"
                      @change="setRvValue(d,m,parseFloat($event))"
                      :value="getRvValue(d, m)"
                      clearable
                      :placeholder="$t('base.value')"
                      :readonly="readonly"
                    />
                    <v-select
                      v-if="readonly !== true && residualSelect === 'InputKMK'"
                      :items="modColourClassInput"
                      item-text="modClass"
                      @change="setModClassColor(d,m,$event)"
                      :value="getModClassValue(d, m)"
                    ></v-select>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <v-col>
          <v-label>{{ $t('condAdmin.rvTableList.subProductModifications') }}</v-label>
          <div>
            {{ params.subProductModifications == null ? $t('base.none') : JSON.stringify(params.subProductModifications) }}
          </div>
        </v-col>
      </v-row>
      -->
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import { minRVValues } from '@/condAdmin/js/condAdminHelper.js';
import condAdminMixin from '@/condAdmin/js/condAdminMixin.js';
import EditField from '@/common/EditField';

export default {
  name: 'ResidualValuesVersionDetail_daag',
  components: {
    // KeyValueRow,
    EditField
    // RvTableVersionDetail
  },
  mixins: [ condAdminMixin ],
  props: {
    params: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      tmpParams: this.params,

      modColourClassInput: [
        { modClass: 0, colorClass: 'colorClass0' },
        { modClass: 1, colorClass: 'colorClass1' },
        { modClass: 2, colorClass: 'colorClass2' },
        { modClass: 3, colorClass: 'colorClass3' },
        { modClass: 4, colorClass: 'colorClass4' },
        { modClass: 5, colorClass: 'colorClass5' },
        { modClass: 6, colorClass: 'colorClass6' },
        { modClass: 7, colorClass: 'colorClass7' },
        { modClass: 8, colorClass: 'colorClass8' },
        { modClass: 9, colorClass: 'colorClass9' }
      ],
      residualSelect: 'InputRV',
      residualInput: [
        { id: 1, text: this.$t('condAdmin.rvTableEditor.rvInputMode.daag.inputRVValue'), value: 'InputRV' },
        { id: 2, text: this.$t('condAdmin.rvTableEditor.rvInputMode.daag.inputRVClass'), value: 'InputKMK' }
      ]
    };
  },
  computed: {
  },
  watch: {
    params () {
      this.tmpParams = this.params;
    }
  },
  mounted () {
    this.tmpParams = this.params;
    this.init();
  },
  methods: {
    async init () {
    },
    formatValues (v) {
      return this.formatValue(v, this.$t('condAdmin.noneValue'));
    },
    changeMileages (oldValue, newValue) {
      let index = this.tmpParams.mileages.indexOf(oldValue);
      if (index > -1) {
        this.tmpParams.mileages[index] = newValue;
        this.$emit('setDirty');
        this.tmpParams.mileages = [...new Set(this.tmpParams.mileages.sort((a, b) => { return a - b; }))];
      }
    },
    removeMileages (mileage) {
      if (this.tmpParams.mileages.length > 1) {
        let att = this.tmpParams.attributes.filter((e) => e.mileage !== mileage);
        if (att != null) {
          this.tmpParams.attributes = [];
          this.tmpParams.attributes = att;
          let mileagesArray = this.tmpParams.mileages.filter((e) => e !== mileage);
          this.tmpParams.mileages = [];
          this.tmpParams.mileages = mileagesArray;
          this.$emit('setDirty');
        }
      } else {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.removeLastMileage') }]);
      }
    },
    changeDuration (oldValue, newValue) {
      let index = this.tmpParams.durations.indexOf(oldValue);
      if (index > -1) {
        this.tmpParams.durations[index] = newValue;
        this.$emit('setDirty');
        this.tmpParams.durations = [...new Set(this.tmpParams.durations.sort((a, b) => { return a - b; }))];
      }
    },
    removeDuration (duration) {
      if (this.tmpParams.durations.length > 1) {
        let att = this.tmpParams.attributes.filter((e) => e.duration !== duration);
        if (att != null) {
          this.tmpParams.attributes = [];
          this.tmpParams.attributes = att;
          let durationsArray = this.tmpParams.durations.filter((e) => e !== duration);
          this.tmpParams.durations = [];
          this.tmpParams.durations = durationsArray;
          this.$emit('setDirty');
        }
      } else {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.removeLastDuration') }]);
      }
    },
    getModClassColor (d, m) {
      let att = this.tmpParams.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        switch (att.modClass) {
          case 1: return 'colorClass1';
          case 2: return 'colorClass2';
          case 3: return 'colorClass3';
          case 4: return 'colorClass4';
          case 5: return 'colorClass5';
          case 6: return 'colorClass6';
          case 7: return 'colorClass7';
          case 8: return 'colorClass8';
          case 9: return 'colorClass9';
          default: return 'colorClass0';
        }
      }

      return 'colorClass0';
    },
    getModClassValue (d, m) {
      let att = this.tmpParams.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        switch (att.modClass) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9: return att.modClass;
          default: return 0;
        }
      }

      return 0;
    },
    setModClassColor (d, m, value) {
      // this.$emit('setDirty');
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      let att = this.tmpParams.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        att.modClass = value;
      } else {
        let att = { 'mileage': m, 'duration': d, 'modClass': value, 'residualValue': 0 };
        this.tmpParams.attributes.push(att);
      }
    },
    getRvValue (d, m) {
      let att = this.params.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        return att.residualValue;
      }
    },
    setRvValue (d, m, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      let minAllowedRvValue = minRVValues[this.$store.state.condAdmin.countrySettings.key];
      if (value < minAllowedRvValue) {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.minResidualValue', { minRv: minAllowedRvValue }) }]);
      }
      if (value > 99) {
        this.$globals.Info.open([{ type: 'info', message: this.$t('condAdmin.rvTableEditor.rvInputMode.maxResidualValue') }]);
      }

      let att = this.tmpParams.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att != null) {
        if (value) {
          att.residualValue = value;
        } else {
          this.tmpParams.attributes = this.tmpParams.attributes.filter(el => (el.duration !== att.duration || el.mileage !== att.mileage));
        }
      } else {
        let att = { 'mileage': m, 'duration': d, 'residualValue': value, 'modClass': 0 };
        this.tmpParams.attributes.push(att);
      }
    },
    getRvTooltip (d, m) {
      let att = this.params.attributes.find((e) => e.duration === d && e.mileage === m);
      if (att == null) {
        return this.$t('condAdmin.rvTable.noRvValueSet');
      }
      let msg = this.$t('condAdmin.rvTable.duration') + ': ' + att.duration + ' ' + this.$t('base.months') + '<br/>';
      msg += this.$t('condAdmin.rvTable.mileage') + ': ' + att.mileage + ' km<br/>';
      msg += this.$t('condAdmin.rvTable.modClass') + ': ' + att.modClass + '<br/>';
      msg += this.$t('condAdmin.rvTable.residualValue') + ': ' + att.residualValue + '%';

      return msg;
    }

  }
};
</script>

<style scoped>
</style>
