<template>
  <v-card class="minContent" v-if="filteredList != null">
    <v-card-title>
      <v-menu offset-y v-if="hasUserRight('manageCondition') && getOrgSetting('createOwnData') === true">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="selected.length === 0" color="secondary">
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <!--<v-list-item
            v-if="getOrgSetting('createOwnData') === true"
            @click="editCondition()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ capitalizeString("base.edit")}}
          </v-list-item>-->
          <v-list-item @click="deleteConditions()" v-if="getOrgSetting('createOwnData') === true">
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ capitalizeString("base.delete") }}
          </v-list-item>
          <v-list-item v-if="hasInheritedConditions" @click="enableConditions(true)">{{
            capitalizeString('condAdmin.actions.enable') }}
          </v-list-item>
          <v-list-item v-if="hasInheritedConditions" @click="enableConditions(false)">{{
            capitalizeString('condAdmin.actions.disable') }}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <QuickFilter v-if="hasInheritedConditions" class="mr-2" :label="$t('condAdmin.filter.inherited')"
        :count="inheritedCount" :active="showInherited === true" :deselectValue="null" @clicked="applyInheritedFilter" />
      <QuickFilter v-if="hasInheritedConditions" :label="$t('condAdmin.filter.own')"
        :count="filteredList.length - inheritedCount" :active="showInherited === false" :selectValue="false"
        :deselectValue="null" @clicked="applyInheritedFilter" />
      <v-btn v-if="hasUserRight('manageCondition') && getOrgSetting('createOwnData') === true" @click="createCondition()"
        color="secondary" class="mx-8" v-tooltip="$tooltip($t('condAdmin.tooltip.newCondition'))">
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon @click="getConditionList(true)" color="secondary" class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))">mdi-reload</v-icon>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))">mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item v-for="(col, index) in listAvailableColumns" :key="index">
            <v-checkbox v-model="listAvailableColumns[index].active" :label="$t(listAvailableColumns[index].text)"
              @change="setAvailableHeaders(listAvailableColumns[index], $event)"></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon @click="saveUserTableSettings('conditionTable')" color="secondary" class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))">mdi-content-save-cog
      </v-icon>
      <JsonImporter v-if="hasUserRight('manageCondition') && getOrgSetting('createOwnData') === true" class="ml-8"
        objectType="conditionList" module="condAdmin" :preImportCheck="preImportCheckFunction" @import="importData" />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="filteredList"
            :items-per-page="footerProps.itemsPerPage"
            :footer-props="footerProps"
            item-key="feid"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.condAdmin.conditionsLoading"
            @update:items-per-page="getItemPerPage">
            <template v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`body.prepend`]="{ }">
              <tr>
                <td></td>
                <td v-for="(h, index) in listHeaders" :key="index">
                  <SearchSelect v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                    :value="filter[h.value].value" @filter="applyFilters($event, h.value)"
                    :items="conditionSelectLists(filter[h.value].items)" :item-text="filter[h.value].itemText"
                    :item-value="filter[h.value].itemValue" />
                  <SearchField v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                    :value="filter[h.value].value" @filter="applyFilters($event, h.value)">
                  </SearchField>
                  <SearchDatePicker v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'DatePicker'"
                    :value="filter[h.value].value" @filter="applyFilters($event, h.value)" />
                </td>
              </tr>
            </template>
            <template v-slot:[`header.menu`]="{ }">
            </template>
            <template v-slot:[`item.durations`]="{ item }">
              {{ getDurationsText(item.durations) }}
            </template>
            <template v-slot:[`item.productTypes`]="{ item }">
              {{ getProductTypesText(item.productTypes) }}
            </template>
            <template v-slot:[`item.subProductTypes`]="{ item }">
              {{ getSubProductTypesText(item.subProductTypes) }}
            </template>
            <template v-slot:[`item.minCredit`]="{ item }">
              {{ getRangeText(item.minCredit, item.maxCredit, currency) }}
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-chip :class="getStateClass(item)">
                {{ getStateText(item) }}
                <v-icon class="ml-2" v-if="item.inherited === true"
                  v-tooltip="$tooltip($t('condAdmin.condition.inheritedCondition'))">{{ getOrgSetting('createOwnData') ===
                    true ? 'mdi-link' : 'mdi-link-lock' }}</v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.interestType`]="{ item }">
              <v-chip :class="getInterestTypeClass(item.additionalParams.interestType, false)">
                {{ getInterestTypeText(item.additionalParams.interestType) }}
              </v-chip>
            </template>
            <template v-slot:[`item.menu`]="{ item }">
              <v-menu offset-y v-if="getOrgSetting('createOwnData') === true">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="hasUserRight('manageCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('edit', item)" @click="editCondition('edit', item)">{{
                      capitalizeString('condAdmin.actions.edit') }}
                  </v-list-item>
                  <v-list-item v-if="hasUserRight('manageCondition') && getOrgSetting('createOwnData') === true"
                    :disabled="!enableAction('copy', item)"
                    @click="editCondition('copy', item) && getOrgSetting('createOwnData') === true">{{
                      capitalizeString('condAdmin.actions.copy') }}
                  </v-list-item>
                  <!--<v-list-item
                    v-if="hasUserRight('manageCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('end', item)"
                    @click="end(item)"
                  >{{ capitalizeString('condAdmin.actions.end') }}
                  </v-list-item>-->
                  <v-list-item
                    v-if="hasUserRight('manageCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && item.inherited === true"
                    :disabled="!enableAction('disable', item)" @click="enableCondition(item, item.disabled === true)">{{
                      capitalizeString(item.disabled === true ? 'condAdmin.actions.enable' : 'condAdmin.actions.disable') }}
                  </v-list-item>
                  <v-list-item
                    v-if="hasUserRight('manageCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('remove', item)" @click="removeDraft(item)">{{
                      capitalizeString('condAdmin.actions.delete') }}
                  </v-list-item>
                  <v-list-item
                    v-if="hasUserRight('manageCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('setCheck', item)" @click="setConditionState(2, item)">{{ hasExamination() ?
                      capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}
                  </v-list-item>
                  <v-list-item
                    v-if="hasExamination() && hasUserRight('examineCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('setChecked', item)" @click="setConditionState(4, item)">{{
                      capitalizeString('condAdmin.actions.setChecked') }}
                  </v-list-item>
                  <v-list-item
                    v-if="hasExamination() && hasUserRight('examineCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('setRework', item)" @click="setConditionState(1, item)">{{
                      capitalizeString('condAdmin.actions.setRework') }}
                  </v-list-item>
                  <v-list-item
                    v-if="hasActivatorRole() && hasUserRight('activateCondition', item.providerUid, item.brands) && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('enable', item)" @click="setConditionState(6, item)">{{
                      capitalizeString('condAdmin.actions.enable') }}
                  </v-list-item>
                  <v-list-item v-if="item.inherited !== true"
                    @click="showHistory('getConditionHistory', item.uid, item.name)">{{ $t('condAdmin.actions.history') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <JsonExporter objectType="conditionList" module="condAdmin" ref="jsonExporter" />
    <ConditionImportDlg ref="conditionImportDlg" />
  </v-card>
</template>

<script lang="js">
import { complexFilter } from '@/base/js/ObjectHelper';
import { newMessage, isProcessStateOK } from '@/base/js/ProcessDataHelper';
import condAdminConditionMixin from '../js/condAdminConditionMixin.js';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import SearchDatePicker from '@/common/SearchDatePicker';
import QuickFilter from '@/common/QuickFilter';
import HistoryDlg from './HistoryDlg';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import ConditionImportDlg from './ConditionImportDlg';

export default {
  name: 'ConditionTable',
  components: {
    SearchField,
    SearchSelect,
    SearchDatePicker,
    QuickFilter,
    HistoryDlg,
    JsonImporter,
    JsonExporter,
    ConditionImportDlg
  },
  mixins: [condAdminConditionMixin],
  data () {
    return {
      selected: [],
      filterToggle: true,
      listSortKey: 'conditionKey',
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      },
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.condition.conditionKey'), active: true, value: 'conditionKey', isAvailable: true },
        { text: this.$t('condAdmin.condition.name'), active: true, value: 'name', isAvailable: true },
        { text: this.$t('condAdmin.condition.calcTypeShort'), active: false, value: 'calcType', isAvailable: true },
        { text: this.$t('condAdmin.condition.calcType'), active: true, value: 'calcTypeName', isAvailable: true },
        { text: this.$t('condAdmin.condition.provider'), active: true, value: 'providerName', isAvailable: true },
        { text: this.$t('condAdmin.condition.duration'), active: false, value: 'durations', isAvailable: true },
        { text: this.$t('condAdmin.condition.credit'), active: false, value: 'minCredit', isAvailable: true },
        { text: this.$t('condAdmin.product.productType'), active: false, value: 'productTypes', formatter: this.formatArray, isAvailable: true },
        { text: this.$t('condAdmin.product.subProductType'), active: false, value: 'subProductTypes', formatter: this.formatArray, isAvailable: true },
        { text: this.$t('condAdmin.product.brand'), active: false, value: 'brands', formatter: this.formatArray, isAvailable: true },
        { text: this.$t('condAdmin.version'), active: true, value: 'version', isAvailable: true },
        { text: this.$t('condAdmin.validFrom'), active: true, value: 'validFrom', formatter: this.formatBeginDate, isAvailable: true },
        { text: this.$t('condAdmin.validTo'), active: true, value: 'validTo', formatter: this.formatEndDate, isAvailable: true },
        { text: this.$t('condAdmin.condition.interestType'), active: true, value: 'interestType', isAvailable: this.useInterestType() },
        { text: this.$t('base.state'), active: true, value: 'state', isAvailable: true }
      ],
      showInherited: null,
      filter: {
        conditionKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        name: { condition: 'startsWith', value: null, type: 'SearchField' },
        calcType: { condition: 'is', value: null, type: 'SelectBox', items: 'calcTypeList', itemText: 'calcType', itemValue: 'calcType' },
        calcTypeName: { condition: 'is', value: null, type: 'SelectBox', items: 'calcTypeList', itemText: 'text', itemValue: 'text' },
        providerName: { condition: 'startsWith', value: null, type: 'SelectBox', items: 'providerList', itemText: 'name', itemValue: 'name' },
        durations: { condition: 'arr.=', value: null, type: 'SearchField' },
        minCredit: { condition: '>=', value: null, type: 'SearchField' },
        maxCredit: { condition: '<=', value: null, type: 'SearchField' },
        productTypes: { condition: 'arr.is', value: null, type: 'SelectBox', items: 'productTypeList', itemText: 'name', itemValue: 'uid' },
        subProductTypes: { condition: 'arr.is', value: null, type: 'SelectBox', items: 'subProductTypeList', itemText: 'name', itemValue: 'uid' },
        brands: { condition: 'arr.is', value: null, type: 'SelectBox', items: 'brandList', itemText: 'text', itemValue: 'text' },
        validFrom: { condition: 'date.<=', value: null, type: 'DatePicker' },
        validTo: { condition: 'date.>=', value: null, type: 'DatePicker' },
        interestType: { condition: 'is', value: null, type: 'SelectBox', items: 'interestTypeList', itemText: 'text', itemValue: 'interestType' },
        state: { condition: '=', value: null, type: 'SelectBox', items: 'stateList', itemText: 'text', itemValue: 'state' }
      },
      doubletFields: [
        { key: 'providerKey', text: this.$t('condAdmin.provider.key'), unique: true, readonly: false }
      ]
    };
  },
  computed: {
    calcTypeList () {
      return this.$store.state.condAdmin.calcTypeList.filter((i) => i.isActive === true);
    },
    subProductTypeList () {
      let list = [];
      for (let pt of this.$store.state.condAdmin.productTypeList) {
        if (this.filter.productTypes.value == null || pt.uid === this.filter.productTypes.value) {
          list = list.concat(pt.subProductTypes);
        }
      }
      return list.sort();
    },
    productTypeList () {
      let list = [].concat(this.$store.state.condAdmin.productTypeList);
      return list.sort();
    },
    brandList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.conditionList) {
        for (let brand of c.brands) {
          if (brand != null && !list.includes(brand)) list.push(brand);
          if (brand == null) list.push(this.NULLVALUE);
        }
      }
      return list.sort();
    },
    stateList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.conditionList) {
        if (list.find((i) => i.state === c.feState) == null) list.push({ state: c.feState, text: this.$t('condAdmin.state.' + this.states[c.feState]) });
      }
      return list.sort();
    },
    interestTypeList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.conditionList) {
        if (list.find((i) => i.interestType === c.additionalParams.interestType.name) == null) {
          list.push({ interestType: c.additionalParams.interestType.name, text: this.$t('condAdmin.interestType.' + c.additionalParams.interestType.indexName + '.name') });
        }
      }
      return list.sort();
    },
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.isAvailable === true) {
          if (mc.active === true) {
            h.push(mc);
          }
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    listAvailableColumns () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.isAvailable === true) {
          h.push(mc);
        }
      }
      return h;
    },
    hasInheritedConditions () {
      return (this.$store.state.condAdmin.conditionList.filter((c) => c.inherited === true).length > 0);
    },
    inheritedCount () {
      return this.filteredList.filter((e) => e.inherited === true).length;
    }
  },
  watch: {
    '$store.state.condAdmin.conditionList': function () {
      this.filterConditions();
    }
  },
  mounted () {
    this.getUserTableSettings('conditionTable');
    this.filterConditions();
    this.$emit('setPageMode', 'table');
  },
  methods: {
    filterConditions () {
      let list = this.$store.state.condAdmin.conditionList;
      if (this.showInherited === true) {
        list = list.filter((e) => e.inherited === true);
      } else if (this.showInherited === false) {
        list = list.filter((e) => e.inherited !== true);
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    applyInheritedFilter (value) {
      this.showInherited = value;
      console.log('inheritedFilter', this.showInherited);
      this.filterConditions();
    },
    conditionSelectLists (value) {
      switch (value) {
        case 'calcTypeList':
          return this.calcTypeList;
        case 'providerList':
          return this.$store.state.condAdmin.providerList;
        case 'productTypeList':
          return this.productTypeList;
        case 'subProductTypeList':
          return this.subProductTypeList;
        case 'brandList':
          return this.brandList;
        case 'stateList':
          return this.stateList;
        case 'interestTypeList':
          return this.interestTypeList;
      }
    },
    async getConditionList (force = false) {
      if (force || this.$store.state.condAdmin.conditionList == null || this.$store.state.condAdmin.conditionList.length === 0) {
        this.filteredList = null;
        await this.refreshConditionList(true, true);
      }
      this.filterConditions();
    },
    enableAction (action, condition) {
      switch (action) {
        case 'edit': return condition.inherited !== true;
        case 'copy': return true;
        case 'end': return false;
        case 'remove': return condition.inherited !== true && condition.state < 6;
        case 'setCheck': return condition.inherited !== true && (condition.state === 0 || condition.state === 1);
        case 'setChecked': return condition.inherited !== true && (condition.state === 2 || condition.state === 4);
        case 'setRework': return condition.inherited !== true && (condition.state === 2 || condition.state === 4);
        case 'enable': return condition.inherited !== true && condition.state === 5;
      }
      return true;
    },
    useInterestType () {
      return this.$store.state.condAdmin.orgSettings.useInterestType === true;
    },
    async createCondition () {
      if (await this.checkEditPreConditions('new') === true) {
        this.$router.push('/condAdmin/condition/edit');
      }
    },
    async editCondition (mode, condition) {
      if (condition.versions == null) {
        condition = await this.readCondition(condition.uid, condition.version, true);
      }
      if (await this.checkEditPreConditions(mode, condition) === true) {
        this.$router.push('/condAdmin/condition/edit');
      }
    },
    async deleteConditions () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.conditionEditor.dlghDeleteConditions'), this.$t('condAdmin.conditionEditor.dlgtDeleteConditions'))) {
        for (let condition of this.selected) {
          await this.deleteCondition(condition, true);
        }
        this.selected = [];
      }
    },
    async removeDraft (condition) {
      console.log('removeDraft:', condition.conditionKey, 'v' + condition.version);
      if (await this.deleteCondition(condition) === true) {
        this.$router.push('/condAdmin/condition');
      }
    },
    async setConditionState (state, condition) {
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        if (condition.versions == null || condition.attributes == null) {
          condition = await this.readCondition(condition.uid, condition.version, true);
        }
        let productAssignmentsCheck = await this.validateProductAssignments(condition);
        let beginDateCheck = await this.validateBeginDate(condition);
        let endDateCheck = await this.validateEndDate(condition);
        let rvCheck = await this.validateResidualValues(condition);

        if (!productAssignmentsCheck || !beginDateCheck || !endDateCheck || !rvCheck) {
          return false;
        }
      }
      await this.setState(condition, state);
      await this.getConditionList(true);
    },
    viewCondition (condition = null) {
      this.$router.push('/condAdmin/condition/' + condition.uid + '/' + condition.version);
    },
    clickedRow (condition) {
      this.viewCondition(condition);
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['conditionTable'].footerPropItemsPerPage = val;
      console.debug('getItemPerPage', val);
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['conditionTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.$logger.debug('condAdmin', 'set filter ' + filtername + ' to ' + value);
      this.filterConditions();
      this.$store.state.condAdmin.userSettings['conditionTable'].filter[filtername] = value;
    },
    async exportData () {
      let conditionList = [];
      for (let c of this.selected) {
        let condition = JSON.parse(JSON.stringify(await this.readCondition(c.uid, c.version, true)));
        delete condition.uid;
        delete condition.id;
        delete condition.clientKey;
        delete condition.providerUid;
        delete condition.calcTypeUid;
        delete condition.calcTypeName;
        delete condition.feid;
        delete condition.versions;
        delete condition.timestamp;
        delete condition.interestLines;
        for (let product of condition.products) {
          product.productType = null;
          product.subProductType = null;
          if (product.productTypeUid != null) {
            let pt = this.$store.state.condAdmin.productTypeList.find((e) => e.uid === product.productTypeUid);
            product.productType = pt.name;
            if (product.subProductTypeUid != null) {
              let spt = pt.subProductTypes.find((e) => e.uid === product.subProductTypeUid);
              product.subProductType = spt.name;
            }
          }
          delete product.id;
          delete product.conditionId;
          delete product.timestamp;
          delete product.productTypeUid;
          delete product.subProductTypeUid;
        }

        conditionList.push(condition);
      }
      this.$refs.jsonExporter.exportJsonData({ conditionList });
    },
    async importData (json) {
      await this.refreshConditionList(true, true);
      let imported = [];
      let total = json.data.conditionList.length;

      for (let imp of json.data.conditionList) {
        let action = await this.$refs.conditionImportDlg.show(imp);
        switch (action) {
          case 'startImport':
            this.createInterestLines(imp);
            let processData = await this.saveCondition(imp, false);
            if (processData != null) {
              this.$store.state.condAdmin.conditionList.push(processData.ioData.condition);
              imported.push({ uid: processData.ioData.condition.uid, version: processData.ioData.condition.version });
            }
            break;
          case 'skip':
          default:
            break;
        }
      }
      this.$globals.Info.open([newMessage('success', this.$t('condAdmin.importResult', { imported, total }))]);
      if (imported.length > 0) {
        let activate = await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.conditionImport.dlghActivateImports'), this.$t('condAdmin.conditionImport.dlgtActivateImports'), this.$t('condAdmin.actions.setChecked'), this.$t('condAdmin.actions.keepDraft'));
        if (activate === true) { // activate conditions
          for (let cond of imported) {
            this.setState(cond, 6);
          }
        }
        this.getConditionList(true);
      }
    },
    preImportCheckFunction (json) {
      if (json.data.conditionList == null || json.data.conditionList.length < 1) {
        this.$globals.Info.open([newMessage('userError', this.$t('condAdmin.conditionImport.noImportData'))]);
        return false;
      }
      return true;
    },
    enableConditions (value) {
      let warning = false;
      let list = [];
      for (let condition of this.selected) {
        if (condition.inherited === true) {
          if (!list.includes(condition.uid)) list.push(condition.uid);
        } else if (value === false) {
          warning = true;
        }
      }
      if (warning) {
        this.$globals.Info.open([newMessage('warning', this.$t('condAdmin.conditionList.notToDisable'))]);
      }
      this.callEnableConditions(list, value);
    },
    enableCondition (condition, value) {
      if (condition.inherited === true) {
        this.callEnableConditions([condition.uid], value);
      } else if (value === false) {
        this.$globals.Info.open([newMessage('warning', this.$t('condAdmin.conditionList.notToDisable'))]);
      }
    },
    async callEnableConditions (list, enable) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        conditionList: list
      };
      let processName = enable ? 'enableConditions' : 'disableConditions';

      let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
      if (isProcessStateOK(processData)) {
        for (let condition of this.$store.state.condAdmin.conditionList) {
          if (processData.ioData.conditionList.includes(condition.uid)) {
            condition.disabled = !enable;
            condition.feState = this.getModifiedStateValue(condition);
          }
        }
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    }
  }
};
</script>

<style scoped>
</style>
