<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card v-if="rvTable != null">
      <v-card-title class="primary-bg">
        {{ $t('condAdmin.rvTableImport.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row v-if="errors.length > 0">
          <v-col class="error--text">{{ $t('condAdmin.rvTableImport.errors') }}</v-col>
        </v-row>
        <v-row
          v-for="err, index in errors"
          :key="index"
        >
          <v-col class="error--text">{{ err }}</v-col>
        </v-row>
        <v-row v-if="errors.length === 0">
          <v-col>{{ $t(found ? 'condAdmin.rvTableImport.existingFound' : 'condAdmin.rvTableImport.noExistingFound') }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.residualValuesList.headline') }}</v-col>
          <v-col>{{ rvTable.rvTableKey }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.version') }}</v-col>
          <v-col>{{ rvTable.version }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.validFrom') }}</v-col>
          <v-col>{{ rvTable.validFrom }}</v-col>
        </v-row>
        <v-row v-if="warnings.length > 0">
          <v-col class="warning--text">{{ $t('condAdmin.rvTableImport.warnings') }}</v-col>
        </v-row>
        <v-row
          v-for="warn, index in warnings"
          :key="index"
        >
          <v-col class="warning--text">{{ warn }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :showSave="errors.length === 0"
          @cancel="skip"
          @save="startImport"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">

import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'ResidualValuesTableImportDlg',
  components: {
    SaveCancelButtonBar
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      errors: [],
      warnings: [],
      found: false,
      rvTable: null
    };
  },
  methods: {
    show (rvTable) {
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvTable = rvTable;
      let tempRvTableList = this.$store.state.condAdmin.rvTableList;

      //
      let existing = tempRvTableList.find(
        (e) => e.rvTableKey === rvTable.rvTableKey
      );
      if (existing != null) {
        rvTable.rvTableKey = existing.rvTableKey;
        // Zusatzprodukt befindet sich im Entwurfmodus
        // TODO : überprüfen ob es ein Produkt im status Entwurf gibt
        let stateExist = tempRvTableList.find(
          (e) => e.rvTableKey === rvTable.rvTableKey && e.state === 0);
        if (stateExist != null) {
          rvTable.version = stateExist.version;
          this.warnings.push(this.$t('condAdmin.rvTableImport.draftToBeOverwritten'));
        } else {
          // find highest Version
          let subList = tempRvTableList.filter(rvt => rvt.rvTableKey === rvTable.rvTableKey);
          let maxVersion = Math.max(...subList.map(function (o) { return o.version; }));

          rvTable.version = maxVersion + 1;
        }
      } else {
        rvTable.version = 1;
      }
      rvTable.state = 0;// Warum wird der State auf 0 gesetzt
      this.found = existing != null;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    skip () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvTable = null;
      this.resolve('skip');
    },
    startImport () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.rvTable = null;
      this.resolve('startImport');
    }
  }
};
</script>

<style scoped>
</style>
