<template>
  <v-row class="py-2">
    <v-dialog
      v-model="showPicker"
      :disabled="disabled"
      persistent
      width="360px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          :class="labelClass"
        >
          <v-icon
            color="secondary"
            class="mr-2"
            :disabled="disabled"
          >
            mdi-calendar
          </v-icon>
          {{ displayedDateTime }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="primary-bg">
          {{ headlineText }}
        </v-card-title>
        <v-card-text class="px-0">
          <v-row no-gutters>
            <v-col>
              <div
                class="my-8 text-center v-date-picker-title__date"
                v-if="unlimited"
              >{{ $t('base.unlimited') }}</div>
              <v-date-picker
                v-else
                v-model="date"
                full-width
                @change="changedDate"
                :min="formatDatePickerDate(DateTimeHelper.NOW)"
                :max="formatDatePickerDate(DateTimeHelper.INFINITEDATE)"
              >
              </v-date-picker>
              <v-text-field
                v-model="time"
                v-if="useTime && !unlimited"
                prepend-icon="mdi-clock-outline"
                :append-icon="timeClearIcon"
                :rules="[rules.timeFormat]"
                maxlength="5"
                @click:append="clearTime"
                class="px-4"
              />
            </v-col>
            <!--<v-col>
              <v-time-picker
                v-if="useTime && !unlimited"
                format="24hr"
                v-model="time"
              ></v-time-picker>
            </v-col>-->
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-spacer></v-spacer>
            <v-btn
              v-if="useTime === true"
              @click="setNow"
              class="mr-4 prio2"
            >
              {{ $t('base.now') }}
            </v-btn>
            <v-btn
              @click="setToday"
              class="prio2"
              :class="isEnd ? 'mr-4' : ''"
            >
              {{ $t('base.today') }}
            </v-btn>
            <v-btn
              v-if="isEnd"
              @click="unlimited ? unlimited = false : setUnlimited()"
              class="prio2"
            >
              {{ unlimited ? $t('base.datePicker.chooseDate') : $t('base.unlimited') }}
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-text>
          <SaveCancelButtonBar
            class="mt-4"
            :cancel-text="$t('base.cancel')"
            :save-text="$t('base.ok')"
            @cancel="cancel()"
            @save="setNewValue()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import commonMixin from '@/common/js/commonMixin.js';

export default {
  name: 'DatePicker',
  components: {
    SaveCancelButtonBar
  },
  mixins: [ commonMixin ],
  props: {
    headline: { type: String, default: null },
    useTime: { type: Boolean, default: true },
    isEnd: { type: Boolean, default: false },
    isBegin: { type: Boolean, default: false },
    value: { type: String, default: null },
    nullText: { type: String, default: '' },
    resetable: { type: Boolean, default: false },
    resetText: { type: String, default: null },
    resetValue: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    labelClass: { type: String, default: 'secondary--text' }
  },
  data () {
    return {
      showPicker: false,
      unlimited: false,
      date: null,
      time: null,
      rules: {
        timeFormat: value => {
          let timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return timePattern.test(value) || this.$t('base.datePicker.timeFormatError');
        }
      }
    };
  },
  computed: {
    headlineText () {
      if (this.headline != null) return this.headline;
      if (this.isBegin === true) return this.$t('base.datePicker.chooseBegin');
      if (this.isEnd === true) return this.$t('base.datePicker.chooseEnd');
      return this.$t('base.datePicker.chooseDate');
    },
    displayedDateTime () {
      let v = this.value;
      if (v == null) return this.nullText;
      if (this.DateTimeHelper.isLaterOrEqual(v, this.DateTimeHelper.INFINITEDATE)) return this.$t('base.unlimited');
      if (this.useTime === true) {
        if (this.isEnd === true) v = this.formatEndDate(v);
        else if (this.isBegin === true) v = this.formatBeginDate(v);
        else v = this.DateTimeHelper.formatDateTime(v);
      } else {
        v = this.DateTimeHelper.formatDate(v);
      }
      return v;
    },
    timeClearIcon () {
      if (this.isEnd === true) {
        if (this.time === '23:59') return '';
        else return 'mdi-close';
      } else {
        if (this.time === '00:00') return '';
        else return 'mdi-close';
      }
    }
  },
  mounted () {
    this.init(this.value);
  },
  watch: {
    value () {
      this.init(this.value);
    }
  },
  methods: {
    formatDatePickerDate (value) {
      return this.DateTimeHelper.formatLocaleString(value, this.NULLVALUE, { timeZone: 'Europe/Berlin', dateStyle: 'short' }, 'sv-SE');
    },
    init (v, time = null) {
      if (v == null) {
        v = this.isEnd === true ? this.DateTimeHelper.INFINITEDATE : this.DateTimeHelper.NOW;
      }
      if (this.DateTimeHelper.isLaterOrEqual(v, this.DateTimeHelper.INFINITEDATE)) {
        v = this.DateTimeHelper.NOW;
        this.unlimited = true;
      } else {
        this.unlimited = false;
      }
      this.date = this.formatDatePickerDate(v);
      this.time = time != null ? time : this.DateTimeHelper.formatTime(v);
    },
    setNow () {
      this.init(this.DateTimeHelper.NOW);
    },
    setToday () {
      if (this.isEnd === true) this.init(this.DateTimeHelper.NOW, '23:59');
      else this.init(this.DateTimeHelper.NOW, '00:00');
    },
    changedDate () {
      this.unlimited = false;
      if (this.isEnd === true) this.time = '23:59';
      else this.time = '00:00';
    },
    clearTime () {
      this.isEnd === true ? this.time = '23:59' : this.time = '00:00';
    },
    setUnlimited () {
      this.init(this.DateTimeHelper.INFINITEDATETIME);
    },
    cancel () {
      this.init(this.value);
      this.$emit('cancel');
      this.showPicker = false;
    },
    validateTime () {
    },
    setNewValue () {
      let newDateTime = this.unlimited === true ? this.DateTimeHelper.INFINITEDATETIME : this.DateTimeHelper.formatSqlDateTime(new Date(this.date + ' ' + this.time + ':00'));
      // console.log('DatePicker.setNewValue', newDateTime);
      this.$emit('change', newDateTime);
      this.$emit('update', newDateTime);
      // eslint-disable-next-line vue/no-mutating-props
      this.value = newDateTime;
      this.showPicker = false;
    }
  }
};
</script>

<style scoped>
.v-label {
  padding-top: 4px;
  padding-left: 2px;
  font-size: 0.875rem;
}
.v-label:not(.v-label--is-disabled) {
  color: var(--v-secondary-base);
}
</style>
