<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card v-if="additionalProduct != null">
      <v-card-title class="primary-bg">
        {{ $t('condAdmin.additionalProductImport.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row v-if="errors.length > 0">
          <v-col class="error--text">{{ $t('condAdmin.additionalProductImport.errors') }}</v-col>
        </v-row>
        <v-row
          v-for="err, index in errors"
          :key="index"
        >
          <v-col class="error--text">{{ err }}</v-col>
        </v-row>
        <v-row v-if="errors.length === 0">
          <v-col>{{ $t(found ? 'condAdmin.additionalProductImport.existingFound' : 'condAdmin.additionalProductImport.noExistingFound') }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.additionalProductList.headline') }}</v-col>
          <v-col>{{ additionalProduct.tariffFEName }}</v-col>
        </v-row>
        <!--
        <v-row>
          <v-col>{{ $t('condAdmin.condition.calcType') }}</v-col>
          <v-col>{{ condition.calcTypeName }}</v-col>
          <v-col>{{ $t('condAdmin.condition.provider') }}</v-col>
          <v-col>{{ condition.providerName }}</v-col>
        </v-row>
        -->
        <v-row>
          <v-col>{{ $t('condAdmin.version') }}</v-col>
          <v-col>{{ additionalProduct.version }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ $t('condAdmin.validFrom') }}</v-col>
          <v-col>{{ additionalProduct.validFrom }}</v-col>
        </v-row>
        <v-row v-if="warnings.length > 0">
          <v-col class="warning--text">{{ $t('condAdmin.additionalProductImport.warnings') }}</v-col>
        </v-row>
        <v-row
          v-for="warn, index in warnings"
          :key="index"
        >
          <v-col class="warning--text">{{ warn }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :showSave="errors.length === 0"
          @cancel="skip"
          @save="startImport"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import { v4 as uuidv4 } from 'uuid';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'ConditionImportDlg',
  components: {
    SaveCancelButtonBar
  },
  props: {
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      errors: [],
      warnings: [],
      found: false,
      additionalProduct: null
    };
  },
  methods: {
    show (additionalProduct) {
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.additionalProduct = additionalProduct;
      let tempAddList = this.$store.state.condAdmin.additionalProductList;

      // get conditionUid from conditionKey and provider and check calcType
      let existing = tempAddList.find(
        (e) => e.tariffName === additionalProduct.tariffName
      );
      if (existing != null) {
        additionalProduct.uid = existing.uid;
        // Zusatzprodukt befindet sich im Entwurfmodus
        // TODO : überprüfen ob es ein Produkt im status Entwurf gibt
        let stateExist = tempAddList.find(
          (e) => e.tariffName === additionalProduct.tariffName && e.state === 0);
        if (stateExist != null) {
          additionalProduct.version = stateExist.version;
          this.warnings.push(this.$t('condAdmin.additionalProductImport.draftToBeOverwritten'));
        } else {
          // find highest Version
          let subList = tempAddList.filter(addProd => addProd.tariffName === additionalProduct.tariffName);
          let maxVersion = Math.max(...subList.map(function (o) { return o.version; }));

          additionalProduct.version = maxVersion + 1;
          additionalProduct.objektKey = additionalProduct.uid + '_' + additionalProduct.version;
        }
      } else {
        additionalProduct.uid = uuidv4();
        additionalProduct.version = 1;
        additionalProduct.objektKey = additionalProduct.uid + '_' + additionalProduct.version;
      }
      additionalProduct.state = 0;// Warum wird der State auf 0 gesetzt
      this.found = existing != null;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    skip () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.additionalProduct = null;
      this.resolve('skip');
    },
    startImport () {
      this.dialog = false;
      this.errors = [];
      this.warnings = [];
      this.found = false;
      this.additionalProduct = null;
      this.resolve('startImport');
    }
  }
};
</script>

<style scoped>
</style>
