<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-bank"
    />
    <router-view
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.providerList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';

export default {
  name: 'Provider',
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.providerList.headline'),
      menuColumns: [
        {
          text: 'condAdmin.provider.key',
          active: true,
          value: 'providerKey'
        },
        {
          text: 'base.contact.name',
          active: true,
          value: 'name'
        },
        {
          text: 'base.contact.street',
          active: true,
          value: 'street'
        },
        {
          text: 'base.contact.zipCode',
          active: true,
          value: 'zipCode'
        },
        {
          'text': 'base.contact.city',
          'active': true,
          'value': 'city'
        },
        {
          'text': 'base.contact.country',
          'active': false,
          'value': 'country'
        },
        {
          'text': 'base.contact.phone',
          'active': false,
          'value': 'phone'
        },
        {
          'text': 'base.contact.email',
          'active': false,
          'value': 'email'
        },
        {
          'text': 'base.contact.fax',
          'active': false,
          'value': 'fax'
        },
        {
          'text': 'base.contact.additionalData',
          'active': false,
          'value': 'additionalData'
        },
        {
          'text': 'base.state',
          'active': true,
          'value': 'state'
        }
      ]
    };
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('condAdmin.providerList.providerCreate');
          break;
        case 'edit':
          this.headlineText = /* this.$t('condAdmin.providerList.providerEdit') + ': ' + */ headlineParam;
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.providerList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
