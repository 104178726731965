/* <script> */
// import { v4 as uuidv4 } from 'uuid';
import condAdminMixin from './condAdminMixin.js';
import { v4 as uuidv4 } from 'uuid';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { formatLock, minRVValues } from '@/condAdmin/js/condAdminHelper.js';

export default {
  mixins: [condAdminMixin],
  data () {
    return {
      rules: {
        required: (value) => !!value || this.$t('base.required')
      }
    };
  },
  computed: {
  },
  methods: {
    /* ***************************************************************************** */
    //  LOAD                                                                         //
    /* ***************************************************************************** */
    async readRvTableData (rvTableKey, rvVersion) {
      let rvTable = this.$store.state.condAdmin.rvTableList.find((v) => v.rvTableKey === rvTableKey && v.version === rvVersion);

      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        rvTableKey: rvTableKey,
        rvVersion: rvVersion
      };

      if (rvTable != null && rvTable.inherited === true) {
        ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
      }
      let processData = await this.$restClient.callProcess('condAdmin', 'readRvTable', ioData, true);
      if (isProcessStateOK(processData)) {
        if (rvTable == null) {
          rvTable = processData.ioData.condition;
        } else {
          Object.assign(rvTable, processData.ioData.rvTable);
        }
        return rvTable;
      } else {
        this.$globals.Info.open(processData.processState.messages);
        return null;
      }
    },
    // --------------------------------------------------------------------
    //
    // --------------------------------------------------------------------
    async refreshRvTableList () {
      this.$store.state.condAdmin.rvTableList = [];
      this.$store.state.condAdmin.rvTablesLoading = true;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };

      let processData = await this.$restClient.callProcess('condAdmin', 'getRvTableList', ioData);
      if (isProcessStateOK(processData)) {
        this.$store.state.condAdmin.rvTableList = processData.ioData.rvTableList;
        this.$store.state.condAdmin.rvTableList = this.prepareRvTableList(processData.ioData.rvTableList);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      this.$store.state.condAdmin.rvTablesLoading = false;
    },

    prepareRvTableList (rvTableList) {
      if (rvTableList == null || (rvTableList.length > 0 && rvTableList[0] == null)) return [];
      for (let rvTable of rvTableList) {
        rvTable.feid = rvTable.rvTableKey + '_' + rvTable.version + '_' + rvTable.clientKey;
      }
      return rvTableList;
    },

    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    async checkEditPreResidualValuesTable (mode, rvTable = null) {
      let newVersion = 1;
      let rvTableKey = null;
      let rvTableList = this.$store.state.condAdmin.rvTableList;

      if (mode === 'edit') {
        rvTableKey = rvTable.rvTableKey;

        if (rvTable.state !== 0 && rvTable.state !== 1) {
          // check if draft--version already exists
          let draft = rvTableList.find((v) => v.rvTableKey === rvTableKey && v.state === 0);

          if (draft != null) {
            try {
              let replace = await this.$globals.Confirm.yesNoCancelDlg(
                this.$t('condAdmin.rvTableEditor.dlghDraftExists'),
                this.$t('condAdmin.rvTableEditor.dlgtDraftExists'),
                this.$t('base.replace'), this.$t('base.keep'));
              if (!replace) {
                rvTable = draft;
              }
              newVersion = draft.version;
            } catch (e) {
              return false;
            }
          } else {
            newVersion = 1;
            // find highest version
            for (let v of rvTableList) {
              if (v.version > newVersion && v.rvTableKey === rvTableKey) newVersion = v.version;
            }
            newVersion++;// = newVersion + 1;
          }
        } else {
          newVersion = rvTable.version;
        }
      } else { // copy or new-mode
        rvTableKey = uuidv4();
      }
      let isLockSet = false;
      if (mode !== 'new' && mode !== 'copy') {
        // check and set editLock in BE

        let lockIoData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          targetType: 'rvTable',
          targetIdentifier: rvTableKey,
          targetVersion: newVersion
        };

        let forceUpdateLock = false;
        let finishedLockCheck = false;
        let processName = 'createLock';
        while (!finishedLockCheck) {
          let processData = await this.$restClient.callProcess('condAdmin', processName, lockIoData, true);
          if (isProcessStateOK(processData)) {
            if (processData.processState.state === 'warning') {
              // additionalProduct already locked
              let lock = processData.ioData.lock;
              forceUpdateLock = await this.$globals.Confirm.yesNoDlg(
                this.$t('condAdmin.rvTableEditor.dlghRVTableLocked'),
                this.$t('condAdmin.rvTableEditor.dlgtRVTableLocked', formatLock(lock)),
                this.$t('base.overwrite'), this.$t('base.cancel'));
              if (forceUpdateLock) {
                processName = 'forceLock';
              } else {
                finishedLockCheck = true;
              }
            } else {
              this.$globals.Info.open(processData.processState.messages);
              finishedLockCheck = true;
              isLockSet = true;
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
            finishedLockCheck = true;
          }
        }
      }

      if (isLockSet || mode === 'new' || mode === 'copy') {
        this.setWorkingResidualValuesTable(mode, rvTableKey, newVersion, rvTable);
        return true;
      }
      return false;
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    setWorkingResidualValuesTable (mode = 'new', rvTableKey, version = 1, source = null) {
      console.log('setWorkingResidualValuesTable', mode);

      let workingValidFrom = this.DateTimeHelper.NOW;
      let workingValidTo = this.DateTimeHelper.INFINITEDATETIME;

      if (mode === 'copy') {
        if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
        if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
      }
      if (mode === 'edit') {
        if (source.state === 0 || source.state === 1) {
          if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
          if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
        } else {
          if (source.validTo != null && this.DateTimeHelper.isLater(source.validTo /* than NOW */)) {
            if (this.DateTimeHelper.isEarlier(source.validTo, this.DateTimeHelper.INFINITEDATE)) {
              workingValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter(source.validTo);
            } else if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) {
              workingValidFrom = source.validFrom;
            }
          }
        }
      }

      let wAP = {
        workingMode: mode,
        isDirty: false,
        clientKey: this.$store.state.condAdmin.clientKey,
        feid: rvTableKey + '_' + version + '_' + (mode === 'edit' ? source.clientKey : this.$store.state.condAdmin.clientKey),
        rvTableKey: rvTableKey,
        version: version,
        name: (mode === 'edit' || mode === 'copy') ? source.name : '',
        validFrom: this.DateTimeHelper.formatSqlDateTime(workingValidFrom),
        validTo: this.DateTimeHelper.formatSqlDateTime(workingValidTo),
        state: 0,
        moreStates: mode === 'new' ? false : source.moreStates,
        durations: (mode === 'edit' || mode === 'copy') ? source.durations : [],
        mileages: (mode === 'edit' || mode === 'copy') ? source.mileages : [],
        attributes: (mode === 'edit' || mode === 'copy') ? JSON.parse(JSON.stringify(source.attributes)) : [],
        versions: (mode === 'edit' || mode === 'copy') && source.versions != null ? JSON.parse(JSON.stringify(source.versions)) : []
      };
      // Init versions
      if (mode === 'new') {
        wAP.mileages = [10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000];
        wAP.durations = [12, 24, 36, 48, 60, 72, 84];
        wAP.attributes = [];
        wAP.versions = [];

        // wAP.versions = temp;
      }
      // console.debug('wAP', JSON.parse(JSON.stringify(wAP)));
      this.$store.state.condAdmin.workingRVTable = wAP;
      if (mode === 'edit' && source != null) source.outdated = true;
    },
    /* *******************
    *
    * DELETE
    *
    ******************* */
    async deleteRvTable (rvTable, skipDialog = false) {
      if (rvTable.state > 5 || rvTable.inherited === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.rvTableEditor.infoCannotDeleteRVTable') }]);
        return false;
      }
      if (skipDialog === true || await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvTableEditor.dlghDeleteRVTable'), this.$t('condAdmin.rvTableEditor.dlgtDeleteRVTable'))) {
        let ioData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          rvTableKey: rvTable.rvTableKey,
          version: rvTable.version
        };
        let processData = await this.$restClient.callProcess('condAdmin', 'deleteRvTable', ioData, true);
        if (isProcessStateOK(processData)) {
          this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.rvTableEditor.infoDeletedRVTable') }]);
          this.refreshRvTableList();
          return true;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
      return false;
    },
    /* *******************
    *
    * Save and check
    *
    ******************* */
    async saveResidualValues (rvTable, checkCustomComponents = true) {
      if (rvTable.state !== 0 && rvTable.state !== 1) {
        return false;
      }

      // this.collectCustomComponentsData(additionalProduct);

      let messages = [];
      // check mandatory values
      // check mandatory values
      // rvGroup Name
      if (this.rules.required(rvTable.rvTableKey) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvTable.rvTableKey') });

      // if condition.validTo is passed an error will be returned, because condition can't become active anymore
      if (rvTable.validTo != null && this.DateTimeHelper.isEarlier(rvTable.validTo /* than NOW */)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') });
      }

      if (rvTable.attributes.length < 1) {
        messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.rvTableEditor.missingResidualValues') });
      } else {
        // check RVClasses
        let maxModClass = Math.max(...rvTable.attributes.map(function (o) { return o.modClass; }));
        if (maxModClass === 0) {
          messages.push({ type: 'userError', message: this.$t('condAdmin.rvTableEditor.rvInputMode.missingModClasses') });
        } else {
          for (let mdClass = 1; mdClass < maxModClass; mdClass++) {
            if (rvTable.attributes.find((e) => e.modClass === mdClass) == null) {
              messages.push({ type: 'userError', message: this.$t('condAdmin.rvTableEditor.rvInputMode.missingModClass') + ': ' + mdClass });
            }
          }
        }
        // check residual Values
        let minAllowedRvValue = minRVValues[this.$store.state.condAdmin.countrySettings.key];
        let tempMap = rvTable.attributes.map(function (o) { return o.residualValue; });
        let minRV = Math.min(...tempMap);
        let maxRV = Math.max(...tempMap);
        if (minRV < minAllowedRvValue || maxRV > 99) {
          messages.push({ type: 'userError', message: this.$t('condAdmin.rvTableEditor.rvInputMode.wrongResidualValues', { minRv: minAllowedRvValue }) });
        }
      }

      // validate custom components data
      if (checkCustomComponents === true) {
        this.validateCustomComponentsData(rvTable, messages);
      }

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        try {
          // if condition.validFrom is passed it will be set to current date
          if (rvTable.validFrom == null || this.DateTimeHelper.isEarlier(rvTable.validFrom /* than NOW */)) {
            rvTable.validFrom = this.DateTimeHelper.NOW;
            this.$globals.Info.open([{ type: 'warning', message: this.$t('condAdmin.rvTableEditor.infoValidFromChangedToToday') }]);
          }

          let rvTable2save = JSON.parse(JSON.stringify(rvTable));

          // clean Values
          delete rvTable2save.versions;
          delete rvTable2save.isDirty;
          delete rvTable2save.workingMode;

          // create ioData
          let ioData = {
            clientKey: this.$store.state.condAdmin.clientKey,
            rvTable: rvTable2save
          };

          // backend-request
          console.debug('save rvTable2save', JSON.stringify(rvTable2save));
          console.debug('save rvTable', JSON.stringify(rvTable));
          let processName = rvTable.workingMode === 'edit' ? 'updateRvTable' : 'createRvTable';
          console.debug('processName', processName);
          let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
          if (isProcessStateOK(processData)) {
            return processData;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        } catch (err) {
          console.error(err.message);
        }
        return null;
      }
    },
    // collectCustomComponentsData () {
    // },
    /*
    validateCustomComponentsData (rvTable, messages) {
      this.$refs.customAdditionalProduct.validateCustomParams(rvTable, messages);
    }, */
    async setCheckState (rvTable) {
      if (rvTable.isDirty && await this.save(rvTable) !== true) {
        return false;
      }
      // if additionalProduct.validTo is passed an error will be returned, because additionalProduct can't become active anymore
      if (rvTable.validTo != null && this.DateTimeHelper.isEarlier(rvTable.validTo /* than NOW */)) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') }]);
      }
      // check plausibilities
      let beginDateCheck = await this.validateBeginDate(rvTable);
      let endDateCheck = await this.validateEndDate(rvTable);

      if (!beginDateCheck || !endDateCheck) {
        return false;
      }

      await this.setState(rvTable, 2);
      if (rvTable.state !== 0 && rvTable.state !== 1) {
        this.locked = false;
        this.$router.push('/condAdmin/residualValues/' + rvTable.rvTableKey);
      }
    },
    async setState (rvTable, state) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        rvTableKey: rvTable.rvTableKey,
        rvTableVersion: rvTable.version,
        state: state
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'updateRvTableState', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoChangedState') }]);
        rvTable.state = processData.ioData.newState;
        let listedrvTable = this.$store.state.condAdmin.rvTableList.find((v) => v.rvTableKey === rvTable.rvTableKey && v.version === rvTable.version);
        if (listedrvTable != null) {
          listedrvTable.state = processData.ioData.newState;
        }

        return true;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return false;
    }
  }
};
// </script>
