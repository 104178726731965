<template>
  <v-card flat tile class="px-0 py-8">
    <v-row class="grey lighten-5" v-if="$store.state.condAdmin.orgSettings.useInterestScale === true">
      <v-col>
        <span class="pt-2 text-h6">{{ $t('condAdmin.condition.commissions') }}</span>
      </v-col>
    </v-row>
    <v-row v-show="$store.state.condAdmin.orgSettings.useInterestScale === true"
      v-for="(comTable, index) in commissionParams" :key="interestLineCounter * index" class="grey lighten-5">
      <v-col>
        <v-card flat tile>
          <v-card-text class="pl-0 pb-1">
            <v-row no-gutters>
              <v-col cols="12" lg="5" md="5" sm="12" xs="12">
                <v-select :value="comTable.name" @change="changeCommissionMode(comTable, comTable.mode, $event)"
                  :label="$t('condAdmin.condition.commissionType.label')" :items="commissionTypes" item-text="text"
                  item-value="value" hide-details dense class="ml-2 mr-6 tfexpand"
                  :prepend-icon="comTable.expand === true ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  @click:prepend="comTable.expand = !comTable.expand" :class="comTable.isSubsidy ? 'subsidyField' : ''" />
              </v-col>
              <v-col cols="12" lg="5" md="5" sm="10" xs="10">
                <v-select :value="comTable.mode" @change="changeCommissionMode(comTable, $event, comTable.name)"
                  :label="$t('condAdmin.condition.commissionMode.label')" :items="commissionModes" item-text="text"
                  item-value="value" hide-details dense />
              </v-col>
              <v-col class="text-right" cols="12" lg="2" md="2" sm="2" xs="2">
                <v-btn icon color="secondary" @click="removeCommission(comTable.uid)">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-show="comTable.expand === true" class="pl-12 py-0">
            <component
              v-bind:is="customComponents.ConditionCommissionSettings"
              ref="customConditionsCommissionSettings"
              :filter="comTable.filter || {}"
              :commissionUid="comTable.uid"
              :objectKey="objectKey"
              @setDirty="setDirty"
              v-show="customComponents.ConditionCommissionSettings != null">
            </component>
            <v-row v-for="(interestLine, ilIndex) in interestLines" :key="interestLine.index">
              <v-col class="pl-7" v-if="comTable.expand === true">
                <v-row no-gutters>
                  <span class="body-1 pb-1">{{ $t('condAdmin.condition.credit') + ': ' +
                    getRangeText(interestLine.minCredit, interestLine.maxCredit, currency) }}</span>
                  <v-icon v-if="ilIndex < interestLines.length - 1"
                    @click="copyCommissionAttributes(interestLine, comTable)" class="clickable secondary--text ml-4 mb-1"
                    v-tooltip="$tooltip($t('condAdmin.conditionEditor.ttCopyCommissionAttributes'))">mdi-arrow-down</v-icon>
                </v-row>
                <v-row no-gutters class="comTable">
                  <table class="text-center mb-2" cellspacing=0 cellpadding=0>
                    <tr>
                      <th class="fixCol first greyRow px-4 pt-2">{{ $t('condAdmin.condition.duration') }}</th>
                      <th class="greyRow">
                        {{ $t('condAdmin.condition.bestInterest') }}
                      </th>
                      <th v-for="(interest) in interestScaleList" :key="interest" class="greyRow">
                        {{ formatValue($filters.formatNumber(interest, null, NULLVALUE), '%') }}
                      </th>
                    </tr>
                    <tr v-for="(duration) in getDurationListForCommission(interestLine, attributes)"
                      :setTmp="bestInterest = getAttributeValue('%', interestLine.index, duration)"
                      :setTmp2="interestLineMaxDuration = getDurationListForCommission(interestLine, attributes).at(-1)"
                      :key="duration">
                      <td class="fixCol first pt-2" :class="duration === interestLineMaxDuration ? 'lastRow' : ''">{{
                        duration }}</td>
                      <td class="px-4" :class="duration === interestLineMaxDuration ? 'lastRow' : ''">
                        <EditField
                          @change="setCommissionValue(comTable, interestLine.index, duration, null, interestLine.minCredit, interestLine.maxCredit, $event)"
                          :value="getCommissionValue(comTable, interestLine.index, duration, null, interestLine.minCredit, interestLine.maxCredit)"
                          :placeholder="$t('base.value')" clearable hideDetails
                          :suffix="comTable.mode === 'amount' ? currency : '%'" type="float"
                          :class="comTable.isSubsidy ? 'subsidyField' : ''" />
                      </td>
                      <td v-for="(interest) in interestScaleList" :key="interest" class="px-4"
                        :class="duration === interestLineMaxDuration ? 'lastRow' : ''" style="min-width: 150px;">
                        <EditField
                          @change="setCommissionValue(comTable, interestLine.index, duration, interest, interestLine.minCredit, interestLine.maxCredit, $event)"
                          :value="getCommissionValue(comTable, interestLine.index, duration, interest, interestLine.minCredit, interestLine.maxCredit)"
                          :placeholder="$t('base.value')" :disabled="bestInterest === interest"
                          :clearable="bestInterest !== interest" hideDetails
                          :suffix="comTable.mode === 'amount' ? currency : '%'" type="float"
                          :class="comTable.isSubsidy ? 'subsidyField' : ''" />
                      </td>
                    </tr>
                  </table>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="grey lighten-5" v-if="$store.state.condAdmin.orgSettings.useInterestScale === true">
      <v-col>
        <v-btn icon color="secondary" @click="addNewCommission">
          <v-icon large>mdi-plus-box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="js">
import condAdminConditionMixin from '../js/condAdminConditionMixin.js';
import EditField from '@/common/EditField';

export default {
  name: 'CommissionEditor',
  components: {
    EditField
  },
  mixins: [condAdminConditionMixin],
  props: {
    customComponents: { type: Object, required: true },
    interestLineCounter: { type: Number, required: true },
    interestScaleList: { type: Array, required: true },
    durationList: { type: Array, required: true },
    attributes: { type: Array, required: true },
    interestLines: { type: Array, required: true },
    commissionParams: { type: Array, required: true },
    objectKey: { type: String, required: true }
  },
  data () {
    return {
      attribData: this.attributes === null ? {} : JSON.parse(JSON.stringify(this.attributes))
      // condition: null,
      // isDirty: false
      // customComponents: {},
      // interestLineCounter: 0
    };
  },
  computed: {
  },
  methods: {
    setDirty () {
      this.$emit('setDirty');
    },
    updateAttributes (attributesCopy) {
      this.$emit('updateConditionAttributes', attributesCopy);
    },

    /* *******************
    *
    * InterestLines
    *
    ******************* */
    getConditionAttribute (minCredit, maxCredit, duration, ilIndex, doPush = false, interest = null, returnNull = false) {
      // let att = null;
      // for (let a of this.attributes) {
      //   if (a.minCredit === minCredit &&
      //   a.maxCredit === maxCredit &&
      //   a.duration === duration &&
      //   a.ilIndex === ilIndex &&
      //   ((interest == null && a.commissionInterest !== true) || a.interestRate === interest)) {
      //     return a;
      //   }
      // }

      let att = this.attributes.find((a) =>
        a.minCredit === minCredit &&
        a.maxCredit === maxCredit &&
        a.duration === duration &&
        a.ilIndex === ilIndex &&
        ((interest == null && a.commissionInterest !== true) || a.interestRate === interest)
      );

      if (att == null && returnNull !== true) {
        att = {
          duration: duration,
          minCredit: minCredit,
          maxCredit: maxCredit,
          commission: [],
          commissionInterest: interest != null,
          interestRate: interest,
          plusFactor: null,
          minusFactor: null,
          ilIndex: ilIndex
        };
        // eslint-disable-next-line vue/no-mutating-props
        if (doPush === true) this.attributes.push(att);
      }
      return att;
    },
    getAttributeValue (prop, ilIndex, duration) {
      let att = this.getConditionAttribute(
        this.getInterestLineByIndex(ilIndex).minCredit,
        this.getInterestLineByIndex(ilIndex).maxCredit,
        duration,
        ilIndex,
        false
      );
      switch (prop) {
        case '+': return att.plusFactor;
        case '-': return att.minusFactor;
        default: return att.interestRate;
      }
    },
    // --------------------------------------------
    getInterestLineByIndex (ilIndex) {
      return this.interestLines.find((i) => i.index === ilIndex);
    },
    /* *******************
    *
    * commissionParams
    *
    ******************* */
    changeCommissionMode (comTable, mode, name) {
      comTable.mode = mode;
      comTable.name = name.trim();
      let commissionType = this.commissionTypes.find((c) => c.value === name);
      comTable.isSubsidy = commissionType.isSubsidy;

      // let attributes = this.attributes.filter((a) => a.commissionInterest === true);
      for (let att of this.attributes) {
        let com = this.getAttributeCommission(att, comTable);
        if (com != null) {
          com.mode = mode;
          com.name = name;
        }
      }
      this.setDirty();
    },
    addNewCommission () {
      let ct = this.createNewCommission(this.commissionParams, null);
      ct.mode = this.commissionModes[0].value;
      ct.expand = true;
      this.setDirty();
    },
    removeCommission (uid) {
      let attributesCopy = this.attributes;
      let index = this.commissionParams.findIndex((c) => c.uid === uid);
      if (index > -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.commissionParams.splice(index, 1);
      }
      // let attributes = this.attributes.filter((a) => a.commissionInterest === true);
      let attributes = attributesCopy.filter((a) => a.commissionInterest === true);
      for (let att of attributes) {
        att.commission = att.commission.filter((c) => c.uid !== uid);
        if (att.commission.length === 0) {
          att.delete = true;
        }
      }
      // eslint-disable-next-line vue/no-mutating-props
      // this.attributes = this.attributes.filter((a) => a.delete !== true);
      attributesCopy = attributesCopy.filter((a) => a.delete !== true);

      //
      // attributes = this.attributes.filter((a) => a.commissionInterest === false);
      attributes = attributesCopy.filter((a) => a.commissionInterest === false);
      for (let att of attributes) {
        att.commission = att.commission.filter((c) => c.uid !== uid);
        if (att.commission.length === 0) {
          att.commission = [];
        }
      }
      this.setDirty();
      this.updateAttributes(attributesCopy);
    },
    copyCommissionAttributes (sourceILine, comTable) {
      console.log('copy commissions');
      // get position of current interestLine (source)
      let sourceIlPos = this.interestLines.findIndex((e) => e.index === sourceILine.index);
      if (sourceIlPos != null && sourceIlPos < this.interestLines.length - 1) {
        // get index of following interestLine (target)
        let sourceIl = this.interestLines[sourceIlPos];
        let targetIl = this.interestLines[sourceIlPos + 1];
        console.log('targetIlIndex', targetIl.index);

        // copy all comTable-attributes from source to target interestLine
        for (let duration of this.durationList) {
          for (let interest of this.interestScaleList) {
            let commValue = this.getCommissionValue(comTable, sourceIl.index, duration, interest, sourceIl.minCredit, sourceIl.maxCredit);
            this.setCommissionValue(comTable, targetIl.index, duration, interest, targetIl.minCredit, targetIl.maxCredit, commValue);
          }
          let commValue = this.getCommissionValue(comTable, sourceIl.index, duration, null, sourceIl.minCredit, sourceIl.maxCredit);
          this.setCommissionValue(comTable, targetIl.index, duration, null, targetIl.minCredit, targetIl.maxCredit, commValue);
        }
      }
    },
    getAttributeCommission (att, comTable) {
      // for (let c of att.commission) {
      //   if (c.uid === comTable.uid) return c;
      // }
      // return null;
      return att.commission.find((c) => c.uid === comTable.uid);
    },
    getCommissionValue (comTable, ilIndex, duration, interest, minCredit, maxCredit) {
      let att = this.getConditionAttribute(minCredit, maxCredit, duration, ilIndex, false, interest);
      let com = this.getAttributeCommission(att, comTable);
      if (com == null) return null;
      return com.value;
    },
    setCommissionValue (comTable, ilIndex, duration, interest, minCredit, maxCredit, value) {
      let att = this.getConditionAttribute(minCredit, maxCredit, duration, ilIndex, true, interest);
      let com = this.getAttributeCommission(att, comTable);
      if (com == null && value != null) {
        com = {
          name: comTable.name,
          mode: comTable.mode,
          uid: comTable.uid,
          value: value
        };
        att.commission.push(com);
      } else if (com != null) {
        com.value = value;
        if (value == null) {
          att.commission = att.commission.filter((e) => e.value != null);
        }
      }
      this.setDirty();
    },
    getCalcTypeInterestScale (calcType) {
      let ct = this.$store.state.condAdmin.calcTypeList.find((i) => i.calcType === calcType);
      return ct.settings.interestScale.sort((a, b) => { return a - b; });
    },
    /* *******************
    *
    * save
    *
    ******************* */
    getCommisionData (condition) {
      if (this.$refs.customConditionsCommissionSettings != null) {
        for (let customComponent of this.$refs.customConditionsCommissionSettings) {
          customComponent.getCustomParams(condition);
        }
      }
    }
  }
};
</script>

<style scoped>
.conditionTable,
.comTable {
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 150px;
  padding: 0;
}

.conditionTable table,
.comTable table {
  border-collapse: separate;
  border-spacing: 0;
}

.conditionTable td:not(.smallRow),
.conditionTable th:not(.smallRow),
.comTable td,
.comTable th,
.greyRow:not(.smallRow) {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  white-space: nowrap;
  min-width: 150px;
}

.conditionTable .smallRow {
  width: 75px !important;
}

.conditionTable td.first,
.conditionTable th.first,
.comTable td.first,
.comTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.conditionTable td.noBorder,
.comTable td.noBorder {
  border: 0px !important;
}

.comTable td,
.comTable th {
  height: 36px !important;
}

.conditionTable .creditCol {
  height: 104px !important;
}

.conditionTable .fixCol {
  position: absolute !important;
  width: 150px;
  left: 32px;
  top: auto;
}

.comTable .fixCol {
  position: absolute !important;
  width: 150px;
  left: 64px;
  top: auto;
}

.conditionTable .interestField.v-input,
.comTable .v-input {
  margin-top: -12px !important;
}

.interestField {
  width: 150px !important;
  max-width: 150px !important;
}

.creditField {
  width: 174px !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.lastRow {
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  /*padding-bottom: 8px !important;*/
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.tfexpand>>>button {
  color: var(--v-secondary-base) !important;
}

.tfexpand>>>.v-input__icon--prepend {
  margin-left: 8px !important;
  margin-right: 16px !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>.v-select__selection,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
