<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="px-4 py-2 primary-bg">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row
          no-gutters
          class="align-center"
        >
          <div class="py-4">
            <v-icon class="warning--text text-h2">mdi-alert-outline</v-icon>
          </div>
          <div
            class="pa-4"
            v-html="message"
            style="max-width: 390px;"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-btn
          @click.native="cancel()"
          class="prio2"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-btn
          @click.native="save(false)"
          color="secondary"
        >
          {{ $t('base.not') + ' ' + $t('base.save')  }}
        </v-btn>
        <v-btn
          @click.native="save(true)"
          color="secondary"
        >
          {{ $t('base.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">

export default {
  name: 'SaveOnLeaveDlg',
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,

      title: '',
      message: ''
    };
  },
  methods: {
    open (title = null, message = null) {
      this.dialog = true;
      this.title = title || this.$t('base.saveOnLeaveDlg.title');
      this.message = this.format(message || this.$t('base.saveOnLeaveDlg.message'));

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save (save) {
      this.resolve(save);
      this.close();
    },
    cancel () {
      this.reject(false);
      this.close();
    },
    close () {
      this.dialog = false;
      this.resolve = null;
      this.reject = null;
    },

    format (message) {
      return message.replaceAll('\n', '<br/>');
    }
  }
};
</script>

<style scoped>
.v-btn {
  min-width: 64px !important;
}
</style>
