<template>
  <v-container class="page">
    <!-- Headline -->
    <Headline
      :title="$t('condAdmin.uploadLabel')"
      icon="mdi-file-upload-outline"
    />
    <v-card
      class="minContent"
      v-if="!isDaagChild"
    >
      <v-card-text>
        <v-row>
          <v-col class="font-weight-bold">
            <v-label>{{ $t("condAdmin.upload.options") }}</v-label>
          </v-col>
          <v-col class="text-right">
            <v-btn
              v-if="!uploadingFile"
              class="secondary--text pl-0"
              text
              @click="selectFile"
            >
              <v-icon class="secondary secondaryContrast--text pa-1 mr-2">mdi-upload</v-icon>
              {{ $t("condAdmin.upload.uploadFile") }}
            </v-btn>
            <input
              accept=".xlsx, .xlsm"
              v-show="false"
              type="file"
              ref="fileSelect"
              @change="fileSelected"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <!--<v-switch
              v-model="activateImports"
              v-tooltip="$tooltip($t('condAdmin.upload.activateHint'))"
              :label="$t('condAdmin.upload.activate')"
            />-->
            <v-row no-gutters>
              <v-switch
                v-model="disableUnupdated"
                v-tooltip="$tooltip($t('condAdmin.upload.disableUnupdatedHint'))"
                :label="$t('condAdmin.upload.disableUnupdated')"
              />
            </v-row>
            <v-row
              no-gutters
              class="pt-4"
            >
              {{ $t('condAdmin.upload.defaultValidFrom') + ' ' }}
              <DatePicker
                class="ml-1"
                :value="defaultValidFrom"
                :useTime="false"
                @change="defaultValidFrom = $event"
              />
            </v-row>
          </v-col>
          <v-col class="text-right pt-0">
            {{ $t("condAdmin.upload.descriptionText") }}
          </v-col>
        </v-row>
        <v-row
          v-if="uploadingFile"
          class="mt-0 pt-0"
        >
          <v-col class="text-center">
            <S3UploadElement
              :file="fileToUpload"
              :s3Config="importS3Config"
              :objectKey="fileObjectKey"
              :cancelIcon="null"
              @uploadError="handleUploadError"
              @uploadSuccess="handleUploadSuccess"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold">
            <v-label>{{ $t("condAdmin.upload.history") }}</v-label>
            <v-btn
              icon
              class="ml-8"
              @click="getImportState()"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-for="(importState, i) in importStateList"
          :key="i"
          class="py-2 mx-0 mb-1 grey lighten-5 grey--text"
        >
          <v-icon
            class="ml-2 mr-6"
            :class="getStateIconClass(importState.state)"
          >{{ getStateIcon(importState.state) }}</v-icon>
          <ItemInfoStrip
            :attributes="getStateObject(importState)"
            fontStyle="grey--text"
            divider=""
            class="px-3 mt-0"
            :placedOnTile="true"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="js">
import ItemInfoStrip from '@/common/ItemInfoStrip';
import Headline from '@/common/Headline';
import S3UploadElement from '@/common/S3UploadElement';
import commonMixin from '@/common/js/commonMixin.js';
import { newMessage, isProcessStateOK } from '@/base/js/ProcessDataHelper';
import DatePicker from '@/common/DatePicker';

export default {
  name: 'ConditionUpload',
  mixins: [commonMixin],
  components: {
    ItemInfoStrip,
    Headline,
    S3UploadElement,
    DatePicker
  },
  data () {
    return {
      importS3Config: null,
      fileToUpload: null,
      uploadingFile: false,
      fileObjectKey: null,
      importStateList: [],
      activateImports: true,
      disableUnupdated: true,
      defaultValidFrom: null
    };
  },
  computed: {
    isDaagChild () {
      return this.$store.state.base.admin.administratedOrgObjects.orgSettings.parent === 'daag';
    }
  },
  async mounted () {
    this.importS3Config = this.$configHelper.getModuleConfigParam('condAdmin', 'importS3.config');
    this.getImportState();
    this.defaultValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter();
  },
  methods: {
    getStateObject (importState) {
      return [{
        mode: 'text',
        value: this.DateTimeHelper.formatDateTime(importState.timestamp),
        prependIcon: { icon: 'mdi-clock' }
      }, {
        mode: 'tv',
        title: this.$t('condAdmin.upload.state.type'),
        value: importState.type
      }, {
        mode: 'tv',
        title: this.$t('condAdmin.version'),
        value: importState.version
      }, {
        mode: 'tv',
        title: this.$t('condAdmin.upload.state.source'),
        value: importState.source
      }, {
        mode: 'text',
        value: importState.userUid == null ? this.$t('base.system') : importState.userUid,
        prependIcon: { icon: 'mdi-account' }
      }
      ];
    },
    // Preview-Image
    selectFile () {
      this.$refs.fileSelect.click();
    },
    fileSelected (e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$logger.debug('condAdmin', 'Upload conditionFile', files[0]);
      this.fileToUpload = files[0];
      this.fileObjectKey = this.$store.state.base.admin.administratedOrg.uid + '/' + this.fileToUpload.name;
      this.uploadingFile = true;
    },
    handleUploadSuccess (file, objectKey) {
      console.log('uploaded', file.name, 'as', objectKey);

      // trigger import via lambda
      let ioData = {
        clientKey: this.$store.state.base.admin.administratedOrg.uid,
        sourceFileName: this.fileToUpload.name,
        actingUser: {
          fullName: this.$store.state.base.user.fullName,
          email: this.$store.state.base.user.email
        },
        activateImports: this.activateImports,
        disableUnupdated: this.disableUnupdated,
        defaultValidFrom: this.defaultValidFrom
      };

      this.$restClient.callProcess('condAdmin', 'startImport', ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.importStateList = processData.ioData.importList;
            this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.upload.uploadSuccess')) ]);
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
      this.uploadingFile = false;
      this.fileToUpload = null;

      // this.$emit('save');
    },
    handleUploadError (err) {
      this.uploadingFile = false;
      this.fileToUpload = null;
      this.$globals.Info.open([ newMessage('systemError', this.$t('condAdmin.upload.errors.uploadError') + ': ' + err.message) ]);
    },
    getImportState () {
      this.importStateList = [];
      let ioData = {
        clientKey: this.$store.state.base.admin.administratedOrg.uid
      };

      this.$restClient.callProcess('condAdmin', 'getImportState', ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.importStateList = processData.ioData.importList;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    getStateIconClass (state) {
      switch (state) {
        case -1: return 'error--text';
        case 0: return 'grey--text';
        default: return 'success--text';
      }
    },
    getStateIcon (state) {
      switch (state) {
        case 1: return 'mdi-check-circle';
        case 0: return 'mdi-timer-sand';
        default: return 'mdi-alert-circle';
      }
    }
  }
};
</script>

<style scoped>
</style>
