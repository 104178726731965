/* <script> */
import { v4 as uuidv4 } from 'uuid';
import condAdminMixin from './condAdminMixin.js';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { formatLock } from './condAdminHelper.js';

export default {
  mixins: [condAdminMixin],
  data () {
    return {
      rules: {
        required: (value) => !!value || this.$t('base.required')
      }
    };
  },
  computed: {
  },
  methods: {
    async refreshPartnerOfTheWeekList () {
      this.$store.state.condAdmin.partnerOfTheWeekList = [];
      this.$store.state.condAdmin.partnerOfTheWeekListLoading = true;
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };

      let processData = await this.$restClient.callProcess('condAdmin', 'getPartnerOfTheWeekList', ioData);
      if (isProcessStateOK(processData)) {
        this.$store.state.condAdmin.partnerOfTheWeekList = processData.ioData.partnerOfTheWeeks;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }

      this.$store.state.condAdmin.partnerOfTheWeekListLoading = false;
    },
    // ------------------------------------------------------------------------
    // async readAdditionalProduct (uid, version, source, getVersions = false) {
    async readPartnerOfTheWeek (partnerOfWeekObjectKey, source, getVersions = false) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        partnerOfTheWeekObjectKey: partnerOfWeekObjectKey,
        includeVersions: getVersions
      };
      if (source != null) {
        ioData.partnerOfTheWeekUid = source.uid;
        if (source.inherited === true) {
          ioData.clientKey = this.$store.state.condAdmin.orgSettings.parentKey;
        }
      } else {
        if (getVersions === true) {
          console.debug('!!! BAD PARAMETERS !!! getVersions === true and source === null');
        }
      }

      let processData = await this.$restClient.callProcess('condAdmin', 'readPartnerOfTheWeek', ioData, true);
      if (isProcessStateOK(processData)) {
        if (source == null) {
          source = processData.ioData.partnerOfTheWeek;
          // prepareConditionList([source]);
        } else {
          Object.assign(source, processData.ioData.partnerOfTheWeek);
        }
        // condition.commissionParams = createcommissionParams(condition);
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return source;
    },

    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    async checkEditPrePartnerOfTheWeek (mode, partnerOfTheWeek = null) {
      let newVersion = 1;
      let uid = null;
      let partOfWeekList = this.$store.state.condAdmin.partnerOfTheWeekList;

      if (mode === 'edit') {
        uid = partnerOfTheWeek.uid;

        if (partnerOfTheWeek.state !== 0 && partnerOfTheWeek.state !== 1) {
          // check if draft--version already exists
          let draft = partOfWeekList.find((v) => v.uid === uid && v.state === 0);

          if (draft != null) {
            try {
              let replace = await this.$globals.Confirm.yesNoCancelDlg(
                this.$t('condAdmin.additionalProductEditor.dlghDraftExists'),
                this.$t('condAdmin.additionalProductEditor.dlgtDraftExists'),
                this.$t('base.replace'), this.$t('base.keep'));
              if (!replace) {
                partnerOfTheWeek = draft;
              }
              newVersion = draft.version;
            } catch (e) {
              return false;
            }
          } else {
            newVersion = 1;
            // find highest version
            for (let v of partOfWeekList) {
              if (v.version > newVersion && v.uid === uid) newVersion = v.version;
            }
            newVersion++;// = newVersion + 1;
          }
        } else {
          newVersion = partnerOfTheWeek.version;
        }
      } else { // copy or new-mode
        uid = uuidv4();
      }

      // check and set editLock in BE
      let isLockSet = false;
      let lockIoData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        targetType: 'partnerOfTheWeek',
        targetIdentifier: uid,
        targetVersion: newVersion
      };

      let forceUpdateLock = false;
      let finishedLockCheck = false;
      let processName = 'createLock';
      while (!finishedLockCheck) {
        let processData = await this.$restClient.callProcess('condAdmin', processName, lockIoData, true);
        if (isProcessStateOK(processData)) {
          if (processData.processState.state === 'warning') {
            // additionalProduct already locked
            let lock = processData.ioData.lock;
            forceUpdateLock = await this.$globals.Confirm.yesNoDlg(
              this.$t('condAdmin.partnerOfTheWeekEditor.dlghPartOfWeekLocked'),
              this.$t('condAdmin.partnerOfTheWeekEditor.dlgtPartOfWeekLocked', formatLock(lock)),
              this.$t('base.overwrite'), this.$t('base.cancel'));
            if (forceUpdateLock) {
              processName = 'forceLock';
            } else {
              finishedLockCheck = true;
            }
          } else {
            this.$globals.Info.open(processData.processState.messages);
            finishedLockCheck = true;
            isLockSet = true;
          }
        } else {
          this.$globals.Info.open(processData.processState.messages);
          finishedLockCheck = true;
        }
      }

      if (isLockSet) {
        this.setWorkingAdditionalProduct(mode, uid, newVersion, partnerOfTheWeek);

        return true;
      }
      return false;
    },
    // ----------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------
    setWorkingAdditionalProduct (mode = 'new', uid, version = 1, source = null) {
      console.log('setWorkingPartnerOfTheWeek', mode);

      let workingValidFrom = this.DateTimeHelper.NOW;
      let workingValidTo = this.DateTimeHelper.INFINITEDATETIME;

      if (mode === 'copy') {
        if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
        if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
      }
      if (mode === 'edit') {
        if (source.state === 0 || source.state === 1) {
          if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) workingValidFrom = source.validFrom;
          if (this.DateTimeHelper.isLater(source.validTo /* than NOW */)) workingValidTo = source.validTo;
        } else {
          if (source.validTo != null && this.DateTimeHelper.isLater(source.validTo /* than NOW */)) {
            if (this.DateTimeHelper.isEarlier(source.validTo, this.DateTimeHelper.INFINITEDATE)) {
              workingValidFrom = this.DateTimeHelper.formatSqlDateTimeAfter(source.validTo);
            } else if (this.DateTimeHelper.isLater(source.validFrom /* than NOW */)) {
              workingValidFrom = source.validFrom;
            }
          }
        }
      }

      let wAP = {
        workingMode: mode,
        isDirty: false,
        clientKey: this.$store.state.condAdmin.clientKey,
        objectKey: uid + '_' + version,
        state: 0,
        partOfTheWeek: mode === 'edit' && source.partOfTheWeek != null ? JSON.parse(JSON.stringify(source.partOfTheWeek)) : [],
        uid: uid,
        validFrom: this.DateTimeHelper.formatSqlDateTime(workingValidFrom),
        validTo: this.DateTimeHelper.formatSqlDateTime(workingValidTo),
        version: version,
        versions: mode === 'edit' && source.versions != null ? JSON.parse(JSON.stringify(source.versions)) : []
      };

      if (mode === 'new') {
        let pow = { calcTypeUid: null, calcTypeName: '', optimisationType: null, optimisationName: '', productTypeUid: null, productTypeName: '', providerUid: null, providerName: '', subProductTypeUid: null, subProductTypeName: '' };
        wAP.partOfTheWeek.push(pow);
      }

      this.$store.state.condAdmin.workingPartnerOfTheWeek = wAP;
      console.debug('wap', JSON.stringify(wAP));
      console.log('workingPartnerOfTheWeek was set');
      if (mode === 'edit' && source != null) source.outdated = true;
    },
    /* *******************
    *
    * DELETE
    *
    ******************* */
    async deletePartnerOfTheWeek (partnerOfTheWeek, skipDialog = false) {
      if (partnerOfTheWeek.state > 5 || partnerOfTheWeek.inherited === true) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.partnerOfTheWeekEditor.infoCannotDeletePartOfWeek') }]);
        return false;
      }
      if (skipDialog === true || await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.partnerOfTheWeekEditor.dlghDeletePartOfWeek'), this.$t('condAdmin.partnerOfTheWeekEditor.dlgtDeletePartOfWeek'))) {
        let ioData = {
          clientKey: this.$store.state.condAdmin.clientKey,
          objectKey: partnerOfTheWeek.objectKey,
          version: partnerOfTheWeek.version
        };
        let processData = await this.$restClient.callProcess('condAdmin', 'deletePartnerOfTheWeek', ioData, true);
        if (isProcessStateOK(processData)) {
          this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.partnerOfTheWeekEditor.infoDeletedPartOfWeek') }]);
          this.refreshPartnerOfTheWeekList();
          return true;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      }
      return false;
    },
    /* *******************
    *
    * Save and check
    *
    ******************* */
    async savePartnerOfTheWeek (partnerOfTheWeek, checkCustomComponents = true) {
      if (partnerOfTheWeek.state !== 0 && partnerOfTheWeek.state !== 1) {
        return false;
      }

      // this.collectCustomComponentsData(additionalProduct);

      let messages = [];
      // check mandatory values

      // if condition.validTo is passed an error will be returned, because condition can't become active anymore
      if (partnerOfTheWeek.validTo != null && this.DateTimeHelper.isEarlier(partnerOfTheWeek.validTo /* than NOW */)) {
        messages.push({ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') });
      }
      // validate custom components data
      if (checkCustomComponents === true) {
        this.validateCustomComponentsData(partnerOfTheWeek, messages);
      }
      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        try {
          // if condition.validFrom is passed it will be set to current date
          if (partnerOfTheWeek.validFrom == null || this.DateTimeHelper.isEarlier(partnerOfTheWeek.validFrom /* than NOW */)) {
            partnerOfTheWeek.validFrom = this.DateTimeHelper.NOW;
            this.$globals.Info.open([{ type: 'warning', message: this.$t('condAdmin.partnerOfTheWeekEditor.infoValidFromChangedToToday') }]);
          }
          let partnerOfTheWeek2save = JSON.parse(JSON.stringify(partnerOfTheWeek));

          // clean Values
          delete partnerOfTheWeek2save.versions;
          delete partnerOfTheWeek2save.isDirty;
          delete partnerOfTheWeek2save.workingMode;
          // create ioData
          let ioData = {
            clientKey: this.$store.state.condAdmin.clientKey,
            partnerOfTheWeek: partnerOfTheWeek2save
          };

          // backend-request
          let processName = partnerOfTheWeek.workingMode === 'edit' ? 'updatePartnerOfTheWeek' : 'createPartnerOfTheWeek';
          console.debug('processName', processName);
          let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
          if (isProcessStateOK(processData)) {
            return processData;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        } catch (err) {
          console.error(err.message);
        }
        return null;
      }
    },
    // collectCustomComponentsData () {
    // },
    validateCustomComponentsData (partnerOfTheWeek, messages) {
      this.$refs.customPartnerOfTheWeek.validateCustomParams(partnerOfTheWeek, messages);
    },
    async setCheckState (partnerOfTheWeek) {
      if (partnerOfTheWeek.isDirty && await this.savePartnerOfTheWeek(partnerOfTheWeek) !== true) {
        return false;
      }
      // if additionalProduct.validTo is passed an error will be returned, because additionalProduct can't become active anymore
      if (partnerOfTheWeek.validTo != null && this.DateTimeHelper.isEarlier(partnerOfTheWeek.validTo /* than NOW */)) {
        this.$globals.Info.open([{ type: 'userError', message: this.$t('condAdmin.common.infoValidToIsPassed') }]);
      }
      // check plausibilities
      let beginDateCheck = await this.validateBeginDate(partnerOfTheWeek);
      let endDateCheck = await this.validateEndDate(partnerOfTheWeek);

      if (!beginDateCheck || !endDateCheck) {
        return false;
      }

      await this.setState(partnerOfTheWeek, 2);
      if (partnerOfTheWeek.state !== 0 && partnerOfTheWeek.state !== 1) {
        this.locked = false;
        this.$router.push('/condAdmin/partnerOfTheWeek/' + partnerOfTheWeek.objectKey);
      }
    },
    async setState (partnerOfTheWeek, state) {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        partnerOfTheWeekUid: partnerOfTheWeek.uid,
        partnerOfTheWeekVersion: partnerOfTheWeek.version,
        state: state
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'updatePartnerOfTheWeekState', ioData, true);
      if (isProcessStateOK(processData)) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoChangedState') }]);
        partnerOfTheWeek.state = processData.ioData.newState;
        let listedPartnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList.find((v) => v.uid === partnerOfTheWeek.uid && v.version === partnerOfTheWeek.version);
        if (listedPartnerOfTheWeek != null) {
          listedPartnerOfTheWeek.state = processData.ioData.newState;
        }

        return true;
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      return false;
    }
  }
};
// </script>
