<template>
  <v-card v-if="provider != null">
    <!-- save or cancel editing -->
    <v-card-text>
      <SaveCancelButtonBar class="mb-4" :cancel-text="$t('base.back')" :saveDisabled="!isDirty"
        :showSave="hasUserRight('manageBaseData')" @cancel="$router.back()" @save="save()" />
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
          <v-text-field v-model="provider.name" :label="$t('base.contact.name') + $t('base.mandatory')"
            :rules="[rules.required]" :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.street" :label="$t('base.contact.street')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.zipCode" :label="$t('base.contact.zipCode')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.city" :label="$t('base.contact.city')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.country" :label="$t('base.contact.country')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
          <v-text-field v-model="provider.providerKey" :label="$t('condAdmin.provider.key') + $t('base.mandatory')"
            :rules="[rules.required]" :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.phone" :label="$t('base.contact.phone')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.email" :label="$t('base.contact.email')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
          <v-text-field v-model="provider.fax" :label="$t('base.contact.fax')" :readonly="!hasUserRight('manageBaseData')"
            @change="setDirty" />
          <v-text-field v-model="provider.additionalData" :label="$t('base.contact.additionalData')"
            :readonly="!hasUserRight('manageBaseData')" @change="setDirty" />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-label>{{ $t('condAdmin.provider.additionalSettings') }}</v-label>
      </v-row>
      <v-row no-gutters class="mt-6">
        <v-label class="pt-1">{{ $t('condAdmin.provider.balloonOptions.label') }}</v-label>
        <v-radio-group v-model="provider.calcSettings.balloonOption" class="ml-4"
          :readonly="!hasUserRight('manageBaseData')" @change="setDirty">
          <v-radio v-for="option in balloonOptions" :key="option" :value="option"
            :label="$t('condAdmin.provider.balloonOptions.' + option)"
            v-tooltip="$tooltip($t('condAdmin.provider.balloonOptions.' + option + '_hint'))" mandatory></v-radio>
        </v-radio-group>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <component v-show="customComponents.ProviderSettings != null" v-bind:is="customComponents.ProviderSettings"
            ref="customProviderSettings" :settings="provider.calcSettings" @update="updateCalcSettings"></component>
        </v-col>
      </v-row>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import condAdminMixin from '../js/condAdminMixin.js';
import { copyProvider, saveProvider } from '../js/condAdminProviderHelper.js';

export default {
  name: 'ProviderDetail',
  components: {
    SaveOnLeaveDlg,
    SaveCancelButtonBar
  },
  mixins: [condAdminMixin],
  props: {
    providerUid: { type: String, default: () => { return null; } }
  },
  data () {
    return {
      provider: null,
      isDirty: false,
      balloonOptions: ['ENDVALUE', 'FINALPAYMENT', 'ENDVALUE_PAYMENT', 'ADDITIONAL_PAYMENT'],
      // es gilt: z.B. für 36 Monate Laufzeit:
      // FINALPAYMENT => 35 gleiche Monatsraten , Ballon ist 36. Rate
      // ENDVALUE => 36 gleiche Monatsraten , Ballon ist zusätzliche Zahlung
      // ENDVALUE_PAYMENT => 35 gleiche Monatsraten , 36. Rate ist Ballon + Monatsrate
      // ADDITIONAL_PAYMENT => 36 gleiche Monatsraten , Ballon ist 25. Rate (1. Monat nach letzter Rate)
      rules: {
        required: (value) => !!value || this.$t('base.required')
      },
      customComponents: {}
    };
  },
  watch: {
    providerUid () {
      this.init();
    }
  },
  async mounted () {
    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    next();
  },
  methods: {
    init () {
      this.getCustomComponents();
      if (this.providerUid != null) {
        this.provider = copyProvider(this.$store.state.condAdmin.providerList.find((p) => p.uid === this.providerUid));
        this.$emit('setPageMode', 'edit', this.provider.name);
      } else {
        this.provider = copyProvider();
        this.$emit('setPageMode', 'create');
      }
      // get countrySettings from orgSettings
      if (this.provider.calcSettings.countryKey !== this.$store.state.condAdmin.countrySettings.key) {
        this.setDirty();
      }
      this.provider.calcSettings.countryKey = this.$store.state.condAdmin.countrySettings.key;
      this.provider.calcSettings.currency = this.$store.state.condAdmin.countrySettings.currency;
      this.provider.calcSettings.currencyCent = this.$store.state.condAdmin.countrySettings.currencyCent;
      this.provider.calcSettings.vat = this.$store.state.condAdmin.countrySettings.vat;
    },
    updateCalcSettings (calcSettings) {
      this.provider.calcSettings = calcSettings;
      this.isDirty = true;
    },
    getCustomComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('Provider'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + shortname + '.vue');
        }
      }
    },
    async save () {
      let state = await saveProvider(this.provider);
      this.isDirty = !state;
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
