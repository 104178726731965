<template>
  <v-card
    v-if="loaded"
    flat
    class="px-4"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          lg="2"
          md="3"
          sm="6"
          xs="6"
        >
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="3"
          sm="6"
          xs="6"
        >
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="3"
          sm="6"
          xs="6"
        >
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="4"
          sm="6"
          xs="6"
          class="mt-2"
        >
          <v-row
            no-gutters
            class="justify-end"
          >
          <v-icon
            @click="getPartnerOfTheWeekList(true)"
            color="secondary"
            class="mr-8"
            v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
            >mdi-reload
          </v-icon>

            <v-btn
              v-if="hasUserRight('manageCondition') && partnerOfTheWeek != null"
              @click.native="edit('edit')"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.edit') }}
            </v-btn>

            <v-btn
              v-if="hasUserRight('manageCondition') && partnerOfTheWeek == null"
              @click.native="edit('new')"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.create') }}
            </v-btn>

            <v-btn
              v-if="hasUserRight('examineCondition') && enableAction('setChecked') && partnerOfTheWeek != null"
              @click.native="setPartnerOfTheWeekState(4)"
              color="secondary"
              class="mr-4"
            >
              {{ $t('condAdmin.actions.setChecked') }}
            </v-btn>

            <v-menu
              offset-y
              v-if="hasUserRight('manageCondition') || hasUserRight('examineCondition')"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('remove')"
                  @click="removeDraft()"
                >{{ capitalizeString('condAdmin.actions.delete') }}
                </v-list-item>
                <v-list-item
                  v-if="hasUserRight('manageCondition')"
                  :disabled="!enableAction('setCheck')"
                  @click="setPartnerOfTheWeekState(2)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition')"
                  :disabled="!enableAction('setChecked')"
                  @click="setPartnerOfTheWeekState(4)"
                >{{ capitalizeString('condAdmin.actions.setChecked') }}
                </v-list-item>
                <v-list-item
                  v-if="hasExamination() && hasUserRight('examineCondition')"
                  :disabled="!enableAction('setRework')"
                  @click="setPartnerOfTheWeekState(1)"
                >{{ capitalizeString('condAdmin.actions.setRework') }}
                </v-list-item>
                <v-list-item
                  v-if="hasActivatorRole() && hasUserRight('activateCondition')"
                  :disabled="!enableAction('enable')"
                  @click="setPartnerOfTheWeekState(6)"
                >{{ capitalizeString('condAdmin.actions.enable') }}
                </v-list-item>
              </v-list>
            </v-menu>

          </v-row>
        </v-col>
      </v-row>
      <v-row>
      </v-row>

      <v-row v-if="partnerOfTheWeek != null">
        <v-col
          lg="8"
          md="8"
          sm="12"
          xs="12"
        >
          <v-select
            :label="$t('condAdmin.version')"
            :value="partnerOfTheWeek.version"
            :items="partnerOfTheWeek.versions"
            item-key="objectKey"
            item-text="version"
            @change="loadVersion"
          >
            <template v-slot:selection="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 150px;"
                >{{ item.version }}</div>
                <div style="width: 600px;">
                  {{ $t('condAdmin.validFrom') + ' ' }}
                  <span class="">{{ formatBeginDate(item.validFrom) }}</span>
                  {{ $t('base.to') }}
                  <span class="">{{ formatEndDate(item.validTo) }}</span>
                </div>
                <div>
                  <v-chip
                    :class="getStateClass(item, false)"
                    class=""
                  >
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-row
                class="align-center"
                no-gutters
              >
                <div
                  class="secondary--text"
                  style="width: 100px;"
                >{{ item.version }}</div>
                <div style="width: 150px;">
                  <v-chip :class="getStateClass(item, false)">
                    {{ getStateText(item, false) }}
                  </v-chip>
                </div>
                <div>
                  <span class="text-caption">{{ $t('condAdmin.validFrom') + ' ' }}</span>
                  {{ formatBeginDate(item.validFrom) }}
                  <span class="text-caption">{{ $t('base.to') }}</span>
                  {{ formatEndDate(item.validTo) }}
                </div>
              </v-row>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- #### Produkt-Ein-/Ausschlüsse #### -->

      <v-row
        v-if="partnerOfTheWeek != null"
        class="grey lighten-5">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.partnerOfTheWeekLabel') }}</span>
        </v-col>
      </v-row>
      <v-row
        v-if="partnerOfTheWeek == null"
        class="grey lighten-5">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.partnerOfTheWeekDetail.noData') }}</span>
        </v-col>
      </v-row>
      <v-row
        v-if="partnerOfTheWeek != null"
        class="grey lighten-5" >
        <v-col>
          <v-data-table
              :headers="partOfWeekHeaders"
              :items="partnerOfTheWeek.partOfTheWeek"
              fixed-header
              hide-default-footer
              :no-data-text="$t('condAdmin.partnerOfTheWeekDetail.noPartnerOfTheWeek')"
              item-key="id"
              class="grey lighten-5 noLines"
          >
            <template
              v-for="header in partOfWeekHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.productType`]="{ item }">
              {{ ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)) == null ? item.productTypeUid : ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)).name }}
              <v-icon
                v-if="($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid && p.state === 10)) != null"
                v-tooltip="$tooltip($t('condAdmin.productType.disabled'))"
              >mdi-alert-outline</v-icon>
            </template>
            <template v-slot:[`item.subProductType`]="{ item }">
              {{ getSubProductTypesText([item.subProductTypeUid]) }}
              <v-icon
                v-if="getSubProductTypeState(item.subProductTypeUid) === 10"
                v-tooltip="$tooltip($t('condAdmin.productType.subProductTypeDisabled'))"
              >mdi-alert-outline</v-icon>
            </template>
            <template v-slot:[`item.optimisationType`]="{ item }">
              {{ getOptimisationType(item.optimisationType)  }}
            </template>
            <template v-slot:[`item.calcType`]="{ item }">
              {{ ($store.state.condAdmin.calcTypeList.find((p) => p.uid === item.calcTypeUid)) == null ? item.calcTypeUid : ($store.state.condAdmin.calcTypeList.find((p) => p.uid === item.calcTypeUid)).text }}
            </template>
            <template v-slot:[`item.providerType`]="{ item }">
              {{ ($store.state.condAdmin.providerList.find((p) => p.uid === item.providerUid)) == null ? item.providerUid : ($store.state.condAdmin.providerList.find((p) => p.uid === item.providerUid)).name }}
            </template>
            <template v-slot:item.isInclude="{ item }">
              <v-chip :class="inExcludeModes[item.isInclude].class">
                {{ item.isInclude ? $t('condAdmin.isInclude.include') : $t('condAdmin.isInclude.exclude') }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import condAdminPartnerOfTheWeekMixin from '../js/condAdminPartnerOfTheWeekMixin.js';

export default {
  name: 'PartnerOfTheWeekDetail',
  components: {
  },
  mixins: [ condAdminPartnerOfTheWeekMixin ],
  props: { partOfTheWeekObjectKey: { type: String }

  },
  data () {
    return {
      partnerOfTheWeek: null,
      partnerOfTheWeekObjectKey: null,
      loaded: false
    };
  },
  computed: {

    partOfWeekHeaders () {
      let ph = [
        { width: '150px', text: this.$t('condAdmin.product.productType'), value: 'productType', formatter: this.formatValue },
        { width: '150px', text: this.$t('condAdmin.product.subProductType'), value: 'subProductType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.optimisationType'), value: 'optimisationType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.calcType'), value: 'calcType', formatter: this.replaceAsAll },
        { width: '150px', text: this.$t('condAdmin.condition.provider'), value: 'providerType', formatter: this.replaceAsAll }
      ];

      ph.push({ width: '90px', text: null, value: 'menu', sortable: false });
      return ph;
    }
  },
  async mounted () {
    console.debug('mounted');
    if (this.$store.state.condAdmin.partnerOfTheWeekList == null) {
      await this.refreshPartnerOfTheWeekList();
    }
    this.partnerOfTheWeekObjectKey = this.partOfTheWeekObjectKey;
    this.init(this.partnerOfTheWeekObjectKey);
  },
  methods: {
    async init (partOfTheWeekObjectKey, force = false) {
      this.loaded = false;
      if (partOfTheWeekObjectKey && !force) {
        if (this.$store.state.condAdmin.partnerOfTheWeekList == null) {
          await this.refreshPartnerOfTheWeekList();
        }
        this.partnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList.find((c) => c.objectKey === partOfTheWeekObjectKey);
        if (this.partnerOfTheWeek == null || this.partnerOfTheWeek.versions == null) {
          this.partnerOfTheWeek = await this.readPartnerOfTheWeek(partOfTheWeekObjectKey, this.partnerOfTheWeek, true);
        }
      } else {
        if (this.$store.state.condAdmin.partnerOfTheWeekList != null && this.$store.state.condAdmin.partnerOfTheWeekList.length > 0 && !force) {
          let maxVersion = Math.max(...this.$store.state.condAdmin.partnerOfTheWeekList.map(function (o) { return o.version; }));
          this.partnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList.find(pt => pt.version === maxVersion);
          partOfTheWeekObjectKey = this.partnerOfTheWeek.objectKey;
          // this.partnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList[0];
          if (this.partnerOfTheWeek) {
            this.partnerOfTheWeek = await this.readPartnerOfTheWeek(this.partnerOfTheWeek.objectKey, this.partnerOfTheWeek, true);
          }
        } else {
          await this.refreshPartnerOfTheWeekList();
          if (this.$store.state.condAdmin.partnerOfTheWeekList.length > 0) {
            if (partOfTheWeekObjectKey != null) {
              this.partnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList.find((c) => c.objectKey === partOfTheWeekObjectKey);
            } else {
              let maxVersion = Math.max(...this.$store.state.condAdmin.partnerOfTheWeekList.map(function (o) { return o.version; }));
              this.partnerOfTheWeek = this.$store.state.condAdmin.partnerOfTheWeekList.find(pt => pt.version === maxVersion);
              partOfTheWeekObjectKey = this.partnerOfTheWeek.objectKey;
            }
            if (this.partnerOfTheWeek == null || this.partnerOfTheWeek.versions == null) {
              this.partnerOfTheWeek = await this.readPartnerOfTheWeek(partOfTheWeekObjectKey, this.partnerOfTheWeek, true);
            }
          }
        }
      }
      this.$emit('setPageMode', 'view', 'Partner der Woche');
      this.loaded = true;
    },
    loadVersion (version) {
      let tempAP = this.partnerOfTheWeek.versions.find((e) => e.version === version);
      if (tempAP != null) {
        this.init(tempAP.objectKey);
      } else {
        this.init(this.partOfTheWeekObjectKey);
      }
    },

    enableAction (action) {
      if (this.partnerOfTheWeek != null) {
        switch (action) {
          case 'edit':
          case 'copy':
          case 'create': return true;
          case 'end': return false;
          case 'remove': return this.partnerOfTheWeek.inherited !== true && this.partnerOfTheWeek.state < 6;
          case 'setCheck': return this.partnerOfTheWeek.state === 0 || this.partnerOfTheWeek.state === 1;
          case 'setChecked': return this.partnerOfTheWeek.state === 2 || this.partnerOfTheWeek.state === 4;
          case 'setRework': return this.partnerOfTheWeek.state === 2 || this.partnerOfTheWeek.state === 4;
          case 'enable': return this.partnerOfTheWeek.state === 5;
        }
      }
      return false;
    },
    async getPartnerOfTheWeekList (force = false) {
      if (force || this.$store.state.condAdmin.partnerOfTheWeekList == null || this.$store.state.condAdmin.partnerOfTheWeekList.length === 0) {
        await this.init(this.partnerOfTheWeekObjectKey, true);
      }
    },
    getOptimisationType (item) {
      return this.optimisationTypes[item];
    },

    async edit (mode) {
      if (await this.checkEditPrePartnerOfTheWeek(mode, this.partnerOfTheWeek) === true) {
        this.$router.push('/condAdmin/partnerOfTheWeek/edit');
      }
    },
    async removeDraft () {
      if (await this.deletePartnerOfTheWeek(this.partnerOfTheWeek) === true) {
        this.partnerOfTheWeekObjectKey = null;
        this.init(this.partnerOfTheWeekObjectKey);
        this.$router.push('/condAdmin/partnerOfTheWeek');
      }
    },
    async setPartnerOfTheWeekState (state) {
      let setState = true;
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        let beginDateCheck = await this.validateBeginDate(this.partnerOfTheWeek);
        let endDateCheck = await this.validateEndDate(this.partnerOfTheWeek);
        setState = (beginDateCheck && endDateCheck);
      }

      if (setState) {
        await this.setState(this.partnerOfTheWeek, state);
        let v = this.partnerOfTheWeek.versions.find((c) => c.version === this.partnerOfTheWeek.version);
        v.state = this.partnerOfTheWeek.state;
      }
    },

    end () {
    }
  }
};
</script>

<style scoped>
</style>
