<template>
  <router-view v-if="isReady"></router-view>
</template>

<script lang="js">
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { defaultOrgSettings } from '@/condAdmin/js/condDefaults.js';
import { loadJsonFromUrl } from '@/base/js/JsonLoader';
import { countrySettings } from '@/base/js/baseHelper';
import { refreshCalcTypeList, refreshProductTypeList } from './js/condAdminHelper.js';
import { refreshProviderList } from './js/condAdminProviderHelper.js';
import { refreshDealerList } from './js/condAdminDealerHelper.js';
import deepmerge from '@/base/js/Deepmerge.js';

export default {
  name: 'CondAdmin',
  data () {
    return {
      clientVerified: false,
      isInitialised: false
    };
  },
  computed: {
    isReady () {
      if (this.$store.state.base.admin.administratedOrg != null &&
        this.$i18n.keyExists('condAdmin.packageId') && this.clientVerified && this.isInitialised) {
        return true;
      }
      return false;
    }
  },
  mounted () {
    if (this.$store.state.condAdmin == null || this.$store.state.condAdmin.clientKey == null || this.$store.state.base.admin.administratedOrg.uid !== this.$store.state.condAdmin.clientKey) {
      this.initClient();
    } else {
      this.clientVerified = true;
      this.isInitialised = true;
    }
  },
  watch: {
    '$store.state.base.admin.administratedOrg': function () {
      this.resetLists();
      this.initClient();
    }
  },
  methods: {
    async initClient () {
      if (this.$store.state.base.admin.administratedOrg == null) return;
      this.$store.state.condAdmin.clientKey = this.$store.state.base.admin.administratedOrg.uid;

      // get countrySettings from orgSettings
      let countryKey = 'de';
      if (this.$store.state.base.admin.administratedOrgObjects.orgSettings != null && this.$store.state.base.admin.administratedOrgObjects.orgSettings.countrySettings != null) {
        countryKey = this.$store.state.base.admin.administratedOrgObjects.orgSettings.countrySettings.countryKey || 'de';
      }
      let cs = countrySettings.find((e) => e.key === countryKey);
      if (cs != null) {
        this.$store.state.condAdmin.countrySettings = cs;
      }

      this.clientVerified = false;
      let ioData = {
        clientKey: this.$store.state.base.admin.administratedOrg.uid,
        clientName: this.$store.state.base.admin.administratedOrg.shortname,
        countryKey: countryKey
      };

      this.$restClient.callProcess('condAdmin', 'checkClient', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.clientVerified = true;
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
      await this.initModule();
      await Promise.all([
        refreshProviderList(),
        refreshDealerList(),
        refreshCalcTypeList(),
        refreshProductTypeList()
      ]);
      this.isInitialised = true;
    },
    resetLists () {
      this.isInitialised = false;
      this.$store.state.condAdmin.providerList = null;
      this.$store.state.condAdmin.dealerList = null;
      this.$store.state.condAdmin.calcTypeList = null;
      this.$store.state.condAdmin.productTypeList = null;
      this.$store.state.condAdmin.conditionList = null;
      this.$store.state.condAdmin.productList = null;
      this.$store.state.condAdmin.rvTableList = null;
      this.$store.state.condAdmin.rvGroupList = null;
      this.$store.state.condAdmin.additionalProductList = null;
    },
    async initModule () {
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey
      };
      let processData = await this.$restClient.callProcess('condAdmin', 'initModule', ioData);
      if (isProcessStateOK(processData)) {
        this.$store.state.condAdmin.userRights = processData.ioData.userRights;

        this.$store.state.condAdmin.userSettings = deepmerge.customArrayMerge(this.$store.state.condAdmin.userSettings, processData.ioData.userSettings, 'userSettings');

        // auto-react to changed settings-Structure
        if (processData.ioData.orgSettings.useCustomizedComponents !== true &&
          (processData.ioData.orgSettings.customConditionCommissionSettings === true || processData.ioData.orgSettings.customConditionDealerSettings === true)
        ) {
          processData.ioData.orgSettings.useCustomizedComponents = true;
        }
        delete processData.ioData.orgSettings.customConditionCommissionSettings;
        delete processData.ioData.orgSettings.customConditionDealerSettings;

        this.$store.state.condAdmin.orgSettings = deepmerge(defaultOrgSettings, processData.ioData.orgSettings);

        // load customComponents-list
        if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
          let shortname = processData.ioData.orgSettings.customShortname;
          let path = this.$configHelper.getResourcesPath() + 'customComponents/';
          this.$store.state.condAdmin.customComponents = await loadJsonFromUrl(path + 'condAdmin_' + shortname + '.json');
        } else {
          this.$store.state.condAdmin.customComponents = [];
        }
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
    }
  }
};
</script>
