<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card v-if="existingObject != null && newObject != null">
      <v-card-title class="primary-bg">
        {{ $t('base.doubletDlg.title') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row v-if="hint != null">
          <v-col>{{ hint || $t('base.doubletDlg.defaultHint') }}</v-col>
        </v-row>
        <v-card
          tile
          flat
        >
          <v-card-text class="px-3">
            <v-row class="primary lighten-5">
              <v-col class="v-label text-center">{{ $t('base.doubletDlg.attributes')}}</v-col>
              <v-col class="v-label text-center">{{ $t('base.doubletDlg.existing')}}</v-col>
              <v-col class="v-label text-center">{{ $t('base.doubletDlg.new')}}</v-col>
              <v-col v-if="!simple">
                <v-btn
                  small
                  class="prio2"
                  @click.native="reset"
                >
                  {{ $t('base.reset') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-for="(field, index) in fields"
              :key="index"
            >
              <v-col class="v-label text-right pt-9 pr-8">{{ field.text }}</v-col>
              <v-col>
                <v-text-field
                  :value="existingObject[field.key]"
                  disabled
                />
              </v-col>
              <v-col>
                <v-text-field
                  :disabled="simple || field.readonly === true"
                  v-model="mergedObject[field.key]"
                  @change="isDirty = true"
                  :class="(field.unique === true && uniqueCheckFunction(mergedObject) != null) ? 'notUnique' : ''"
                />
              </v-col>
              <v-col v-if="!simple"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row no-gutters>
          <v-spacer />
          <v-btn
            class="secondary mr-4"
            @click.native="skipNew"
          >
            {{ $t('base.doubletDlg.skipNew') }}
          </v-btn>
          <v-btn
            class="secondary mr-4"
            @click.native="overwrite"
            :disabled="isDirty"
          >
            {{ $t('base.doubletDlg.overwrite') }}
          </v-btn>
          <v-btn
            v-if="!simple"
            class="secondary mr-4"
            @click.native="copy"
            :disabled="uniqueCheckFunction(mergedObject) != null"
          >
            {{ $t('base.doubletDlg.copy') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
export default {
  name: 'DoubletDlg',
  props: {
    hint: { type: String, default: null },
    fields: { type: Array, required: true },
    uniqueCheckFunction: { type: Function, default: () => { return null; } }
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      isDirty: false,
      simple: false,

      existingObject: null,
      newObject: null,
      mergedObject: null
    };
  },
  methods: {
    showSimple (existingObject, newObject) {
      this.existingObject = existingObject;
      this.newObject = newObject;
      this.mergedObject = JSON.parse(JSON.stringify(newObject));
      this.simple = true;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    show (existingObject, newObject) {
      this.existingObject = existingObject;
      this.newObject = newObject;
      this.mergedObject = JSON.parse(JSON.stringify(newObject));
      this.simple = false;
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    skipNew () {
      this.dialog = false;
      if (this.simple) this.resolve('skipNew');
      else this.resolve(['skipNew', null]);
    },
    overwrite () {
      this.dialog = false;
      if (this.simple) this.resolve('overwrite');
      else this.resolve(['overwrite', this.newObject]);
    },
    copy () {
      if (this.uniqueCheckFunction(this.mergedObject) == null) {
        this.dialog = false;
        this.resolve(['copy', this.mergedObject]);
      }
    },
    reset () {
      this.mergedObject = JSON.parse(JSON.stringify(this.newObject));
      this.isDirty = false;
    }

  }
};
</script>

<style scoped>
.notUnique >>> .v-text-field__slot,
.notUnique >>> .v-select__selection,
.notUnique >>> input {
  color: var(--v-error-base) !important;
}
</style>
