<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-package-variant-closed"
    />
    <router-view
      v-if="$store.state.condAdmin.productList != null"
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.productList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';
import { newMessage } from '@/base/js/ProcessDataHelper';
import { refreshProductList } from './js/condAdminHelper.js';
import condAdminMixin from './js/condAdminMixin.js';

export default {
  name: 'Product',
  mixins: [condAdminMixin],
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.productList.headline')
    };
  },
  async mounted () {
    if (this.$store.state.condAdmin.orgSettings.showResidualValues !== true || (this.$route.path.startsWith('/condAdmin/product') && !this.hasUserRight('readResidualValues'))) {
      if (this.$store.state.condAdmin.orgSettings.showConditions === true && this.hasUserRight('readCondition')) {
        this.$router.push('/condAdmin/condition');
      } else if (this.$store.state.condAdmin.orgSettings.showBaseData === true && this.hasUserRight('readBaseData')) {
        this.$router.push('/condAdmin/productType');
      } else {
        this.$globals.Info.open([newMessage('userError', this.$t('base.error.mismatchingRights'))]);
      }
      return;
    }
    if (this.$store.state.condAdmin.productList == null || this.$store.state.condAdmin.productList.length === 0) {
      await refreshProductList();
    }
  },
  methods: {
    setPageMode (mode) {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('condAdmin.productList.headline') + ' ' + this.$t('condAdmin.productList.productCreate');
          break;
        case 'edit':
          this.headlineText = this.$t('condAdmin.productList.productEdit');
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.productList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
