<template>
  <v-card class="minContent">
    <v-card-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="editAdditionalProduct()"
            :disabled="selected.length !== 1"
          >
            <v-icon class="mr-4">mdi-pencil</v-icon>
            {{ capitalizeString("base.edit")}}
          </v-list-item>
          <v-list-item @click="deleteAdditionalProducts()">
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ capitalizeString("base.delete")}}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <!--
      <v-btn
        @click="createAdditionalProduct()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.newAdditionalProduct'))"
      >
        <v-icon class="mr-2">mdi-bank-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      -->
      <v-icon
        @click="getAdditionalProductList()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>

      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('additionalProductTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
      <JsonImporter
        v-if="hasUserRight('manageCondition')"
        class="ml-8"
        objectType="additionalProductList"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="filteredList"
            :items-per-page="footerProps.itemsPerPage"
            :footer-props="footerProps"
            item-key="objectKey"
            show-select
            fixed-header
            :sort-by="listSortKey"
            @click:row="clickedRow"
            :loading="$store.state.condAdmin.additionalProductLoading"
            @update:items-per-page="getItemPerPage"
          >
            <template
              v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`body.prepend`]="{}">
              <tr>
                <td></td>
                <td
                  v-for="(h, index) in listHeaders"
                  :key="index"
                >
                  <SearchSelect
                    v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                    :items="stateList"
                    :item-text="filter[h.value].itemText"
                    :item-value="filter[h.value].itemValue"
                  />
                  <SearchField
                    v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                  />
                  <SearchDatePicker
                    v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'DatePicker'"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                  />
                </td>
              </tr>
            </template>
            <!--
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="secondary"
                v-bind="props"
                v-on="on"
              ></v-simple-checkbox>
            </template>
            -->
            <template v-slot:[`header.menu`]="{}">
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-chip :class="getStateClass(item, false)">
                {{ getStateText(item, false) }}
              </v-chip>
            </template>
            <template v-slot:[`item.menu`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- bearbeiten des Produkts -->
                  <v-list-item
                    v-if="hasUserRight('manageCondition')"
                    :disabled="!enableAction('edit', item)"
                    @click="editAdditionalProduct('edit', item)"
                  >{{ capitalizeString('condAdmin.actions.edit') }}
                  </v-list-item>
                  <!-- löschen des Produkts -->
                  <v-list-item
                    v-if="hasUserRight('manageCondition')"
                    :disabled="!enableAction('remove', item)"
                    @click="removeDraft(item)"
                  >
                    {{ capitalizeString('base.delete')}}
                  </v-list-item>
                  <!-- prüfen des Produkts -->
                  <v-list-item
                    v-if="hasUserRight('manageCondition')"
                    :disabled="!enableAction('setCheck', item)"
                    @click="setAdditionalProductState(2, item)"
                  >{{ capitalizeString('condAdmin.actions.setCheck') }}
                  </v-list-item>
                  <!-- freigeben des Produkts -->
                  <v-list-item
                    v-if="hasExamination() && hasUserRight('examineCondition')"
                    :disabled="!enableAction('setChecked', item)"
                    @click="setAdditionalProductState(4, item)"
                  >{{ capitalizeString('condAdmin.actions.setChecked') }}
                  </v-list-item>
                  <!-- korrigieren des Produkts -->
                  <v-list-item
                    v-if="hasExamination() && hasUserRight('examineCondition')"
                    :disabled="!enableAction('setRework', item)"
                    @click="setAdditionalProductState(1, item)"
                  >{{ capitalizeString('condAdmin.actions.setRework') }}
                  </v-list-item>
                  <v-list-item @click="showHistory('getAdditionalProductHistory', item.uid, item.tariffFEName)">{{ $t('condAdmin.actions.history') }}</v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <JsonExporter
      objectType="additionalProductList"
      module="condAdmin"
      ref="jsonExporter"
    />
    <AdditionalProductImportDlg ref="additionalProductImportDlg" />
  </v-card>
</template>

<script lang="js">
import condAdminAdditionalProductMixin from '../js/condAdminAdditionalProductMixin.js';
import { newMessage } from '@/base/js/ProcessDataHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import SearchDatePicker from '@/common/SearchDatePicker';
import HistoryDlg from './HistoryDlg';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import AdditionalProductImportDlg from './AdditionalProductImportDlg';

export default {
  name: 'AdditionalProductTable',
  components: {
    SearchField,
    SearchSelect,
    SearchDatePicker,
    HistoryDlg,
    JsonImporter,
    JsonExporter,
    AdditionalProductImportDlg
  },
  mixins: [ condAdminAdditionalProductMixin ],
  data () {
    return {
      selected: [],
      listSortKey: 'tariffName',
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.additionalProductList.headline'), active: true, value: 'tariffName' },
        { text: this.$t('condAdmin.version'), active: true, value: 'version' },
        { text: this.$t('condAdmin.validFrom'), active: true, value: 'validFrom', formatter: this.formatBeginDate },
        { text: this.$t('condAdmin.validTo'), active: true, value: 'validTo', formatter: this.formatEndDate },
        { text: this.$t('base.state'), active: true, value: 'state' }
      ],
      filter: {
        tariffName: { condition: 'startsWith', value: null, type: 'SearchField' },
        validFrom: { condition: 'date.<=', value: null, type: 'DatePicker' },
        validTo: { condition: 'date.>=', value: null, type: 'DatePicker' },
        state: { condition: '=', value: null, type: 'SelectBox', items: 'stateList', itemText: 'text', itemValue: 'state' }
      },
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      }

    };
  },
  computed: {
    additionalProductList () {
      return complexFilter(this.$store.state.condAdmin.additionalProductList, this.filter);
    },
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    stateList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.additionalProductList) {
        if (list.find((i) => i.value === c.state) == null) {
          list.push({ state: c.state, text: this.$t('condAdmin.state.' + this.states[c.state]) });
        }
      }
      return list.sort();
    }

  },
  async mounted () {
    this.getUserTableSettings('additionalProductTable');
    await this.refreshAdditionalProductList();
    this.filteredList = complexFilter(this.$store.state.condAdmin.additionalProductList, this.filter);
    this.$emit('setPageMode', 'table');
  },
  methods: {

    async getAdditionalProductList () {
      await this.refreshAdditionalProductList();
      this.filteredList = complexFilter(this.$store.state.condAdmin.additionalProductList, this.filter);
    },
    async deleteAdditionalProducts () {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.additionalProductEditor.dlghDeleteAddProducts'), this.$t('condAdmin.additionalProductEditor.dlgtDeleteAddProducts'))) {
        for (let additionalProduct of this.selected) {
          await this.deleteAdditionalProduct(additionalProduct, true);
        }
      }
    },
    async removeDraft (additionalProduct) {
      if (await this.deleteAdditionalProduct(additionalProduct) === true) {
        await this.getAdditionalProductList();
      }
    },
    async createAdditionalProduct () {
      // this.notImplemented('createAdditionalProduct');
      if (await this.checkEditPreAdditionalProduct('new') === true) {
        this.$router.push('/condAdmin/additionalProduct/edit');
      }
    },
    async editAdditionalProduct (mode, additionalProduct = null) {
      additionalProduct = this.$store.state.condAdmin.additionalProductList.find((c) => c.objectKey === additionalProduct.objectKey);
      if (additionalProduct.versions == null) {
        additionalProduct = await this.readAdditionalProduct(additionalProduct.objectKey, additionalProduct, true);
      }
      if (await this.checkEditPreAdditionalProduct(mode, additionalProduct) === true) {
        this.$router.push('/condAdmin/additionalProduct/edit');
      } else {
        this.$logger.debug('condAdmin', 'AdditionalProductTable.vue: edit else !!!');
      }
    },
    clickedRow (additionalProduct) {
      this.viewAdditionalProduct(additionalProduct);
    },
    viewAdditionalProduct (additionalProduct = null) {
      this.$router.push('/condAdmin/additionalProduct/' + additionalProduct.objectKey);
    },
    enableAction (action, additionalProduct) {
      switch (action) {
        case 'edit':
        case 'copy': return true;
        case 'end': return false;
        case 'remove': return additionalProduct.inherited !== true && additionalProduct.state < 6;
        case 'setCheck': return additionalProduct.state === 0 || additionalProduct.state === 1;
        case 'setChecked': return additionalProduct.state === 2 || additionalProduct.state === 4;
        case 'setRework': return additionalProduct.state === 2 || additionalProduct.state === 4;
        case 'enable': return additionalProduct.state === 5;
      }
      return true;
    },
    async setAdditionalProductState (state, additionalProduct) {
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        if (additionalProduct.versions == null) {
          additionalProduct = await this.readAdditionalProduct(additionalProduct.objectKey, additionalProduct, true);
        }
        let beginDateCheck = await this.validateBeginDate(additionalProduct);
        let endDateCheck = await this.validateEndDate(additionalProduct);
        if (!beginDateCheck || !endDateCheck) {
          return false;
        }
      }
      await this.setState(additionalProduct, state);
      let v = additionalProduct.versions.find((c) => c.version === additionalProduct.version);
      v.state = additionalProduct.state;
      await this.getAdditionalProductList();
    },

    // clearFilters () {
    //   this.filter = {
    //     name: null,
    //     street: null,
    //     zipCode: null,
    //     city: null,
    //     country: null
    //   };
    // },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['additionalProductTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['additionalProductTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      // this.$logger.debug('condAdmin', 'set filter ' + filtername + ' to ' + value);
      this.filteredList = complexFilter(this.$store.state.condAdmin.additionalProductList, this.filter);
      this.$store.state.condAdmin.userSettings['additionalProductTable'].filter[filtername] = value;
    },
    // -------------------- Export / Import ---------------------------
    async exportData () {
      let additionalProductList = [];
      for (let c of this.selected) {
        let addProduct = JSON.parse(JSON.stringify(await this.readAdditionalProduct(c.objectKey, c, true)));

        delete addProduct.versions;
        delete addProduct.uid;
        delete addProduct.clientKey;
        delete addProduct.timestamp;
        delete addProduct.objectKey;
        // bei der Erstbefüllung in der Datenbank von Hand wurde bei Repair und GAP kein Objekt dafür angelegt
        // dies führt jetzt zu einem Fehler beim Import
        if (addProduct.dealerCommission == null) addProduct.dealerCommission = {};

        additionalProductList.push(addProduct);
      }
      this.$refs.jsonExporter.exportJsonData({ additionalProductList });
    },
    async importData (json) {
      await this.refreshAdditionalProductList();
      let imported = 0;
      let total = json.data.additionalProductList.length;

      for (let imp of json.data.additionalProductList) {
        let action = await this.$refs.additionalProductImportDlg.show(imp);
        switch (action) {
          case 'startImport':
            let processData = await this.saveAdditionalProduct(imp, false);
            if (processData != null) {
              this.$store.state.condAdmin.additionalProductList.push(processData.ioData.additionalProduct);
              imported++;
            }
            break;
          case 'skip':
          default:
            break;
        }
      }
      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported > 0) this.getAdditionalProductList();
    },
    preImportCheckFunction (json) {
      if (json.data.additionalProductList == null || json.data.additionalProductList.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.additionalProductImport.noImportData')) ]);
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
</style>
