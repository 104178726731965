<template>
  <v-container class="page">
    <Headline
      :title="$t('condAdmin.calcTypeLabel')"
      icon="mdi-calculator-variant-outline"
    />
    <v-row no-gutters>
      <v-spacer />
      <v-icon
        @click="getCalcTypeList()"
        color="secondary"
        class="mr-4"
      >mdi-reload</v-icon>
      <v-btn
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
        :disabled="!this.isDirty"
        @click="save()"
        color="secondary"
        class="mr-4"
      >
        {{ $t("base.save") }}
      </v-btn>
      <v-menu
        offset-y
        v-if="hasUserRight('manageBaseData') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
          <v-list-item @click="startImport()">
            <v-icon class="mr-4">mdi-import</v-icon>
            {{ capitalizeString('base.import') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row
      no-gutters
      v-if="$store.state.condAdmin.calcTypesLoading === false"
    >
      <v-col>
        <v-card
          v-for="element in workElementList"
          :key="element.calcType"
          flat
          tile
          class="my-4"
          :class="element.active ? 'activeCalcType' : 'inactiveCalcType'"
        >
          <v-card-text v-if="element.active && element.custom != null">
            <v-row dense>
              <v-col
                class="font-weight-bold"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
              >
                <v-label>{{ element.defaults.text }}</v-label>
              </v-col>
              <v-col
                v-if="hasUserRight('manageBaseData') && isEditable(element) === true"
                class="text-right"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
              >
                <v-btn
                  small
                  class="mr-8 my-0 prio2"
                  @click="enable(element, false)"
                >{{ $t('condAdmin.calcType.disable') }}</v-btn>
                <v-btn
                  small
                  class="prio2"
                  @click="setDefaults(element)"
                >{{ $t('condAdmin.calcType.setDefaults') }}</v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  dense
                  @change="setDirty"
                  v-model="element.custom.text"
                  :readonly="!hasUserRight('manageBaseData') || isEditable(element) !== true"
                  :label="$t('condAdmin.calcType.text') + $t('base.mandatory')"
                />
              </v-col>
              <v-col>
              </v-col>
            </v-row>
            <!-- ### Durations ### -->
            <v-row>
              <v-col
                cols="12"
                lg="10"
                md="9"
                sm="12"
                xs="12"
              >
                <v-row no-gutters>
                  <span class="mt-1">{{ $t('condAdmin.calcType.durations') }}</span>
                  <v-btn
                    v-if="hasUserRight('manageBaseData') && isEditable(element) === true"
                    :disabled="element.custom.settings.durations.includes(null)"
                    @click="element.custom.settings.durations.push(null)"
                    icon
                    color="secondary"
                  >
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </v-row>
                <v-row no-gutters>
                  <v-chip
                    v-for="(v) in element.custom.settings.durations"
                    :key="v"
                    :close="hasUserRight('manageBaseData') && isEditable(element) === true"
                    @click:close="removeDuration(element, v)"
                    class="mr-2 mb-1"
                    :class="element.custom.settings.defaultDuration === v ? 'primary lighten-3' : ''"
                  >
                    <EditField
                      style="width: 50px"
                      class="mt-0 caption"
                      v-if="v == null"
                      @change="changeDuration(element, v, $event)"
                      :value="v"
                      :placeholder="$t('base.value')"
                      hideDetails
                      dense
                      type="uint"
                    />
                    <div v-else>{{ v }}</div>
                  </v-chip>
                  <span v-if="element.custom.settings.durations.length === 0">{{ $t('base.none') }}</span>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="2"
                md="3"
                sm="12"
                xs="12"
              >
                <EditField
                  :readonly="!hasUserRight('manageBaseData') || isEditable(element) !== true"
                  @change="setDirty"
                  v-model="element.custom.settings.defaultDuration"
                  :label="$t('condAdmin.calcType.defaultDuration')"
                  :suffix="$t('base.months')"
                  type="uint"
                />
              </v-col>
            </v-row>
            <!-- ### Mileages ### -->
            <v-row v-if="element.calcType !== 'CC'">
              <v-col
                cols="12"
                lg="10"
                md="9"
                sm="12"
                xs="12"
              >
                <v-row no-gutters>
                  <span class="mt-1">{{ $t('condAdmin.calcType.mileages') }}</span>
                  <v-btn
                    v-if="hasUserRight('manageBaseData') && isEditable(element) === true"
                    :disabled="element.custom.settings.mileages.includes(null)"
                    @click="element.custom.settings.mileages.push(null)"
                    icon
                    color="secondary"
                  >
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </v-row>
                <v-row
                  v-if="element.calcType !== 'CC'"
                  no-gutters
                >
                  <v-chip
                    v-for="(v) in element.custom.settings.mileages"
                    :key="v"
                    :close="hasUserRight('manageBaseData') && isEditable(element) === true"
                    @click:close="removeMileage(element, v)"
                    class="mr-2 mb-1"
                    :class="element.custom.settings.defaultMileage === v ? 'primary lighten-3' : ''"
                  >
                    <EditField
                      style="width: 50px"
                      class="mt-0 caption"
                      v-if="v == null"
                      @change="changeMileage(element, v, $event)"
                      :value="$filters.formatNumber(v, null, NULLVALUE)"
                      :placeholder="$t('base.value')"
                      hideDetails
                      dense
                      type="uint"
                    />
                    <div v-else>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
                  </v-chip>
                  <span v-if="element.custom.settings.mileages.length === 0">{{ $t('base.none') }}</span>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="2"
                md="3"
                sm="12"
                xs="12"
              >
                <EditField
                  @change="setDirty"
                  v-model="element.custom.settings.defaultMileage"
                  :label="$t('condAdmin.calcType.defaultMileage')"
                  suffix="km"
                  type="uint"
                  :readonly="!hasUserRight('manageBaseData') || isEditable(element) !== true"
                />
              </v-col>
            </v-row>
            <!-- ### InterestScale ### -->
            <v-row v-if="$store.state.condAdmin.orgSettings.useInterestScale === true">
              <v-col
                cols="12"
                lg="10"
                md="9"
                sm="12"
                xs="12"
              >
                <v-row no-gutters>
                  <span class="mt-1">{{ $t('condAdmin.calcType.interestScale') }}</span>
                  <v-btn
                    v-if="hasUserRight('manageBaseData') && isEditable(element) === true"
                    :disabled="element.custom.settings.interestScale.includes(null)"
                    @click="element.custom.settings.interestScale.push(null)"
                    icon
                    color="secondary"
                  >
                    <v-icon>mdi-plus-box</v-icon>
                  </v-btn>
                </v-row>
                <v-row no-gutters>
                  <v-chip
                    v-for="(v) in element.custom.settings.interestScale"
                    :key="v"
                    :close="hasUserRight('manageBaseData') && isEditable(element) === true"
                    @click:close="removeInterest(element, v)"
                    class="mr-2 mb-1"
                  >
                    <EditField
                      style="width: 50px"
                      class="mt-0 caption"
                      v-if="v == null"
                      @change="changeInterest(element, v, $event)"
                      :value="$filters.formatNumber(v, null, NULLVALUE)"
                      :placeholder="$t('base.value')"
                      hideDetails
                      dense
                      type="ufloat"
                    />
                    <div v-else>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
                  </v-chip>
                  <span v-if="element.custom.settings.interestScale.length === 0">{{ $t('base.none') }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="element.active === false && getOrgSetting('createOwnData') === true">
            <v-row>
              <v-col class="font-weight-bold">
                <v-label>{{ element.defaults.text }}</v-label>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  color="secondary"
                  class="ml-8 "
                  @click="enable(element, true)"
                >{{ $t('condAdmin.calcType.enable') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <SaveOnLeaveDlg ref="leaveDialog" />
    <JsonExporter
      objectType="calcTypes"
      module="condAdmin"
      ref="jsonExporter"
    />
    <DoubletDlg
      ref="doubletDlg"
      :fields="doubletFields"
      :hint="this.$t('condAdmin.calcType.importDoublet')"
    />
    <JsonImporter
      ref="jsonImporter"
      objectType="calcTypes"
      module="condAdmin"
      :preImportCheck="preImportCheckFunction"
      @import="importData"
      :show="false"
    />
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';
import EditField from '@/common/EditField';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';
import condAdminMixin from './js/condAdminMixin.js';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { refreshCalcTypeList } from './js/condAdminHelper.js';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import DoubletDlg from '@/common/DoubletDlg';

export default {
  name: 'CalcType',
  components: {
    Headline,
    EditField,
    SaveOnLeaveDlg,
    JsonImporter,
    JsonExporter,
    DoubletDlg
  },
  mixins: [ condAdminMixin ],
  data () {
    return {
      workElementList: [],
      isDirty: false,
      doubletFields: [
        { key: 'calcType', text: this.$t('condAdmin.calcType.label'), unique: true, readonly: true },
        { key: 'text', text: this.$t('condAdmin.calcType.text'), unique: false, readonly: true },
        { key: 'mileages', text: this.$t('condAdmin.calcType.mileages'), unique: false, readonly: true },
        { key: 'durations', text: this.$t('condAdmin.calcType.durations'), unique: false, readonly: true },
        { key: 'interestScale', text: this.$t('condAdmin.calcType.interestScale'), unique: false, readonly: true }
      ]
    };
  },
  computed: {
  },
  watch: {
    '$store.state.condAdmin.calcTypeList': function () {
      this.mergeLists();
    }
  },
  mounted () {
    if (this.hasUserRight('manageBaseData') === true) {
      this.getDefaultCalcTypeList();
    } else {
      for (let e of this.$store.state.condAdmin.calcTypeList) {
        let workElement = {
          active: true,
          defaults: e,
          calcType: e.calcType,
          custom: e,
          inherited: e.inherited
        };
        this.workElementList.push(workElement);
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    next();
  },
  methods: {
    async getCalcTypeList () {
      await refreshCalcTypeList();
      this.isDirty = false;
    },
    isEditable (element) {
      return this.getOrgSetting('createOwnData') === true && element.inherited !== true;
    },
    mergeLists () {
      for (let workElement of this.workElementList) {
        let custom = this.$store.state.condAdmin.calcTypeList.find((ct) => ct.calcType === workElement.defaults.calcType);
        console.log('merging', JSON.stringify(custom));
        if (custom != null) {
          workElement.custom = JSON.parse(JSON.stringify(custom));
          workElement.active = workElement.custom.isActive;
          workElement.inherited = workElement.custom.inherited;
        } else {
          workElement.custom = null;
          workElement.active = false;
          workElement.inherited = false;
        }
      }
    },
    getDefaultCalcTypeList () {
      this.workElementList = [];
      this.$restClient.callProcess('condAdmin', 'getCalcTypeDefaultList', {}, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            for (let ct of processData.ioData.calcTypeList) {
              let workElement = {
                active: false,
                defaults: ct,
                calcType: ct.calcType,
                custom: null,
                inherited: false
              };
              this.workElementList.push(workElement);
            }
            this.mergeLists();
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        }
      );
    },
    enable (element, enable) {
      if (enable && element.custom == null) {
        this.setDefaults(element);
      }
      this.setDirty();
      element.active = enable;
      element.custom.isActive = enable;
    },
    async setDefaults (element) {
      if (await this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.calcType.dlghReset'), this.$t('condAdmin.calcType.dlgtReset'), this.$t('base.yes')) === false) {
        return false;
      }
      element.custom = JSON.parse(JSON.stringify(element.defaults));
      this.setDirty();
      delete element.custom.uid;
    },
    removeDuration (element, v) {
      let index = element.custom.settings.durations.indexOf(v);
      if (index > -1) {
        element.custom.settings.durations.splice(index, 1);
        this.setDirty();
      }
    },
    changeDuration (element, oldValue, newValue) {
      let index = element.custom.settings.durations.indexOf(oldValue);
      if (index > -1) {
        element.custom.settings.durations[index] = newValue;
        this.setDirty();
        element.custom.settings.durations = [...new Set(element.custom.settings.durations.sort((a, b) => { return a - b; }))];
      }
    },
    removeMileage (element, v) {
      let index = element.custom.settings.mileages.indexOf(v);
      if (index > -1) {
        element.custom.settings.mileages.splice(index, 1);
        this.setDirty();
      }
    },
    changeMileage (element, oldValue, newValue) {
      let index = element.custom.settings.mileages.indexOf(oldValue);
      if (index > -1) {
        element.custom.settings.mileages[index] = newValue;
        this.setDirty();
        element.custom.settings.mileages = [...new Set(element.custom.settings.mileages.sort((a, b) => { return a - b; }))];
      }
    },
    removeInterest (element, v) {
      let index = element.custom.settings.interestScale.indexOf(v);
      if (index > -1) {
        element.custom.settings.interestScale.splice(index, 1);
        this.setDirty();
      }
    },
    changeInterest (element, oldValue, newValue) {
      let index = element.custom.settings.interestScale.indexOf(oldValue);
      if (index > -1) {
        element.custom.settings.interestScale[index] = newValue;
        this.setDirty();
        element.custom.settings.interestScale = [...new Set(element.custom.settings.interestScale.sort((a, b) => { return a - b; }))];
      }
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    },
    save () {
      const ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        calcTypeList: []
      };

      for (let workElement of this.workElementList) {
        if (workElement.active === true) {
          ioData.calcTypeList.push(workElement.custom);
        }
      }

      this.$restClient.callProcess('condAdmin', 'updateCalcTypeList', ioData, true).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$store.state.condAdmin.calcTypeList = ioData.calcTypeList;
            this.isDirty = false;
          }
          this.$globals.Info.open(processData.processState.messages, true);
        }
      );
    },
    exportData () {
      let calcTypeList = [];
      for (let workElement of this.workElementList) {
        if (workElement.active === true) {
          let e = JSON.parse(JSON.stringify(workElement.custom));
          delete e.base;
          delete e.uid;
          delete e.isActive;
          calcTypeList.push(e);
        }
      }
      this.$refs.jsonExporter.exportJsonData({ calcTypeList });
    },
    startImport () {
      this.$refs.jsonImporter.startImport();
    },
    async importData (json) {
      await refreshCalcTypeList();
      let imported = 0;
      let total = json.data.calcTypeList.length;

      for (let imp of json.data.calcTypeList) {
        // check already existing dealerKey
        let existing = this.workElementList.find((i) => i.calcType === imp.calcType && i.active === true && i.custom != null);
        if (existing != null) {
          // build temporay calcTypeElements to be displayed in doubletDlg
          let impTmp = this.createDoubletObject(imp);
          let exTmp = this.createDoubletObject(existing.custom);

          let action = await this.$refs.doubletDlg.showSimple(exTmp, impTmp);
          switch (action) {
            case 'overwrite':
              let workElement = this.workElementList.find((i) => i.calcType === imp.calcType);
              if (workElement.custom != null && workElement.custom.uid != null) {
                imp.uid = workElement.custom.uid;
              }
              imp.base = workElement.custom.base;
              imp.isActive = true;
              workElement.active = true;
              workElement.custom = imp;
              // console.log('imported', JSON.stringify(imp));
              imported++;
              break;
            case 'skipNew':
            default:
              break;
          }
        } else {
          let workElement = this.workElementList.find((i) => i.calcType === imp.calcType);
          if (workElement.custom != null && workElement.custom.uid != null) {
            imp.uid = workElement.custom.uid;
          }
          imp.base = workElement.default.base;
          imp.isActive = true;
          workElement.active = true;
          workElement.custom = imp;
          // console.log('imported', JSON.stringify(imp));
          imported++;
        }
      }
      await this.save();
      if (imported > 0) refreshCalcTypeList();
      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
    },
    preImportCheckFunction (json) {
      if (json.data.calcTypeList == null || json.data.calcTypeList.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.calcType.noImportData')) ]);
        return false;
      }
      return true;
    },
    createDoubletObject (ct) {
      let o = {
        calcType: ct.calcType,
        text: ct.text,
        mileages: '',
        durations: '',
        interestScale: ''
      };
      if (ct.settings.mileages != null) {
        for (let m of ct.settings.mileages) o.mileages += ', ' + m;
        o.mileages = o.mileages.substring(2);
      }
      if (ct.settings.durations != null) {
        for (let d of ct.settings.durations) o.durations += ', ' + d;
        o.durations = o.durations.substring(2);
      }
      if (ct.settings.interestScale != null) {
        for (let i of ct.settings.interestScale) o.interestScale += ', ' + this.$filters.formatNumber(i, null, this.NULLVALUE);
        o.interestScale = o.interestScale.substring(2);
      }
      return o;
    }
  }
};
</script>

<style scoped lang="scss">
.activeCalcType {
  border: 1px solid var(--v-grey-lighten2);
  border-left: 5px solid var(--v-success-base);
}
.inactiveCalcType {
  border: 1px solid var(--v-grey-lighten2);
  border-left: 5px solid var(--v-grey-lighten2);
}
</style>
