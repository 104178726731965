<template>
  <v-card flat tile v-if="loaded">
    <v-card-text class="pa-0">
      <!-- #### Produkt-Ein-/Ausschlüsse #### -->
      <v-row class="grey lighten-5">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.condition.products') }}</span>
        </v-col>
      </v-row>
      <v-row class="grey lighten-5">
        <v-col>
          <v-data-table :headers="productHeaders" :items="condition.products" fixed-header sort-by="brand"
            hide-default-footer class="grey lighten-5">
            <template v-for="header in productHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>
            <template v-slot:item.productType="{ item }">
              {{ ($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid)) == null ?
                item.productTypeUid : ($store.state.condAdmin.productTypeList.find((p) => p.uid ===
                  item.productTypeUid)).name }}
              <v-icon
                v-if="($store.state.condAdmin.productTypeList.find((p) => p.uid === item.productTypeUid && p.state === 10)) != null"
                v-tooltip="$tooltip($t('condAdmin.productType.disabled'))">mdi-alert-outline</v-icon>
            </template>
            <template v-slot:item.subProductType="{ item }">
              {{ getSubProductTypesText([item.subProductTypeUid]) }}
              <v-icon v-if="getSubProductTypeState(item.subProductTypeUid) === 10"
                v-tooltip="$tooltip($t('condAdmin.productType.subProductTypeDisabled'))">mdi-alert-outline</v-icon>
            </template>
            <template v-slot:item.isInclude="{ item }">
              <v-chip :class="inExcludeModes[item.isInclude].class">
                {{ item.isInclude ? $t('condAdmin.isInclude.include') : $t('condAdmin.isInclude.exclude') }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- #### Betragsbänder #### -->
      <v-row>
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.condition.interestLines') }}</span>
        </v-col>
      </v-row>
      <v-row style="overflow-x: auto; overflow-y: hidden;">
        <v-col>
          <table class="text-center conditionTable v-data-table" cellspacing=0 cellpadding=0>
            <tr>
              <th class="text-center firstRow first px-2">{{ $t('condAdmin.condition.credit') }}</th>
              <th class="py-2 px-4 firstRow" v-for="(il) in condition.interestLines" :key="il.index">
                {{ getRangeText(il.minCredit, il.maxCredit, currency, 2) }}
              </th>
              <td class="noBorder"></td>

              <th class="px-4 first firstRow text-left"
                v-if="condition.additionalParams.residualValues != null && condition.calcType !== 'CC'">
                {{ $t('condAdmin.condition.rvMaxRaise') + ': ' +
                  formatValue($filters.formatNumber(condition.additionalParams.rvMaxRaise, null, NULLVALUE), '%-Pkt')
                }}<br />
                {{ $t('condAdmin.condition.rvMaxLowering') + ': ' +
                  formatValue($filters.formatNumber(condition.additionalParams.rvMaxLowering, null, NULLVALUE), '%-Pkt') }}

              </th>
            </tr>
            <tr>
              <th class="first greyRow">{{ $t('condAdmin.condition.duration') }}</th>
              <th :colspan="condition.interestLines.length" class="py-2 greyRow">
                {{ condition.interestMode === 'factors' ? $t('condAdmin.condition.leasingFactors') :
                  $t('condAdmin.condition.interestRate') }}
              </th>
              <td v-if="condition.additionalParams.residualValues != null" class="pl-8 noBorder"></td>
              <th class="first greyRow px-4" v-if="condition.additionalParams.residualValues != null">
                {{ isLeasing(condition.calcType) ? $t('condAdmin.condition.residualValue') :
                  $t('condAdmin.condition.finalPayment') }}
              </th>
            </tr>
            <tr v-for="(d) in durationList" :key="d">
              <td class="first">{{ d }}</td>
              <td v-for="(il) in condition.interestLines" :key="il.index" class="px-4" style="min-width: 150px;">
                <v-row class="align-center py-2">
                  <v-col>
                    <v-text-field v-if="condition.interestMode === 'factors'"
                      :label="$t('condAdmin.condition.plusFactor')"
                      :value="$filters.formatNumber(getAttributeValue('+', il.index, d), null, NULLVALUE)" readonly />
                    <v-text-field v-if="condition.interestMode === 'factors'"
                      :label="$t('condAdmin.condition.minusFactor')"
                      :value="$filters.formatNumber(getAttributeValue('-', il.index, d))" readonly />
                    <span
                      v-else-if="(useInterestType() === false || condition.additionalParams.interestType.name != 'Aktion')">{{
                        formatValue($filters.formatNumber(getAttributeValue('%', il.index, d), null, NULLVALUE),
                          '%') }}</span>
                    <span v-else>
                      {{ formatValue($filters.formatNumber(getAttributeValue('%', il.index, d), null, NULLVALUE), '%') }}
                      <v-chip v-if="getInterestTypeAttribute(il.index, d) != null"
                        :outlined="!getInterestTypeAttribute(il.index, d)"
                        :class="getInterestTypeClass(condition.additionalParams.interestType, false, getInterestTypeAttribute(il.index, d))">
                        {{ getInterestTypeText(condition.additionalParams.interestType, true) }}
                      </v-chip>

                    </span>
                  </v-col>
                </v-row>
              </td>
              <td class="noBorder"></td>
              <td v-if="condition.additionalParams.residualValues != null && condition.calcType !== 'CC'"
                class="first px-4">
                <span>{{ formatValue($filters.formatNumber(getResidualValue(d), null, NULLVALUE), '%') }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <!-- #### Provisionen #### -->
      <v-row class="grey lighten-5" v-if="$store.state.condAdmin.orgSettings.useInterestScale === true">
        <v-col>
          <span class="pt-2 text-h6">{{ $t('condAdmin.condition.commissions') }}</span>
        </v-col>
      </v-row>
      <v-row v-show="$store.state.condAdmin.orgSettings.useInterestScale === true"
        v-for="(comTable, index) in condition.commissionParams" :key="comIndex * index" class="grey lighten-5">
        <v-col>
          <v-card flat tile>
            <v-card-text class="pl-0 pb-0">
              <v-row no-gutters>
                <v-col cols="12" lg="5" md="5" sm="12" xs="12">
                  <v-row no-gutters>
                    <v-btn icon color="secondary" @click="toggleExpandCommissions(comTable.uid, !comTable.expand)">
                      <v-icon>{{ comTable.expand === true ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    <v-text-field :value="$t('condAdmin.condition.commissionType.' + comTable.name)"
                      :label="$t('condAdmin.condition.commissionType.label') + $t('base.mandatory')" readonly dense
                      class="ml-1 mr-6" :class="comTable.isSubsidy ? 'subsidyField' : ''" />
                  </v-row>
                </v-col>
                <v-col cols="12" lg="5" md="5" sm="10" xs="10">
                  <v-select :value="comTable.mode" :label="$t('condAdmin.condition.commissionMode.label')"
                    :items="commissionModes" readonly item-text="text" item-value="value" hide-details dense />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="comTable.expand === true" class="pl-6 pt-0">
              <component v-bind:is="customComponents.ConditionCommissionSettings" ref="customConditionsCommissionSettings"
                :filter="comTable.filter || {}" :commissionUid="comTable.uid"
                :objectKey="condition.clientKey + '/' + condition.uid + '_v' + condition.version" :readonly="true"
                v-show="customComponents.ConditionCommissionSettings != null"></component>
              <v-row v-for="(interestLine) in condition.interestLines" :key="interestLine.index">
                <v-col class="pl-7">
                  <v-row no-gutters>
                    <span class="body-1 pb-1">{{ $t('condAdmin.condition.credit') + ': ' +
                      getRangeText(interestLine.minCredit, interestLine.maxCredit, currency) }}</span>
                  </v-row>
                  <v-row no-gutters style="overflow-x: auto; overflow-y: hidden;">
                    <table class="text-center conditionTable mb-2" cellspacing=0 cellpadding=0>
                      <tr>
                        <th class="first greyRow px-4">{{ $t('condAdmin.condition.duration') }}</th>
                        <th class="py-2 greyRow">
                          {{ $t('condAdmin.condition.bestInterest') }}
                        </th>
                        <th v-for="(interest) in interestScaleList" :key="interest" class="pa-2 greyRow">
                          {{ formatValue($filters.formatNumber(interest, null, NULLVALUE), '%') }}
                        </th>
                      </tr>
                      <tr v-for="(duration) in getDurationListForCommission(interestLine, condition.attributes)"
                        :setTmp="bestInterest = getAttributeValue('%', interestLine.index, duration)" :key="duration">
                        <td class="first" :class="duration === maxDuration ? 'lastRow' : ''">{{ duration }}</td>
                        <td class="py-0 px-4" :class="duration === maxDuration ? 'lastRow' : ''"
                          style="min-width: 100px;">
                          {{ formatValue(getCommissionValue(comTable, interestLine.index, duration, null,
                            interestLine.minCredit, interestLine.maxCredit)) }}
                        </td>
                        <td v-for="(interest) in interestScaleList" :key="interest" class="py-0 px-4"
                          :class="(duration === maxDuration ? 'lastRow ' : ' ') + (comTable.isSubsidy ? 'error--text' : '')"
                          style="min-width: 100px;">
                          {{ formatValue(getCommissionValue(comTable, interestLine.index, duration, interest,
                            interestLine.minCredit, interestLine.maxCredit)) }}
                        </td>
                      </tr>
                    </table>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- #### Konditionsschlüssel #### -->
      <v-row>
        <v-col class="pb-0">
          <component v-show="customComponents.ConditionKeyID != null && condition.conditionKeyFix === false"
            v-bind:is="customComponents.ConditionKeyID" ref="customConditionId" :attributes="condition.attributes"
            :interestLines="condition.interestLines" :products="condition.products" :durationList="durationList"
            :conditionKeyParams="condition.conditionKeyParams === null ? {} : JSON.parse(JSON.stringify(condition.conditionKeyParams))"
            :readonly="true" :clearable="false"></component>
        </v-col>
      </v-row>

      <!-- #### Konditions-Einstellungen #### -->
      <v-row class="">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('base.settings') }}</span>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.durations')"
            :value="condition.durations.join(', ') + ' ' + $t('base.months')" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.credit')"
            :value="getRangeText(condition.minCredit, condition.maxCredit, currency, 2)" />
        </v-col>
      </v-row>
      <v-row class="">
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.interestMode.label')"
            :value="getInterestModeText(condition.interestMode)" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.commissionRetention')"
            :value="formatValue($filters.formatNumber(condition.additionalParams.commissionRetention, null, NULLVALUE), currency)" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.add.billingPeriod')"
            :value="DateTimeHelper.formatDateTime(condition.additionalParams.billingPeriod, NULLVALUE)" />
        </v-col>
      </v-row>
      <v-row class="">
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.deposit')"
            :value="getRangeText(condition.minDepositPercent, condition.maxDepositPercent, '%')" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.minRate')"
            :value="formatValue(condition.minRate, currency)" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field readonly dense :label="$t('condAdmin.condition.vehicleAge')"
            :value="getRangeText(condition.minVehicleAge, condition.maxVehicleAge, $t('base.months'))" />
        </v-col>
      </v-row>
      <v-row class="">
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-text-field v-if="condition.calcType != 'CC'" readonly dense :label="$t('condAdmin.condition.maxMileage')"
            :value="formatValue(condition.maxMileage, 'km')" />
        </v-col>
        <v-col lg="4" md="4" sm="6" xs="6">
          <v-row v-if="condition.calcType != 'CC' && condition.calcType != 'BC'">
            <v-col>
              <v-text-field readonly dense :label="$t('condAdmin.condition.add.addKmCharge')"
                :value="formatValue($filters.formatNumber(condition.additionalParams.addKmCharge, null, NULLVALUE), currencyCent)" />
            </v-col>
            <v-col>
              <v-text-field readonly dense :label="$t('condAdmin.condition.add.lowKmCharge')"
                :value="formatValue($filters.formatNumber(condition.additionalParams.lowKmCharge, null, NULLVALUE), currencyCent)" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- #### Händler Ein-/Ausschlüsse #### -->
      <v-row class="grey lighten-5">
        <v-col class="pb-0 text-h6">
          <span>{{ $t('condAdmin.condition.dealers') }}</span>
        </v-col>
      </v-row>
      <v-row class="grey lighten-5" v-if="$store.state.condAdmin.orgSettings.useInternalDealerList !== false">
        <v-col>
          <v-data-table :headers="dealerHeaders" :items="condition.dealers" fixed-header sort-by="dealerId"
            hide-default-footer>
            <template v-for="header in dealerHeaders.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.isInclude`]="{ item }">
              <v-chip :class="inExcludeModes[item.isInclude].class">
                {{ item.isInclude ? $t('condAdmin.isInclude.include') : $t('condAdmin.isInclude.exclude') }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <component class="pb-4 grey lighten-5" v-bind:is="customComponents.ConditionDealerSettings"
        ref="customConditionsDealerSettings" :params="condition.additionalParams || {}" :readonly="true"
        v-show="customComponents.ConditionDealerSettings != null"></component>
    </v-card-text>
    <!-- save or cancel editing -->
  </v-card>
</template>

<script lang="js">
import { getCalcTypeDurations } from '../js/condAdminHelper.js';
import condAdminConditionMixin from '../js/condAdminConditionMixin.js';

export default {
  name: 'ConditionVersionDetail',
  components: {
  },
  mixins: [condAdminConditionMixin],
  props: {
    conditionUid: { type: String, required: true },
    conditionVersion: { type: Number, required: true }
  },
  data () {
    return {
      loaded: false,
      comIndex: 1,
      condition: null,
      customComponents: {},
      attributeHeaders: [],
      attributeTable: [],
      dealerHeaders: [
        { width: '170px', text: this.$t('condAdmin.isInclude.label'), value: 'isInclude' },
        { text: this.$t('condAdmin.dealer.dealerKey'), value: 'dealerKey', formatter: this.replaceAsAll },
        { text: this.$t('base.contact.name'), value: 'name', formatter: this.formatValue },
        { text: this.$t('base.contact.city'), value: 'city', formatter: this.formatValue }
      ]
    };
  },
  computed: {
    productHeaders () {
      let ph = [
        { width: '170px', text: this.$t('condAdmin.isInclude.label'), value: 'isInclude' },
        { text: this.$t('condAdmin.product.productType'), value: 'productType', formatter: this.formatValue },
        { text: this.$t('condAdmin.product.subProductType'), value: 'subProductType', formatter: this.formatValue }
      ];

      if (this.hasResidualValuesPart() === true) {
        ph.push({ text: this.$t('condAdmin.product.brand'), value: 'brand', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.productName'), value: 'productName', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.chassis'), value: 'chassis', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.driveType'), value: 'driveType', formatter: this.formatValue });
      }
      return ph;
    },
    durationList () {
      return getCalcTypeDurations(this.condition.calcType);
    },
    interestScaleList () {
      return this.getCalcTypeInterestScale(this.condition.calcType);
    },
    maxDuration () {
      return this.durationList[this.durationList.length - 1];
    }
  },
  mounted () {
    this.init(parseInt(this.conditionVersion));
    // this.init();
  },
  methods: {
    async init (version) {
      this.getCustomComponents();
      // this.condition = this.$store.state.condAdmin.conditionList.find((c) => c.uid === this.conditionUid && c.version === this.conditionVersion);
      this.condition = this.$store.state.condAdmin.conditionList.find((c) => c.uid === this.conditionUid && c.version === version);
      if (this.condition == null || this.condition.interestLines == null) {
        this.loaded = false;
        // this.condition = await this.readCondition(this.conditionUid, this.conditionVersion);
        this.condition = await this.readCondition(this.conditionUid, version);
      }
      this.loaded = true;
    },
    getCustomComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('Condition'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + shortname + '.vue');
        }
      }
    },
    useInterestType () {
      return this.$store.state.condAdmin.orgSettings.useInterestType === true;
    },
    getInterestTypeAttribute (index, duration) {
      let att = this.condition.attributes.find((a) =>
        a.minCredit === this.getInterestLineByIndex(index).minCredit &&
        a.maxCredit === this.getInterestLineByIndex(index).maxCredit &&
        a.duration === duration &&
        a.ilIndex === index &&
        !a.commissionInterest);
      if (att) { return att.interestTypeAttribute; } else { return null; }
    },
    getCalcTypeInterestScale (calcType) {
      let ct = this.$store.state.condAdmin.calcTypeList.find((i) => i.calcType === calcType);
      return ct.settings.interestScale.sort((a, b) => { return a - b; });
    },
    getResidualValue (d) {
      let e = this.condition.additionalParams.residualValues.find((r) => r.duration === d);
      if (e != null) return e.residualValue;
      return null;
    },
    getConditionAttribute (minCredit, maxCredit, duration, ilIndex, interest = null) {
      let att = this.condition.attributes.find((a) =>
        a.minCredit === minCredit &&
        a.maxCredit === maxCredit &&
        a.duration === duration &&
        a.ilIndex === ilIndex &&
        ((interest == null && a.commissionInterest !== true) || a.interestRate === interest)
      );
      return att;
    },
    getAttributeValue (prop, ilIndex, duration) {
      let att = this.getConditionAttribute(
        this.getInterestLineByIndex(ilIndex).minCredit,
        this.getInterestLineByIndex(ilIndex).maxCredit,
        duration,
        ilIndex
      );
      if (att == null) return null;
      switch (prop) {
        case '+': return att.plusFactor;
        case '-': return att.minusFactor;
        default: return att.interestRate;
      }
    },
    getAttributeCommission (att, comTable) {
      return att.commission.find((c) => c.uid === comTable.uid);
    },
    getCommissionValue (comTable, ilIndex, duration, interest, minCredit, maxCredit) {
      let att = this.getConditionAttribute(minCredit, maxCredit, duration, ilIndex, interest);
      if (att == null) return null;
      let com = this.getAttributeCommission(att, comTable);
      if (com == null) return null;
      if (com.value == null) return null;
      return this.$filters.formatNumber(com.value, null, this.NULLVALUE) + (comTable.mode === 'amount' ? ' ' + this.currency : ' %');
    },
    getInterestLineByIndex (ilIndex) {
      return this.condition.interestLines.find((i) => i.index === ilIndex);
    },
    toggleExpandCommissions (uid, v) {
      let com = this.condition.commissionParams.find((e) => e.uid === uid);
      if (com != null) {
        com.expand = v;
        this.comIndex++;
      }
    }
  }
};
</script>

<style scoped>
.conditionTable>>>.v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}

.conditionTable td,
.conditionTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.conditionTable .borderBottom {
  border-right: 0px !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.conditionTable td.noBorder {
  border: 0px !important;
}

.conditionTable td.first,
.conditionTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  min-width: 120px !important;
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
