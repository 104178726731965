<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-plus-minus-variant"
    />
    <router-view
      v-if="$store.state.condAdmin.rvGroupList != null && $store.state.condAdmin.productList != null"
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('condAdmin.rvGroupList.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline';
import { newMessage } from '@/base/js/ProcessDataHelper';
import { refreshProductList } from './js/condAdminHelper.js';
import condAdminRvGroupMixin from './js/condAdminRvGroupMixin.js';

export default {
  name: 'RvGroup',
  mixins: [condAdminRvGroupMixin],
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('condAdmin.rvGroupList.headline')
    };
  },
  async mounted () {
    if (this.$store.state.condAdmin.orgSettings.showResidualValues !== true || (this.$route.path.startsWith('/condAdmin/rvGroup') && !this.hasUserRight('readResidualValues'))) {
      if (this.$store.state.condAdmin.orgSettings.showConditions === true && this.hasUserRight('readCondition')) {
        this.$router.push('/condAdmin/condition');
      } else if (this.$store.state.condAdmin.orgSettings.showBaseData === true && this.hasUserRight('readBaseData')) {
        this.$router.push('/condAdmin/productType');
      } else {
        this.$globals.Info.open([newMessage('userError', this.$t('base.error.mismatchingRights'))]);
      }
      return;
    }
    let promises = [];
    if (this.$store.state.condAdmin.productList == null || this.$store.state.condAdmin.productList.length === 0) {
      promises.push(refreshProductList());
    }
    if (this.$store.state.condAdmin.rvGroupList == null || this.$store.state.condAdmin.rvGroupList.length === 0) {
      promises.push(this.refreshRvGroupList());
    }
    if (promises.length > 0) await Promise.all(promises);
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
      switch (mode) {
        case 'create':
          this.headlineText = this.$t('condAdmin.rvGroupList.tableCreate');
          break;
        case 'edit':
        case 'view':
          this.headlineText = this.$t('condAdmin.rvGroupDetailEditor.headline') + ' ' + headlineParam;
          break;
        default: // 'table':
          this.headlineText = this.$t('condAdmin.rvGroupList.headline');
          break;
      }
    }
  }
};
</script>

<style scoped>
</style>
